import { Box, Checkbox, FormControl, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import FileUploadCohort from "../FileUpload/FileUploadCohort";
import services from "../../Services/patientServices";
import { CircularProgress } from "@mui/material";
import swal from "sweetalert";
import CloseIcon from '@mui/icons-material/Close';
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toLowerCase, toCapitalize } from "../../Utils/stringOprations";
import cohortLabels from "../ComparisonAnalytics/CohortLabels";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  backdrop: {
    zIndex: 500,
    color: "#78cbf2",
  },
}));

const useStylesName = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 10px 7px 10px !important",
    textAlign: "left",
    color: "#666",
    width: "104%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      borderRadius: 20,
      borderColor: "#80bdff",
      backgroundColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  focused: {},
}));

const NameTextField = (props) => {
  const classes = useStylesName();
  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
};

const CreateCohort = ({ patientSets, isFetchings, refreshData }) => {
  const classes = useStyles();
  const { updatePatientSetInfo } = useContext(PatientSetContext);
  const { patient_name, cohort_name } = JSON.parse(localStorage.getItem("user")).user;

  const [cohortName, setCohortName] = useState("");
  const [cohortLabel, setCohortLabel] = useState([]);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openFailureSnack, setOpenFailureSnack] = useState(false);
  const [uploader, setUploader] = useState(undefined);
  const [dataSent, setDataSent] = useState(0);
  const [complete, setComplete] = useState(false);
  const [patientIds, setPatientIds] = useState([]);
  const [notFoundIds, setNotFoundIds] = useState([]);
  const [open, setOpen] = useState(false);

  const startUpload = async () => {
    try {
      const validity = checkValidity({
        cohort_name: cohortName,
        file,
      });
      if (!validity.isValid) {
        swal(validity.message, "", "error");
        return;
      } else {
        setCohortName("");
      }

      setIsFetching(true);

      const response = await services.initiateCohort({
        file_name: file.name,
        content_type: file.type,
      })

      console.log("response => ", response);
      if (response.status === 200) {
        const uploadFile = await axios.put(response.data.presignedUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        })

        if (uploadFile.status === 200) {
          const filteredCohortLabel = cohortLabel.filter((item) => item !== 'All');
          const complete = await services.createCohort({
            cohort_name: cohortName,
            key: response.data.key,
            cohortLabel: filteredCohortLabel.join(","),
          });
          setPatientIds(complete.data.data.duplicateIds);
          if (complete.data) {
            setIsFetching(false);
            setOpenSuccessSnack(true);
            const result = await refreshData();
            const { data } = result.data;
            let newData = data[0];
            // delete newData["care_coordinator_list"];
            newData.selected_date = newData.last_updated.slice(0, 10);
            newData.last_updated = newData.last_updated.slice(0, 10);
            updatePatientSetInfo(newData);
          }
        }
      }
    } catch (error) {
      setIsFetching(false);
      if (Array.isArray(error.response.data.requestId)) setNotFoundIds(error.response.data.requestId);
      else console.log(error.response.data.message);
      setOpen(true);
    } finally {
      setCohortLabel([])
    }
  };

  const cancelUpload = () => {
    if (progress !== 100 && progress !== 0) setOpenFailureSnack(true);
    else if (isFetching) setOpenFailureSnack(true);

    setProgress(0);
    setIsFetching(false);
    if (uploader) {
      uploader.abort();
      setUploader(undefined);
    }
    setFile(null);
    setDataSent(0);
  };

  const checkValidity = (params) => {
    const { cohort_name, file } = params;
    if (
      typeof cohort_name !== "string" ||
      /[0-9]+/g.test(cohort_name) ||
      cohort_name === ""
    ) {
      return { isValid: false, message: `Invalid cohort name.` };
    }
    if (!file || file == null) {
      return { isValid: false, message: "Please choose and upload a file." };
    }
    return { isValid: true };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCohortTypeChange = (e) => {
    const { value } = e.target;
    if (value.includes('All')) {
      const data = value.filter((item) => item !== 'All');   
      if (cohortLabel.includes('All') && data.length < cohortLabels.length) {
        setCohortLabel(data);
      } else {
        setCohortLabel(["All", ...cohortLabels]);
      }
    } else {
      if (cohortLabel.includes('All')) {
        setCohortLabel([])
      } else {
        if (value.length === cohortLabels.length) {
          setCohortLabel(["All", ...cohortLabels]);
        } else {
          setCohortLabel(value);
        }
      }
    }
  }

  return (
    <div className={classes.paper}>
      <div className="patient-create-set-title"> Create New {toCapitalize(cohort_name)} </div>

      <form className={classes.root} noValidate autoComplete="off">
        <div className="mui-textfield-form">
            <label className="form-label">{toCapitalize(cohort_name)} Name *</label>
            <NameTextField
              className="input-field-size"
              defaultValue={cohortName}
              multiline
              variant="filled"
              onChange={(e) => {
                setCohortName(e.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              name="cohortName"
              value={cohortName}
            />
        </div>
        <div className="mui-textfield-form">
          <label className="form-label">{toCapitalize(cohort_name)} Labels</label>
          <FormControl fullWidth sx={{ padding: "0px 15px"}}>
          <Select
            fullWidth
            size="small"
            renderValue={(selected) => (selected.includes("All") ? "All" : selected.join(","))}
            multiple
            onChange={handleCohortTypeChange}
            placeholder="Select Cohort Labels"
            value={cohortLabel}
            MenuProps={{ disableScrollLock: true }}
            sx={{ textAlign: 'left', marginTop: "10px !important", borderRadius: "40px" }}
          >
            <MenuItem value={"All"} dense className={`${classes.menuItem}`}>
              <Checkbox checked={cohortLabel.includes("All")} icon={<CheckBoxOutlineBlankOutlined /> } checkedIcon={<CheckBoxOutlined />} />
              <Typography>All</Typography>
            </MenuItem>
            {cohortLabels.map((item) => (
              <MenuItem key={item} dense value={item} className={classes.menuItem}>                  
                <Checkbox checked={cohortLabel.includes(item)} icon={<CheckBoxOutlineBlankOutlined /> } checkedIcon={<CheckBoxOutlined />}  />
                <Typography>{item}</Typography>
              </MenuItem>
            ))}
            </Select>
          </FormControl>
        </div>            
      </form>
      <FileUploadCohort
        file={file}
        setFile={setFile}
        setProgress={setProgress}
        isFetching={isFetching}
        openSuccessSnack={openSuccessSnack}
        setOpenSuccessSnack={setOpenSuccessSnack}
        openFailureSnack={openFailureSnack}
        setOpenFailureSnack={setOpenFailureSnack}
        dataSent={dataSent}
        setDataSent={setDataSent}
        complete={complete}
        setComplete={setComplete}
        cancelUpload={cancelUpload}
        patientSets={patientSets}
        isFetchings={isFetchings}
        refreshData={refreshData}
        patientIds={patientIds}
      />
      <div className="d-flex flex-row-reverse justify-content-between">
        <button 
            className="export-Btn mt-4"
            onClick={() => {
              startUpload();
            }}
        >
            {isFetching && <><CircularProgress size={14} />&nbsp; &nbsp;Loading... </>}
            {!isFetching && `Create a New ${toCapitalize(cohort_name)}`}  
        </button>
        <div className="mt-4">                         
          <CSVLink
            data={[["ID"]]}
            filename={`sample-csv.csv`}
            className="export-Btn"
            uFEFF={false}
            >
          <span className="table-btn-span">
              <span>Download sample CSV</span>
          </span>
          </CSVLink>       
        </div>
      </div>
      {
        notFoundIds?.length > 0 ? 
          <div className="upload-snack-success"> 
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="success-modal">
                <div className="close-icon"><CloseIcon onClick={() => handleClose()}/></div>
                <div className="add-link" style={{ padding: "10px 30px 30px 30px" }}>
                  <>
                    <div className="not-found-id">
                      <b>
                        Several {toLowerCase(patient_name)} ID(s) provided in the CSV file were not
                        found in the existing data.
                      </b>
                    </div>
                    <br />
                  </>
                  <div className="id-description-container">
                    { notFoundIds.map((ids) => {
                          return <div className="id-description">{ids.ID}</div>;
                        })
                    }
                  </div>
                </div>
              </Box>
            </Modal>
          </div> : null
      }
    </div>
  );
};

export default CreateCohort;
