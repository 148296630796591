import React from "react";
import { useLocation } from "react-router-dom";
import NoDataAvailable from "../../Assets/Images/no_data_available.svg";
import "./Error.scss";

const Error = ({ statusCode: propStatusCode, message: propMessage , ehr = false}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusCode = params.get("statusCode") || propStatusCode || "404";
  const message = params.get("message") || propMessage || "Internal Server Error.";

  return (
    <div className="error-main-container">
      <div className="inner-container">
        <img src={NoDataAvailable} alt=""/>
        { ehr ? <p className="heading1">No data available</p>:<p className="heading1">{statusCode}</p>}
        <p className="heading2">{message}</p>
      </div>
    </div>
  );
};

export default Error;
