import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert
} from "@mui/material";
import ExpandIcon from "../../../Assets/Images/expand-icon.png";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import "../_Dashboard.scss";
import ReactApexChart from "react-apexcharts";
import InsightsAndRecommendationModal from "./Modals/InsightsAndRecommendationsModal";
import { useContext } from "react";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import services from "../../../Services/patientServices";
import { useEffect } from "react";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import { InsightsAndRecommendationSkeleton } from "../../../Components/SkeletonLoader/DashboardSkeleton";

import Spinner from "../../../Components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import './InsightsAndRecommendation.css';

export default function InsightsAndRecommendation() {
  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;
  const history = useNavigate();
  const carolinaOrgGuid = "10a7319f-b662-4c79-85fd-7fc5c9cc1461"
  const Tabs = [
    {
      key: "diag_contrib",
      title: org_guid === carolinaOrgGuid ? "Diagnosis Risk Contributors" : "Diagnostic Risk Contributors",
      riskData: [],
    },
    {
      key: "sdoh_contrib",
      title: "SDoH Risk Contributors",
      riskData: [],
    },
    {
      key: "intervention",
      title: "Interventions",
      riskData: [],
    },
  ];
  const [currentTab, setCurrentTab] = useState("diag_contrib");
  const [openModal, setOpenModal] = useState(false);
  const { patientSetInfo } = useContext(PatientSetContext);
  const [tabs, setTabs] = useState(Tabs);
  const [isFetching, handleIsFetching] = useState(false);


  useEffect(() => {
    if (patientSetInfo) {
    fetchData();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSetInfo]);

  const fetchData = async (type) => {
      handleIsFetching(true);
      const { patient_set_id: pid, selected_date } = patientSetInfo;

      const contributorData = await services.contributions(
        {
          filterBy: {
            topContributors: { diag: 10, sdoh: 10 },
            count: { diag: 0, sdoh: 0 },
            sortBy: {
              diag: {
                id: 2,
                title: "Current member count",
                value: "current_count",
                order: "DESC",
              },
              sdoh: {
                id: 2,
                title: "Current member count",
                value: "current_count",
                order: "DESC",
              },
            },
            contributorType1: {
              value: "diag",
              mnemo: "diag",
              title: "Diagnostic Contributors",
              is_diag: 1,
              is_sdoh: 0,
              dial_title: "Diagnostic Risk Today",
              dial_type: "diag_risk_score",
            },
            contributorType2: {
              value: "sdoh",
              mnemo: "sdohr",
              title: "SDoH Contributors",
              is_diag: 0,
              is_sdoh: 1,
              dial_title: "SDoH Risk Today",
              dial_type: "sdoh_risk_score",
            },
          },
          dataSourceDiag: false,
          dataSourceSdoh: false,
          selected_date,
        },
        pid
      );

      const interventionData = await services.interventions(
        {
          filter: {
            sortBy: "it.cost_delta",
            order: "ASC",
            items: 10,
            countType: "patient_count",
            count: 0,
            dataSource: false,
          },
          selected_date,
        },
        pid
      );

      const updatedTabs = tabs.map((tab) => {
        switch (tab.key) {
          case "diag_contrib":
            tab.riskData = contributorData.data.diag_contrib;
            break;
          case "sdoh_contrib":
            tab.riskData = contributorData.data.sdoh_contrib;
            break;
          case "intervention":
            tab.riskData = interventionData.data.interventions;
            break;
          default:
            break;
        }
        return tab;
      });
      setTabs(updatedTabs);
      handleIsFetching(false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };



  return (
    <React.Fragment>
      <Card sx={{ height: "480px", borderRadius: "15px" }}>
        {(isFetching || !patientSetInfo) ? (
          <InsightsAndRecommendationSkeleton />
        ) : (
          <React.Fragment>
            <CardHeader
              title="What are the top insights for your population?"
              titleTypographyProps={{ fontWeight: 700, fontSize: 20 }}
            />
            <CardContent className="cohort-tabs" sx={{ pb: 0, height: "90%" }}>
              <Grid
                container
                direction={"column"}
                justifyContent={"space-between"}
                columnSpacing={1}
                height={"100%"}
              >
                <Grid item container justifyContent={"space-between"}>
                  <Grid item>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: 700, fontSize: 16 }}
                    >
                      Population Health Insights
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <img src={ExpandIcon} alt="" style={{ right: 0 }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item>
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    <RecommendationTabs
                      handleTabChange={handleTabChange}
                      currentTab={currentTab}
                      tabs={tabs}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Box display="flex" justifyContent="flex-end">
                    <Link
                      onClick={() =>
                        history(
                          currentTab === "diag_contrib" ||
                            currentTab === "sdoh_contrib"
                            ? "/contributors"
                            : "/interventions"
                        )
                      }
                      sx={{ display: "flex", cursor: "pointer" }}
                    >
                      View Details
                      <KeyboardDoubleArrowRightSharpIcon />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </React.Fragment>
        )}
      </Card>
      <InsightsAndRecommendationModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        tabs={tabs}
      />
    </React.Fragment>
  );
}

export const RecommendationTabs = ({
  openModal,
  currentTab,
  handleTabChange,
  tabs,
}) => {
  const [tableData, setTableData] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [currentTabMax, setCurrentTabMax] = useState(0);

  useEffect(() => {
    const tabData = tabs.find((tab) => tab.key === currentTab);
    let riskData = tabData.riskData;
    if (!openModal) {
      riskData = riskData.slice(0, 5);
    }
    const seriesData = riskData.map((data) => {
      if (currentTab === "intervention") {
        setCurrentTabMax(data.max_patient_count);
        return (data.patient_count / data.max_patient_count) * 100;
      }
      setCurrentTabMax(data.max_elig);
      return (data.current_count / data.max_elig) * 100;
    });
    const labels = riskData.map((i) => {
        return i.label.replace(`${i.abbr} - `, "");
    });
    console.log(({labels}));

    setChartSeries(seriesData);
    setLabels(labels);
    setTableData(riskData);
  }, [currentTab, tabs, openModal]);

  const chartOptions = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        offsetY: -10,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "20%",
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#f2f2f2",
          strokeWidth: "97%",
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ["#CEF0FF", "#64D1FE", "#0AA2E0", "#0475A3", "#004967"],
    labels: labels,
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 40,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const v = (val / 100) * currentTabMax;
          return v.toFixed();
        },
      },
    },
  };

  const formatDeltaCost = (cost) => {
    if (cost === 0) {
      cost = +cost;
    } else {
      cost = -cost;
    }
    return cost;
  };

  return (
    <TabContext value={currentTab}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: openModal ? "30px" : 0,
        }}
      >
        <TabList onChange={(e, v) => handleTabChange(e, v)}>
          {tabs.map((tab, index) => (
            <Tab
              sx={{ justifyContent: "flex-end", p: "12px" }}
              label={tab.title}
              value={tab.key}
              key={index}
            />
          ))}
        </TabList>
      </Box>
      {tabs.map((tab,index) => (
        <TabPanel value={tab.key} key={index} sx={{ p: openModal ? 0 : "0 24px" }}>
          {tab.riskData.length ? (
            <Grid container className="apex-radial-chart">
              <Grid item md={6} id="radial-bar-chart">
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="radialBar"
                  height={chartSeries.length > 7 ? 440 : 310}
                />
                <div className={`custom-legend ${openModal ? 'custom-legend-expanded-view' : ''}`}>
                  {labels.map((i) => (
                    <div
                      className={`legend-item ${openModal ? 'legend-item-expanded-view' : ''}`}
                      data-tooltip={i}
                    >
                      <div className="legend-item-wrap">{i}</div>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item md={6} pt={2} className="risk-contributor-list">
                <TableContainer
                  sx={{ maxHeight: openModal ? "330px" : "210px" }}
                >
                  <Table aria-label="simple table" stickyHeader size={"small"}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>
                          {tab.title}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="center">
                          Count
                        </TableCell>
                        {openModal && (
                          <TableCell sx={{ fontWeight: 600 }} align="center">
                            PMPM Cost
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell scope="row">{item.label}</TableCell>
                          <TableCell align="center">
                            {tab.key === "intervention"
                              ? item.patient_count
                              : item.current_count}
                          </TableCell>
                          {openModal && (
                            <TableCell align="center">
                              $
                              {tab.key === "intervention"
                                ? formatDeltaCost(item.cost_delta)
                                : item.pmpm}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ) : (
            <Box height={"260px"}>
              <Alert
                icon={false}
                severity="warning"
                className="my-4 text-center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                No data available.
              </Alert>
            </Box>
          )}
        </TabPanel>
      ))}
    </TabContext>
  );
};
