import React, { useState } from "react";
import "./FileUpload.scss";
import Button from "@mui/material/Button";
import Dropzone from "./Dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Clear";
import ProgressBar from "./ProgressBar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { Uploader } from "../../Utils/upload";
import LockIcon from "@mui/icons-material/Lock";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect } from "react";

const Alert = React.forwardRef((  props,  ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FileUploadWidget = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openFailureSnack, setOpenFailureSnack] = useState(false);
  const [uploader, setUploader] = useState(undefined);
  const [dataSent, setDataSent] = useState(0);
  const [complete, setComplete] = useState(false); 

  const startUpload = () => {
    if (file) {
      setIsFetching(true);
      const uploader = new Uploader({ file, fileName: file.name, setDataSent });
      setUploader(uploader);

      let percentage = undefined;
      uploader
        .onProgress(({ percentage: newPercentage, sent }) => {
          percentage = newPercentage;
          setProgress(percentage);
          setDataSent(sent);
        })
        .onError((error) => {
          cancelUpload();
          console.error("Error in upload => ", error);
        });
      uploader.isComplete(({ complete: done }) => {
        console.log("Is complete => ", done);
        if (done) {
          setFile(null);
          setOpenSuccessSnack(true);
          setIsFetching(false);
          setComplete(true);
        }
      });

      uploader.start();
    }
  };

  useEffect(() => {
    if (!complete) {
      setProgress(0);
      setDataSent(0);
    }
  }, [complete]);

  const cancelUpload = () => {
    if (progress !== 100 && progress !== 0) setOpenFailureSnack(true);
    else if (isFetching) setOpenFailureSnack(true);

    setProgress(0);
    setIsFetching(false);
    if (uploader) {
      uploader.abort();
      setUploader(undefined);
    }
    setFile(null);
    setDataSent(0);
  };

  const handleClose = (callback) => {
    callback(false);
  };

  return (
    <div className="upload-wrapper">
      <div className="upload-title mt-4">Secure Data Upload</div>
      <div className="upload-subtitle">
        Securely upload files to your organization's pre-processing folder.
      </div>
      <div className="upload-limits mt-4">
        <span>Accepted file types: .csv, .zip, .rar and .gz.</span>
        <span>
          <LockIcon />
          Secure
        </span>
      </div>
      <Dropzone setFile={setFile} setComplete={setComplete} />
      <div className="upload-submit">
        <Button
          variant="contained"
          component="label"
          color="primary"
          disabled={isFetching}
          onClick={() => {
            startUpload();
          }}
        >
          Upload
        </Button>
      </div>
      {file ? (
        <div className="upload-preview">
          <div className="upload-preview-flex">
            <div>
              <FolderIcon />
              <span className="ml-1">{file.name}</span>
              <span className="ml-1">
                (
                {(isFetching || complete) && (
                  <span>{(dataSent / (1024 * 1024)).toFixed(2)}MB/</span>
                )}
                {(file.size / (1024 * 1024)).toFixed(2)}MB)
              </span>
            </div>
            <div>
              {complete ? (
                <DoneIcon style={{ cursor: "pointer", color: "#33e033" }} />
              ) : (
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => cancelUpload()}
                />
              )}
            </div>
          </div>
          {(isFetching || complete) && (
            <ProgressBar progress={progress} setProgress={setProgress} />
          )}
        </div>
      ) : null}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSuccessSnack}
          onClose={() => handleClose(setOpenSuccessSnack)}
          sx={{ paddingTop: '20px', paddingLeft: '60px' }}
          autoHideDuration={8000}
        >
          <Alert
            onClose={() => handleClose(setOpenSuccessSnack)}
            severity="success"
            sx={{ width: "100%" }}
          >
            File uploaded successfully.
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openFailureSnack}
          sx={{ paddingTop: '20px', paddingLeft: '60px' }}
          onClose={() => handleClose(setOpenFailureSnack)}
          autoHideDuration={8000}
        >
          <Alert
            onClose={() => handleClose(setOpenFailureSnack)}
            severity="error"
            sx={{ width: "100%" }}
          >
            File upload cancelled.
          </Alert>
        </Snackbar>
    </ div>
  );
};

export default FileUploadWidget;
