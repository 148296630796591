import React from 'react';
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import UpLight from "../../Assets/Images/up-light.png";
import UpDark from "../../Assets/Images/up-dark.png";
import DownLight from "../../Assets/Images/down-light.png";
import DownDark from "../../Assets/Images/down-dark.png";

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
    },
    icon: {
      width: "14px !important",
      height: "14px !important",
      cursor: "pointer"
    },
  }));


const SortButton = ({onClick, name, sortConfig}) => {

    let ascIcon;
    let descIcon;

    let { direction } = sortConfig.find((item) => item.key === name);

    if(direction === 'asc'){
        ascIcon = UpDark;
        descIcon = DownLight;
    }else{
        ascIcon = UpLight;
        descIcon = DownDark;
    }

    const classes = useStyles();

    return (
        <Box className={classes.iconContainer}>
            <img src={ascIcon} alt="" className={classes.icon} onClick={() => onClick(name, 'asc')}/>
            <img src={descIcon} alt="" className={classes.icon} onClick={() => onClick(name, 'desc')}/>
        </Box>
    )
}

export default SortButton