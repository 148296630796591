import React from "react";
import { Tooltip, MenuItem, FormControl, Select, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "200px"
  },
}));

const CoordinatorDropdown = ({ handleCoordinatorChange, options, selected }) => {
  const classes = useStyles();

  return (
    <div className="row filter-dropdown-field-wrapper">
      <div>
        <FormControl className={classes.formControl}>
          <Select
            defaultValue={selected}
            disabled={false}
            variant="standard"
            MenuProps={{disableScrollLock: true}}
          >
            <MenuItem
              value='unassigned'
              onClick={() => {
                handleCoordinatorChange('unassigned');
              }}
            >
              Unassigned
            </MenuItem>
            {options.map((item) => {
              return (
                <MenuItem
                  onClick={() => {
                    handleCoordinatorChange(item);
                  }}
                  value={item.id}
                >
                 {
                    (item.name).length < 25 ? item.name: 
                      <Tooltip
                        title={`${item.name}`}
                        placement="bottom"
                        arrow
                        enterDelay={2000}
                        TransitionComponent={Zoom}
                      ><div>{`${(item.name).slice(0, 25) + "..."} `}</div></Tooltip>
                  }
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default CoordinatorDropdown;
