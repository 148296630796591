import { Box, Grid, Skeleton } from "@mui/material";
import "./SkeletonLoader.scss"

export const PatientComparisionSkeleton = () => {
  return (
    <Grid item lg={12} sm={12} xs={12} md={12} sx={{ height: "100%" }}>
      <div
        className="GraphBg widget-bg-shadow cohortClaimsCosts"
        style={{ height: "100%", width: "100%", backgroundColor: "#fff" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            padding: "30px 20px 40px 20px !important",
          }}
        >
          <Skeleton variant="rectangular" height={25} />
          <Skeleton variant="rectangular" height={25} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Skeleton variant="circular" width={200} height={200} />
            <Skeleton variant="rectangular" width={150} height={250} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="rectangular" width={100} height={25} />
            <Skeleton variant="rectangular" width={100} height={25} />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export const InsightsAndRecommendationSkeleton = () => {
  return (
    <Grid item lg={12} sm={12} xs={12} md={12} sx={{ height: "100%" }}>
      <div
        className="GraphBg widget-bg-shadow cohortClaimsCosts"
        style={{ height: "100%", width: "100%", backgroundColor: "#fff" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            padding: "30px 20px 40px 20px !important",
          }}
        >
          <Skeleton variant="rectangular" height={25} />
          <Skeleton variant="rectangular" height={25} width={200} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="rectangular" height={25} width={100} />
            <Skeleton variant="rectangular" height={25} width={100} />
            <Skeleton variant="rectangular" height={25} width={100} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Skeleton variant="circular" width={200} height={200} />
            <Skeleton variant="rectangular" width={150} height={250} />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export const CohortComparisionSkeleton = () => {
  return (
    <Grid item lg={12} sm={12} xs={12} md={12} sx={{ height: "100%" }}>
      <div
        className="GraphBg widget-bg-shadow cohortClaimsCosts"
        style={{ height: "100%", width: "100%", backgroundColor: "#fff" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            padding: "30px 20px 40px 20px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="rectangular" height={25} width={400} />
            <Skeleton variant="rectangular" height={25} width={300} />
          </Box>
          <Skeleton variant="rectangular" height={1} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="rectangular" height={25} width={200} />
            <Skeleton variant="rectangular" height={25} width={200} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Skeleton variant="rectangular" height={25} width={100} />
            <Skeleton variant="rectangular" height={25} width={100} />
            <Skeleton variant="rectangular" height={25} width={100} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export const GraphSkeleton = () => {
  return (
    <Grid item lg={12} sm={12} xs={12} md={12} sx={{ height: "100%" }}>
      <div
        className="GraphBg widget-bg-shadow cohortClaimsCosts"
        style={{ height: "100%", width: "100%", backgroundColor: "#fff" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "18px",
            flexDirection: "column",
            padding: "30px 20px 40px 20px !important",
          }}
        >
          <Skeleton variant="rectangular" height={25} width={100} />
          <Box sx={{ display: "flex", gap: "30px", alignItems: "flex-end" }}>
            <Skeleton variant="rectangular" height={250} width={50} />
            <Skeleton variant="rectangular" height={200} width={50} />
            <Skeleton variant="rectangular" height={210} width={50} />
            <Skeleton variant="rectangular" height={230} width={50} />
            <Skeleton variant="rectangular" height={250} width={50} />
            <Skeleton variant="rectangular" height={200} width={50} />
            <Skeleton variant="rectangular" height={250} width={50} />
            <Skeleton variant="rectangular" height={200} width={50} />
            <Skeleton variant="rectangular" height={210} width={50} />
            <Skeleton variant="rectangular" height={230} width={50} />
            <Skeleton variant="rectangular" height={250} width={50} />
            <Skeleton variant="rectangular" height={200} width={50} />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export const TabSkeletonLoader1 = () => {
  return (
    <Grid item lg={12} sm={12} xs={12} md={12} sx={{ height: "100%" }}>
      <div
        className="GraphBg widget-bg-shadow skeleton-loader-1"
        style={{ height: "100%", width: "100%", backgroundColor: "#fff" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "25px",
            flexDirection: "column",
            padding: "10px 20px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="rectangular" height={30} width={150} />
            <Skeleton variant="rectangular" height={30} width={150} />
            <Skeleton variant="rectangular" height={30} width={150} />
            <Skeleton variant="rectangular" height={30} width={150} />
            <Skeleton variant="rectangular" height={30} width={150} />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export const TabSkeletonLoader2 = () => {
  return (
    <Grid
      item
      lg={12}
      sm={12}
      xs={12}
      md={12}
      sx={{ paddingTop: "0 !important" ,height: "100%"}}
    >
      <div className="GraphBg skeleton-loader-2">
        <Box
          sx={{
            display: "flex",
            gap: "25px",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "2%" }}>
            {new Array(5).fill(0).map((_, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "18%",
                  border: "1px solid #0000001f",
                  borderRadius: "4px",
                  padding: "20px 10px !important",
                  height: "125px",
                  backgroundColor: "#fff"
                }}
              >
                <Skeleton
                  variant="rectangular"
                  height={15}
                  sx={{ width: "90%" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={20}
                  sx={{ width: "40%" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={15}
                  sx={{ width: "90%" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export const ComparisionTableLoader = () => {
  return (
    <Grid
      item
      lg={12}
      sm={12}
      xs={12}
      md={12}
      className="comparisiontableloader"
    >
        <Box className="inner-container">
          <Box className="header-container">
            <Skeleton
              variant="rectangular"
              height={25}
              sx={{ width: "30%" }}
            />
            <Skeleton
              variant="rectangular"
              height={25}
              sx={{ width: "5%" }}
            />
          </Box>
          <Box className="loader-containerr">
            {new Array(3).fill(0).map((_, index) => (
              <Box key={index} className="skeleton-loader">
                <Skeleton
                  variant="rectangular"
                  height={20}
                  sx={{ width: "20%" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={20}
                  sx={{ width: "20%" }}
                />
                <Skeleton
                  variant="rectangular"
                  height={20}
                  sx={{ width: "20%" }}
                />
              </Box>
            ))}
          </Box>
          <Box className="header-container">
            <Skeleton
              variant="rectangular"
              height={25}
              sx={{ width: "30%" }}
            />
            <Skeleton
              variant="rectangular"
              height={25}
              sx={{ width: "5%" }}
            />
          </Box>
        </Box>
    </Grid>
  );
};
