import React, { useEffect, useState } from "react";
import Alert from "@mui/lab/Alert";
import Spinner from "../../Components/Spinner/Spinner";
import services from "../../Services/patientServices";
import RefreshIcon from "@mui/icons-material/Refresh";
import withRouter from "../../Utils/withRouterHOC";
import Sidebar from "../../Components/Sidebar/Sidebar";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import FileListModal from "./FileListModal";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const DcaPreviousOutput = ()  => {
  const [isFetching, setIsFetching] = useState(true);
  const [previousOutputs, setPreviousOutputs] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    getPreviousOutputs();
  }, []);

  const getPreviousOutputs = async () => {
    try {
      setIsFetching(true);
      const { data } = await services.getPreviousDcaOutput();
      const dca_prev_data = data?.map(({ dca_output, filePath, upload_date }) => {
        const fileNames = dca_output["Input Files"]
          .map(({ filename }) => filename)
        const date = upload_date || dca_output["Uploaded On"];
        return { fileNames, date, dca_output, filePath: filePath };
      });
      setPreviousOutputs(dca_prev_data);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log(error);
    }
  };

  return (
    <Sidebar>
      <div className="patient-set-container m-5">
        <FileListModal show={open} setShow={setOpen} files={selectedFiles} setFiles={setSelectedFiles}/>
    
        <div className="db-table-ar" style={{ minHeight: "350px" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div
            className="patient-create-set-title mb-2"
            style={{ fontWeight: "bold" }}
          >
            Previous Assessments
          </div>
          <div className="upload-home">
            <BackIcon />
            <span onClick={() => history(-1)}>
              Back to Data Content Assessment
            </span>
          </div>
        </div>
        
        <table className="table table-light">
          <thead style={{ borderBottom: "15px solid #FFF" }}>
            <tr>
              <th
                width="50%"
                style={{ backgroundColor: "#0082B2", color: "#FFF" }}
              >
                <div className="headings-wrapper">Input Files</div>
              </th>
              <th
                width="30%"
                style={{ backgroundColor: "#0082B2", color: "#FFF" }}
              >
                <div className="headings-wrapper">Date And Time</div>
              </th>
              <th
                width="20%"
                style={{ backgroundColor: "#0082B2", color: "#FFF" }}
              >
                <div className="headings-wrapper">Output</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <tr>
                <td colSpan="5">
                  <Spinner />
                </td>
              </tr>
            ) : !isFetching && !previousOutputs.length ? (
              <tr>
                <td colSpan="5">
                  <Alert icon={false} severity="warning">
                    There is no previous assessments available.
                  </Alert>
                </td>
              </tr>
            ) : (
              previousOutputs.map((patient, index) => {
                return (
                  <tr key={index}>
                    <td width="50%">
                        {
                          patient.fileNames.length < 4 ?
                          (
                            <div className="d-flex flex-column" style={{ maxHeight: '85px'}}>
                            {patient.fileNames.map((file) => <span>{file}</span>)}
                            </div>
                          ):
                          (
                            <div className="d-flex flex-column" style={{ maxHeight: '85px' }} onClick={() => { setSelectedFiles(patient); setOpen(true) }}>
                              {patient.fileNames.slice(0, 3).map((file) => <span>{file}</span>)}
                              <span style={{ lineHeight: '10px'}}>...</span>
                            </div>
                          )
                        }
                    </td>
                    <td width="35%">
                    {dayjs
                      .utc(patient.date)
                      .tz(dayjs.tz.guess())
                      .format("MM/DD/YYYY HH:mm")
                    }
                    </td>
                    <td width="15%">
                      <button className="export-Btn" onClick={(e) => history(`/data-content-analysis/output/${encodeURIComponent(patient.filePath)}`)}>View Output</button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-row-reverse">
        <div>
          <button
            className="export-Btn"
            onClick={() => {
              getPreviousOutputs();
            }}
          >
            Refresh
            <span className="ps-table-refresh">
              <RefreshIcon />
            </span>
          </button>
        </div>
      </div>
      </div>
      </Sidebar>
  );
};

export default withRouter(DcaPreviousOutput);
