import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import services from "../../../Services/patientServices";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  TabSkeletonLoader2,
} from "../../../Components/SkeletonLoader/DashboardSkeleton";


const CohortOverview = ({ cohort_id, selectedTimeFrame }) => {
  const [overViewData, setOverViewData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { user } = JSON.parse(localStorage.getItem("user"));

  const getCohortOverViewData = async (cohort_id, timeFrame) => {
    try {
      setIsFetching(true);
      const result = await services.cohortOverView({
        cohort_id: cohort_id,
        timeframe: timeFrame
      });
      const data = result.data.data;
      if (data?.length) {
        const filteredData = data.map((cohort) => {
          if (cohort.counts.length) {
            cohort.counts = cohort.counts.filter((i) => i.data.total);
            if (user?.org_guid === "10a7319f-b662-4c79-85fd-7fc5c9cc1461") {
              cohort.counts = cohort.counts.filter(count => count.title !== "Overall Comprehensive Risk Score")
            }
          }
          return cohort;
        });
        setOverViewData(filteredData);
      }
    } catch (error) {
      console.log("Error while fetching cohort overview", error);
    } finally {
      setIsFetching(false);
    }
  };

  const getArrowAndColor = (title, type, changes) => {
    const titles = ['Total Cost', 'PMPM Cost', 'Inpatient Admits', 'ER Visits', 'Overall Comprehensive Risk Score'];
    
    const isSpecialTitle = titles.includes(title);
    
    if (type === "arrow") {
      return changes > 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    } else {
      return changes > 0 
        ? (isSpecialTitle ? "#FA0607" : "#0AB27D") 
        : (isSpecialTitle ? "#0AB27D" : "#FA0607");
    }
  }
  
  const getLgValue = (countsLength) =>{
    if (countsLength > 4) {
      return 2.4;
    } else if (countsLength > 3) {
      return 3;
    } else if (countsLength > 2) {
      return 4;
    } else {
      return 6;
    }
  }
  
  useEffect(() => {
    if(cohort_id){
      getCohortOverViewData(cohort_id, selectedTimeFrame);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohort_id, selectedTimeFrame]);

  if (isFetching) return <TabSkeletonLoader2 />;
  
  return (
    <>
      {overViewData.map(
        (tab, index) =>
          tab.cohort_id === cohort_id && (
            <Grid container spacing={2} justifyContent={"space-between"} key={index}>
              {tab.counts.map((count) => (
                  <Grid
                    item
                    lg={getLgValue(tab.counts.length)}
                    md={6}
                    xs={12}
                  >
                    <Card sx={{ width: "100%", height: 125 }}>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14, lineHeight: 1.5, pb: "2px" }}
                          color="text.secondary"
                        >
                          {count.title}
                        </Typography>
                        <Typography variant="h5" fontWeight={700} component="div">
                          {count.data.total}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={getArrowAndColor(count.title, "color", Number(count.data.changes))}
                          display={"flex"}
                        >
                          {getArrowAndColor(count.title, "arrow", Number(count.data.changes))}
                          {count.data.changes + "%"}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          )
      )}
    </>
  );
};

export default CohortOverview;
