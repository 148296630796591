import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "../GenerateReport.scss";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import BackdropLoader from "../../../Components/Backdrop/Backdrop";
import withRouter from "../../../Utils/withRouterHOC";
import BackButton from "../../../Components/BackButton/BackButton";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import reportServices from "../../../Services/reportServices";
import { formateDateWithTimezone } from "../../../Utils/formatDate";
import { IframeContext } from "../../../Contexts/iframeContext";
import DatePicker2 from "../../../Components/DatePicker/DatePicker2";

const IFGenerateCarePlanReport = ( ) => {
  const [isFetching, setIsFetching] = useState(false);
  const todayDate = new Date();
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [dateError, setDateError] = useState(false);
  // const [patientCoordinator, setpatientCoordinator] = useState("");
  const { iframe } = useContext(IframeContext);

  let history = useNavigate();

  const onStartDateChange = (date) => {
    setStartDate(new Date(date));
  };

  const onEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const compareDate = () => {
    if (endDate < startDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const generateReport = async () => {
    if (dateError) return;
    const todayDate = formateDateWithTimezone(new Date());
    setIsFetching(true);
    const requestBody = {
      end_date: endDate,
      start_date: startDate,
      created_at: todayDate,
    };
    try {
      const response = await reportServices.getCarePlanReportData(
        requestBody,
        iframe
      );
      const { data } = response.data;
      localStorage.setItem("report_data", JSON.stringify(data));
      setIsFetching(false);
      history("/if/report/care-plan-report")
    } catch (error) {
      // Handle error here
      console.error("An error occurred while generating the report:", error);
      setIsFetching(false);
    }
  };

  const getReportFilters = async () => {
    try {
      setIsFetching(true);
      const response = await reportServices.getCarePlanReportFilter(iframe);
      const { data } = response.data;
      const max_date = new Date(data.max_date);
      const min_date = new Date(data.min_date);
      setEndDate(max_date);
      setStartDate(min_date);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getReportFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    compareDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <>
      <Sidebar>
        <div className={`${iframe ? "ccs__iframe__active" : ""}`}>
          <div className="uploadpage app-bg-color DashboardOuter rp-overflow">
            <div className="btn-back">
              <BackButton color={"#fff"} backgroundColor={ iframe ? "#00ab55": "#0082b2"} />
            </div>
            <div className="upload-outer gt-report-box">
              <div className="upload-inner  widget-bg-shadow">
                <div className="report-title">Care Plan Outcomes Summary</div>
                <div className="query-container">
                  <div>
                    <div className="query-label">Start Date:</div>
                    <div className="query-value">
                      <DatePicker2
                        onChange={() => false}
                        onAccept={onStartDateChange}
                        value={startDate}
                        inputFormat="MM/DD/YYYY"
                        className="datepicker"
                        maxDate={endDate}
                        minDate={startDate}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="query-label">End Date:</div>
                    <div className="query-value">
                      <DatePicker2
                        onChange={() => false}
                        onAccept={onEndDateChange}
                        value={endDate}
                        inputFormat="MM/DD/YYYY"
                        className="datepicker"
                        minDate={startDate}
                        maxDate={endDate}
                      />
                      {dateError ? (
                        <div className="error">
                          End Date should be greater than Start Date
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="upload-submit">
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={generateReport}
                    disabled={isFetching}
                  >
                    Generate Report
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
      <BackdropLoader isFetching={isFetching} />
    </>
  );
};

export default withRouter(IFGenerateCarePlanReport);
