import React, { useState, useEffect, useContext } from "react";
import LandingPageHeader from "../../Components/Header/LandingPageHeader";
import { LandingPageFooter } from "../../Components/Header/LandingPageFooter";
import dashlogo from "../../Assets/Images/dashboard-logo.png";
import Admin from "../Admin/Admin";
// import DashboardOverview from "../CohortOverview/DashboardOverview";
import Dashboard from "../Dashboard/_Dashboard";
import "./Home.scss";
import { sidebarContext } from "../../Contexts/sidebarContext";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import { useNavigate } from "react-router-dom";

const Home = ()  => {
  const [userLoggedOut, setUserLoggedOut] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState("");
  const [display, setDisplay] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const { navData } = useContext(sidebarContext);
  const { event } = useContext(AnalyticsContext);
  const history = useNavigate();
  
  useEffect(() => {
    const user_logged_out = localStorage.getItem("user_logged_out");
    setUserLoggedOut(user_logged_out);

    const user = JSON.parse(localStorage.getItem("user"));
    const isLoggedIn = user ? user.isLoggedIn : null;
    if (isLoggedIn) {
      const is_admin = user && user.user ? user.user.is_admin : 0;
      console.log('is admin', is_admin, user);
      setIsAdmin(is_admin === 1 ? true : false);
      setTimeout(() => {
        trackUser();
      }, 500);
    }
    setIsLoggedIn(isLoggedIn);
    
    if(user_logged_out) {
      setStatus();
    };
    setDisplay(true);

    //Redirect to reports page if user is from the Genesis org
    if (user && user.user.org_guid === "b51be224-3f52-4047-8db6-262731693d27") {
      history('/reports');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStatus = () => {
    const logout_status = localStorage.getItem("logout_status");

    //Logout status set to show_inactivity_button, hence inactivity button already shown before
    if(logout_status === "show_inactivity_button") {
      setLogoutStatus("show_login_button");
      localStorage.setItem("logout_status", "show_login_button");
    }

    //Logout status already set to show_login_button, keep it to show_login_button
    else if(logout_status === "show_login_button") {
      setLogoutStatus("show_login_button");
    }

    //Logout status not set yet, user logged out for the first time
    else {
      const time_since_logged_out = localStorage.getItem('time_since_logged_out');
      console.log('time_since_logged_out', time_since_logged_out);
      if(time_since_logged_out && (time_since_logged_out < 120)) {
        setLogoutStatus("show_inactivity_button");
        localStorage.setItem("logout_status", "show_inactivity_button");
      }
      else {
        setLogoutStatus("show_login_button");
        localStorage.setItem("logout_status", "show_login_button");
      }
    } 
  };

  const onClickHandler = () => {
    localStorage.clear();
    window.location.href = window.location.origin + '/signin';
  };

  useEffect(()=>{
      if(navData.length && !isAdmin){
        const summaryLock = navData.find((doc)=>(doc.pageName === "summary" && doc.state === "locked"));
        if(summaryLock){
          history('/overview');
          setIsSummary(false);
        }else{
          setIsSummary(true);
        }
      }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[navData])

  //GA - Track FHS user or Client user Logged in
  const trackUser = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const is_admin = user && user.user ? user.user.is_admin : 0;
    if (is_admin === 1 && user.user.org_guid === "01000000-0000-0000-0000-000000000000") {
      event("fhs_user", { org_name:  user.user.org_name });
    } else if(!localStorage.getItem('aka_session_enabled')) {
      event("client_user", { org_name:  user.user.org_name, admin_user: is_admin === 1 ? 'Yes' : 'No' });
    }
  };

  return (
    <>
      {display && (
        <div>
          {userLoggedOut || !isLoggedIn ? (
            <>
              <LandingPageHeader />
              <div className="container my-5 d-flex justify-content-center">
                <div className="card text-center" style={{ width: "450px" }}>
                  <img
                    src={dashlogo}
                    alt="logo"
                    width={230}
                    style={{ margin: "30px auto 30px auto" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Risk Management App</h5>
                    {logoutStatus === "show_inactivity_button" && (
                      <p className="card-text">
                        For security reasons, you have been logged out due to
                        inactivity. Please login again.
                      </p>
                    )}
                    <button
                      onClick={() => {onClickHandler() }} 
                      className="btn btn-primary mb-3"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              <LandingPageFooter />
            </>
            ) : isAdmin ? <Admin /> : isSummary ? <Dashboard /> :"" //  <DashboardOverview />
          }
        </div>
      )}
    </>
  );
};

export default Home;
