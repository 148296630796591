import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import "../GenerateReport.scss";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import services from "../../../Services/patientServices";
import { toCapitalize } from "../../../Utils/stringOprations";
import BackdropLoader from "../../../Components/Backdrop/Backdrop";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import DatePicker2 from "../../../Components/DatePicker/DatePicker2";

const GenerateCareActivityReport = () => {
  const { patientSetInfo } = useContext(PatientSetContext);
  const { patient_set_id: pid } = patientSetInfo;

  const [isFetching, setIsFetching] = useState(false);
  const [patientCoordinatorList, setPatientCoordinatorList] = useState([]);
  const [patientCoordinator, setPatientCoordinator] = useState("");
  const [cohortList, setCohortList] = useState([]);
  const [cohort, setCohort] = useState("");

  const todayDate = new Date();
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [dateError, setDateError] = useState(false);

  const history = useNavigate();
  
  useEffect(() => {
    try {
      getPatientCoordinator();
      getCohortList();
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    compareDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const getPatientCoordinator = async () => {
    const responseData = await services.patientCoordinators(pid);
    const list = responseData.data.data;
    setPatientCoordinatorList(list);
  };

  const getCohortList = async () => {
    // get only vhc cohorts
    const params = {
      org_guid: "50cc73a6-23c1-49ba-8276-eaf6589c789f",
    };
    const result = await services.patientSets(params);
    const { data } = result.data;
    if (data.length) {
      const { patient_set_id } = data?.filter(
        (element) => element.is_default === "true"
      )[0];
      setCohortList(data);
      setCohort(patient_set_id);
    }
  };

  const onStartDateChange = (date) => {
    setStartDate(new Date(date));
  };

  const onEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const compareDate = () => {
    if (endDate < startDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const onCohortChange = (event) => {
    setCohort(event.target.value);
  };

  const onPatientCoordinatorChange = (event) => {
    setPatientCoordinator(event.target.value);
  };

  const generateReport = async () => {
    if (dateError) return;
    if (!cohort) return;
    setIsFetching(true);
    const body = {
      cohort_id: cohort,
      selected_date: "2021-07-31",
      patient_coordinator_id: patientCoordinator,
      startDate: startDate,
      endDate: endDate,
    };
    const responseData = await services.careActivityReport(body);
    const reportData = responseData.data.data;
    localStorage.setItem("report_data", JSON.stringify(reportData));

    setIsFetching(false);
    history("/if/report/care-activity");
  };

  return (
    <>
      <Sidebar>
        <div className="uploadpage app-bg-color DashboardOuter">
          <div className="upload-outer">
            <div className="upload-inner widget-bg-shadow">
              <div className="report-title">Care Activity Report</div>
              <div className="query-container">
                <div>
                  <div className="query-label">Start Date:</div>
                  <div className="query-value">
                    <DatePicker2
                      onChange={() => false}
                      onAccept={onStartDateChange}
                      value={startDate}
                      inputFormat="MM/DD/YYYY"
                      className="datepicker"
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div>
                  <div className="query-label">End Date:</div>
                  <div className="query-value">
                    <DatePicker2
                      onChange={() => false}
                      onAccept={onEndDateChange}
                      value={endDate}
                      inputFormat="MM/DD/YYYY"
                      className="datepicker"
                      maxDate={new Date()}
                    />
                    {dateError ? (
                      <div className="error">
                        End Date should be greater than Start Date
                      </div>
                    ) : null}
                  </div>
                </div>

                <div>
                  <div className="query-label">
                    {toCapitalize(patientSetInfo.cohort_name)}:
                  </div>
                  <div className="query-value">
                    <FormControl
                      sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                    >
                      <Select
                        value={cohort}
                        onChange={onCohortChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="select-box"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        disabled={isFetching}
                      >
                        {cohortList.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.patient_set_id}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <div className="query-label">
                    {patientSetInfo.patient_name} Coordinator (Optional):
                  </div>
                  <div className="query-value">
                    <FormControl
                      sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                    >
                      <Select
                        value={patientCoordinator}
                        onChange={onPatientCoordinatorChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="select-box"
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        disabled={isFetching}
                      >
                        <MenuItem value="">
                          <em>All</em>
                        </MenuItem>
                        {patientCoordinatorList.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item?.patient_coordinator}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="upload-submit">
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={generateReport}
                >
                  Generate Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
      <BackdropLoader isFetching={isFetching} />
    </>
  );
};

export default GenerateCareActivityReport;
