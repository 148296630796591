import { useContext, useEffect, useState } from "react";
import { Box, Skeleton, Typography, Button, Modal, TextField, IconButton, CircularProgress, Snackbar, Alert } from "@mui/material";
import { WarningAmber } from '@mui/icons-material';
import upArrow from "../../Assets/Images/double-up-arrow.svg";
import downArrow from "../../Assets/Images/double-down-arrow.svg";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import wcchcServices from "../../Services/wcchcServices";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import { useLocation } from "react-router-dom";


const PatientSummaryCard = ({ identifier_id, org_id, showFeedbackIcons, demo }) => {
  const { state } = useContext(WcchcPatientSummaryContext);
  const { patientSummary: statePatientSummary } = state;
  const [expand, setExpand] = useState(false);
  const [summary, setSummary] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const location = useLocation();

  const getUserId = () => {
    const queryParams = new URLSearchParams(location.search);
    const queryUserId = queryParams.get('userId');
  
    // Safely retrieve user from localStorage
    const storedUser = localStorage.getItem("user");
    let userId;
  
    // Check if the userId exists in the query parameters or use the one from localStorage
    if (queryUserId) {
      userId = queryUserId;
    } else if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        userId = parsedUser?.user?.user_id;
      } catch (error) {
        console.error("Failed to parse user data from localStorage:", error);
      }
    }
  
    return userId;
  };

  const userId = getUserId();
  console.log('userId', userId)

  useEffect(() => {
    const text = statePatientSummary?.summary || '';
    setShowMore(text?.length > 400);
    if (!expand) {
      setSummary(text?.length > 400 ? `${text.slice(0, 440)}...` : text);
    } else {
      setSummary(text);
    }
  }, [expand, statePatientSummary]);

  const handleThumbsUp = async () => {
    try {
      setIsSubmitting(true)
      await submitFeedback('positive');
    } catch (error) {
      console.error('Error submitting positive feedback:', error);
    }
  };

  const handleThumbsDown = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFeedbackText('');
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      setOpen(false);
      setFeedbackText('');
      await submitFeedback('negative', feedbackText);
    } catch (error) {
      console.error('Error submitting negative feedback:', error);
    }
  };

  const submitFeedback = async (feedback, text = '') => {
    const payload = {
      feedback,
      text,
      feedbackBy: userId,
      summaryId: statePatientSummary.Summary_id
    };
    if (!demo) {
      const response = await wcchcServices.patientEMRSummaryFeedback(identifier_id, org_id, payload);
      if (response.data) {
        setIsSubmitting(false)
        setShowSuccessAlert(true)
      }
    } else {
      setIsSubmitting(false)
      setShowSuccessAlert(true)
    }
  };

  const hideAlert = () => {
    setShowSuccessAlert(false);
  }

  // if (!propPatientSummary && (!statePatientSummary || statePatientSummary.length === 0)) {
  //   console.log("Inside null condition")
  //   return null;
  // }

  return (
    <>
      <Box className="summary-card">
        <Box className="summary-card-header">
          <Typography className="header-text">Summary</Typography>
        </Box>
        <Box className="summary-card-body">
          {!state.isFetchingSummary ? (
            <>
              <Typography component="span" className="summary-text">
                {summary || 'No Summary Found'}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton height={25} />
              <Skeleton height={25} />
              <Skeleton height={25} width={200} />
            </>
          )}
          <Box
            className="expandable-box"
            component="span"
            onClick={() => setExpand(!expand)}
          >
            {!state.isFetchingSummary && showMore && (
              <>
                <Typography component="span" className="expand-button">
                  Read {expand ? "Less..." : "More..."}
                </Typography>

                {expand ? (
                  <img className="expand-icon" src={upArrow} alt="up-arrow" />
                ) : (
                  <img
                    className="expand-icon"
                    src={downArrow}
                    alt="down-arrow"
                  />
                )}
              </>
            )}
          </Box>
          <Box className="caution-container">
            <div className="caution-container">
              <WarningAmber style={{ fill: "#FD0808", fontSize: '20px' }} />
              <Typography className="disclaimer-text" fontStyle={'italic'} >
                Disclaimer: This summary includes processing with Generative AI and requires human review.
              </Typography>
            </div>
            {showFeedbackIcons && <div className="caution-container" style={{alignItems: 'center'}}>
              {isSubmitting && <CircularProgress size={'20px'} />}
              {/* Thumbs up and down buttons */}
              <IconButton onClick={handleThumbsUp} sx={{
                backgroundColor: '#68EB8DB2', borderRadius: '20%',
                '&:hover': {
                  backgroundColor: '#68DB8D', // Darken the background on hover
                },
              }}>
                <ThumbUpIcon style={{ color: '#fff' }} />
              </IconButton>
              <IconButton onClick={handleThumbsDown} sx={{
                backgroundColor: '#EB6868D8', borderRadius: '20%',
                '&:hover': {
                  backgroundColor: '#EB3868', // Darken the background on hover
                },
              }}>
                <ThumbDownIcon style={{ color: '#fff' }} />
              </IconButton>

              {/* Modal for thumbs-down feedback */}
              <Modal open={open} onClose={handleClose}  >
                <Box sx={modalStyle}>
                  <Typography variant="h6" gutterBottom style={{ paddingBottom: '10px' }} >
                    Provide Additional Feedback
                  </Typography>
                  <TextField
                    fullWidth
                    label="Feedback"
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                  <Box sx={buttonContainerStyle}>
                    <Button variant="outlined" onClick={handleSubmit} sx={buttonStyle}>
                      Skip
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} sx={buttonStyle}>
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </div>}
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessAlert}
        autoHideDuration={6000}
        onClose={hideAlert}
      >
        <Alert
          onClose={hideAlert}
          variant="filled" 
          severity="success"
          sx={{ width: '75%', padding: '0 12px', fontSize: '14px !important'  }}
        >
          Feedback Submitted Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

// Modal styling
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  padding: "18px !important",
};

// Button container styling (to align buttons to the bottom-right corner)
const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 2,
};

// Button styling
const buttonStyle = {
  marginLeft: 2,
  textTransform: 'none',
  padding: '8px 16px',
};

export default PatientSummaryCard;
