import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import format from "../../../Utils/formatNumber";
import { toPluralize } from "../../../Utils/stringOprations";

const PieChart = ({ patients, patient_name }) => {
  const [series, setSeries] = useState([]);

  const [options] = useState({
    chart: {
      height: 230,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          background: "#c9ece5b8",
          strokeWidth: "70%",
          margin: 2,
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            offsetY: -10,
            color: "#008ffb",
          },
          value: {
            show: true,
            offsetY: 8,
            formatter: function (val) {
              return format((val/100) * patients.total);
            },
          },
          total: {
            show: true,
            label: `All-Time ${toPluralize(patient_name)}`,
            color: "#008ffb",
            fontSize: "12px",
            fontWeight: 400,
            formatter: function (w) {
              return format(patients.total);
            },
          },
        },
      },
    },
    fill: {
      colors: ["#A6D9E7", "#A6E7C1", "#a6e7db"],
      type: "solid",
    },
    stroke: {
      lineCap: "butt",
    },
    labels: [`All-Time  ${toPluralize(patient_name)}`, `Active  ${toPluralize(patient_name)}`, `${patient_name} Updates`],
  });

  useEffect(() => {
    const { total, num_updates, active_count } = patients;
    const totalCount = 100; //percent
    const activeCount = total ? (active_count / total) * totalCount : 0;
    const updateCount = total ? (num_updates / total) * totalCount : 0;
    console.log('Pie chart values => ',totalCount, activeCount, updateCount);
    setSeries([totalCount, activeCount, updateCount]);
  }, [patients, series.length]);

  return (
    <div className="donut">
      <Chart options={options} series={series} type="radialBar" height="230" />
    </div>
  );
};

export default PieChart;
