import { useContext } from "react";
import { Box, Typography, Skeleton } from "@mui/material";

import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import formatHeading from "../../Utils/formatHeading";
import ExpandButton from "./ExpandButton";

const PatientDetailsCard = ({ setPatientDetailsModal, demo = false }) => {
  const { state } = useContext(WcchcPatientSummaryContext);

  return (
    <>
      <Box className="patient-details-card">
        <Box className="patient-details-card-header">
          <Typography className="header-text">Patient Details</Typography>
          <Box className="right-container">
            {/* <Box className="last-update-box">
              <Typography className="detail-text" sx={{ marginRight: "3px" }}>
                Last Updated On:
              </Typography>
              <Typography className="detail-text">11/17/2023</Typography>
            </Box> */}
            <ExpandButton
              onClick={() => {
                setPatientDetailsModal(true);
              }}
              disable={state.isFetchingPatientDetails}
            />
          </Box>
        </Box>
        <Box className="patient-details-card-body">
          <Box className="patient-details-cell">
            <Typography className="patient-details-info-cell">
              Patient Name:
            </Typography>
            {state.isFetchingPatientDetails ? (
              <Skeleton
                width={100}
                height={30}
                className="patient-details-skeleton"
              />
            ) : (
              <Typography className="patient-details-info-cell">
                {demo
                  ? "Noah O Cooper"
                  : state.patientDetails.name !== "null null"
                  ? state.patientDetails.name
                  : "--"}
              </Typography>
            )}
          </Box>
          <Box className="patient-details-cell">
            <Typography className="patient-details-info-cell">
              Gender:
            </Typography>
            {state.isFetchingPatientDetails ? (
              <Skeleton
                width={100}
                height={30}
                className="patient-details-skeleton"
              />
            ) : (
              <Typography className="patient-details-info-cell">
                {formatHeading(state.patientDetails.gender)}
              </Typography>
            )}
          </Box>
          <Box className="patient-details-cell">
            <Typography className="patient-details-info-cell">DOB:</Typography>
            {state.isFetchingPatientDetails ? (
              <Skeleton
                width={100}
                height={30}
                className="patient-details-skeleton"
              />
            ) : (
              <Typography className="patient-details-info-cell">
                {demo ? "21/06/1974" : state.patientDetails.dob}
              </Typography>
            )}
          </Box>
          <Box className="patient-details-cell">
            <Typography className="patient-details-info-cell">Age:</Typography>
            {state.isFetchingPatientDetails ? (
              <Skeleton
                width={100}
                height={30}
                className="patient-details-skeleton"
              />
            ) : (
              <Typography className="patient-details-info-cell">
                {demo ? "49" : state.patientDetails.age} Yrs
              </Typography>
            )}
          </Box>
          <Box className="patient-details-cell">
            <Typography className="patient-details-info-cell">
              Payer:
            </Typography>
            {state.isFetchingPatientDetails ? (
              <Skeleton
                width={100}
                height={30}
                className="patient-details-skeleton"
              />
            ) : (
              <Typography className="patient-details-info-cell">
                {demo ? "Regence Blue Shield - MA" : state.patientDetails.payer}
              </Typography>
            )}
          </Box>
          <Box className="patient-details-cell">
            <Typography className="patient-details-info-cell">
              Case Manager:
            </Typography>
            {state.isFetchingPatientDetails ? (
              <Skeleton
                width={100}
                height={30}
                className="patient-details-skeleton"
              />
            ) : (
              <Typography className="patient-details-info-cell">
                {demo ? "Samuel Pierce" : state.patientDetails.care_manager}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PatientDetailsCard;
