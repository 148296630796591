import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ComparisonChart = ({ chartData, selectedChart }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);

  useEffect(() => {
    console.log("Chart data and key: ", chartData, selectedChart);
    const selectedKey = selectedChart.key;

    const formattedData = chartData.map((cohort) => {
      return {
        name: cohort.cohort_name,
        data: cohort[selectedKey] ? Object.values(cohort[selectedKey]).map(item => item).reverse() : [],
      };
    });
    setSeriesData(formattedData);

    const newData = chartData.filter(d => d[selectedKey]).reduce((acc, d) => {
      const keys = Object.keys(d[selectedKey])
      keys.length > acc.length && (acc = keys);
      return acc;
    }, [])

    const labels = newData.length ? newData.map((label) => (`${label}`)).reverse() : [];
    setXLabels(labels);

  }, [chartData, selectedChart]);

  const formatTooltip = (count) => {
    if (count == null || isNaN(Number(count))) return "-";
  
    const type = selectedChart.format;
  
    switch (type) {
      case "comma-separated":
        return Number(count).toLocaleString();
      case "decimal-fixed":
        return Number(count).toFixed(2);
      case "currency-string":
        return "$" + Number(count).toLocaleString();
        case "currency-string-million":
          return "$" + (count / 1000000).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
          "M"
      default:
        return "-";
    }
  };

  const chartOptions = {
    chart: {
      id: "comparison-chart",
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      categories: xLabels,
      tooltip: {
        enabled: false
      },
    },
    yaxis: {
      title: {
        text: selectedChart.title,
      },
      labels: {
        formatter: formatTooltip,
      },
    },
    stroke: {
      width: 3,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.8,
      },
    },
    markers: {
      size: [5, 5],
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      markers: {
        width: 14,
        height: 14,
        radius: 0
      }
    },
    tooltip: {
      y: {
        formatter: formatTooltip, // Format tooltip values as millions
      },
    },
  };

  return (
    <div className="comparison-chart">
      <Chart
        options={chartOptions}
        series={seriesData}
        type="line"
        height={400}
      />
    </div>
  );
};

export default ComparisonChart;