import { Grid, Skeleton } from "@mui/material";

const AnalysisSkeleton = () => {
  return (
    <div className="GraphBg cohortClaimsCosts" style={{ minHeight: "250px" }}>
      {new Array(7).fill(0).map((_, index) => (
        <Grid
          container
          justifyContent={"space-between"}
          key={index}
          spacing={2}
          sx={{ padding: "10px" }}
        >
          <Grid item xs={1.5} sx={{ padding: "0px" }}>
            <Skeleton
              variant="rectangular"
              height={28}
              sx={{ marginRight: "10px", borderRadius: "15px" }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <Skeleton
              variant="rectangular"
              height={28}
              sx={{ marginRight: "10px", borderRadius: "15px" }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <Skeleton
              variant="rectangular"
              height={28}
              sx={{ marginRight: "10px", borderRadius: "15px" }}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default AnalysisSkeleton;
