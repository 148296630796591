import React, { useContext } from "react";
import { Modal, Box, Typography } from "@mui/material";
import MedicationsTable from "../../Tables/WcchcDashboard/MedicationsTable";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";

const useStyles = makeStyles((theme) => ({
  mainModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "900px",
    height: (props) => props.height,
    maxHeight: "95vh",
    maxWidth: "95vw",
    backgroundColor: "#fff",
    outline: "none",
  },
  header: {
    padding: "11px 12px 9px 18px !important",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "20px !important",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.03em",
    color: "#0080b2",
  },
  headerIcon: {
    fill: "#0080b2",
    cursor: "pointer",
  },
  detailsContainer: {
    padding: "0px 20px !important",
    maxHeight: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
  },
}));

const MedicationsModal = ({ open, setMedicationsModal, setAttentive }) => {
  const { state } = useContext(WcchcPatientSummaryContext);
  let height = state.tablesDataLength.medications ? "95vh" : "250px";
  const classes = useStyles({height});
  return (
    <Modal
      open={open}
      onClose={() => setMedicationsModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className={classes.mainModalContainer}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>Medications</Typography>
          <Close
            onClick={() => setMedicationsModal(false)}
            className={classes.headerIcon}
          />
        </Box>
        <Box className={classes.detailsContainer}>
          <MedicationsTable
            maxHeight={520}
            modal={true}
            setAttentive={setAttentive}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default MedicationsModal;
