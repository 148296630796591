import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import "./GenerateReport.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { behavioralConsultantList as list } from "../../Utils/clinicalReportData";
import { toCapitalize } from "../../Utils/stringOprations";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import DatePicker2 from "../../Components/DatePicker/DatePicker2";

const GeneratePatientReport = () => {
  const { patientSetInfo } = useContext(PatientSetContext);

  const [isFetching, setIsFetching] = useState(false);
  const behavioralConsultantList = list;
  const [behavioralConsultant, setbehavioralConsultant] = useState("");
  const [cohortList] = useState([{ name: "Genesis" }]);
  const [cohort, setCohort] = useState("Genesis");
  const todayDate = new Date();
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [dateError, setDateError] = useState(false);

    let history = useNavigate();

    useEffect(() => {
       compareDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

  const onStartDateChange = (date) => {
    setStartDate(new Date(date));
  };

  const onEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const compareDate = () => {
    if (endDate < startDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const onCohortChange = (event) => {
    setCohort(event.target.value);
  };

  const onbehavioralConsultantChange = (event) => {
    setbehavioralConsultant(event.target.value);
  };

  const generateReport = async () => {
    if (dateError) return;
    setIsFetching(true);
    const reportData = {
      selected_date: startDate,
      cohort_name: "Genesis",
      cohort_id: "50cc73a6-23c1-49ba-8276-eaf6589c789f",
      end_date: endDate,
      start_date: startDate,
      user_id: behavioralConsultant,
    };
    localStorage.setItem("clinical_report_data", JSON.stringify(reportData));
    setIsFetching(false);
    history('/report/patient');
  };

  return (
    <Sidebar>
      <div className="uploadpage app-bg-color DashboardOuter">
        <div className="upload-outer">
          <div className="upload-inner widget-bg-shadow">
            <div className="report-title">Generate Report</div>
            <div className="query-container">
              <div>
                <div className="query-label">Start Date:</div>
                <div className="query-value">
                  <DatePicker2
                    onChange={() => false}
                    onAccept={onStartDateChange}
                    value={startDate}
                    inputFormat="MM/DD/YYYY"
                    className="datepicker"
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div>
                <div className="query-label">End Date:</div>
                <div className="query-value">
                  <DatePicker2
                    onChange={() => false}
                    onAccept={onEndDateChange}
                    value={endDate}
                    inputFormat="MM/DD/YYYY"
                    className="datepicker"
                    maxDate={new Date()}
                  />
                  {dateError ? (
                    <div className="error">
                      End Date should be greater than Start Date
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <div className="query-label">
                  {toCapitalize(patientSetInfo.cohort_name)}:
                </div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={cohort}
                      onChange={onCohortChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      disabled={isFetching}
                    >
                      {cohortList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="query-label">
                  {patientSetInfo.patient_name} (Optional):
                </div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={behavioralConsultant}
                      onChange={onbehavioralConsultantChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      disabled={isFetching}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {behavioralConsultantList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="query-label">Clinic site (Optional):</div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={behavioralConsultant}
                      onChange={onbehavioralConsultantChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      disabled={isFetching}
                    >
                      <MenuItem value="">
                        <em>Genesis</em>
                      </MenuItem>
                      {behavioralConsultantList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="upload-submit">
              <Button
                variant="contained"
                component="label"
                color="primary"
                onClick={generateReport}
              >
                Generate Report
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default GeneratePatientReport;
