import React, { useContext } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecentLabsTable from "../Tables/WcchcDashboard/RecentLabsTable";
import DateHeader from "./DateHeader";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    width: "100%",
    height: (props) => props.height,
    overflow: "hidden",
  },
}));

const RecentLabs = (props) => {
  const { state } = useContext(WcchcPatientSummaryContext);
  let height = state.tablesDataLength.recentlabstable ? "329px" : "200px";
  const classes = useStyles({height});

  return (
    <Box 
        className={`${classes.mainContainer} ${props.showBorder ? "selected-border" : ""}`} 
        id="lab-results"
    >
      <DateHeader 
          title="Recent Labs" {...props} 
          disable={state.isFetchingLabResults} 
      />
      <RecentLabsTable 
          maxHeight={220} 
          type={'widget'}
          {...props} 
      />
    </Box>
  );
};

export default RecentLabs;
