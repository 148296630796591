import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import Slider, { SliderThumb } from "@mui/material/Slider";

const PrettoSlider = withStyles({
  root: {
    color: '#88d479 !important',
  },
  thumb: {
    height: "35px !important",
    width: "fit-content !important",
    minWidth: "35px",
    padding: "6px",
    backgroundColor: '#fff !important',
    color: '#000',
    border: '2px solid #88d479',
    fontSize: '11px',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit !important',
    },
  },
  track: {
    height: "26px !important",
    borderRadius: "16px !important",
  },
  rail: {
    height: "26px !important",
    opacity: "0.5 !important",
    backgroundColor: "#bfbfbf !important",
    borderRadius: "14px !important",
  }
})(Slider);

const ThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span>
        {children.props.value}
      </span>
    </SliderThumb>
  );
};

const CustomizedSlider = ({ maxCount, count, setCount }) => {
  const [value, setValue] = useState(0);

  const handleChange = () => {
    setCount(value);
  };

  return (
    <div style={{    
      width: '60px',
      marginBottom: "2px",
      marginLeft: "5px",
    }}>
      <PrettoSlider
        components={{ Thumb: ThumbComponent }}
        aria-label="pretto slider" 
        min={0}
        max={maxCount}
        value={count}
        onChange={(e, v) => setValue(v)}
        onChangeCommitted={handleChange}
      />
    </div>
  );
};
export default CustomizedSlider; 
