import React, { useCallback, useEffect, useRef } from "react";

const IndeterminateInput = ({ id, name, selected, onChange, mnemo = "", component, hit, value }) => {
  
  const el = useRef();

  const onChangeHandler = useCallback(
    (event) => {
      console.log('Selected: ', id, name, selected, mnemo)
      onChange({ id, name, selected: (selected + 1) % 3, mnemo: mnemo });
    },
    [id, name, selected, onChange, mnemo]
  );

  useEffect(() => {
    el.current.checked = selected === 1;
    el.current.indeterminate = selected === 2;
  }, [selected, name]);

  return (
    <>
      <input
        type="checkbox"
        name={name}
        className="filter-checkbox"
        ref={el}
        onChange={onChangeHandler}
      />
      <span onClick={() => { onChangeHandler(); }}> 
        {
          component ? (
            <component.Highlight hit={hit} attribute={'label'} />
          ) : (
            <span>{value}</span>
          )
        }
      </span>
    </>
  );
}

export default IndeterminateInput;