import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import withRouter from "../../Utils/withRouterHOC";
import { useNavigate } from "react-router-dom";

const Hr = styled.hr({
  margin: "0.5rem 0rem",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
};

const FileListModal = ({ show, setShow, files, setFiles }) => {
  const history = useNavigate();

  const handleClose = () => {
    setShow(false);
    setFiles([]);
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={style}>
        <Grid container spacing={1}>
          <Grid
            item
            container
            xs={12}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item xs={8}>
              <Typography variant="h6">Input Files</Typography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="end">
              <IconButton onClick={() => handleClose()}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid
              item
              container
              xs={12}
              display="flex"
              justifyContent="space-between"
              spacing={1}
              padding={1}
              sx={{ maxHeight: "300px", overflowY: "scroll" }}
            >
              {files?.fileNames?.map((file) => (
                <Grid item xs={12} spacing={2}>
                  <Typography variant="body1">{file}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
            <Hr />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              Date And Time:{" "}
              {dayjs
                .utc(files.date)
                .tz(dayjs.tz.guess())
                .format("MM/DD/YYYY HH:mm")}
            </Typography>
            <button
              className="submit-form-btn"
              onClick={(e) =>
                history(
                  `/data-content-analysis/output/${encodeURIComponent(
                    files.filePath
                  )}`
                )
              }
              style={{ width: "150px" }}
            >
              View Output
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default withRouter(FileListModal);
