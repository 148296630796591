import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ProblemsIdentifiedTable from "../../Tables/WcchcDashboard/ProblemsIdentifiedTable";

import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";

const useStyles = makeStyles((theme) => ({
  mainModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "900px",
    height: "95vh",
    backgroundColor: "#fff",
    outline: "none",
    maxHeight: "95vh",
    maxWidth: "95vw",
  },
  header: {
    padding: "11px 12px 9px 18px !important",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "20px !important",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.03em",
    color: "#0080b2",
  },
  headerIcon: {
    fill: "#0080b2 !important",
    cursor: "pointer",
  },
  detailsContainer: {
    padding: "0px 20px !important",
    height: "calc(100% - 65px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  filterContainer: {
    marginBottom: "40px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
  },
  selectInput: {
    border: "1px solid #edebe9",
    padding: "7px 8px 8px 14px !important",
  },
  listItem: {
    width: "360px",
  },
}));

const ProblemsIdentified = ({ open, setProblemsIdentifiedModal }) => {
  const classes = useStyles();

  const { state } = useContext(WcchcPatientSummaryContext);

  const [icdCodeList, setIcdCodeList] = useState([]);
  const [missingHccCodeList, setMissingHccCodeList] = useState([]);
  const [billingIcdCodeListValue, setBillingIcdCodeListValue] = useState("");
  const [missingHccCodeListValue, setMissingHccCodeListValue] = useState("");

  useEffect(() => {
    if (
      state.problemsIdentified.ICD10_codes &&
      state.problemsIdentified.ICD10_codes.length
    ) {
      const icdCodes = [];
      state.problemsIdentified.ICD10_codes.forEach((item) => {
        if (!icdCodes.some((i) => i.code === item?.code) && item.description) {
          icdCodes.push(item);
        }
      });
      setIcdCodeList(icdCodes);
    }
    if (
      state.problemsIdentified.HCC_codes &&
      state.problemsIdentified.HCC_codes.length
    ) {
      const hccCodes = [];
      state.problemsIdentified.HCC_codes.forEach((item) => {
        if (!hccCodes.some((i) => i.code === item?.code) && item.description) {
          hccCodes.push(item);
        }
      });
      setMissingHccCodeList(hccCodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state?.problemsIdentified?.icdCodeList)]);

  return (
    <Modal
      open={open}
      onClose={() => setProblemsIdentifiedModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className={classes.mainModalContainer}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>
            Active Diagnoses
          </Typography>
          <Close
            onClick={() => setProblemsIdentifiedModal(false)}
            className={classes.headerIcon}
          />
        </Box>
        <Box className={classes.detailsContainer}>
          <ProblemsIdentifiedTable modal={true} />
          <Box className={classes.filterContainer}>
            <Select
              className={classes.selectInput}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={billingIcdCodeListValue}
              variant="standard"
              onChange={(e) => setBillingIcdCodeListValue(e.target.value)}
              sx={{ fontFamily: "Lato" }}
            >
              <MenuItem sx={{ fontFamily: "Lato" }} value="">
                <div className={classes.listItem}>
                  <Typography noWrap>Billing ICD Code List</Typography>
                </div>
              </MenuItem>
              {icdCodeList.map((item, index) => (
                <MenuItem
                  sx={{ fontFamily: "Lato" }}
                  value={item?.code}
                  key={index}
                >
                  <Tooltip title={item?.description} placement="top" arrow>
                    <div className={classes.listItem}>
                      <Typography noWrap>
                        {item?.code} {"-"} {item?.description}
                      </Typography>
                    </div>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
            <Select
              className={classes.selectInput}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={missingHccCodeListValue}
              label="Age"
              onChange={(e) => setMissingHccCodeListValue(e.target.value)}
              variant="standard"
              sx={{ fontFamily: "Lato" }}
            >
              <MenuItem sx={{ fontFamily: "Lato" }} value="">
                <div className={classes.listItem}>
                  <Typography noWrap>Missing HCC Code</Typography>
                </div>
              </MenuItem>
              {missingHccCodeList?.map((item, index) => (
                <MenuItem
                  sx={{ fontFamily: "Lato" }}
                  value={item?.code}
                  key={index}
                >
                  <Tooltip title={item?.description} placement="top" arrow>
                    <div className={classes.listItem}>
                      <Typography noWrap>
                        HCC{item?.code} {"-"} {item?.description}
                      </Typography>
                    </div>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProblemsIdentified;
