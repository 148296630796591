import React from "react";

const ProgressBar = ({ value, max }) => {
  let width = ((value * 100) / max)
  return (
    <div className="progressOut">
        <div className="progress">
            <div 
                className="progress-bar bg-success" 
                role="progressbar" 
                aria-valuenow={value} 
                aria-valuemin="0" 
                aria-valuemax={max} 
                style={{width: `${width}%` }}
            >
              {value}
            </div>
        </div>
    </div>
  );
};

export default ProgressBar;
