import React from "react";

const BracketedText = ({ text, className }) => {
  if (text === undefined || text === null) {
    return <span className={className}>{""}</span>; // Return nothing if text is undefined or null
  }

  if (typeof text === "number" || text === 0) {
    return <span className={className}>{text}</span>;
  }
  
  const substrings = text.split(/(\(.*?\))/);
  return (
    <>
      {substrings.map((substring, index) => {
        if (substring.startsWith("(") && substring.endsWith(")")) {
          return (
            <span key={index} style={{ color: "#FF6347", cursor: "pointer" }}>
              {substring}
            </span>
          );
        }
        return (
          <span className={className} key={index}>
            {substring}
          </span>
        );
      })}
    </>
  );
};

export default BracketedText;
