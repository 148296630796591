import React from "react";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { withStyles, makeStyles } from "@mui/styles";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: "20px !important",
    position: "relative",
    backgroundColor: `${theme.palette.background.paper} !important`,
    border: "1px solid #ced4da !important",
    fontSize: "16px !important",
    padding: "5px 26px 5px 12px !important",
    textAlign: "left",
    color: "#666 !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 20,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    "@media (max-width: 1023px)": {
      fontSize: 10
    }
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  formControl: {
    width: (props) => (
      props.type === "count" ? "90px" : "200px"
    ),
    "@media (max-width: 1023px)": {
      width: (props) => (
        props.type === "count" ? "100px" : "160px"
      )
    }
  },
}));

const DropdownRiskFilter = ({ elementId, setData, type, options, className, value }) => {
  const classes = useStyles({ type });

  return (
    <div className="row filter-dropdown-field-wrapper">
      <div>
        <FormControl className={classes.formControl} >
          <Select
            id={elementId}
            role="listbox"
            defaultValue={options[0].id}
            disabled={false}
            input={<BootstrapInput />}
            style={{width: className ? "200px" : "100%"}}
            value={value}
          >
            {options.map((item, index) => {
              return (
                <MenuItem
                role="listbox"
                  onClick={() => {
                    setData(item);
                  }}
                  value={item.id}
                  key={index}
                >
                  {`${item.title}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default DropdownRiskFilter;
