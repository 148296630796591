import React, { createContext, useEffect, useState, useContext } from "react";
import { AuthContext } from "./authContext";
import services from "../Services/patientServices";
import { IframeContext } from "./iframeContext";

const sidebarContext = createContext();

function SidebarProvider(props) {
  const [navData, setNavData] = useState([]);
  const [ geoGraphicAvailable, setGeoGraphicAvailable ] = useState(false);
  const { state } = useContext(AuthContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const genesisOrgsId = ["b51be224-3f52-4047-8db6-262731693d27"];
  const { iframe } = useContext(IframeContext);
  const [initialized, setInitialized] = useState(false);

  const fetchSidebarFeatures = async () => {
    try {
      const response = await services.getSidebarFeatures();
      const { list } = response.data.data;

      const filteredList = list.filter((item) => item.pageName !== "geographic");
      setNavData(filteredList);
      
      const geographicItem = list.find((item) => item.pageName === "geographic");
      setGeoGraphicAvailable(geographicItem && geographicItem.state === "available");
      
      setNavData(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("sidebar context iframe", iframe);
  
    if (iframe) {
      setShowSidebar(false);
      return;
    }
  
    if (!state || !state.user) {
      return;
    }
  
    console.log("state and iframe value in sidebar context", state, iframe);
    const { user = {} } = state;
    const { org_guid = "" } = user;
    const isGenesisOrg = genesisOrgsId.includes(org_guid);
  
    // set sidebar visibility based on org id
    const shouldShowSidebar = !isGenesisOrg;
  
    setShowSidebar(shouldShowSidebar);
  
    if (shouldShowSidebar) {
      fetchSidebarFeatures();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, iframe]);
  

  const setSidebarVisibility = (sidebarVisible) => {
    setShowSidebar(sidebarVisible);
  };

  return (
    <sidebarContext.Provider
      value={{
        showSidebar,
        setSidebarVisibility,
        navData,
        fetchSidebarFeatures,
        geoGraphicAvailable,
        initialized,
        setInitialized
      }}
    >
      {props.children}
    </sidebarContext.Provider>
  );
}

export { sidebarContext, SidebarProvider };
