import React, { useEffect, useState, useContext } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./GenesisIframe.scss";
import { verifyGenesisAccessToken } from "../../Services/genesisServices";
import { AuthContext } from "../../Contexts/authContext";
import CumulativeTrendChart from "./CumulativeTrendChart";
import LearnerTypeProgress from "./LearnerTypeProgress";
import CohortAnalysisChart from "./CohortAnalysisChart";
import LearnerVsClientProgress from "./LearnerVsClientProgress";
import ErrorPage from "./ErrorPage";
import Loader from "../../Components/Genesis/Loader";
import { IframeContext } from "../../Contexts/iframeContext";
import ReportSelection from "../ReportSelection/ReportSelection";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    width: "100%",
    marginBottom: "10px",
    padding: "10px 25px !important",
    backgroundColor: "#e6feff !important",
  },
  tab: {
    textTransform: "none !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    color: "#717274 !important",
    minHeight: "40px !important",
    "&.Mui-selected": {
      backgroundColor: "#1aafd0; !important",
      color: "#fff !important",
      borderBottom: "none",
      textTransform: "none",
      borderRadius: "30px !important",
    },
  },
}));

const GenesisIframe = () => {
  console.log("Genesis Iframe is called");
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [chartType, setChartType] = useState("");
  const [isVerified, setIsVerifed] = useState(false)
  const [childId, setChildId] = useState(0)
  const { dispatch } = useContext(AuthContext);
  const { isGenesisReport, chartName, setChartName, setIsGenesisReport } = useContext(IframeContext);

  const handleOnTabChange = (event, newValue) => {
    setChartName(newValue);
    setIsGenesisReport(false)
  };

  const renderChart = (chartName,childId) => {
    console.log("render chart is called");
    console.log("chartName====>", chartName);
    console.log("Inside render chart",childId);
    if(isGenesisReport){
      return <ReportSelection isGenesisIframe={true}/>
    }
    switch (chartName) {
      case "cumulativetrend":
        return <CumulativeTrendChart clientId={childId}/>;
      case "leanertypeprogress":
        return <LearnerTypeProgress />;
      case "learnervsclient":
        return <LearnerVsClientProgress clientId={childId}/>;
      case "cohortanalysis":
        return <CohortAnalysisChart />;
      case "reports":
        return <ReportSelection isGenesisIframe={true}/>
      default:
        return <h1>NA</h1>;
    }
  };

  useEffect(() => {
    console.log("Useeffect is called");
    if(isGenesisReport){
      setIsVerifed(true);
      setChartName("reports")
      setChartType("overall")
      return
    }

    localStorage.clear();
    let timeoutId;

    const verifyToken = async (token, chartType,clientDetails) => {
      console.log("verify token called");
      console.log("ChartType", chartType);
      console.log("clientDetails", clientDetails);
      try {
        setLoader(true);
        const response = await verifyGenesisAccessToken(token);
        console.log("response", response);
        // console.log("response?.data?.data?.user",response?.data?.data?.user)
        const { isUserVerified } = response?.data?.data;
        if (isUserVerified) {
          dispatch({ type: "GENESIS_LOGIN_SUCCESS" });
          localStorage.setItem("access_token",response?.data?.data?.access_token);
          let user = {user: response?.data?.data?.user}
          localStorage.setItem("user",JSON.stringify(user));
          let chartName =
            chartType === "individual"
              ? "cumulativetrend"
              : "leanertypeprogress";
          setChartType(chartType);
          setChartName(chartName);
          setChildId(clientDetails.ChildId)
          setIsVerifed(true)
        }
      } catch (error) {
        console.error(error);
        setError(true);
        setErrorMessage(
          error?.response?.data?.message || "Unknown error occurred"
        );
      } finally {
        setLoader(false);
      }
    };

    const verifyOrigin = (event) => {
      console.log("data recieved in iframe", event.data);
      if (!event?.data) {
        setError(true);
        setErrorMessage("Access token error!");
        return;
      }
      clearTimeout(timeoutId);
      const validOrigins = [window.config.genesis_frontend_url];
      console.log("validOrigins",validOrigins)
      console.log("Event Origin",event.origin)
      if (!validOrigins.includes(event.origin)) {
        setError(true);
        setErrorMessage("Origin of URL is invalid");
        return;
      }
      let { chartType, Token, Data } = event?.data;
      verifyToken(Token, chartType,Data);
    };

    const addEventListener = () => {
      console.log("Adding event listener");
      window.addEventListener("message", verifyOrigin);

      // Set a timeout to handle the case where the parent does not send a message
      timeoutId = setTimeout(() => {
        setError(true);
        setErrorMessage("Timeout: No message received from the parent");
        removeEventListener();
      }, 30000); // 5 seconds timeout (adjust as needed)
    };

    const removeEventListener = () => {
      console.log("Removing event listener");
      window.removeEventListener("message", verifyOrigin);
      clearTimeout(timeoutId);
    };

    const sendMessageToParent = () => {
      const parentWindow = window.parent;
      console.log("Sending message to parent");
      parentWindow.postMessage(
        {
          ReqType: "GetToken",
        },
        "*"
      );
    };

    addEventListener();
    sendMessageToParent();

    return removeEventListener;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main-iframe-container">
      {loader ? (
        <Loader />
      ) : error ? (
        <ErrorPage errorMessage={errorMessage} />
      ) : isVerified &&  (
          <Box>
            {chartType && chartType === "individual" ? (
              <Tabs
                value={chartName}
                onChange={handleOnTabChange}
                textColor="secondary"
                indicatorColor=""
                aria-label="secondary tabs example"
                className={classes.tabContainer}
              >
                <Tab
                  value="cumulativetrend"
                  label="Cumulative Trend"
                  className={classes.tab}
                  disableRipple
                />
                <Tab
                  value="learnervsclient"
                  label="Learner Vs Client"
                  className={classes.tab}
                  disableRipple
                />
              </Tabs>
            ) : (
              <Tabs
                value={chartName}
                onChange={handleOnTabChange}
                textColor="secondary"
                indicatorColor=""
                aria-label="secondary tabs example"
              >
                <Tab
                  value="leanertypeprogress"
                  label="Learner 1 vs Learner 2"
                  className={classes.tab}
                  disableRipple
                />
                <Tab
                  value="cohortanalysis"
                  label="Cohort Analysis"
                  className={classes.tab}
                  disableRipple
                />
                <Tab
                  value="reports"
                  label="Performance Reports"
                  className={classes.tab}
                  disableRipple
                />
              </Tabs>
            )}
            {chartName && renderChart(chartName,childId)}
          </Box>
      )}
    </div>
  );
};

export default GenesisIframe;
