import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import formatDate from "../../Utils/formatDate";
import "./DateSelect.scss";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

const DateSelect = ({ selectableDates, time, handleDateChange }) => {
  const iconComponent = (props) => {
    return <ExpandMoreRoundedIcon className={props.className} />;
  };

  // moves the menu below the select input
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    disableScrollLock: true,
  };

  return (
    <div className="date-select-container">
      <FormControl>
        <Select
          disableUnderline
          MenuProps={menuProps}
          IconComponent={iconComponent}
          value={time}
          onChange={(e) => {
            handleDateChange(e.target.value);
          }}
          renderValue={(value) => {
            return (
              <span className="date-select-value">
                <CalendarMonthRoundedIcon />
                <span>{value}</span>
              </span>
            );
          }}
          variant="standard"
        >
          <MenuItem value={null} disabled>
            <span style={{ marginTop: 3, textAlign: "left" }}>
              Service Date Up To Including:
            </span>
          </MenuItem>
          {selectableDates.map((item, index) => {
            return (
              <MenuItem selected key={index} value={item}>
                {formatDate(item)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default DateSelect;
