import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import formatHeading from "../../Utils/formatHeading";
import Spinner from "../Spinner/Spinner";

const ListModal = (props) => {
  const { open, setOpen, heading, list = [], headers, isFetching } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: "10001" }}
        className="popup-width"
      >
        <DialogTitle id="alert-dialog-title">{`${heading}`}</DialogTitle>
        {isFetching ? (
          <Spinner />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {headers.map((heading, index) => (
                      <th key={index} scope="col">
                        {formatHeading(heading)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {list.map((element, index) => (
                    <tr key={index}>
                      {headers.map((item, index2) => (
                        <td key={index2}>{element[item]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListModal;
