import React, { useContext, useState } from "react";
import { Modal, Box } from "@mui/material";
import RecentLabsTable from "../../Tables/WcchcDashboard/RecentLabsTable";
import { makeStyles } from "@mui/styles";
import DateHeader from "../../WcchcPatientSummary/DateHeader";
import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";


const useStyles = makeStyles((theme) => ({
  mainModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "900px",
    height: (props) => props.height,
    backgroundColor: "#fff",
    maxHeight: "95vh",
    maxWidth: "95vw",
    outline: "none",
  },
  header: {
    padding: "11px 12px 9px 18px !important",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "20px !important",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.03em",
    color: "#0080b2",
  },
  headerIcon: {
    fill: "#0080b2",
    cursor: "pointer",
  },
  detailsContainer: {
    padding: "0px 20px !important",
    maxHeight: "calc(100% - 90px)",
    overflow: "hidden",
  },
}));

const RecentLabsModal = ({duration, ...props}) => {
  const [recentLabModalDuration, setRecentLabModalDuration] = useState(duration);
  const { state } = useContext(WcchcPatientSummaryContext);
  let height = state.tablesDataLength.recentlabsmodal ? "611px" : "250px";
  const classes = useStyles({height});

  return (
    <Modal
      open={props.open}
      onClose={() => props.handleModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className={classes.mainModalContainer}>
        <DateHeader 
            title="Recent Labs" 
            modal={true} 
            duration={recentLabModalDuration}
            {...props} 
            setDuration={setRecentLabModalDuration}
        />
        <Box className={classes.detailsContainer}>
          <RecentLabsTable 
            maxHeight={520} 
            modal={true} 
            duration={recentLabModalDuration}
            {...props} 
            setDuration={setRecentLabModalDuration}
            type={'modal'}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default RecentLabsModal;
