import { createContext, useState } from "react";
import { Helmet } from "react-helmet";


const MetaDetailsContext = createContext({
    pageTitle: 'FHS ',
    setPageTitle: () => { }
});

const MetaDescriptionProvider = (props) => {
    const [pageTitle, setPageTitle] = useState('FHS ');
    const value = { pageTitle, setPageTitle }
    return (
        <MetaDetailsContext.Provider value={value} >
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {props.children}
        </MetaDetailsContext.Provider>
    );
}

export { MetaDescriptionProvider, MetaDetailsContext } 