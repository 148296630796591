import { useContext, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Popover,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";
import formatHeading from "../../../Utils/formatHeading";

const useStyles = makeStyles((theme) => ({
  mainModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    maxHeight: "95vh",
    maxWidth: "95vw",
    backgroundColor: "#fff",
    outline: "none",
  },
  header: {
    padding: "11px 12px 9px 18px !important",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "20px !important",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.03em",
    color: "#0080b2",
  },
  headerIcon: {
    fill: "#0080b2",
    cursor: "pointer",
  },
  detailsContainer: {
    margin: "20px !important",
    maxHeight: "100%",
    boxSizing: "border-box",
    width: "auto !important",
  },
  detailsGrid: {
    margin: "0px !important",
    padding: "0px !important",
  },
  detailsGrid2: {
    margin: "0px 0px 0px 10px !important",
    padding: "0px !important",
  },
  detailsLabel: {
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "14px !important",
    lineHeight: "29px",
  },
  detailsText: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px !important",
    lineHeight: "29px",
  },
  ul: {
    padding: "10px 50px",
    listStyleType: "disc",
    fontSize: "12px",
    listStylePosition: "inside",
  },
  liText: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "12px !important",
  },
  tableContainer:{
    marginRight: "15px !important"
  },
  table1: {
    "& > tbody > tr > td": {
      borderBottom: "none !important",
      marginTop: "0px !important",
      padding: "5px 0px 7px 0px!important",
      verticalAlign: 'top'
    },
    "& > tbody > tr > td:nth-child(1)": {
      fontFamily: "Lato",
      fontWeight: 600,
      fontSize: "14px !important",
      lineHeight: "18px", 
      width: "45% !important"   
    },
    "& > tbody > tr > td:nth-child(2)": {
      fontFamily: "Lato",
      fontWeight: 600,
      fontSize: "14px !important",
      lineHeight: "18px", 
      width: "5% !important"   
    },
    "& > tbody > tr > td:nth-child(3)": {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "14px !important",
      lineHeight: "18px", 
      width: "50% !important"   
    },
  },
}));

const PatientDetailsModal = ({
  open,
  setPatientDetailsModal,
  demo = false,
}) => {
  const classes = useStyles();

  const { state } = useContext(WcchcPatientSummaryContext);
  const details = state.patientDetails;

  const patientFields = [
    "ID field",
    "Coordinators list",
    "Diagnostic Risk field",
    "SDoH Risk field",
    "Impactability field",
    "Interventions/Services Provided list",
    "Simulated Risk field",
    "Patient Engagement field",
    // "LOB field",
    // "Care Center Location field",
  ];

  return (
    <Modal
      open={open}
      onClose={() => setPatientDetailsModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className={classes.mainModalContainer}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>
            Patient Details
          </Typography>
          <Close
            onClick={() => setPatientDetailsModal(false)}
            className={classes.headerIcon}
          />
        </Box>
        {/* <Box className={classes.detailsContainer}> */}
        <Grid container spacing={3} className={classes.detailsContainer}>
          <Grid item md={4} sm={12} spacing={2} className={classes.detailsGrid}>
            <Grid container spacing={2} className={classes.detailsGrid}>
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table1}>
                  <TableBody>
                        <TableRow>
                          <TableCell>Patient Name</TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>  
                            {demo
                              ? "Noah O Cooper"
                              : details?.name !== "null null"
                              ? details?.name
                              : "--"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Gender</TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>{formatHeading(details.gender)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>DOB</TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>{demo ? "21/06/1974" : details?.dob}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Age</TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>{demo ? "49" : details?.age} Yrs</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Payer</TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>{demo ? "Regence Blue Shield - MA" : details?.payer}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Case Manager</TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>{demo ? "Samuel Pierce" : details?.care_manager}</TableCell>
                        </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item md={8} sm={12} spacing={2} className={classes.detailsGrid}>
            <Grid container spacing={2} className={classes.detailsGrid2}>
              <Grid item md={5} className={classes.detailsGrid}>
                {patientFields.map((label) => (
                  <Typography className={classes.detailsLabel}>
                    {label}
                  </Typography>
                ))}
              </Grid>
              <Grid item md={7} className={classes.detailsGrid}>
                <Typography className={classes.detailsText}>
                  : {demo ? "5609" : details.patient_id}
                </Typography>
                {Coordinators(demo, details.coordinator_list)}
                <Typography className={classes.detailsText}>
                  : {demo ? "1.62" : details.hcc_score}
                </Typography>
                <Typography className={classes.detailsText}>
                  : {demo ? "15.65" : details.sdoh_score}
                </Typography>
                <Typography className={classes.detailsText}>
                  : {demo ? "12.09" : details.impactability}
                </Typography>
                {Interventions(demo, details.intervention_service)}
                <Typography className={classes.detailsText}>
                  : {"Unavailable"}
                </Typography>
                <Typography className={classes.detailsText}>
                  : {details.patient_engagement}
                </Typography>
                {/* <Typography className={classes.detailsText}>
                  : {"--"}
                </Typography>
                <Typography className={classes.detailsText}>
                  : {"--"}
                </Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </Box> */}
      </Box>
    </Modal>
  );
};

const Coordinators = (demo, list) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    if (list.length <= 1) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (demo) {
    return <Typography className={classes.detailsText}>: --</Typography>;
  }

  return (
    <>
      <Typography
        className={classes.detailsText}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {list.length > 1 ? ": Multiple" : `: ${list?.name}`}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
      >
        <ul className={classes.ul}>
          {list.map((coordinator, index) => {
            return (
              <Typography key={index} className={classes.liText} component="li">
                {coordinator?.name}{" "}
              </Typography>
            );
          })}
        </ul>
      </Popover>
    </>
  );
};

const Interventions = (demo, list) => {
  const classes = useStyles();
  if (demo) {
    return <Typography className={classes.detailsText}>: None</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "29px !important",
      }}
    >
      <Typography
        className={classes.detailsText}
        component="div"
        sx={{ paddingBottom: "3px" }}
      >
        :&nbsp;{" "}
      </Typography>
      {list.length ? (
        <Select
          variant="standard"
          value="display"
          MenuProps={{ disableScrollLock: true }}
          sx={{ width: "300px" }}
        >
          <MenuItem style={{ cursor: "pointer" }} value="display" disabled>
            <Typography className={classes.detailsText} component="span">
              {list.length}&nbsp;
            </Typography>
            <Typography className={classes.detailsText} component="span">
              Intervention Types (in
            </Typography>
            <Typography className={classes.detailsText} component="span">
              &nbsp;
              {list.reduce((a, b) => a + b.count, 0)}
              &nbsp;
            </Typography>
            <Typography className={classes.detailsText} component="span">
              Total Service Events)
            </Typography>
          </MenuItem>
          {list.map((item, index) => {
            return (
              <MenuItem style={{ cursor: "pointer" }} key={index}>
                <Typography
                  className={classes.detailsText}
                  component="span"
                >{`${item.label} (${item.count})`}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Typography className={classes.detailsText}>: None</Typography>
      )}
    </Box>
  );
};

export default PatientDetailsModal;
