import { useEffect, useState } from "react";
import { Typography, Paper, Checkbox, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import services from "../../Services/patientServices";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 350,
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowY: "auto",
  },
  checkItem: {
    display: "flex",
    margin: "5px 0px 10px 50px",
  },
  fileName: {
    fontSize: "15px !important", 
    display: "flex",
    alignItems: "center",
  },
  checkText: {
    fontSize: "12px !important",
    margin: "5px 0px 5px 0px",
    lineHeight: "10px",
  },
  checkBox: {
    display: 'flex',
    alignItems: 'start',
  },
}));

const FileList = ({
  isFetching,
  setIsFetching,
  selectedFiles,
  setSelectedFiles,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const [availableFile, setAvailableFile] = useState([]);
  const [checkedValues, setCheckedValues] = useState({});

  useEffect(() => {
    setAvailableFile([]);
    if (
      dayjs(startDate).isValid() &&
      dayjs(endDate).isValid() &&
      startDate &&
      endDate
    ) {
      getOrganizationFiles(startDate.toISOString(), endDate.toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    setSelectedFiles([]);
    if (availableFile.length) {
      const checked = Object.assign(
        ...availableFile.map(({ path }) => ({ [path]: false })),
        {
          All: false,
        }
      );
      setCheckedValues(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFile]);

  const getOrganizationFiles = async (startDate, endDate) => {
    try {
      setIsFetching(true);
      const { data } = await services.getOrgFileList(startDate, endDate);
      setAvailableFile(data?.files);
      setIsFetching(false);
    } catch (error) {
      console.log("Error while fetching file list => ", error.message);
    }
  };

  const handleAllCheck = () => {
    const checkedData = checkedValues;
    const keys = Object.keys(checkedValues);
    if (checkedData["All"] === true) {
      setSelectedFiles([]);
      keys.forEach((element) => {
        if (element === "All") {
          checkedData[element] = false;
        } else {
          checkedData[element] = false;
        }
      });
    } else {
      const fileNames = availableFile.map(({ path }) => path);
      setSelectedFiles(["All", ...fileNames]);
      keys.forEach((element) => {
        if (element === "All") {
          checkedData[element] = true;
        } else {
          checkedData[element] = true;
        }
      });
    }
    setCheckedValues(checkedData);
  };

  const handleFileChange = (e) => {
    if (e.target.name === "All") {
      handleAllCheck();
    } else {
      if (selectedFiles.includes("All")) {
        setSelectedFiles(
          selectedFiles.filter((i) => !["All", e.target.name].includes(i))
        );
        const checkedData = checkedValues;
        checkedData.All = false;
        checkedData[e.target.name] = false;
        setCheckedValues(checkedData);
      } else if (selectedFiles.includes(e.target.name)) {
        setSelectedFiles(
          selectedFiles.filter((value) => value !== e.target.name)
        );
        const checkedData = checkedValues;
        checkedData[e.target.name] = false;
        setCheckedValues(checkedData);
      } else {
        const checkedData = checkedValues;
        if ([...selectedFiles, e.target.name].length === availableFile.length) {
          setSelectedFiles([...selectedFiles, e.target.name, "All"]);
          checkedData["All"] = true;
        } else {
          setSelectedFiles([...selectedFiles, e.target.name]);
        }
        checkedData[e.target.name] = true;
        setCheckedValues(checkedData);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      {isFetching ? (
        <Grid
        item
        xs={12}
        display="flex"
        direction="column"
        justifyContent="center"
        m={2}
      >
        {new Array(7).fill(0).map((_, index) => <Skeleton key={index} variant="rectangular" height={30} sx={{ margin: "7px" }} />)}
      </Grid>
      ) : (
        (!availableFile.length || !startDate || !endDate) ? (
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          m={2}
        >
          <Typography variant="subtitle1">
            No files found. Please select the date range
          </Typography>
        </Grid>
      ) : (
        <>
          <div className={classes.checkItem}>
            <Checkbox
              name={"All"}
              className="dca-checkBox"
              checked={checkedValues["All"] || false}
              onClick={(e) => handleFileChange(e)}
              style={{ marginLeft: "-20px" }}
            />
            <Typography className={classes.fileName} fontSize="30px">
              Select All
            </Typography>
          </div>
          {availableFile?.map((item, index) => (
            <div className={`${classes.checkItem}`} key={index} >
              <div className={classes.checkBox}>

              <Checkbox
                name={item.path}
                className="dca-checkBox"
                checked={checkedValues[item.path] || false}
                onClick={(e) => handleFileChange(e)}
                />
              </div>
              <div>
                
              <Typography className={classes.fileName}>{item.name}</Typography>
          
              <Typography className={classes.checkText}>
                &nbsp;({item.size},&nbsp;
                {dayjs
                  .utc(item.dateUploaded)
                  .tz(dayjs.tz.guess())
                  .format("MM/DD/YYYY HH:mm")}
                )
              </Typography>
              </div>
            </div>
          ))}
        </>
      ))}
    </Paper>
  );
};

export default FileList;
