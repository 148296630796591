import { createContext, useCallback, useState, useReducer, useContext } from "react";
import services from "../Services/patientServices";
import { useNavigate } from "react-router-dom";
let reducer = (info, newInfo) => {
  return { ...info, ...newInfo };
};

const localState = JSON.parse(localStorage.getItem("patient_set"));
const PatientSetContext = createContext();

function PatientSetProvider(props) {
  const [fetchingPatientSet, setFetchingPatientSet] = useState(false);
  const [patientSetInfo, setPatientSetInfo] = useReducer(reducer, localState || null);
  // Dropdown patient set list
  const [patientSets, setPatientSets] = useState([]);
  const [allPatientSets, setAllPatientSets] = useState([]);
  const prevCohortList = JSON.parse(localStorage.getItem("cohort_list_tabs"));
  const [selectedCohorts, setSelectedCohorts] = useState(prevCohortList ?? []);
  const [analysisType, setAnalysisType] = useState('cost_analysis');
  const [totalPatientsInCohort, setTotalPatientsInCohort] = useState(JSON.parse(localStorage.getItem('total_patient_count')))
  const history = useNavigate();

  const fetchTotalPatientsInCohort = async () => {
    const { patient_set_id: pid, selected_date } = JSON.parse(localStorage.getItem("patient_set"))
    if (isNaN(totalPatientsInCohort?.count) || pid !== totalPatientsInCohort?.pid) {
      const data = await services.caseLoad(pid, {
        cohort_last_updated: selected_date,
      })
      const obj = { count: data?.data?.patientset?.all_time_patient_count, pid }
      setTotalPatientsInCohort(obj)
      localStorage.setItem("total_patient_count", JSON.stringify(obj));
    }
  }

  const fetchPatientSets = useCallback(async (setLocal) => {
    try {
      const prevPatientSet = JSON.parse(localStorage.getItem("patient_set"));
      setFetchingPatientSet(true);
      const result = await services.patientSets();
      console.log("result in patient set", result);
      let { data } = result.data;
      if(!data.length) history('/patient-sets')

      data = data.map((item) => {
        item.selectable_dates = item.selectable_dates.map(el => el.service_date_end.slice(0, 10));
        return item;
      })

      let defaultPatientSet = data.find(
        ({ is_default }) => is_default === "true"
      );

      if (setLocal) {
        const patientSet = data.find(({ patient_set_id }) => patient_set_id === prevPatientSet.patient_set_id);
        if (patientSet){
          patientSet.selected_date = patientSet.last_updated.slice(0, 10);
          patientSet.last_updated = patientSet.last_updated.slice(0, 10);
          localStorage.setItem("patient_set", JSON.stringify(patientSet));
          setPatientSetInfo(patientSet);
          setFetchingPatientSet(false);
          return;
        }
      }

      if(!defaultPatientSet) defaultPatientSet = data[0];

      defaultPatientSet.selected_date = defaultPatientSet.last_updated.slice(0, 10);
      defaultPatientSet.last_updated = defaultPatientSet.last_updated.slice(0, 10);
      if (!prevPatientSet && !patientSetInfo) {
        localStorage.setItem("patient_set", JSON.stringify(defaultPatientSet));
        setPatientSetInfo(defaultPatientSet);
        // window.location.reload();
      } else if (prevPatientSet && !patientSetInfo) {
        setPatientSetInfo(prevPatientSet);
      }

      if (!prevPatientSet) {
        const patientSetData = data?.filter(({ is_default }) => is_default === "false").slice(0, 5);
        setPatientSets(patientSetData);
      } else {
        const patientSetData = data?.filter(({ patient_set_id }) => patient_set_id !== prevPatientSet.patient_set_id).slice(0, 5);
        setPatientSets(patientSetData);
      }      
      setFetchingPatientSet(false);

    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllPatientSets = async () => {
    try {
      setFetchingPatientSet(true);
      const result = await services.patientSets();
      let { data } = result.data;
      if(!data.length) history('/patient-sets')
      data = data.map((item) => {
        item.selectable_dates = item.selectable_dates.map(el => el.service_date_end.slice(0, 10));
        return item;
      })

      const prevPatientSet = JSON.parse(localStorage.getItem("patient_set"));
      let defaultPatientSet = data.find(
        ({ is_default }) => is_default === "true"
      );
      if (!defaultPatientSet) defaultPatientSet = data[0];

      defaultPatientSet.selected_date = defaultPatientSet.last_updated.slice(0, 10);
      defaultPatientSet.last_updated = defaultPatientSet.last_updated.slice(0, 10);
      if (!prevPatientSet && !patientSetInfo) {
        localStorage.setItem("patient_set", JSON.stringify(defaultPatientSet));
        setPatientSetInfo(defaultPatientSet);
      } else if (prevPatientSet && !patientSetInfo) {
        setPatientSetInfo(prevPatientSet);
      }

      setAllPatientSets(data);
      setFetchingPatientSet(false);
      console.log("Patient sets data => ", data);
      return result;
    } catch (err) {
      console.log(err);
      console.log('Error message => ', err.message);
    }
  };

  /**
   * Update Patient set on the local storage.
   * @param {*} _patientSet 
   */
  const updatePatientSetInfo = (_patientSet) => {
    _patientSet.selected_date = _patientSet.selected_date ? _patientSet.selected_date : _patientSet.last_updated.slice(0, 10);
    _patientSet.last_updated = _patientSet.last_updated.slice(0, 10);
    setPatientSetInfo(_patientSet);
    localStorage.setItem("patient_set", JSON.stringify(_patientSet));
  };

  const updateSelectedCohorts = (cohorts) => {
    localStorage.setItem("cohort_list_tabs", JSON.stringify(cohorts));
    setSelectedCohorts(cohorts)
  }

  return (
    <PatientSetContext.Provider
      value={{
        allPatientSets,
        patientSets,
        patientSetInfo,
        setPatientSetInfo,
        fetchPatientSets,
        fetchAllPatientSets,
        updatePatientSetInfo,
        fetchingPatientSet,
        selectedCohorts,
        setSelectedCohorts: updateSelectedCohorts,
        analysisType, setAnalysisType,
        totalPatientsInCohort,
        setTotalPatientsInCohort,
        fetchTotalPatientsInCohort
      }}
    >
      {props.children}
    </PatientSetContext.Provider>
  );
}

const usePatientSetContext = () => {
  const context = useContext(PatientSetContext);
  if (!context) {
    throw new Error("usePatientSetContext must be used within a PatientSetProvider");
  }
  return context;
};

export { PatientSetContext, PatientSetProvider, usePatientSetContext };