const carePlanList = [
  {
    "Client_count_:_(average relative risk)": "Short-term",
    Recommended: "10 (1.2)",
    "In_Progress/Current": "6 (1.4)",
    "Completed_:_Goal_Met": "25",
    "Completed_:_Goal_Partially_Met": "5",
    "Completed_:_Goal_Not_Met": "3",
    "Deleted/Voided": "3",
  },
  {
    "Client_count_:_(average relative risk)": "Long-term",
    Recommended: "7 (1.6)",
    "In_Progress/Current": "9 (1.7)",
    "Completed_:_Goal_Met": "45",
    "Completed_:_Goal_Partially_Met": "6",
    "Completed_:_Goal_Not_Met": "2",
    "Deleted/Voided": "4",
  },
  {
    "Client_count_:_(average relative risk)": "Total",
    Recommended: "17 (1.2)",
    "In_Progress/Current": "15 (1.5)",
    "Completed_:_Goal_Met": "70",
    "Completed_:_Goal_Partially_Met": "11",
    "Completed_:_Goal_Not_Met": "5",
    "Deleted/Voided": "7",
  },
];

const carePlanPopUpList = [
  {
    Name: "Diamond, Keith",
    "Number of UNFINISHED": "5, 3, 8 (1.2)",
    "Number of COMPLETED": "6, 7, 13 (1.4)",
    "Number of TOTAL": "11, 10, 21 (1.7)",
  },
  {
    Name: "Burns, Reilly",
    "Number of UNFINISHED": "8, 5, 13 (1.2)",
    "Number of COMPLETED": "10, 1, 13 (1.6)",
    "Number of TOTAL": "18, 6, 26 (1.8)",
  },
  {
    Name: "Miller, Ashley",
    "Number of UNFINISHED": "6, 7, 13 (1.1)",
    "Number of COMPLETED": "8, 4, 5 (1.3)",
    "Number of TOTAL": "14, 11, 17 (1.8)",
  },
  {
    Name: "Rodriguez, Karen",
    "Number of UNFINISHED": "4, 3, 1 (1.5)",
    "Number of COMPLETED": "6, 7, 12 (1.9)",
    "Number of TOTAL": "10, 10, 3 (1.0)",
  },
];

const carePlanColumnList = [
  {
    title: "Client_count_:_(average relative risk)",
    isAvailable: true,
    haveInsideTable: false,
    havePopup: false,
    haveTooltip: false,
    api_params: [],
    api_url: "",
  },
  {
    title: "Recommended",
    isAvailable: true,
    haveInsideTable: false,
    havePopup: true,
    haveTooltip: false,
    api_params: [],
    api_url: "",
  },
  {
    title: "Completed_:_Goal_Met",
    isAvailable: true,
    haveInsideTable: false,
    havePopup: false,
    haveTooltip: false,
    api_params: [],
    api_url: "",
  },
  {
    title: "Completed_:_Goal_Partially_Met",
    isAvailable: true,
    haveInsideTable: false,
    havePopup: false,
    haveTooltip: false,
    api_params: [],
    api_url: "",
  }, {
    title: "Completed_:_Goal_Not_Met",
    isAvailable: true,
    haveInsideTable: false,
    havePopup: false,
    haveTooltip: false,
    api_params: [],
    api_url: "",
  },
  {
    title: "In_Progress/Current",
    isAvailable: true,
    haveInsideTable: false,
    havePopup: true,
    haveTooltip: false,
    api_params: ["start_date", "end_date", "id"],
    api_url: "genesis/clinicalStaff/services",
  },
  {
    title: "Deleted/Voided",
    isAvailable: true,
    haveInsideTable: false,
    havePopup: false,
    haveTooltip: false,
    api_params: "",
    api_url: "",
  },
];

const carePlanReportData = {
  list: carePlanList,
  report_title: "Treatment Plan Outcomes Summary",
  filter_list: "",
  start_date: "",
  end_date: "",
  column_list: "",
};

export {
  carePlanList,
  carePlanPopUpList,
  carePlanReportData,
  carePlanColumnList
};
