import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import eyeIcon from "../../../Assets/Images/eye.png";
import recommendationIcon from "../../../Assets/Images/recommendation.svg";
import { Tooltip } from "@mui/material";
import CustomModal from "../../Modal/CustomModal";
import InterventionTable from "../../Tables/InterventionTable";
import { AnalyticsContext } from "../../../Contexts/analyticsContext";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import { toLowerCase, toPluralize } from "../../../Utils/stringOprations";
import "../../../App.scss";

const SegmentedSlider = (props) => {
  const { event } = useContext(AnalyticsContext);
  const { patientSetInfo } = useContext(PatientSetContext);
  const currentValueRef = useRef(null);
  const nextRef = useRef(null);

  const [state, setState] = useState({
    value: 0,
    issue: '',
    tooltip: ''
  });

  const [modal, setModal] = useState({ open: false, view: '' });
  const [isIndividual, setIsIndividual] = useState(true);
  const [percent, setPercent] = useState(0);
  const [show, setShow] = useState([]);
  const [showDataSource, setShowDataSource] = useState(false);
  const [dataSourceObj, setDataSourceObj] = useState({});

  const calcSlideWidth = (slideVal) => {
    const sum = props.sections.reduce((acc, section) => (acc += section.dsrc), 0);
    const percentageVal = (percent) / sum;
    return percentageVal * slideVal;
  };

  const calcTooltipNotes = (dsrc, text) => {
    const tot_patients = props.sections.reduce((acc, section) => (acc += section.dsrc), 0);
    const affected = ((dsrc / tot_patients) * 100).toFixed(1);
    const total = ((dsrc / props.maxElig) * 100).toFixed(1);
    const final_string = `${props.issue}: ${dsrc} ${toPluralize(toLowerCase(patientSetInfo.patient_name))} identified via ${text}, 
                          which is ${affected}% of affected ${toPluralize(toLowerCase(patientSetInfo.patient_name))}, 
                          and ${total}% of the possible total number of ${toPluralize(toLowerCase(patientSetInfo.patient_name))}.`;
    return final_string;
  };

  const updatePosition = useCallback(() => {
    setState({ value: props.sliderValue, issue: props.issue, tooltip: props.tooltip});

    const { min, max } = currentValueRef.current;
    const new_val = props.sliderValue;
    const percent = ((new_val / (max - min))) * 100;
    setPercent(percent);
    currentValueRef.current.style.setProperty("--webkitProgressPercent", `${percent}%`);

    const controlThumbWidth = currentValueRef.current.getAttribute(
      "thumbwidth"
    );
    const positionOffset = Math.round(controlThumbWidth * (percent / 100)) + 5;
    nextRef.current.style.left = `calc((${percent}%) - ${positionOffset}px)`; //for maintain space in slider marker
    nextRef.current.style.text = new_val;

  }, [props.sliderValue, props.issue,props.tooltip]);

  const openModal = (e, view) => {
    console.log('Open Modal');

    //GA - Track clicking on the eye icon
    event('click_contributor_eye' , {});
    setModal({ open: true, view });
    setIsIndividual(true);
  };

  const recommendationOpenModal = (e, view) => {

    //GA4 - Track click intervention eye
    event('click_intervention_recommendation' , {});
    setModal({ open: true, view });
    setIsIndividual(false);
  };

  const closeModal = (close) => {
    setModal({ open: close, view: '' });
  };

  const handleCloseDataSource =(close)=>{
    setShowDataSource(close);

  }

  useEffect(() => {
    updatePosition();
  }, [updatePosition]);

  useEffect(() => {
    setArrOfBooleans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setArrOfBooleans = () => {
    const arrOfBoolean = props.sections.map(() => {
      return false;
    });
    setShow(arrOfBoolean);
  };

  const ifOpen = (i) => {
    const open = show[i];
    if(open) return true;
    return false;
  };

  const setOpen = (i) => {
    if(modal.open) return;
    //Open tooltip for slider i and close all the other tooltips
    const newShow = show.map((e, index) => {
      if(i === index) return true;
      else return false; 
    });
    setShow(newShow);
  };

  return (
    <>
      <div className="SlideOuterBx">
        <div
          className="slideInpOuter"
          // style={{ position: "relative", marginTop: 20 }}
        >
          <input
            type="range"
            min={props.minElig}
            max={props.maxElig}
            step="1"
            value={state.value}
            className="slider slider-progress"
            thumbwidth="30"
            disabled={true}
            ref={currentValueRef}
            style={{borderRadius: "15px 0 0 15px"}}
          />
          <Tooltip
            placement="top"
            title={props.tooltip}
            interactive="true"
            arrow
          >
            <span className="customize_progressbar" >
              <span data-testid="contibutorName" className="customize_progressbar_icon" ref={nextRef}>
                <output name="rangeVal" style={{ pointerEvents: "all", cursor: "pointer" }}>
                  {state.value}
                </output>
              </span>
            </span>
          </Tooltip>

          <div className="slidestepOuter">
            {props.sections.map((section, i) => {

              const sectWidth = calcSlideWidth(section.dsrc);
              const sectNotes = calcTooltipNotes(section.dsrc, section.note);

              const findIndex = props.slideClassIndexes.findIndex(i => i === section.note);
              const sectionClass = `slide${findIndex + 1}`;
              return (           
                  <Tooltip 
                    key={i} 
                    placement="top" 
                    arrow
                    open={ifOpen(i)} 
                    disableHoverListener
                    onMouseEnter={() => setOpen(i)}
                    onMouseLeave={() => { setArrOfBooleans(); }} 
                    title={
                      <div style={{ fontSize: "13px", padding: "3px" }}>
                        <span>{sectNotes}</span>
                        <div className="mt-1">(Click for viewing {section.dsrc} {toPluralize(toLowerCase(patientSetInfo?.patient_name))})</div>
                      </div> 
                    }               
                  >
                    <span
                      className={sectionClass}
                      style={{ width: sectWidth + "%" }}
                    >
                       <div className="slidepopOuter">
                          <button 
                              id={section.dsrc} 
                              type="button" 
                              onClick={(e) => {
                                setShowDataSource(true);
                                setDataSourceObj(section);
                              }}
                              style={{lineHeight: "15px", color: "#fff"}}
                          >
                            {section.dsrc}
                          </button>
                    </div>
                    </span>
                  </Tooltip>
                
              );
            })}
          </div>
        </div>
        <div className="valueOuter dignosValueOuter clearfix">
          <label data-testid="minEligLable" className="valueLeft">{props.minElig}</label>
          <label data-testid="maxEligLable" className="valueRight">{props.maxElig}</label>
          <button>
          <Tooltip
            key={state.value}
            title={props.type === "intervention" ? props.tooltip : `View ${toPluralize(toLowerCase(patientSetInfo?.patient_name))} with ${(state.tooltip).slice(39)}`}
            placement="top"
            arrow
          >
              <img
                style={{ bottom: "26px" }}
                src={eyeIcon}
                alt="eye icon"
                onClick={(e) => openModal(e, "overall")}
              />
          </Tooltip>
          </button>

          {props.type === "intervention" && <button style={{position:"absolute", right:"-50px"}}>
          <Tooltip
            key={state.value}
            title={`${toPluralize(patientSetInfo?.patient_name)} recommended for ${props.issue.toLowerCase()}`}
            placement="top"
            arrow
          >
              <img
                style={{ bottom: "26px" }}
                src={recommendationIcon}
                alt="recommend icon"
                onClick={(e) => recommendationOpenModal(e, "overall")}
              />
          </Tooltip>
          </button>}
          
        </div>
        <div className="segmntvalue">
          {
            props.sections.map((section, i) => {

              const findIndex = props.slideClassIndexes.findIndex(i => i === section.note);
              const dsrcClass = `dsrc${findIndex + 1}`;

              return (
                <span
                  key={i}  
                  onMouseEnter={() => setOpen(i)}
                  onMouseLeave={() => { setArrOfBooleans(); }} 
                  onClick={()=>{
                    setShowDataSource(true);
                    setDataSourceObj(section);
                  }}
                 className={dsrcClass}>{section.note}: {section.dsrc} </span>
              )
            })
          }
        </div>
      </div>
      {modal.open && modal.view === 'overall' && (
        <CustomModal
          show={modal.open}
          close={closeModal}
          label={props.type === "intervention" ? props.issue : (state.tooltip).slice(39)}
          type={props.type}
          abbr={props.abbr}
          contr_type={props.contr_type}
          mnemo={!isIndividual ? "recommand" : props.mnemo}
          is_individual={isIndividual}
        >
          <InterventionTable is_individual={isIndividual} contr_type={props.contr_type} type={props.type} />
        </CustomModal>
      )}
       { showDataSource && (
        <CustomModal
          show={showDataSource}
          close={handleCloseDataSource}
          label={`${dataSourceObj.note}: ${dataSourceObj.dsrc} ${toPluralize(toLowerCase(patientSetInfo.patient_name))}`}
          type={props.type}
          dataSource={true}
        >
          <InterventionTable
            is_individual={isIndividual}
            contr_type={props.contr_type}
            type={props.type}
            src_label={dataSourceObj.note}
          />
        </CustomModal>)}
    </>
  );
};

export default SegmentedSlider; 