import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import closeIcon from "../../Assets/Images/close.png";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PatientSetFilters from "../SideAccordian/PatientSetFilters";
import { PyramidContext } from "../../Contexts/uiContext";
import withRouter from "../../Utils/withRouterHOC";
import services from "../../Services/patientServices";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/lab/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import { Box, Checkbox, TextField } from "@mui/material";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toCapitalize, toLowerCase, toPluralize } from "../../Utils/stringOprations";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #eee",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 600,
    height: 680,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    display: "flex",
    alignItems: "center"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    margin:"0 !important"
  },
  column: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0px 15px 0px"
  },
  backdrop: {
    zIndex: 500,
    color: '#78cbf2',
  },
  notes: {
    fontSize: theme.typography.pxToRem(15),
    position: "relative",
    top: "10px"
  }
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CreatePatientSet = ({ toggle, setToggle }) => {
  const classes = useStyles();
  const { info, setInfo, setPatientData } = useContext(PyramidContext);
  const { state} = useContext(DropdownFilterContext);
  const { event } = useContext(AnalyticsContext);
  const { patientSetInfo, updatePatientSetInfo, fetchPatientSets } = useContext(PatientSetContext);
  const { creator_name, creator_id, patient_set_id, selected_date } = patientSetInfo;
  const [isFetching, setIsFetching] = useState(false);
  const [newPatientSet, setNewPatientSet] = useState("");
  const [cohortName, setCohortName] = useState("");
  const [patientCheckBox, setPatientCheckBox] = useState(false);
  const [totalPatient, setTotalPatient] = useState("")
  const history = useNavigate();

  // eslint-disable-next-line
  const totalPatientCount = parseInt(totalPatient.replace(/\,/g, ''))
  const totalCount = parseInt(state.totalCount);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setInfo({ showToast: false });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const handleClose = () => {
    setToggle(false);
  };

  const setData = async () => {
    try {
      if(!cohortName) return alert(`Please enter a ${patientSetInfo.cohort_name} name!`);
      setIsFetching(true);
      const { selectedFilters, selectedFiltersCheckbox } = state;
      
      const body = {
        patient_set_name: cohortName,
        creator_id: creator_id,
        current_patient_set_id: patient_set_id,
        filters: selectedFilters.concat(selectedFiltersCheckbox),
        selected_date,
        patient_count: patientCheckBox ? parseInt(totalPatient) : totalCount
      };
      console.log('Request body => ', body);
      const filteredPatients = await services.createPatientSet(body);
      console.log('Patient set created => ', filteredPatients.data);
      setNewPatientSet(filteredPatients.data.data.pid);
      setPatientData(filteredPatients);
      setInfo({
        showToast: true
      });
      setIsFetching(false);
      setToggle(false);
    }
    catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  const switchToNew = () => {
    updatePatientSetInfo({ ...patientSetInfo, patient_set_id: newPatientSet });
    fetchPatientSets(true);
    history('/');
    handleAlertClose();
  };

  const handleChange = (value) => {
    setCohortName(value);
  };

  const handleCheckBox = () => {
    setPatientCheckBox(!patientCheckBox)
    if (!patientCheckBox) {
      setTotalPatient("")
    }
  }

  const patientCount = (value) => {
    setTotalPatient(value)
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={toggle}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={toggle}>
          <div className={`${classes.paper}`}>
            <h5 className="modalHd" id="transition-modal-title">
              <div className="modalIcons">
                <img
                  className="closemodal"
                  src={closeIcon}
                  alt="close icon"
                  onClick={handleClose}
                />
              </div>
            </h5>
            <div className="patient-set-body">
              <div className={classes.section1}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h5">
                      Create {toCapitalize(patientSetInfo.cohort_name)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography color="textSecondary" variant="body2">
                Create a new {patientSetInfo.cohort_name} and store the filtered {toPluralize(toLowerCase(patientSetInfo.patient_name))} in the {patientSetInfo.cohort_name}.
                </Typography>
              </div>
              <Divider variant="middle" />

              <div className="auto-scroll">
                <div className={classes.section2}>
                  <div>
                    <Typography className={classes.heading}>Selected Filters:</Typography>
                    <PatientSetFilters />
                  </div>

                  <Divider variant="middle" />

                  <div className={classes.column}>
                    <div className="d-flex" style={{marginLeft: "-10px"}}>
                      <Checkbox {...label} onChange={handleCheckBox}/>
                      <Typography className={classes.heading}>Limit number of {toPluralize(toLowerCase(patientSetInfo.patient_name))}:</Typography>
                    </div>
                    {
                      patientCheckBox ? 
                        <div className="d-flex align-items-center">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            style={{ width: "90px" }}
                            className="d-flex justify-content-end align-items-center customPatient"
                          >
                            <TextField
                              type="number"
                              onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,totalCount.toString().length)
                              }}
                              id="standard-basic"
                              variant="standard"
                              placeholder="00000"
                              autoFocus
                              onChange={(e) => patientCount(e.target.value)}
                              sx={{width: `${totalCount.toString().length * 8.33}px`}}
                            />
                            <Typography className={classes.secondaryHeading}>/{totalCount}</Typography>
                          </Box>
                        </div> :  
                         <Typography className={classes.secondaryHeading}>{totalCount}/{totalCount}</Typography>
                    }
                  </div>

                  <Divider variant="middle" />

                  <div className={classes.column}>
                    <Typography className={classes.heading}>Creator Name:</Typography>
                    <Typography className={classes.secondaryHeading}>{creator_name}</Typography>
                  </div>

                  <Divider variant="middle" />

                  <div className={classes.column}>
                    <Typography className={classes.heading}>{toCapitalize(patientSetInfo.cohort_name)} Name:</Typography>
                    <input
                      type="text"
                      className="form-control modal-input"
                      name="from"
                      placeholder={`Enter ${toCapitalize(patientSetInfo.cohort_name)} name`}
                      value={cohortName}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  </div>

                  <Divider variant="middle" />
                </div>

                {
                  patientCheckBox && (totalPatientCount > totalCount) ? 
                  <p style={{color: "red", fontSize: "12px", textAlign: "right"}}>*Please enter valid number of {toPluralize(toLowerCase(patientSetInfo.patient_name))}</p> : null
                }
                <div className="btn-modal-container">
                  <button className="btn-modal" onClick={() => { setToggle(false) }}>Cancel</button>
                  <button
                    className="btn-modal"
                    onClick={() => { setData(); event("click_patient_set_create", { cohort_id: patient_set_id.slice(-12) }); }}
                    disabled={patientCheckBox && (totalPatientCount > totalCount || !totalPatientCount) ? true : false}
                  >
                    Create
                </button>
                </div>
              </div>
            </div>
            <Backdrop className={classes.backdrop} open={isFetching}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </Fade>
      </Modal>
      <div className="toaster">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={info.showToast}
          autoHideDuration={16000}
          onClose={handleClose}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleAlertClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert onClose={handleAlertClose} variant="outlined" severity="success">
            <span>{toCapitalize(patientSetInfo.cohort_name)} has been created successfully. </span>
            <span className="click-here" onClick={() => switchToNew()}>Click here </span>
            <span>to switch to the new {patientSetInfo.cohort_name}.</span>
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default withRouter(CreatePatientSet);
