import React from "react";
import ReportFooter from "./ReportFooter/ReportFooter";
import ReportHeader from "./ReportHeader/ReportHeader";
import ReportOuterTable from "./ReportTable/ReportOuterTable";
import DownloadReportCsv from "./DownloadReportCsv/DownloadReportCsv";

const Report = () => {
  const reportData = JSON.parse(localStorage.getItem("report_data"));
  const { filter_list, report_title, start_date, end_date, list, column_list } =
    reportData;
  const { user = { first_name: "local", last_name: "test" } } =
    JSON.parse(localStorage.getItem("user")) || {};
  const user_name = `${user.last_name}, ${user.first_name}`;
  console.log(reportData);

  return (
    <div style={{ overflow: "scroll", height: "100vh" }}>
      <ReportHeader
        filterList={filter_list}
        userName={user_name}
        title={report_title}
        startDate={start_date}
        endDate={end_date}
      />
      <ReportOuterTable list={list} columnList={column_list} reportTitle={report_title}/>
      <DownloadReportCsv data={reportData}/>
      <ReportFooter />
    </div>
  );
};
export default Report;
