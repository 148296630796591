import { useRef, useEffect } from 'react';

const ReportColumnHeading = ({ heading }) => {
  const headingRef = useRef(null);

  useEffect(() => {
    if (headingRef.current) {
      if(heading.includes(':')){
        headingRef.current.innerHTML = heading.replace(':', '<br>');
      }else{
        headingRef.current.innerHTML = heading;
      }
    }
  }, [headingRef, heading]);

  return <span ref={headingRef}></span>;
}

export default ReportColumnHeading;
