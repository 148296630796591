import { useContext, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// import CohortComparisonChart from "../CohortComparisonChart";
import AT_RISK_DATA from "../Data/At_Risk.json";
import ProgramBarChart from "../ProgramBarChart";
import { toCapitalize } from "../../../../Utils/stringOprations";
import { PatientSetContext } from "../../../../Contexts/patientSetContext";
import CohortComparisonChart from "../CohortComparisonChart";
import { useEffect } from "react";

const PROGRAMS = [
  {
    key: "atRisk",
    title: "Re-admit and Risk Management",
    data: AT_RISK_DATA,
  },
];

function ComparisonChartsModal({
  openModal,
  handleCloseModal,
  columns,
  selectedProgram,
  setSelectedProgram,
  timeframe,
  tableData
}) {
  const [currentTab, setCurrentTab] = useState('');
  const { patientSetInfo } = useContext(PatientSetContext);
  const environment = window.config.environment;
  
  useEffect(()=> {
    setCurrentTab(columns[0]?.key);
  }, [columns])
  
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChange = (event) => {
    setSelectedProgram(event.target.value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xl"}
      open={openModal}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          height: "calc(100% - 64px)"
        }
      }}
      className="cohort-tabs"
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          display={"flex"}
        >
          <Grid item>{`${toCapitalize(patientSetInfo?.cohort_name)} Costs & Metrics`}</Grid>
          <Grid item>
            {environment === "demo-a" || environment === "demo" ? (
              <FormControl sx={{ p: "0 25px" }}>
                <Select
                  value={selectedProgram}
                  onChange={handleChange}
                  size={"small"}
                >
                  {PROGRAMS.map((prog) => (
                    <MenuItem key={prog.key} value={prog.key}>
                      {prog.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <IconButton aria-label="close" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TabContext value={currentTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: openModal ? "30px" : 0,
            }}
          >
            <TabList
              onChange={(e, v) => handleTabChange(e, v)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {columns.map((column, index) => (
                <Tab
                  sx={{ justifyContent: "flex-end", p: "12px" }}
                  label={column.title}
                  value={column.key}
                  key={index}
                />
              ))}
            </TabList>
          </Box>
          {columns.map((column, index) => (
            <TabPanel value={column.key} sx={{ pt: 0, p: openModal && 0 }} key={index}>
              {environment === "demo-a" || environment === "demo" ? (
                <ProgramBarChart
                  selectedProgram={selectedProgram}
                  setSelectedProgram={setSelectedProgram}
                  column={column}
                />
              ) : (
                <CohortComparisonChart
                  selectedChart={column}
                  selectedProgram={patientSetInfo?.patient_set_id}
                  timeframe={timeframe}
                  tableData={tableData}
                />
              )}
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
      {/* <DialogActions sx={{ p: 2 }}>
        <Button endIcon={<KeyboardDoubleArrowRightSharpIcon />}>
          View Details
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default ComparisonChartsModal;
