export const mmYYDateFormatter = (date) =>{
    if(!date) return "";
    // Split the date string into its components
    const dateParts = date.split('/');
    // Extract the month and year
    const month = dateParts[0];
    const year = dateParts[2].slice(-2); // Get the last two digits of the year
    // Combine the month and year in the desired format
    return `${month}/${year}`;
}

export const getMarkerColor = (selectedDisease, item, colors, i) => {
    const { attentive } = item;

    if (selectedDisease === "vitals") {
        if (attentive === "high") {
            return "#FD0808";
        } else if (attentive === "low") {
            return "#FF8500";
        } else {
            return "#36E948";
        }
    } else {
        if (attentive === "none") {
            return "#808080";
        } else if (attentive) {
            return "#FD0808";
        } else {
            return "#36E948";
        }
    }
};

export const parseDate =(dateString) => {
    if(dateString === '-' || !dateString || dateString === "Invalid Date") return null;
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
}

export const sortData = (data, key, direction) =>{
    let sortedData = [...data];
    sortedData.sort((a, b) => {
        const dateA = parseDate(a[key]);
        const dateB = parseDate(b[key]);

        if(dateA === null){
          return 1;
        }

        if(dateB === null){
          return -1;
        }
        
        if (direction === 'asc') {
            return dateA - dateB;
        } else {
            return dateB - dateA;
        }
    });

    return [...sortedData]
}

export const capitalizeWords = (text) => {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};

export const sortGraphData = (arr) =>{
    let data = [...arr];
    data.sort((a, b) => {
        const [monthA, dayA, yearA] = a.x.split("/").map(Number);
        const [monthB, dayB, yearB] = b.x.split("/").map(Number);
    
        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);
    
        return dateA - dateB;
    });
    return [...data];
}
