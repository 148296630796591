import getCognitoToken from "../Utils/getCognitoToken";
import combinedServices from "./patientServices";

//This piece of code gets executed whenever the frontend mounts initially in the browser
const checkAuth = async (auth) => {
  try {
    const { login, setAuthSuccess, setAuthFailure, logout } = auth;
    const user = JSON.parse(localStorage.getItem("user"));
    const isLoggedIn = user ? user.isLoggedIn : null;
    const cognitoToken = await getCognitoToken('accessToken');
    const userLoggedOut = JSON.parse(localStorage.getItem("user_logged_out"));
    console.log('IsLoggedIn => ', isLoggedIn);

    if(userLoggedOut) {
      console.log("auth failed due to user logged out")
      setAuthFailure();
      return;
    };

    //First time login with cognito, execute the login route, this is only executed if the user lands on our frontend
    //from the cognito hosted ui initially, hence we only execute the login endpoint once per user session and
    //don't need it for further page refreshes. Logging in successfully means token is valid and isLoggedIn is stored
    //in the localstorage along with the access and refresh token. 
    
    //CognitoToken (access token from cognito) is always sent by lambda edge and stored in the cookies, if session
    //is created in the hosted ui. And we validate both custom + cognito access token in our login endpoint.
    //All error handling is done in the login endpoint itself to keep the code and conditions here clean and minimal. 
    if (!isLoggedIn && cognitoToken) {
      console.log('User is logging in from cognito for the first time');
      await login();
      setAuthSuccess();
    }

    //If isLoggedIn in our localstorage exists and is true, the user is already logged in hence no need to interact
    //with the login endpoint again. At this point, no further validation of cognito token is needed (but it still
    //needs to be present in the cookies). We can now also go ahead and check if our custom access token exists or not.
    else if (isLoggedIn || cognitoToken) {
      //The user is already logged in, check their custom access token (the usual jwt way)
      //If the token exists, user is authorized to use app features, and token will keep being validated on the backend
      //If for some reason it does not exist, logout, end the session and redirect them to cognito hosted login
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        console.log('User already logged in and authorized');
        setAuthSuccess();
      }
      else {
        console.log('User logged out and unauthorized');
        await logout();
      }
    }

    //Execution reaches here if the cognito token does not exist
    else {
      // Check if the access token in the cookie is valid
      const isAccessTokenValid = await isAccessTokenInCookieValid(); // Add your function to check the access token

      // The access token in the cookie is valid, suggesting the user is authenticated
      // This scenario may occur when the access token is generated directly from the backend
      // and sent to the frontend, bypassing the Cognito token flow
      if (isAccessTokenValid) {
        console.log('Access token in the cookie is valid');
        setAuthSuccess();
      } else {
        console.log('Access token in the cookie is not valid');
        setAuthFailure();
      }

      return
    }
  }
  catch (error) {
    console.log("error while logging in",error);
    throw (error);
  }
};


const isAccessTokenInCookieValid = async () => {
  try {
    await combinedServices.getSidebarFeatures();
    return true;  // Access token in cookie is valid
  } catch (error) {
    return false; // Access token in cookie is not present or not valid
  }
};

export default checkAuth;