import axios from "axios";
import swal from 'sweetalert';

const userClient = (accessToken) => {
    const runtime = window.config.environment === "local" ? "" : "/node";
    const instance = axios.create({
        baseURL: window.config.apiUrl + runtime,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
        responseType: "json"
    });

    // Use interceptor to catch api errors globally
    instance.interceptors.response.use((response) => response, (error) => {
        console.log('Api error catched by axios interceptor');
        if(error.response.status === 503) {
        swal("Oops!", "Something went wrong (503). Please refresh the page and try again.", "error");
        }
        throw error;
    });

    return instance;
}

export default userClient;