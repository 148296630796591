import React, { useEffect, useRef, useState } from "react";
// import { IframeContext } from "../../Contexts/iframeContext";
import "./PatientRecommendations.scss";
import {
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CommunityResourcesList from "./CommunityResourcesList";
import RESOURCE_LIST from './resource_list.json'
import { CSVLink } from "react-csv";

const resource_list_columns = [
  {key: 'providerName', title: 'Organization Name'},
  {key: 'providedService', title: 'Services'},
  {key: 'address', title: 'Address'},
  {key: 'contactNumber', title: 'Contact Number'},
  {key: 'service_cost', title: ''},
]

const PatientRecommendations = ({ patientData }) => {
  // const { iframe } = useContext(IframeContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableComResources, setDisableComResources] = useState(false);
  const [exportData, setExportData] = useState([]);
  const exportRef = useRef();
  const { user } = JSON.parse(localStorage.getItem("user"));
  const resourcesList = RESOURCE_LIST;
  const carolinaOrgGuid = "10a7319f-b662-4c79-85fd-7fc5c9cc1461";
  const { org_guid } = user;

  useEffect(() => {
    const disable = user?.org_guid !== "3aae120b-0882-479f-88d9-be402dfb020d";
    setDisableComResources(disable);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recommendation = patientData?.recommendation?.filter(
    (ele) => ele.intrv_type
  );

  if (!patientData || !recommendation?.length) {
    return <div className="not-available">No recommendations available</div>;
  }

  const openCommunityResources = () => {
    setOpenDialog(true)
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const downloadCommunityResources = () => {
    try {
      const finalArray = [];
      const setData = (row) => {
        let rowData = {};
        resource_list_columns.forEach((column) => {
          const title = column.title;
          rowData = { ...rowData, [title]: row[column.key] };
        });
        finalArray.push(rowData);
      };
      resourcesList.forEach((row) => {
        setData(row);
      });
      setExportData(finalArray);
      setTimeout(() => {
        exportRef.current.link.click();
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TableContainer
        sx={{ maxHeight: 400, width: "100%" }}
        className="recommendation-table"
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Care Enabling Service</TableCell>
              <TableCell>Quality Improvement Potential</TableCell>
              <TableCell>Date Last Provided</TableCell>
              <TableCell>Find Community Resources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recommendation.map((data, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {/* {data.tooltip ? data.tooltip : data.label}
                   */}
                  {(org_guid === carolinaOrgGuid ? data.label : data.tooltip) ||
                    data.label}
                </TableCell>
                <TableCell align="center">{`${(data?.cost_delta * 100).toFixed(
                  2
                )}%pt`}</TableCell>
                <TableCell align="center">
                  {data.service_date === "-"
                    ? "-"
                    : new Date(data.service_date)
                        .toISOString()
                        .substring(0, 10)}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    disabled={disableComResources}
                    onClick={openCommunityResources}
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                  <CSVLink
                    data={exportData}
                    className="table-btn"
                    filename={`Community_Resources`}
                    hidden={true}
                    ref={exportRef}
                    target="_blank"
                  />
                  <IconButton
                    disabled={disableComResources}
                    onClick={downloadCommunityResources}
                  >
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullScreen open={openDialog} onClose={handleClose}>
        <CommunityResourcesList handleClose={handleClose} />
      </Dialog>
    </>
  );
};

export default PatientRecommendations;
