const staticFilters = (org_guid) => {

  const environment = window.config.environment;

  var filters = [];

  const timeFrameOptions = [
    {
      label: "Last month",
      value: "1 MONTH",
    },
    {
      label: "Last 3 months",
      value: "3 MONTH",
    },
    {
      label: "Last 6 months",
      value: "6 MONTH",
    },
    {
      label: "Last 1 year",
      value: "1 YEAR",
    },
    {
      label: "Last 3 years",
      value: "3 YEAR",
    },
  ];

  const costOptions = [
    {
      filterType: "radio",
      label: "2nd Percentile",
      value: "second_per",
      abbr: "second_per",
    },
    {
      filterType: "radio",
      label: "5th Percentile",
      value: "five_per",
      abbr: "five_per",
    },
    {
      filterType: "select",
      label: "Time Frame",
      value: "time_frame",
      abbr: "time_frame",
      options: timeFrameOptions,
    },
  ];

  const volumeOptions = [
    {
      filterType: "select",
      label: "Time Frame",
      value: "time_frame",
      abbr: "time_frame",
      options: timeFrameOptions,
    },
    {
      filterType: "range",
      label: "Visit Count",
      value: "visit_count",
      abbr: "visit_count",
    },
  ];

  const outlierOptions = [
    {
      label: "High Total Cost",
      filterType: "dropdown",
      value: "high_total_cost",
      abbr: "high_total_cost",
      options: costOptions,
    },
    {
      label: "High Pharmacy Cost",
      filterType: "dropdown",
      value: "high_pharmacy_cost",
      abbr: "high_pharmacy_cost",
      options: costOptions,
    },
    {
      label: "High ER Utilizer",
      filterType: "outer_dropdown",
      value: "high_ER_utilizer",
      abbr: "high_ER_utilizer",
      options: [
        {
          label: "High Cost",
          filterType: "dropdown",
          value: "er_high_cost",
          options: costOptions,
        },
        {
          label: "Volume",
          filterType: "dropdown",
          value: "er_volume",
          options: volumeOptions,
        },
      ],
    },
    {
      label: "High IP Visit Utilizer",
      filterType: "outer_dropdown",
      value: "high_ip_visit_utilizer",
      abbr: "high_ip_visit_utilizer",
      options: [
        {
          label: "High Cost",
          filterType: "dropdown",
          value: "ip_high_cost",
          options: costOptions,
        },
        {
          label: "Volume",
          filterType: "dropdown",
          value: "ip_volume",
          options: volumeOptions,
        },
      ],
    },
  ];

  // Add the outlier filter to the existing patient filter array
  const outlierFilters = outlierOptions.map((item) => {
    return {
      ...item,
      title: "Outliers",
      mnemo: "outlier",
      filterType: item.filterType || "dropdown",
      key: "outlier",
      isAvailable: true,
      order: 6,
      type: "outlier"
    };
  });
  filters.push(...outlierFilters);
  /* ------------------------- Outliers filter -------------------------- */

  // Comprehensive Risk filter add here
  const compOptions = [
    {
      label: "Critical risk",
      abbr: "crit_risk",
      tooltip: "Critical risk",
      value: "critical",
    },
    {
      label: "High risk",
      abbr: "high_risk",
      tooltip: "High risk",
      value: "high",
    },
    {
      label: "Moderate risk",
      abbr: "mod_risk",
      tooltip: "Moderate risk",
      value: "moderate",
    },
    {
      label: "Low risk",
      abbr: "low_risk",
      tooltip: "Low risk",
      value: "low",
    },
  ];

  const compFilters = compOptions.map((item) => {
    return {
      ...item,
      title: "Comprehensive Risk",
      mnemo: "comp_risk",
      key: "cs",
      name: "ps.comp_score",
      order: 7,
      type: "static"
    };
  });
  filters.push(...compFilters);

  // Impactability filter add here
  const impOptions = [
    {
      label: "Highest impactability",
      abbr: "crit_risk",
      tooltip: "Highest impactability",
      value: "critical",
    },
    {
      label: "High impactability",
      abbr: "high_risk",
      tooltip: "High impactability",
      value: "high",
    },
    {
      label: "Moderate impactability",
      abbr: "mod_risk",
      tooltip: "Moderate impactability",
      value: "moderate",
    },
    {
      label: "Low impactability",
      abbr: "low_risk",
      tooltip: "Low impactability",
      value: "low",
    },
  ];

  const impFilters = impOptions.map((item) => {
    return {
      ...item,
      title: "Impactability",
      mnemo: "imp_risk",
      key: "imp",
      name: "ps.impactability",
      order: 8,
      type: "static"
    };
  });
  filters.push(...impFilters);

  // Composite Quality Risk filter add here
  const qualOptions = [
    {
      label: "Critical risk",
      abbr: "crit_risk",
      tooltip: "Critical risk",
      value: "critical",
    },
    {
      label: "High risk",
      abbr: "high_risk",
      tooltip: "High risk",
      value: "high",
    },
    {
      label: "Moderate risk",
      abbr: "mod_risk",
      tooltip: "Moderate risk",
      value: "moderate",
    },
    {
      label: "Low risk",
      abbr: "low_risk",
      tooltip: "Low risk",
      value: "low",
    },
  ];  

  const qualFilters = qualOptions.map((item) => {
    return {
      ...item,
      title: "Composite Quality Risk",
      mnemo: "qual_risk",
      key: "qs",
      name: "ps.quality_score",
      order: 9,
      type: "static"
    };
  });

  if(org_guid === "10a7319f-b662-4c79-85fd-7fc5c9cc1461" || environment === "demo-a" || environment === "demo") {
    filters.push(...qualFilters);  
  }  

  return filters;
};

export default staticFilters;
