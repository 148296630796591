const formatDate = (dateToFormat) => {
  const date = new Date(dateToFormat);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const updateTime = `${month} ${day}, ${year}`;
  return updateTime;
};

const formateDateWithTimezone = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };
  return date.toLocaleString("en-US", options);
};

const formateDate2 = (val) =>{
  if(!val) return '0';
  let [year, month] = val?.split('-')
  switch (month) {
    case '01':
      return `Jan ${year}`
    case '02':
      return `Feb ${year}`
    case '03':
      return `Mar ${year}`
    case '04':
      return `Apr ${year}`
    case '05':
      return `May ${year}`
    case '06':
      return `Jun ${year}`
    case '07':
      return `July ${year}`
    case '08':
      return `Aug ${year}`
    case '09':
      return `Sep ${year}`
    case '10':
      return `Oct ${year}`
    case '11':
      return `Nov ${year}`
    case '12':
      return `Dec ${year}`
    default: 
      return "None"
  }
}


export const cohortChartDateFormat = (inputDate) =>{
  const months = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12'
  };

  const [month, year] = inputDate.match(/([A-Za-z]+)(\d+)/).slice(1, 3);
  const formattedDate = `${year}-${months[month]}-01`;
  return new Date(formattedDate).setHours(0,0,0,0);
}

// this function formate date January 2023 into Jan 23

const monYYDateFormator = (val) =>{
  if(!val) return
  const date = new Date(val);
  const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', year: '2-digit' }).format(date);
  return formattedDate
}

export default formatDate;
export { formateDateWithTimezone, formateDate2 , monYYDateFormator};
