import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { monYYDateFormator } from "../../Utils/formatDate";

const LineChart = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [onlyPmpmCost, setOnlyPmpmCost] = useState(false);
  const { totalCost } = props;

  const tooltipData = [...props.series];
  tooltipData.reverse();

  const avgCostpmpmValue =
    props.series.reduce((acc, val) => {
      return acc + (val.cost_pmpm / props.series.length);
    }, 0);

  const avgCostibnrValue =
    props.series.reduce((acc, val) => {
      return acc + (val.ibnr_adjusted_pmpm / props.series.length);
    }, 0);

  const totalCostPmpm =
    props.series.reduce((acc, val) => {
      return acc + (val.cost_pmpm);
    }, 0);

  const totalCostIbnr =
    props.series.reduce((acc, val) => {
      return acc + (val.ibnr_adjusted_pmpm);
    }, 0);

  const optionsRef = useRef({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        offsetY: -12,
        offsetX: -10,
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [2, 2],
      colors: ["#3B9CF8", "#EE7D31"],
    },
    markers: {
      size: [5, 0],
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "<span> &#36;" +
          series[seriesIndex][dataPointIndex]
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          "</span>" +
          "</div>"
        );
      },
    },
   
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.8,
      },
    },
    xaxis: {
      labels: {
        show: true,
        rotate: -60,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 180,
        formatter: ((val) => {
          return monYYDateFormator(val)
        }),
        offsetX: 5
      },
      categories: props.series.map((c) => c.label),
    },
   
  });

  const [options, setOptions] = useState({
    series: [
      {
        data: props.series.map((c) => c.cost_pmpm.toFixed(2)),
      },
    ],
  });

  useEffect(() => {

    if (isSelected) {
      setOptions({
        series: [
          {
            name:"IBNR_COST",
            data: props.series.map((c) => {
              return c.ibnr_adjusted_pmpm.toFixed(2)
            }),
          },
          {
            name:"PMPM_TOTAL_COST",
            data: props.series.map(item => totalCostIbnr.toFixed(2))
          }
        ],
      });
      optionsRef.current = {
        ...optionsRef,
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log({ series, seriesIndex, dataPointIndex, w})

            return (
              '<div class="arrow_box">' +
              "<span> &#36;" +
              series[seriesIndex][dataPointIndex] +
              " </span>" +
              "</div>"
            );
          },
        },
        yaxis: [
          {
            seriesName:"IBNR_COST",
            title: {
              text: "IBNR-adjusted PMPM cost"
            },
          },
          {
            seriesName:"PMPM_TOTAL_COST",
            opposite: true,
            title: {
              text: "Monthly cohort cost, adjusted"
            }
          }],
        annotations: {
          yaxis: [
            {
              y: avgCostibnrValue,
             yAxisIndex:0,

              borderColor: '#EE7D31',
              label: {
                borderColor: 'transparent',
                offsetX: -120,
                offsetY: -15,
                style: {
                  color: '#000',
                  background: 'transparent',
                  fontSize: "13px",
                },
                text: `Annual average, adjusted: $${avgCostibnrValue.toFixed(0)}`,
              }
            },
            {
             y:totalCostIbnr,
             yAxisIndex:1,
              borderColor: '#EE7D31',
              label: {
                borderColor: 'transparent',
                offsetX: -120,
                offsetY: -15,
                style: {
                  color: '#000',
                  background: 'transparent',
                  fontSize: "13px",
                },
                text: `Total Cost: ${totalCost}`,
              }
            },
          ]
        },
      };
    } else {
      setOptions({
        series: [
          {
            name:"PMPM_COST",
            data: props.series.map((c) => {
              return c.cost_pmpm.toFixed(2)
            }),
          },
          {
            name:"PMPM_TOTAL_COST",
            data: props.series.map(item => totalCostPmpm.toFixed(2))
          }
        ],
      });
      optionsRef.current = {
        ...optionsRef,
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box">' +
              "<span> &#36;" +
              series[seriesIndex][dataPointIndex]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              "</span>" +
              "</div>"
            );
          },
        },
        yaxis: [
          {
            seriesName:"PMPM_COST",
            title: {
              text: "PMPM cost (unadjusted)"
            },
          },
          {
            opposite: true,
            seriesName:"PMPM_TOTAL_COST",
            title: {
              text: "Monthly cohort cost, unadjusted"
            }
          }],
        annotations: {
          yaxis: [
            {
              y: totalCostPmpm,
              yAxisIndex:1,
              borderColor: '#EE7D31',
              label: {
                borderColor: 'transparent',
                offsetX: -120,
                offsetY: -15,
                style: {
                  color: '#000',
                  background: 'transparent',
                  fontSize: "13px",
                },
                text: `Total Cost: ${totalCost}`,
              }
            },
            {
              y: avgCostpmpmValue,
              yAxisIndex:0,
              borderColor: '#EE7D31',
              label: {
                borderColor: 'transparent',
                offsetX: -120,
                offsetY: -15,
                style: {
                  color: '#000',
                  background: 'transparent',
                  textAlign: "center",
                  fontSize: "13px"
                },
                text: `Annual average, unadjusted: $${avgCostpmpmValue.toFixed(0)}`
              }
            }
          ]
        }
      };
    }
    // eslint-disable-next-line
  }, [isSelected, props.series]);

  useEffect(() => {
    let checkIbnrCf = false;
    // eslint-disable-next-line array-callback-return
    props.series.map((data) => {
      if (data?.ibnr_adjusted_pmpm) {
        checkIbnrCf = true;
      } else {
        checkIbnrCf = false;
      }
    });
    if (checkIbnrCf) {
      setIsSelected(true)
    }
    setOnlyPmpmCost(!checkIbnrCf);
  }, [props.series]);

  

  return (
    <div id="chart">
      <Chart
        options={optionsRef.current}
        series={options.series}
        height={300}
        max-width={500}
      />
      {onlyPmpmCost ? (
        <Typography marginLeft={"10px"} display="flex">
          IBNR CF data is not present
        </Typography>
      ) : (
        <FormControlLabel
          style={{ width: "100%" }}
          control={
            <Checkbox
              checked={isSelected}
              onChange={(e) => setIsSelected(e.target.checked)}
              style={{
                color: "#0080b2",
              }}
            />
          }
          label={
            <>
              <span>Adjust with </span>
              <Tooltip
                arrow
                placement="right"
                title={
                  <React.Fragment>
                    <p color="inherit">
                      IBNR: Incurred But Not Recorded, these pmpm costs are
                      adjusted for the following Completion Factors of claims
                      already paid out within n months after the service date as a
                      ratio of the expected total claims sum.
                    </p>

                    <Grid
                      container
                      spacing={1}
                      style={{ textAlign: "center", paddingBottom: "5px" }}
                    >
                      <Grid item xs={6} style={{ border: "1px solid #ffffff" }}>
                        <b>Lag month(s)</b>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          border: "1px solid #ffffff",
                          borderLeft: "none",
                        }}
                      >
                        <b>Completion Factor</b>
                      </Grid>
                      {tooltipData.map(({ label, completion_factor, lag_month }) => (
                        <>
                          <Grid
                            item
                            xs={6}
                            style={{
                              border: "1px solid #ffffff",
                              borderTop: "none",
                            }}
                          >
                            <span>{`${lag_month} (${label})`}</span>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{
                              border: "1px solid #ffffff",
                              borderTop: "none",
                              borderLeft: "none",
                            }}
                          >
                            <span>
                              {completion_factor / Math.pow(10, -2) % 1 === 0
                                ? (completion_factor / Math.pow(10, -2))
                                : (completion_factor / Math.pow(10, -2)).toFixed(1)}
                              %</span>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </React.Fragment>
                }
              >
                <span>
                  <u>IBNR Completion Factors</u>
                </span>
              </Tooltip>
            </>
          }
        />
      )}
    </div>
  );
};

export default LineChart;
