const cohortLabels = [
  "General",
  "Health Plan Population",
  "Line of Business",
  "Contract Group",
  "Program Group",
  "Caseload",
  "Site"
];

export default cohortLabels;