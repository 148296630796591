import React from 'react';
import './Speedometer.scss'
import { Tooltip } from "@mui/material";
import WarningIcon from "../../Assets/Images/warning.svg";

const items1 = [
  { name: '0', angle: 0 },
  { name: '0.3', angle: 0.3 },
  { name: '0.7', angle: 0.7 },
  { name: '1', angle: 1 },
  { name: '1.3', angle: 1.3 },
  { name: '1.7', angle: 1.7 },
  { name: '2', angle: 2 },
  { name: '2.3', angle: 2.3 },
  { name: '2.7', angle: 2.7 },
  { name: '3', angle: 3 }
]; // Array of items with their names and angles

const items2 = [
  { name: '0', angle: 0 },
  { name: '0.3', angle: 0.3 },
  { name: '0.7', angle: 0.7 },
  { name: '1', angle: 1 },
  { name: '1.3', angle: 1.3 },
  { name: '1.7', angle: 1.7 },
  { name: '2', angle: 2 },
  { name: '2.3', angle: 2.3 },
  { name: '2.7', angle: 2.7 },
  { name: '3', angle: 3 },
  { name: '3.3', angle: 3.3 },
  { name: '3.7', angle: 3.7 },
  { name: '4.0', angle: 4.0 },
  { name: '4.3', angle: 4.3 },
  { name: '4.7', angle: 4.7 },
  { name: '5.0', angle: 5.0 },
  { name: '5.3', angle: 5.3 },
  { name: '5.7', angle: 5.7 },
  { name: '6.0', angle: 6.0 },
]; // Array of items with their names and angles


const colors1 = [
  "#94d886","#88d479","#c1eee6","#a6e7db",
  "#fdbd80","#fcad60","#f8ac9f","#f6917f","#f4755f"
]

const colors2 = [
  "#94d886","#88d479","#c1eee6","#a6e7db",
  "#fdbd80","#fcad60","#f8ac9f","#f6917f","#f4755f",
  "#0082b2", "#339bc1", "#bb4db3", "#c971c2",
  "#7d40bb", "#9766c9", "#f94040", "#fa6666", "#fb8c8c"
]

const angles1 = [ 0.3, 0.7, 1, 1.3, 1.7, 2.0, 2.3, 2.7, 3.0];

const angles2 = [0.3, 0.7, 1, 1.3, 1.7, 2.0, 2.3, 2.7, 3.0, 3.3, 3.7, 
  4.0, 4.3, 4.7, 5.0, 5.3, 5.7, 6.0]


const Speedometer = (props) => {

  let { type, value, diagvalue, heading, label } = props;
  const diag_val = diagvalue !== undefined ? Number(Number(diagvalue).toFixed(2)) : 0;
  const val = value !== undefined ? Number(Number(value).toFixed(2)) : 0;
  const currentValue = diagvalue !== undefined ? diag_val : val;
  const convertorVariable = currentValue < 3 ? 3 : 6; 

  const meterValue = type === "intervention" ? Math.max(0, diag_val) : Math.max(0, val);
  const maxValue = currentValue < 3.0 ? 3.0 : 6.0;
  const valueForAngleCalculation = meterValue >= maxValue ? maxValue : meterValue;
  const showWarning = currentValue >= maxValue;


  const renderLables = (items) =>{
    const radius = 140; // Radius of the semicircle
    const center = { x: 120, y: 110 }; // Center point of the semicircle
  
    // const items = currentValue < 3.0 ? items1 : items2
    const itemSize = 20; // Size of each item

    return items.map((item, index) => {
            let convertedAngle = (item.angle / convertorVariable) * 180
            let angle = (180 - convertedAngle)
            angle = (angle * Math.PI) / 180; // Convert angle to radians
            const x = center.x + radius * Math.cos(angle); // Calculate x position
            const y = center.y - radius * Math.sin(angle); // Calculate y position (negative because y-axis is inverted in CSS)
            return (
              <div
                key={index}
                className="item"
                style={{
                  width: `${itemSize}px`,
                  height: `${itemSize}px`,
                  top: `${y}px`,
                  left: `${x}px`
                }}
              >
                {item.name}
              </div>
            );
          });
  }

  const getGradientCss = () =>{

    let colors = currentValue < 3.0 ? colors1 : colors2;
    let angles = currentValue < 3.0 ? angles1 : angles2;
    const gradient = `conic-gradient(from -90deg, 
      ${colors.map((color, index) => `${color} ${ index && '0' } ${(angles[index] / convertorVariable) * 180}deg`).join(', ')}, 
      #0000 0
    )`;

    return {
      background: `radial-gradient(#ffffff 0 0) content-box, ${gradient}`,
      transition: 'none'
    };
  }

  const getNeedlePosition = () =>{
    let needleCurrentAngle = (270 + Math.trunc((valueForAngleCalculation / convertorVariable) * 180))
    return {
      transform: `rotate(${needleCurrentAngle}deg)`
    }
  }

  return (
    <div className="speedometterBx widget-bg-shadow speedometer-new-container">
      <p className='speedo-meter-heading'>{heading}</p>
      <div className='speedometer-new' style={getGradientCss()}>
        <div className='needle' style={getNeedlePosition()}>
        </div>
        <div className='labels'>{currentValue < 3.0 ? renderLables(items1) : renderLables(items2)}</div>
      </div>
      <div className='speedometer-footer'>
            {/* <p className='metervalue'>{meterValue}</p>
            <p className='label'>{label}</p> */}
            {showWarning &&
                <Tooltip
                  placement="top"
                  title="Value exceeds display range"
                  arrow
                >
                  <img src={WarningIcon} alt="warning icon" className='warning-yellow-icon'/>
                </Tooltip>
            }
            <p className='text'>{label} - {meterValue}</p>
      </div>
    </div>
  )
}

export default Speedometer