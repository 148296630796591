import React, { useContext } from "react";
import "./ReportHeader.scss";
// import printWhite from "../../../Assets/Images/print-white.svg"
import logo from "../../../Assets/Images/logo-white.png";
import formatDate from "../../../Utils/formatDate";
import BackButton from "../../../Components/BackButton/BackButton";
import { IframeContext } from "../../../Contexts/iframeContext";

const ReportHeader = (props) => {
  const { title, startDate, endDate, userName, filterList } = props;
  const filterKeys = Object.keys(filterList);
  const { iframe } = useContext(IframeContext);

  return (
    <div className="ReportHeader">
       <header className={`heroHeader ${iframe ? "ccs__iframe__active" : ""}`}>
        <nav className="navBar">
          <div className="logoContainer">
            <img src={logo} className="logoImg" alt="logo"></img>
            <span className="logo">Foresight Health Solutions</span>
          </div>
          <BackButton color={'#fff'} backgroundColor="transparent"/>
          {/* <div className="btnGroup">
            <button className="btn print">
              <img src={printWhite} alt="printlogo"></img>
              <span className="btnLabel" onClick={()=>window.print()}> Print </span>
            </button>
          </div> */}
        </nav>
        <div className="subHeading">
          <h1 className="reportTitle">{title}</h1>
          <div className="dateRange">
            <span>
              Service date range: {formatDate(startDate)} -{" "}
              {formatDate(endDate)}
            </span>
          </div>
        </div>
        <section className="section__container">
          <div className="detailedHeader">
            <div className="colLeft">
              <div>
                <span className="infoLeft">Report created by:</span>
                <span className="infoRight">{userName}</span>
              </div>
              {filterKeys.map((element, index) =>
                filterList[element] ? (
                  <div key={index}>
                    {element !== "cohort" ? (
                      <span className="infoLeft">{element}:</span>
                    ) : null}
                    <span className="infoRight">{filterList[element]}</span>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </section>
      </header>
    </div>
  );
};
export default ReportHeader;
