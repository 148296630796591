import React, { useState,useEffect } from "react";
import {
    Breadcrumbs, Grid, List, ListItemButton,
    ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography,
    Card, CardHeader, CardContent, Alert
} from "@mui/material";
import { Link } from "react-router-dom";
import "./DataContentAnalysis.scss";
import { styled } from "@mui/styles";
import { useParams } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import withRouter from "../../Utils/withRouterHOC";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import services from "../../Services/patientServices";
import Spinner from "../../Components/Spinner/Spinner";
import dayjs from "dayjs";

const COLUMN_FLAGS = {
    "Right Tick" :{
        icon : <CheckCircleRoundedIcon sx={{ fill: '#36E948 !important' }} />
    },
    "Warning" :{
        icon : <WarningRoundedIcon sx={{ fill: '#ffc107 !important' }} />
    },
    "Error" :{
        icon : <ErrorRoundedIcon sx={{ fill: '#dc3545 !important' }} />
    }

}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    border: '0.5px solid #7D7B7B',
    backgroundColor: '#F7F7F7 !important',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
    color: theme.palette.text.secondary,
}));

const FileDetails = ()  => {
    const params = useParams();

    const [isFetching, setIsFetching] = useState(false);
    const [result, setResult] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);

    const handleListItemClick = (event, data) => {
        setSelectedKey(data);
    }

    const getDcaOutput = async () => {
        setIsFetching(true);
        try {
          const { data } = await services.getPreviousDcaOutput(params.file_key,params.file_name);
          if (Object.keys(data).length>0) {
              const colKeys = Object.keys(data["variables"]);
            setResult(data)
            setSelectedKey(data["variables"][colKeys[0]]);
          }
          
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      const getMinMaxResult = (category, value) =>{

        if(category === "DateTime"){
            return dayjs(value).format('YYYY-MM-DD')
        }else if (category === "Numeric"){
            return value
        }
        return "NULL"
      }
    
      useEffect(() => {
        getDcaOutput();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const createVariables = ()=>{
        let a = []
        for (const [key,value] of Object.entries(result["variables"])) {
            a.push(<div key={key}><Tooltip key={key} arrow placement="top" title={value["column_flag_message"]}>
                <ListItemButton
                   
                    selected={value === selectedKey ? true :false}
                    divider
                    // selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, value)}
                >
                    <ListItemIcon>
                        {value["column_flag"]? COLUMN_FLAGS[value["column_flag"]].icon:""}
                    </ListItemIcon>
                    <ListItemText primary={key} />
                </ListItemButton>
            </Tooltip></div>)
        }
        return a
    }

    return (
        <Sidebar>
            <div className="breadcrums">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >

                    <Link  color="inherit" to="/data-content-analysis">
                        <HomeIcon />
                    </Link>
                    <Link  color="inherit" style={{color:"#2993CE"}} to="/data-content-analysis/dca-new-output">
                        {"View Previous Assessments"}
                    </Link>
                    <Typography key="3" color="#2993CE">
                        {params.file_name}
                    </Typography>
                </Breadcrumbs>
            </div>
            {isFetching ? (<Grid container direction="row" alignItems="center" >
                        <Spinner />
                    </Grid>) : !isFetching && !result ? (<Grid container direction="row" alignItems="center" >
                        <Alert icon={false} severity="warning">
                            There is no previous assessments available.
                        </Alert>
                    </Grid>):(<div className="m-5">
                                    <Card sx={{ borderRadius: 0 }}>
                                        <CardHeader 
                                            title={params.file_name ? params.file_name : 'File'}
                                            sx={{ 
                                                backgroundColor: '#2993CE', 
                                                color: '#fff', 
                                                borderRadius: 0, 
                                                padding: "0.75rem 1.25rem !important" 
                                            }} 
                                            titleTypographyProps={{
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                            }}
                                        />
                                        <CardContent >
                                            <div style={{ padding: '20px' }}>
                                                <div style={{ marginBottom: '30px' }}>
                                                    <Card>
                                                        <CardHeader 
                                                        style={{ 
                                                            color: '#2993CE', 
                                                            backgroundColor: "#00000008",
                                                            padding: "0.75rem 1.25rem",
                                                            borderBottom: "1px solid rgba(0,0,0,.125)" 
                                                            }}
                                                            title="Overview"
                                                            titleTypographyProps={{
                                                                fontSize: "16px",
                                                                lineHeight: "24px",
                                                            }}
                                                        />    
                                                        <CardContent>
                                                            <Grid container rowSpacing={1}>
                                                                <Grid item xs={4}><Typography>Uploaded on: <b>{result["Uploaded On"]}</b></Typography> </Grid>
                                                                <Grid item xs={4}><Typography>Uploaded by: <b>{result["Uploaded by User"]}</b></Typography></Grid>
                                                                <Grid item xs={4}><Typography>Size: <b>{result["table"]["n"]} Row, {result["table"]["n_var"]} Col</b></Typography></Grid>
                                                                <Tooltip arrow placement="top" title={`${result["Total Members"]} member IDs seen in the entire data drop`} >
                                                                    <Grid item xs={4}>
                                                                        <Typography>Members: <b>{result["Total Members"]}</b></Typography>
                                                                    </Grid>
                                                                </Tooltip>
                                                                <Grid item xs={6}><Typography>Service date range: <b>{dayjs(result["table"]["Service Date Min"]).format('MMM DD YYYY')} - {dayjs(result["table"]["Service Date Max"]).format('MMM DD YYYY')}</b></Typography></Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                                <Grid container columnSpacing={3} direction='row' >
                                                    <Grid item xs={3}>
                                                        <Typography fontWeight={700} fontSize={'20px'} color={'#0080B2'}>Column Names</Typography>
                                                        <List component="nav">
                                                            {createVariables()}
                                                        </List>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Typography fontWeight={700} fontSize={'20px'} color={'#0080B2'}>Column Details</Typography>
                                                        <Stack spacing={1} width={500} paddingTop={3}>
                                                            <Item>Data Type : {selectedKey["type"]}</Item>
                                                            <Item>Completeness : {selectedKey["p_missing"] >=0 ? `${Number(100 - selectedKey["p_missing"] * 100).toFixed(0)}%` :""}</Item>
                                                            <Item>Min :  {getMinMaxResult(selectedKey["type"], selectedKey["min"])}</Item>
                                                            <Item>Max :  {getMinMaxResult(selectedKey["type"], selectedKey["max"])}</Item>
                                                            <Item>Unique Values : {selectedKey["n_distinct"]}</Item>
                                                            <Item>NULL Count : {selectedKey["n_missing"]}</Item>
                                                            <Item>Not NULL Count : {selectedKey["count"]}</Item>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </CardContent>
                                    </Card>
                              </div>)}
        </Sidebar>
    );
}

export default withRouter(FileDetails);