const stringTruncate = (text, limit) =>
  text?.length > limit ? text.substr(0, limit).trim() + "..." : text;

const middleTruncate = (fullStr, strLen, separator) => {
  if (fullStr?.length <= strLen) return fullStr;

  separator = separator || "...";

  var sepLen = separator?.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr?.length - backChars)
  );
};

export {
  stringTruncate,
  middleTruncate
};
  