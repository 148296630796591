import { Link } from "react-router-dom";
import { Facebook, LinkedIn } from '@mui/icons-material';
import logo from "../../Assets/Images/logo-white.png";

export function LandingPageFooter() {
    const redirectTo = (url) => {
        window.location.href = (url)
    }
    return (
        <footer className="footer pt-4 px-5" style={{ position: "relative" }}>
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-2">
                    <Link to="#" className="navbar-brand">
                        <img src={logo} height="50" alt="CoolBrand" />
                    </Link>
                    </div>
                    <div className="col-md-2">
                        <ul className="list-unstyled">
                            <li>
                                <Link to="#!">Home</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <ul className="list-unstyled">
                            <li>

                                <Link to="#">Our Solutions</Link>
                            </li>
                            <li>
                               <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/careAnalytics.html')}>Care Analytics</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/technical.html')}>Technology</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <ul className="list-unstyled">
                            <li>
                                <Link to="#">Who We Serve</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/whoweserve.html')}>Community Health Centers</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/whoweserve.html#CommunityBasedOrganizations')}>Community Based Organizations</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo("https://www.foresighthealthsolutions.com/whoweserve.html#ProviderHealthNetworks")}>Provider Health Networks</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/whoweserve.html#HealthPlans')}>Health Plans</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <ul className="list-unstyled">
                            <li>
                                <Link to="#">About Us</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/company.html')}>Company</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/careers.html')}>Careers</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/news.html')}>News</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/contact.html')}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <ul className="list-unstyled">
                            <li>
                                <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/resources.html')}>Resources</Link>
                            </li>
                        </ul>
                    </div>
                
                </div>

            </div>
            <hr className="footer-separator" />
                <div className="row copyright-container">
                    <div className="col-sm-12 col-md-8">
                        <p className="footer-copyright">Copyright © Designed &amp; Developed by Foresight Health Solutions. All rights reserved.</p>
                    </div>
                    <div className="col-sm-12 col-md-4 text-center">
                        <div className="follow-us">
                            <h6>
                                <b className="mx-2 ">Follow Us</b> 

                                <Link to="#" onClick={() => window.open("https://www.facebook.com/ForesightHealthSolutionsPvtLtd/")}>
                                <Facebook />
                                </Link>
                                <Link to="#" onClick={() => window.open("https://www.linkedin.com/company/foresight-health-solutions/")}>
                                    <LinkedIn />
                                </Link>
                            </h6>
                        </div>
                    </div>
            </div>
        </footer>
    )
}

