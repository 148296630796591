import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Header from '../Header/DashboardHeader';
import { useLocation, useNavigate } from 'react-router-dom'
import { PyramidContext } from "../../Contexts/uiContext";
import Tooltip from '@mui/material/Tooltip';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import dashlogo from "../../Assets/Images/dashboard-logo.png";
import mobLogo from "../../Assets/Images/mobLogo.png";

import ContentAssessmentIcon from "../../Assets/Images/sidebar-content.png";
import StatsIcon from "../../Assets/Images/sidebar-overview.png";
import SummaryIcon from "../../Assets/Images/sidebar-summary.png";
import ContributorsIcon from "../../Assets/Images/sidebar-contributor.png";
import InterventionsIcon from "../../Assets/Images/sidebar-intervention.png";
import PatientListIcon from "../../Assets/Images/sidebar-patients.png";
import SimulationsIcon from "../../Assets/Images/sidebar-simulation.png";
import FinanceForesightsIcon from "../../Assets/Images/sidebar-dollar.png";
import GeographicIcon from "../../Assets/Images/sidebar-geographic.png";
import NLPReviewIcon from "../../Assets/Images/sidebar-nlp.png";
import DisparityDashboardIcon from "../../Assets/Images/disparityDashboardIcon.png"
// import GeoIcon from "../../Assets/Images/sidebar-geo.png";
import HelpIcon from "../../Assets/Images/sidebar-help.png";
import PremiumIcon from "../../Assets/Images/sidebar-premium.png";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReportsIcon from "../../Assets/Images/sidebar-report.png";
import ComparativeAnalyticsIcon from "../../Assets/Images/sidebar-comparison.png";
import ProviderProfilingIcon from "../../Assets/Images/sidebar-providers.png";
import UploadIcon from "../../Assets/Images/sidebar-upload.png";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BetaIcon from "../../Assets/Images/beta-icon.png";
import './Sidebar.scss';
import FeatureNotAvailable from '../Modal/FeatureNotAvailable';
import { sidebarContext } from '../../Contexts/sidebarContext';
import { toCapitalize } from '../../Utils/stringOprations';
import { PatientSetContext } from '../../Contexts/patientSetContext';
import { useMediaQuery } from 'react-responsive';
import withRouter from '../../Utils/withRouterHOC';

const drawerWidth = 240;
let isScreenHeightLessThan750 = false;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow:
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  border: "0px",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + ${isScreenHeightLessThan750 ? '15px' : '1px'})`,
  boxShadow:
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  border: "0px",
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  '& .mob-logo': {
    width: '30px',
    cursor: 'pointer',
    alignSelf: "center",
    marginRight: "4px",
  },
  '& .dash-logo': {
    cursor: 'pointer',
    padding: "15px",
    marginLeft: "auto",
  },
  width: "100%",
}));

const DrawerFooter = styled('div')(({ open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: "100%",
  marginBottom: "5px",
  ...(open && {
    justifyContent: 'flex-end',
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) =>({
    width: `calc(100% - ${theme.spacing(7)} - ${isScreenHeightLessThan750 ? '15px' : '0px'} + 1px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    boxShadow: "none",
    border: "0px",
  })
);

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const StyledBox = styled(Box)(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(7)})`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

const Sidebar = (props) => {
  const { info, setInfo } = useContext(PyramidContext);
  const { showSidebar, navData } = useContext(sidebarContext)
  const { patientSetInfo } = useContext(PatientSetContext);
  const [selectedRoute, setSelectedRoute] = useState(0);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [featureDescription, setFeatureDescription] = useState("");
  const history = useNavigate();
  isScreenHeightLessThan750 = useMediaQuery({ maxDeviceHeight: 750 });

  const icon = {
    DisparityDashboardIcon,
    SummaryIcon,
    StatsIcon,
    ContributorsIcon,
    InterventionsIcon,
    HelpIcon,
    PatientListIcon,
    SimulationsIcon,
    ReportsIcon,
    GeographicIcon,
    ComparativeAnalyticsIcon,
    ProviderProfilingIcon,
    NLPReviewIcon,
    ContentAssessmentIcon,
    FinanceForesightsIcon,
    UploadIcon
  };


  useEffect(() => {
    const { route } =
      navData.find((item) => item.route === location.pathname) || {};
    setSelectedRoute(route);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setSelectedRoute, navData]);

  const handleDrawerOpen = () => {
    setInfo({ open: true });
  };

  const handleDrawerClose = () => {
    setInfo({ open: false });
  };

  const backToHome = () => {
    history('/');
  };

  const handleMenuClick = ({ route, pageName, state, description }) => {
    let currentLocation = `${location.pathname}`.slice(1);

    if (state === "locked" || state === "no-data") {
      setFeatureDescription(description);
      setOpen(true);
      return;
    }

    if (route !== '/help') {
      const infoData = {
        ...info,
        currentui: pageName,
        prevui: currentLocation,
        pyramidRiskStatus: 'none',
      }

      setInfo(infoData);
      history(route);
    }
    else {
      window.location.href =
        "mailto:app-support@foresighthealthsolutions.com";
    }
  };

  /**
   * Coustumizable Routes
   * @param {*} data 
   * @returns 
   */
  const coutumizedRoute = (data) => {

    switch (data.featureName) {
      case "stats":
        return `${toCapitalize(patientSetInfo?.cohort_name)} Overview`;
      case "patient-list":
        return `${toCapitalize(patientSetInfo?.patient_name)} List`;
      default:
        return data.title;
    }

  }

  return (
    <>
      {showSidebar ? (
        <>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={info.open}>
              <Toolbar>
                <Header pageTitle={props?.pageTitle} />
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={info.open}>
              <DrawerHeader>
                {info.open ? (
                  <img
                    src={dashlogo}
                    className="dash-logo"
                    alt="logo"
                    onClick={backToHome}
                  />
                ) : (
                  <img
                    src={mobLogo}
                    className="mob-logo"
                    alt="logo"
                    onClick={backToHome}
                  />
                )}
              </DrawerHeader>
              <List sx={{ height: "100vh", backgroundColor: "#fff" }}>
                {navData.map((item, index) => (
                    <Tooltip
                      key={index}
                      title={info.open ? "" : coutumizedRoute(item)}
                      placement="right"
                      arrow
                    >
                      <ListItem
                        // button
                        selected={selectedRoute === item.route}
                        onClick={() => patientSetInfo ? handleMenuClick(item) : ''}
                        className={`MuiListItem-button ${item.route === "#" ? "disabled-image" : null}`}
                        sx={{ cursor: patientSetInfo ? 'pointer' : 'not-allowed' }}
                        disabled={patientSetInfo ? false : true}
                      >
                        <ListItemIcon>
                          {
                            <span style={{ position: "relative" }}>
                              <img
                                src={icon[item.icon]}
                                className={item.className}
                                alt={item.title}
                              />
                              {item.state === "premium" ? (
                                <img
                                  src={PremiumIcon}
                                  className="premiumIcon"
                                  alt={"Premium Icon"}
                                />
                              ) : item.state === "locked" ? (
                                <LockOutlinedIcon className="lockIcon" />
                              ) : item.state === "beta" ? (
                                <img src={BetaIcon} className="betaIcon" alt="beta-icon" />
                              ) : null}
                            </span>
                          }
                        </ListItemIcon>
                          <ListItemText primary={coutumizedRoute(item)}
                            primaryTypographyProps={ 
                              info.open ? {
                              style: {
                                whiteSpace: 'wrap',
                                overflow: 'hidden',
                              }
                            } : null} />
                      </ListItem>
                    </Tooltip>
                ))}
              </List>
            </Drawer>
            <StyledBox component="main" open={info.open}>
              <DrawerHeader />
              {props.children}
            </StyledBox>
          </Box>
          {/* Drawer Footer Collapse/Expand Button */}
          <DrawerFooter
            open={info.open}
            className={`${info.open ? "openDrawer" : "closeDrawer"}`}
          >
            {info.open ? (
              <IconButton onClick={handleDrawerClose} sx={{ height: "unset" }}>
                <ArrowBackIosNewIcon className="expand-collapse-icon" />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerOpen} sx={{ height: "unset" }}>
                <ArrowForwardIosIcon className="expand-collapse-icon" />
              </IconButton>
            )}
          </DrawerFooter>
        </>
      ) : (
        <div>{props.children}</div>
      )}

      <FeatureNotAvailable
        open={open}
        setOpen={setOpen}
        description={featureDescription}
      />
    </>
  );
};

export default withRouter(Sidebar);
