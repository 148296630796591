import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PatientSetContext } from '../../Contexts/patientSetContext';

const ConfirmEdit = ({ open, setOpen, modalData, handlePermissionChange }) => {
  const { patientSetInfo } = useContext(PatientSetContext);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove {modalData.name} permissions?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You are about to remove {modalData.name} permissions on this {patientSetInfo.cohort_name}. 
          This will close this page, and you will no longer have access to these Settings. Continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" className="confirm-edit-button">
            Cancel
          </Button>
          <Button 
            onClick={() => {
              handlePermissionChange(modalData.type, modalData.user_id);
              handleClose();
            }} 
            color="primary" 
            autoFocus
            className="confirm-edit-button"
          >
            Remove my access
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmEdit;
