import React from "react";
import { AuthProvider } from "./authContext";
import { PyramidProvider } from "./uiContext";
import { PatientsProvider } from "./totalPatientsContext";
import { InterventionProvider } from "./interventionColor";
import { PatientViewProvider } from "./patientViewContext";
import { DropdownFilterProvider } from "./dropdownContext";
import { AnalyticsProvider } from "./analyticsContext";
import { MetaDescriptionProvider } from "./metaDetailsContext";
import { SidebarProvider } from "./sidebarContext";
import { PatientSetProvider } from "./patientSetContext";
import { IframeProvider } from "./iframeContext";
import { WcchcPatientSummaryProvider } from "./wcchcPatientSummaryContext";
import { PatientProvider } from "./patientContext";
import { ToastProvider } from "./ToastContext";

const provider = (provider, props = {}) => [provider, props];

const providers = [
  provider(MetaDescriptionProvider),
  provider(AuthProvider),
  provider(PyramidProvider),
  provider(PatientsProvider),
  provider(InterventionProvider),
  provider(PatientViewProvider),
  provider(DropdownFilterProvider),
  provider(AnalyticsProvider),
  provider(IframeProvider),
  provider(SidebarProvider),
  provider(PatientSetProvider),
  provider(WcchcPatientSummaryProvider),
  provider(ToastProvider),
  provider(PatientProvider),
];

const MultiProvider = ({ children }) => {
  for (let i = providers.length - 1; i >= 0; --i) {
    const [Provider, props] = providers[i];
    children = <Provider {...props}>{children}</Provider>
  }
  return children;
}

export default MultiProvider;
