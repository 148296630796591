import format from "./formatNumber";

const calculatePyramidValues = (type, data, selected_date) => {
    const pyramidData = data[type].riskData.find(item => item.date.slice(0, 10) === selected_date);
    //Converts mysql datetime stamp to javascript date object

    const pyramidItems = [
      {
        label: pyramidData ? format(pyramidData.cat4_cnt.count) : 0,
        level: pyramidData,
        name: "top",
        risk: pyramidData ? pyramidData.cat4_cnt.stratus : "critical"
      },
      {
        label: pyramidData ? format(pyramidData.cat3_cnt.count) : 0,
        level: pyramidData - 1,
        name: "middle",
        risk: pyramidData ? pyramidData.cat3_cnt.stratus : "high"
      },
      {
        label: pyramidData ? format(pyramidData.cat2_cnt.count) : 0,
        level: pyramidData - 2,
        name: "bottom",
        risk: pyramidData ? pyramidData.cat2_cnt.stratus : "moderate"
      },
      {
        label: pyramidData ? format(pyramidData.cat1_cnt.count) : 0,
        level: pyramidData - 3,
        name: "last",
        risk: pyramidData ? pyramidData.cat1_cnt.stratus : "low"
      },
    ];

    const unknownCount = pyramidData ? format(pyramidData.cat0_cnt.count) : 0;

    return { pyramidItems, unknownCount };
  };

  export default calculatePyramidValues;