import React, { createContext, useState, useCallback } from "react";
import services from "../Services/patientServices";
import mergeLocalStorage from '../Utils/mergeLocalStorage';
import calculatePyramidValues from "../Utils/calculatePyramidValues";
import calculateSeries from "../Utils/calculateSeries";
import progressionChartValues from "../Utils/progressionChartValues";

const PatientsNumberContext = createContext();

function PatientsProvider(props) {

  const [dataAvailable, setDataAvailable] = useState(false);
  const [filteredDate, setFilteredDate] = useState([]);

  // pie chart
  const [patients, setPatients] = useState({
    total: 0,
    num_updates: 0,
    active_count: 0,
    unknown_count: 0
  });

  // risk stats
  const [hccData, setHccData] = useState([]);

  // bar chart
  const series = [
    {
      name: "Risk Category 4",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "Risk Category 3",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "Risk Category 2",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "Risk Category 1",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "Median",
      type: 'line',
      data: [0, 0, 0, 0],
    }
  ];
  const options = {
    xaxis: {
      type: "category",
      categories: [0, 0, 0, 0],
    },
  };

  //Bar chart - Comp
  const [seriesComp, setSeriesComp] = useState(series);
  const [optionsComp, setOptionsComp] = useState(options);
  const [pmpmData, setPmpmData] = useState({});
  const [customAttrChartData, setCustomAttrChartData] = useState();
  const [totalPmpmCost, settotalPmpmCost] = useState(null);

  //loader for custom attribute chart
  const [customAttributeChartLoader, setCustomAttributeChartLoader] = useState(false);

  //Bar chart - Imp
  const [seriesImp, setSeriesImp] = useState(series);
  const [optionsImp, setOptionsImp] = useState(options);

  const histogramOptions = {
    xaxis: {
      categories: [0, 0, 0, 0, 0, 0]
    },
  }
  const histogramSeries = [{data:[]}];

  //Histogram - comp & diag
  const [histograms, setHistograms] = useState({
    compHistogram: {
      smallHistogram: { series: histogramSeries, options: histogramOptions },
      largeHistogram: { series: histogramSeries, options: histogramOptions },
    },
    diagHistogram: {
      smallHistogram: { series: histogramSeries, options: histogramOptions },
      largeHistogram: { series: histogramSeries, options: histogramOptions },
    },
  });

  // Pyramid chart
  const [pyramidComp, setPyramidComp] = useState([]);
  const [pyramidImp, setPyramidImp] = useState([]);
  const [pyramidDiag, setPyramidDiag] = useState([]);
  const [pyramidQual, setPyramidQual] = useState([]);

  // Unknown count
  const [unknownCountComp, setUnknownCountComp] = useState(0);
  const [unknownCountImp, setUnknownCountImp] = useState(0);
  const [unknownCountDiag, setUnknownCountDiag] = useState(0);
  const [unknownCountQual, setUnknownCountQual] = useState(0);

  //Selectable dates
  const [selectableDates, setSelectableDates] = useState([]);
  const [calculationDate, setCalculationDate] = useState([]);

  // comprehensive and Impactability Progression chart dates
  const [compCalculationTime, setCompCalculationTime] = useState([]);
  const [compServiceDateEnd, setCompServiceDateEnd] = useState([]);
  const [impCalculationTime, setImpCalculationTime] = useState([]);
  const [impServiceDateEnd, setImpServiceDateEnd] = useState([]);

  const fetchData = useCallback(async () => {
    const patient_set = localStorage.getItem("patient_set");
    const { patient_set_id: pid, selected_date } = JSON.parse(patient_set);

    //Start the loading spinner
    setDataAvailable(true);

    const [casedataResponse, histogramResponse] = await Promise.all([
      services.caseLoad(pid, {
        cohort_last_updated: selected_date,
      }),
      services.histogramData({
        id: pid,
        cohort_last_updated: selected_date,
      }),
    ]);

    const data = casedataResponse.data.patientset;

    /* ----------SELECTED DATES -------- */

    const calculation_time = data["comprehensive"].distribution.map((item) =>
      item.calculationTime.slice(0, 10)
    );
    setCalculationDate(calculation_time);

    const selectable_dates = data.selectable_dates.map((item) =>
      item.service_date_end.slice(0, 10)
    );

    setSelectableDates(selectable_dates);
    mergeLocalStorage("patient_set", {
      selectable_dates,
    });

    /* ----------- PIE CHART ----------- */
    const { all_time_patient_count, num_updates, active_patient_count } = data;
    setPatients({
      total: all_time_patient_count,
      num_updates: num_updates,
      active_count: active_patient_count,
    });
    localStorage.setItem("total_patient_count", JSON.stringify({ count: all_time_patient_count, pid }));

    /* ---------- RISK STATS ------------ */
    const hccdata = data.comprehensive.stats;
    setHccData(hccdata);
    setPmpmData(data.pmpm_costs);

    // Active patient total cost
    settotalPmpmCost(data.pmpm_total_cost);

    /* ---------- BAR CHART ------------ */
    const compDateFilter = progressionChartValues(
      data,
      "comprehensive",
      selected_date
    );
    // Filtered last 12 month calculation and service date end dates for comprehensive data
    const compCalculationTime = compDateFilter?.comprehensive?.riskData
      ?.map(({ calculationTime }) => calculationTime?.slice(0, 10))
      .reverse();
    const compServiceDateEnd = compDateFilter?.comprehensive?.riskData
      ?.map(({ serviceDateEnd }) => serviceDateEnd?.slice(0, 10))
      .reverse();
    setCompCalculationTime(compCalculationTime);
    setCompServiceDateEnd(compServiceDateEnd);
    const compSeries = calculateSeries("comprehensive", compDateFilter);
    setOptionsComp({ xaxis: { categories: compSeries.labels } });
    setSeriesComp(compSeries.finalSeries);

    const impFilter = progressionChartValues(
      data,
      "impactability",
      selected_date
    );
    const impSeries = calculateSeries("impactability", impFilter);
    // Filtered last 12 month calculation and service date end dates for Impactability data
    const impCalculationTime = impFilter?.impactability?.riskData
      ?.map(({ calculationTime }) => calculationTime?.slice(0, 10))
      .reverse();
    const impServiceDateEnd = impFilter?.impactability?.riskData
      ?.map(({ serviceDateEnd }) => serviceDateEnd?.slice(0, 10))
    setImpCalculationTime(impCalculationTime);
    setImpServiceDateEnd(impServiceDateEnd);

    setOptionsImp({ xaxis: { categories: impSeries.labels } });
    setSeriesImp(impSeries.finalSeries);

    //Pyramid - Comp and Imp
    const pyramidValuesComp = calculatePyramidValues(
      "comprehensive",
      data,
      selected_date
    );
    const pyramidValuesImp = calculatePyramidValues(
      "impactability",
      data,
      selected_date
    );
    const pyramidValuesDiag = calculatePyramidValues(
      "diagnostic",
      data,
      selected_date
    );
    const pyramidValuesQual = data["quality"]?.riskData.length ? calculatePyramidValues(
      "quality",
      data,
      selected_date
    ) : null;

    setPyramidComp(pyramidValuesComp.pyramidItems);
    setPyramidImp(pyramidValuesImp.pyramidItems);
    setPyramidDiag(pyramidValuesDiag.pyramidItems);
    setPyramidQual(pyramidValuesQual?.pyramidItems);

    //Unknown Count - Comp and Imp
    setUnknownCountComp(pyramidValuesComp.unknownCount);
    setUnknownCountImp(pyramidValuesImp.unknownCount);
    setUnknownCountDiag(pyramidValuesDiag.unknownCount);
    setUnknownCountQual(pyramidValuesQual?.unknownCount);

    //Stop the loading spinner
    setDataAvailable(false);

    /* ------------ HISTOGRAM ------------- */

    const histogramData = histogramResponse.data.data.histogram_data

    if (histogramData.length) {
      let newCompHistogram = {};
      let newDiagHistogram = {};
      histogramData.forEach(statType => {
        const distribution_bins = statType.distribution_bins !== '' ? JSON.parse(statType.distribution_bins) : [[], []];
        const distribution_values = statType.distribution_values !== '' ? JSON.parse(statType.distribution_values) : [[], []];

        if (statType.stats_type === "comprehensive") {

          newCompHistogram = {
            smallHistogram: {
              series: [{ data: distribution_values[0] }],
              options: { xaxis: { categories: distribution_bins[0] } },
            },
            largeHistogram: {
              series: [{ data: distribution_values[1] }],
              options: { xaxis: { categories: distribution_bins[1] } },
            },
          };
        }
        else if (statType.stats_type === "diagnostic") {

          newDiagHistogram = {
            smallHistogram: {
              series: [{ data: distribution_values[0] }],
              options: { xaxis: { categories: distribution_bins[0] } },
            },
            largeHistogram: {
              series: [{ data: distribution_values[1] }],
              options: { xaxis: { categories: distribution_bins[1] } },
            },
          };
        }
      });

      // update comp and diag histograms 
      setHistograms((prevState) => ({
        ...prevState,
        compHistogram: newCompHistogram,
        diagHistogram: newDiagHistogram,
      }));
    }
  }, []);

  /**
   * Get Custom Attribute Chart data from API Service
   */
  const fetchCustomAttributeChartData = useCallback(async () => {
    setCustomAttributeChartLoader(true);

    const patient_set = localStorage.getItem('patient_set');
    const { patient_set_id: pid, selected_date } = JSON.parse(patient_set);
    
    const customAttributeChartData = await services.customAttributeChartData({ cohort_id: pid, selected_date });
    setCustomAttrChartData(customAttributeChartData?.data);
    setCustomAttributeChartLoader(false);
  },[])
 

  const startStats = () => {
    const abortController = new AbortController();

    try {
      fetchData();
    } catch (error) {
      if (abortController.signal.aborted) {
        // cancelled
      }
      else
        throw error;
    }

    return () => {
      abortController.abort();
    };
  };


  return (
    <PatientsNumberContext.Provider
      value={{
        patients,
        hccData,
        pmpmData,
        totalPmpmCost,
        customAttrChartData,
        seriesComp,
        optionsComp,
        seriesImp,
        optionsImp,
        pyramidComp,
        pyramidImp,
        pyramidDiag,
        pyramidQual,
        dataAvailable,
        fetchData,
        startStats,
        fetchCustomAttributeChartData,
        customAttributeChartLoader,
        unknownCountComp,
        unknownCountImp,
        unknownCountDiag,
        unknownCountQual,
        selectableDates,
        calculationDate,
        filteredDate,
        setFilteredDate,
        impCalculationTime,
        impServiceDateEnd,
        compCalculationTime,
        compServiceDateEnd,
        histograms
      }}
    >
      {props.children}
    </PatientsNumberContext.Provider>
  );
}

export { PatientsNumberContext, PatientsProvider };
