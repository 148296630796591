/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import Spinner from "../../Components/Spinner/Spinner";
import { Checkbox, MenuItem, Select, Typography } from "@mui/material";
import LoadFilters from "../ComparisonAnalytics/LoadFilters";
import { toCapitalize, toLowerCase } from "../../Utils/stringOprations";
import ComparisonStats from "../ComparisonAnalytics/ComparisonStats";
import Sidebar from "../../Components/Sidebar/Sidebar";
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import "../ComparisonAnalytics/ComparisonAnalytics.scss";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import SaveComparison from "../ComparisonAnalytics/SaveComparison";
import AssessmentIcon from "@mui/icons-material/Assessment";
import services from "../../Services/patientServices";
import { TablePagination } from "@mui/material";

const ProviderTypeOptions = [
  "FQHC",
  "Hospital",
  "Hospital-Affiliated Clinic",
  "IPA/Independent Group Practice",
  "Group provider",
  "Independent practitioner",
];

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      backgroundColor: "#0080B2 !important",
      color: "#fff",
      "& svg": {
        fill: "#fff !important",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#D1F2FF !important",
      color: "#0080B2",
    },
    "&.MuiMenuItem-dense": {
      padding: "0px 16px",
    },
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      borderColor: "#0080B2",
    },
  },
  checkItem: {
    fill: "#0080B2 !important",
  },
  selectIcon: {
    "&.MuiSelect-iconOpen": {
      fill: "#0080B2 !important",
    },
  },
}));

const ProviderProfiling = () => {
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [chartType, setChartType] = useState('barchart');
  const [isFetching, setIsFetching] = useState(false);
  const [showAllColumns, setShowAllColumns] = useState(true);
  const [showAllRows, setShowAllRows] = useState(true);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(11);
  const [filteredTable, setFilteredTable] = useState([]);
  const [showSave, setShowSave] = useState(false);
  const [showLoadFilters, setShowLoadFilters] = useState(false);
  const [selectedComparison, setSelectedComparison] = useState(null);
  const [openStatsModal, setOpenStatsModal] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedProviderTypes, setSelectedProviderTypes] = useState(["All"]);
  const { setPageTitle } = useContext(MetaDetailsContext);
  const [chartBenchmarks, setChartBenchmarks] = useState();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1);

  useEffect(() => {
    setPageTitle("FHS: Provider Profiling");
    getProviderTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedTimeFrame, selectedProviderTypes]);

  const onPageChange = (event, selectedPage) => {
    setPage(selectedPage);
  };

  const getProviderTypes = async () => {
    try {
      setIsFetching(true);
      const type = selectedProviderTypes.includes("All") ? [] : selectedProviderTypes
      const result = await services.providerProfiling({
        timeframe: selectedTimeFrame,
        page: page,
        type: type
      });
      filterColumns(result.data.columns, result.data.data);
      filterTableByProvider(result.data.data);

      selectedComparison && setSelectedComparison(null);
      setTotalCount(result.data.totalCount);
      setIsFetching(false);
      return result.data.data;
    } catch (error) {
      console.log("Error while fetching provider profiling", error);
      setIsFetching(false);
    }
  };

  // Filter columns and update the state with the show/hide status
  const filterColumns = (columns, rowData) => {
    // Clone the original columns array and set the default show and disable properties for each column
    let filteredColumns = columns.map((col) => ({
      ...col,
      show: true,
      disable: false,
    }));

    // Iterate through each column and check if it should be disabled (if all values in that column are '-' or null)
    filteredColumns = filteredColumns.map((col) => {
      col.disable = false; // (rowData.every(row => row[col.key] === '-' || row[col.key] == null));
      col.show = true; // !col.disable; // Update the show property based on the disable status
      return col;
    });

    setColumns(filteredColumns);
  };

  const handleTimeChange = (event) => {
    setSelectedTimeFrame(event.target.value);
    // getProviderTypes();
  };

  // Handler for column filtering (show/hide columns)
  const handleColumnFilter = (key) => {
    let filterColumns = columns;
    // When individual column option is clicked, toggle the show/hide status
    if (key !== "all") {
      filterColumns = columns.map((col) => {
        if (col.key === key) {
          col.show = !col.show;
        }
        return col;
      });
      setShowAllColumns(
        filterColumns.every(
          (col) => (col.show && !col.disable) || (col.disable && !col.show)
        )
      );
    } // When 'All' option is clicked, toggle the show/hide status for all columns
    else {
      const show = !showAllColumns;
      if (show) {
        // if status is checked
        filterColumns = columns.map((col) =>
          col.disable ? { ...col } : { ...col, show: true }
        );
      } else {
        // if status is unchecked
        filterColumns = columns.map((col) => ({ ...col, show: false }));
      }
      setShowAllColumns(show);
    }
    setColumns(filterColumns);
  };

  const filterTableByProvider = (providerTable) => {
    const filteredData = providerTable.map((data) => {
      const tempData = { ...data, show: true };
      if (filteredTable.length) {
        filteredTable.forEach((i) => {
          if (i.provider_id === data.provider_id) {
            tempData.show = i.show === undefined ? true : i.show;
          }
        });
      }
      return tempData;
    });

    setShowAllRows(filteredData.every((row) => row.show));
    setFilteredTable(filteredData);
  };

  // Handler for table row filtering (show/hide columns)
  const handleProviderChange = (rowId) => {
    let filterRows = filteredTable;
    // When individual provider option is clicked, toggle the show/hide status of the row
    if (rowId !== "all") {
      filterRows = filteredTable.map((row) => {
        if (row.provider_id === rowId) {
          row.show = !row.show;
        }
        return row;
      });
      setShowAllRows(filterRows.every((row) => row.show));
    } // When 'All' option is clicked, toggle the show/hide status for all rows
    else {
      const show = !showAllRows;
      if (show) {
        // if status is checked
        filterRows = filteredTable.map((col) => ({ ...col, show: true }));
      } else {
        // if status is unchecked
        filterRows = filteredTable.map((col) => ({ ...col, show: false }));
      }
      setShowAllRows(show);
    }
    setFilteredTable(filterRows);
  };

  const handleOpenModal = (item, type, benchmark) => {
    setOpenStatsModal(true);
    setSelectedChart(item);
    setChartType(type);
    setChartBenchmarks(benchmark);
  };

  const handleProviderTypeChange = (e) => {
    const { value } = e.target;
    let newSelectedValues = [];
    if (value.includes("All")) {
      const data = value.filter((item) => item !== "All");
      // Unselect any value when all is selected
      if (
        selectedProviderTypes.includes("All") &&
        data.length < ProviderTypeOptions.length + 1
      ) {
        newSelectedValues = data;
      } else {
        // Select all value
        newSelectedValues = ["All", ...ProviderTypeOptions];
      }
    } else {
      // Unselect All
      if (selectedProviderTypes.includes("All")) {
        newSelectedValues = [];
      } else {
        // check if selected values are same length as all items
        if (value.length === ProviderTypeOptions.length + 1) {
          newSelectedValues = ["All", ...ProviderTypeOptions];
        } else {
          // select and deselect any item
          newSelectedValues = value;
        }
      }
    }
    setSelectedProviderTypes(newSelectedValues);
    // getProviderTypes(newSelectedValues);
  };

  const getCustomLabel = (labelText) => {
    const labelStyle = {
      color: "#a1a4a6",
      fontSize: "14px",
      marginBottom: "4px",
    };

    return <label style={labelStyle}>{toCapitalize(labelText)}</label>;
  };

  return (
    <Sidebar pageTitle="Provider Profiling">
      <div className="comparisonPageOuter app-bg-color">
        <div className="comparisonOuter widget-bg-shadow">
          <div className="comparisonTableOuter">
            <div justifycontent="space-between" className="header__container">
              <span className="header-title">Provider Profiling</span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "45%",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {getCustomLabel(`Metrics:`)}
                  <Select
                    className={classes.select}
                    displayEmpty
                    classes={{
                      icon: classes.selectIcon,
                    }}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0080B2",
                        border: "1px solid #0080B2",
                      },
                      "&.MuiSelect-icon": {
                        fill: "#0080B2 !important",
                      },
                    }}
                    size="small"
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: { style: { border: "1px solid #0080B2" } },
                    }}
                    renderValue={() => <em>Show/Hide Columns</em>}
                    multiple
                    value={columns}
                    style={{ minWidth: 165, maxWidth: 180 }}
                  >
                    <MenuItem
                      onClick={() => handleColumnFilter("all")}
                      dense
                      className={classes.menuItem}
                    >
                      <Checkbox
                        checked={showAllColumns}
                        icon={
                          <CheckBoxOutlineBlankOutlined
                            className={classes.checkItem}
                            fontSize="small"
                          />
                        }
                        checkedIcon={
                          <CheckBoxOutlined
                            className={classes.checkItem}
                            fontSize="small"
                          />
                        }
                      />
                      <Typography sx={{ fontSize: "16px !important" }}>
                        All
                      </Typography>
                    </MenuItem>
                    {columns.map((col) => (
                      <MenuItem
                        key={col.key}
                        value={col.key}
                        disabled={col.disable}
                        onClick={() => handleColumnFilter(col.key)}
                        dense
                        className={classes.menuItem}
                      >
                        <Checkbox
                          checked={col.show}
                          icon={
                            <CheckBoxOutlineBlankOutlined
                              className={classes.checkItem}
                              fontSize="small"
                            />
                          }
                          checkedIcon={
                            <CheckBoxOutlined
                              className={classes.checkItem}
                              fontSize="small"
                            />
                          }
                        />
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {col.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {getCustomLabel("Provider Type:")}
                  <Select
                    className={classes.select}
                    displayEmpty
                    classes={{
                      icon: classes.selectIcon,
                    }}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0080B2",
                        border: "1px solid #0080B2",
                      },
                      "&.MuiSelect-icon": {
                        fill: "#0080B2 !important",
                      },
                    }}
                    size="small"
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: { style: { border: "1px solid #0080B2" } },
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select Labels</em>;
                      }
                      return (
                        <Typography
                          className="create-cohort-typography"
                          sx={{
                            overflow: "hidden",
                          }}
                        >
                          {selected.includes("All")
                            ? "All"
                            : selected.join(",")}
                        </Typography>
                      );
                    }}
                    multiple
                    onChange={handleProviderTypeChange}
                    value={selectedProviderTypes}
                    style={{ minWidth: 165, maxWidth: 180 }}
                  >
                    <MenuItem value={"All"} dense className={classes.menuItem}>
                      <Checkbox
                        checked={selectedProviderTypes.includes("All")}
                        icon={
                          <CheckBoxOutlineBlankOutlined
                            className={classes.checkItem}
                            fontSize="small"
                          />
                        }
                        checkedIcon={
                          <CheckBoxOutlined
                            className={classes.checkItem}
                            fontSize="small"
                          />
                        }
                      />
                      <Typography sx={{ fontSize: "16px !important" }}>
                        All
                      </Typography>
                    </MenuItem>
                    {ProviderTypeOptions.map((item) => (
                      <MenuItem
                        key={item}
                        dense
                        value={item}
                        className={classes.menuItem}
                      >
                        <Checkbox
                          checked={selectedProviderTypes.includes(item)}
                          icon={
                            <CheckBoxOutlineBlankOutlined
                              className={classes.checkItem}
                              fontSize="small"
                            />
                          }
                          checkedIcon={
                            <CheckBoxOutlined
                              className={classes.checkItem}
                              fontSize="small"
                            />
                          }
                        />
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {item}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                  {getCustomLabel("Provider(s): ")}
                  <Select
                    className={classes.select}
                    classes={{
                      icon: classes.selectIcon,
                    }}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0080B2",
                        border: "1px solid #0080B2",
                      },
                      "&.MuiSelect-icon": {
                        fill: "#0080B2 !important",
                      },
                    }}
                    size="small"
                    multiple
                    value={filteredTable}
                    renderValue={(selected) =>
                      selected.every((r) => r.show) ? (
                        "All"
                      ) : (
                        <Typography
                          className="create-cohort-typography"
                          sx={{
                            overflow: "hidden",
                          }}
                        >
                          {selected
                            .filter((r) => r.show)
                            .map((r) => r.provider_name)
                            .join(", ")}
                        </Typography>
                      )
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: { style: { border: "1px solid #0080B2" } },
                    }}
                    style={{ minWidth: 165, maxWidth: 180 }}
                  >
                    <MenuItem
                      dense
                      className={classes.menuItem}
                      onClick={() => handleProviderChange("all")}
                    >
                      <Checkbox
                        checked={showAllRows}
                        icon={
                          <CheckBoxOutlineBlankOutlined
                            className={classes.checkItem}
                            fontSize="small"
                          />
                        }
                        checkedIcon={
                          <CheckBoxOutlined
                            className={classes.checkItem}
                            fontSize="small"
                          />
                        }
                      />
                      <Typography sx={{ fontSize: "16px !important" }}>
                        All
                      </Typography>
                    </MenuItem>
                    {filteredTable.map((row) => (
                      <MenuItem
                        key={row.provider_id}
                        value={row.provider_id}
                        onClick={() => handleProviderChange(row.provider_id)}
                        dense
                        className={classes.menuItem}
                      >
                        <Checkbox
                          checked={row.show}
                          icon={
                            <CheckBoxOutlineBlankOutlined
                              className={classes.checkItem}
                              fontSize="small"
                            />
                          }
                          checkedIcon={
                            <CheckBoxOutlined
                              className={classes.checkItem}
                              fontSize="small"
                            />
                          }
                        />
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {row.provider_name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {getCustomLabel(`Time Frame:`)}
                  <Select
                    size="small"
                    defaultValue="1"
                    className={classes.select}
                    classes={{
                      icon: classes.selectIcon,
                    }}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0080B2",
                        border: "1px solid #0080B2",
                      },
                      "&.MuiSelect-icon": {
                        fill: "#0080B2 !important",
                      },
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: { style: { border: "1px solid #0080B2" } },
                    }}
                    value={selectedTimeFrame}
                    onChange={handleTimeChange}
                    style={{ minWidth: 165, maxWidth: 180 }}
                  >
                    <MenuItem value="1">Current month</MenuItem>
                    <MenuItem value="2">Last Quarter</MenuItem>
                    <MenuItem value="5">Last 6 Months</MenuItem>
                    <MenuItem value="11">Last 12 months</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            {!isFetching && (
              <div className="com_table_container">
                <table
                  className="outer__table"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                  width="100%"
                >
                  <thead>
                    <tr className="tRow" style={{ textAlign: "center" }}>
                      {columns.map((item, index) => {
                        if (item.show && !item.disable) {
                          return (
                            <>
                              <th key={index}>
                                <span className="table-head">
                                  {item.title}
                                  {item.statsAvailable && (
                                    <span className="statsIcon">
                                      {" "}
                                      <AssessmentIcon
                                        className="chart-icon"
                                        onClick={(e) =>
                                          handleOpenModal(item, "barchart", item.benchmark)
                                        }
                                      />
                                    </span>
                                  )}
                                </span>
                              </th>
                            </>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {isFetching && !filteredTable?.length ? (
                      <tr className="tRow">
                        <td className="mt-4" colSpan="42">
                          <Spinner />
                        </td>
                      </tr>
                    ) : !isFetching && !filteredTable?.length ? (
                      <tr className="tRow">
                        <td colSpan={10}>
                          <Alert
                            severity="warning"
                            className="my-4 text-center"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Comparison data for this organization is not
                            available yet. Please check back later.
                          </Alert>
                        </td>
                      </tr>
                    ) : filteredTable &&
                      filteredTable.every((row) => !row.show) ? (
                      <tr className="tRow">
                        <td colSpan={10}>
                          <Alert
                            severity="warning"
                            className="my-4 text-center"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            No record found for the applied filter.
                          </Alert>
                        </td>
                      </tr>
                    ) : (
                      filteredTable?.map((item, index) => {
                        return item.show ? (
                          <>
                            <tr
                              className="tRow entryHasNotes"
                              key={index}
                              style={{ textAlign: "center" }}
                            >
                              {columns.map((column, i) => {
                                if (column.key === "provider_id" && column.show)
                                  return (
                                    <td key={i} width={"14%"}>
                                      {item.provider_name}
                                    </td>
                                  );
                                else if (column.show && !column.disable)
                                  return (
                                    <td key={i}>
                                      {item[column.key] + " "}
                                      {column.key &&
                                        Number(item[column.key]) &&
                                        column.benchmark ? (
                                        Number(item[column.key]) >=
                                          column.benchmark.benchmark_2 ? (
                                          <NorthIcon
                                            sx={{
                                              color: "#4caf50",
                                              fontSize: "1.1em",
                                            }}
                                          />
                                        ) : (
                                          <SouthIcon
                                            sx={{
                                              color: "#ef5350",
                                              fontSize: "1.1em",
                                            }}
                                          />
                                        )
                                      ) : null}
                                    </td>
                                  );
                                return null;
                              })}
                            </tr>
                          </>
                        ) : null;
                      })
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {/* <div className="d-flex flex-row-reverse p-4">
                            <div className="tblBtn">
                                <Button className="table-btn">
                                    <span className="table-btn-span">
                                        <span>Download</span>
                                        <GetAppIcon className="table-icon-1" />
                                    </span>
                                </Button>
                                <Button
                                    className="table-btn"
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        setShowLoadFilters(true);
                                    }}
                                >
                                    <span className="table-btn-span">
                                        <span>Load Comparison</span>
                                    </span>
                                </Button>
                                <Button
                                    className="table-btn"
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        setShowSave(true);
                                    }}
                                >
                                    <span className="table-btn-span">
                                        <span>Save Comparison</span>
                                    </span>
                                </Button>
                            </div>
                        </div> */}
            <SaveComparison
              open={showSave}
              setOpen={setShowSave}
              cohorts={filteredTable}
              time_frame={selectedTimeFrame}
            />
            <LoadFilters
              open={showLoadFilters}
              setOpen={setShowLoadFilters}
              setTimeFrame={setSelectedTimeFrame}
              setFilterTable={setFilteredTable}
              getCohortComparison={getProviderTypes}
              setSelectedComparison={setSelectedComparison}
            />
            {openStatsModal && (
              <ComparisonStats
                open={openStatsModal}
                setOpen={setOpenStatsModal}
                timeframe={selectedTimeFrame}
                selectedChart={selectedChart}
                datafilters={filteredTable}
                providerChartData={filteredTable}
                type={chartType}
                benchmark={chartBenchmarks}
              />
            )}
            <Backdrop
              open={isFetching}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 200,
                color: "#78cbf2",
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
        {filteredTable.length ? (
          <div className={classes.root}>
            <TablePagination
              component="div"
              count={totalCount}
              page={Number(page)}
              rowsPerPage={10}
              rowsPerPageOptions={[]}
              onPageChange={onPageChange}
            />
          </div>
        ) : null}
      </div>
    </Sidebar>
  );
};

export default ProviderProfiling;
