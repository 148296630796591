import apiClientGenesis from "./apiClientGenesis";

export const verifyGenesisAccessToken = async(access_token) =>{
    return await apiClientGenesis().post(`genesis/verifyGenesisUser`, {access_token});
}

// /genesis/verifyGenesisUser
const getCumulativeTrendProgress = async (clientId) => {
    return await apiClientGenesis("python").post(`genesis/cumulative-progress-trend`,{ clientId });
};

const getLearnerProgresTrend = async() =>{
    return await apiClientGenesis("python").get(`genesis/learner-type-progress-trend`);
}
const getLearnerVsClientProgresTrend = async(clientId) =>{
    return await apiClientGenesis("python").post(`genesis/client-vs-learner-type-progress-trend`,{ clientId });
}

const getCohortAnalysisData = async() =>{
    return await apiClientGenesis("python").post(`genesis/cohort-analysis`);
}

const getCohortClientDetails = async(treatmentType, location, learnerType) =>{
    return await apiClientGenesis("python").post(`genesis/cohort-client-details/cohort-analysis`, {treatmentType, location, learnerType});
}

let genesisServices = {
    getCumulativeTrendProgress,
    getLearnerProgresTrend,
    getLearnerVsClientProgresTrend,
    getCohortAnalysisData,
    getCohortClientDetails
}

export default genesisServices;
