import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

// import SmartAlertAccordian from "../../Tables/WcchcDashboard/SmartAlertAccordian";
import SmartAlertTabs from "../../WcchcPatientSummary/SmartAlertTabs";

const useStyles = makeStyles((theme) => ({
  mainModalContainer2: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "900px",
    maxWidth: "90vw",
    backgroundColor: "#fff",
    outline: "none",
    maxHeight: "95vh",
    minHeight: "374px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0px !important",
    maxHeight: "85vh",
    overflow: "auto",
  },
  cardContainer2: {
    overflow: "auto",
  },
  header: {
    height: "54px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 28px !important",
    borderBottom: "1px solid #595959b3",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "19px !important",
    fontWeight: 600,
    lineHeight: "29px",
    letterSpacing: "-0.03em",
    color: "#0080b2 !important",
  },
  headerIcon: {
    cursor: "pointer",
    fill: "#0080b2 !important",
  },
}));

const SmartAlertsModal = ({
  open,
  setSmartAlertsModal,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => setSmartAlertsModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className={classes.mainModalContainer2}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>Smart Alerts</Typography>
          <Close
            onClick={() => setSmartAlertsModal(false)}
            className={classes.headerIcon}
          />
        </Box>
        <Box className={classes.cardContainer}>
          {/* <SmartAlertAccordian /> */}
          <SmartAlertTabs />
        </Box>
      </Box>
    </Modal>
  );
};

export default SmartAlertsModal;
