import React, {useEffect, useState, useContext} from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Skeleton,
  Box,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import NoDataMessage from "../../WcchcPatientSummary/NoDataMessage";
import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";
import SortButton from "../../WcchcPatientSummary/SortButton";
import { sortData, capitalizeWords} from "../../../Utils/wcchcPatientSummary";

const useStyles = makeStyles((theme) => ({
  tableMainContainer: {
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
  },
  tableContainerModal: {
    boxShadow: "none",
    maxHeight: "calc(100vh - 10px)",
  },
  tableContainer: {
    boxShadow: "none",
    maxHeight: "271px",
  },
  table: {
    paddingLeft: "20px !important",
    "& > tbody > tr:nth-child(even)": {
      backgroundColor: "#f3f3f3",
    },
    "& > thead > tr > th:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "left !important"
    },
    "& > tbody > tr > td:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "center",
    },
    "& > tbody > tr > td:last-child": {
      textTransform: "capitalize !important"
    }
  },
  tableRow: {
    "& > td:nth-child(1)": {
      fontWeight: 600,
    },
  },
  tableHeaderCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#7D7B7B",
    padding: "10px",
    textAlign: "left",
  },
  tableHeaderCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#060606",
    padding: "8px 6px",
    borderBottom: "0.5px solid #000000 !important",
    textAlign: "left",
  },
  tableDataCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 10px",
    borderBottom: "none",
    textAlign: "left",
  },
  tableDataCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 6px",
    textAlign: "left",
  },
  tableEmptyCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "8px 10px",
    borderBottom: "none",
    textAlign: "center",
  },
  skeletonDataCell1: {
    width: "40%",
    borderBottom: "none",
    padding: "6px !important",
  },
  skeletonDataCell2: {
    width: "40%",
    borderBottom: "none",
    padding: "6px !important",
  },
  table2: {
    paddingLeft: "15px !important",
    "& > thead > tr > th:nth-child(2)": {
      paddingLeft: "20px",
    },
    "& > tbody > tr > td:nth-child(2)": {
      paddingLeft: "20px",
    },
    "& > tbody > tr:nth-child(even)": {
      backgroundColor: "#f3f3f3",
    },
  },
  label:{
    fontWeight: "700",
    fontFamily: "Lato"
  }
}));

const columns = [
  {label: "Date of Referral", sort: true, name: "OrderDate"},
  {label: "Referred By", sort: false, name: "status"},
  {label: "Reason", sort: false, name: "reason"},
  {label: "Referred To", sort: false, name: "referredto"},
  {label: "Status", sort: false, name: "status"},
];

const ReferralsTable = ({ modal = false, maxHeight }) => {
  const { state, dispatch } = useContext(WcchcPatientSummaryContext);
  const classes = useStyles();
  const [referralsData, setReferralsData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [currentSorting, setCurrentSorting] = useState({});
  const [sortConfig, setSortConfig] = useState([
    { key: 'OrderDate', direction: 'desc' },
  ]);

  // const columns = ["Date of Referral", "Referred By", "Reason", "Referred To", "Status"]

  const sortHandler = (key,sortType) => {
    setSortConfig((prevConfig) => {
      const existingConfig = prevConfig.find((config) => config.key === key);
      if (existingConfig) {
        const newDirection = sortType;
        return prevConfig.map((config) =>
          config.key === key ? { ...config, direction: newDirection } : config
        );
      } else {
        return [...prevConfig, { key, sortType, direction: sortType }];
      }
    });
    setCurrentSorting({key,sortType, direction: sortType})
  }

  const sortTableData = () => {
    let sortedData = [...finalData];
    const {direction} = currentSorting;
    const data = sortData(sortedData,'OrderDate', direction)
    setReferralsData([...data]);
  };

  useEffect(() =>{
    if (state.referrals) {
      setReferralsData(state.referrals)
      setFinalData(state.referrals)
      dispatch({ type: "UPDATE_TABLE_LENGTH", payload: {referrals: state.referrals.length} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(state.referrals)])

  useEffect(() =>{
    if(Object.keys(currentSorting)?.length){
      sortTableData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sortConfig])

  if(state.isFetchingReferrals){
    return(
      <Paper className={classes.tableMainContainer}>
        <TableContainer sx={{ maxHeight }} 
            className={modal ? classes.tableContainerModal : classes.tableContainer}
        >
          <Table stickyHeader aria-label="sticky table" className={classes.table2}>
          {
            new Array(6).fill(0).map((_, index) => (
                <TableHead>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={classes.tableRow}
                    >
                      {
                        new Array(5).fill(0).map((_, index) => (
                          <TableCell colSpan={1} className={classes.tableEmptyCell} key={index}>
                            <Skeleton variant="rounded" width="100%" height={25} />
                          </TableCell>
                        ))
                      }
                    </TableRow>
                </TableHead>
            ))
        }
        </Table>
        </TableContainer>
      </Paper>
    )
  }

  return (
    <Paper className={classes.tableMainContainer}>
      <TableContainer sx={{ maxHeight }} 
      // className={classes.tableContainer}
      className={modal ? classes.tableContainerModal : classes.tableContainer}

      >
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
        {referralsData.length ? <TableHead>
           <TableRow>
              {columns.map(({label, sort, name}, index) => (
                <TableCell
                  key={index}
                  className={
                    modal
                      ? classes.tableHeaderCellModal
                      : classes.tableHeaderCell
                  }
                >
                  {
                    sort ?
                      <Box sx={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                        <Typography className={classes.label}>{label}</Typography>
                        <SortButton onClick={sortHandler} name={name} sortConfig={sortConfig}/>
                      </Box>
                      :
                      label
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        :
        null
        }
        <TableBody>
          {
            referralsData?.length ? (
              referralsData?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={classes.tableRow}
                  >
                    
                      {['OrderDate','OrderedBy','ReferralReason', 'ReferTo','Status'].map((column, i) => {
                        const value = row[column] ?? "Not Available";
                        return (
                          <TableCell
                          className={
                            modal
                              ? classes.tableDataCellModal
                              : classes.tableDataCell
                          }
                          key={i}
                        >
                          {column === 'OrderedBy' ? capitalizeWords(value)  : value}
                        </TableCell>
                        )})}
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={classes.tableRow}
              >
                <TableCell colSpan={5} className={classes.tableEmptyCell}>
                  <NoDataMessage 
                    message={"No data available"}
                  />                
                </TableCell>
              </TableRow>
            )}
        </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ReferralsTable;
