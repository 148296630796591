import React, { useState, useEffect, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  ListItemText,
  MenuItem,
  MenuList,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  Tab,
  Grid,
  Box,
  FormControl,
  Select,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TabContext, TabList } from "@mui/lab";
import SettingsIcon from "@mui/icons-material/Settings";
import { ArrowDropDownRounded } from "@mui/icons-material";
import "../_Dashboard.scss";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import CohortOverview from "./CohortOverview";
import { TabSkeletonLoader1 } from "../../../Components/SkeletonLoader/DashboardSkeleton";
import { makeStyles } from "@mui/styles";
import { toCapitalize, toLowerCase } from "../../../Utils/stringOprations";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TabWithToolTip = (props) => {
  const useStyles = makeStyles({
    tooltipContent: {
      fontSize: "14px", // Adjust the font size here
    },
  });

  const classes = useStyles();
  const title = props?.tooltipTitle || "";

  return (
    <Tooltip title={title} classes={{ tooltip: classes.tooltipContent }}>
      <Tab {...props} />
    </Tooltip>
  );
};

const CohortTabs = ({selectedTimeFrame, setSelectedTimeFrame}) => {
  const [open, setOpen] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = React.useState(null);
  const openExport = Boolean(exportAnchorEl);
  const anchorRef = React.useRef(null);
  const [cohortsList, setCohortsList] = useState([]);
  const [cohortDropDownList, setCohortDropDownList] = useState([]);
  const {
    updatePatientSetInfo,
    allPatientSets,
    patientSetInfo,
    fetchingPatientSet,
    selectedCohorts, 
    setSelectedCohorts
  } = useContext(PatientSetContext);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (allPatientSets?.length) {
      const patientSetData = allPatientSets.map((item, i) => ({
        ...item,
        selected: i < 5 || patientSetInfo?.patient_set_id === item.patient_set_id ? true : false,
      }));
      if (selectedCohorts.length) {
        setCohortsList(selectedCohorts);
        setCohortDropDownList(patientSetData);
      } else {
        setSelectedCohorts(patientSetData)
        setCohortsList(patientSetData);
        setCohortDropDownList(patientSetData);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPatientSets]);

  const handleTabChange = (event, newValue) => {
    const selectedTab = cohortsList.find((i) => i.patient_set_id === newValue);
    updatePatientSetInfo(selectedTab);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = (event) => {
    setOpen((prevOpen) => !prevOpen);
    setCohortDropDownList(selectedCohorts);
  };

  const handleChange = (tab) => {
    //Check if key exists in columns and toggle its value
    const updatedSelection = cohortDropDownList.map((item) => {
      if (item.patient_set_id === tab.patient_set_id) {
        return {
          ...item,
          selected: !item.selected,
        };
      } else return item;
    });
    setCohortDropDownList(updatedSelection);
  };

  const handleDateChange = (e) => {
    setSelectedTimeFrame(e.target.value);
  };

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleSave = () => {
    setSelectedCohorts(cohortDropDownList);
    setCohortsList(cohortDropDownList);
    setOpen(false);
  }

  const handleClear = () => {
    const updatedSelection = cohortDropDownList.map((item) => ({
      ...item,
      selected: item.patient_set_id === patientSetInfo?.patient_set_id ? true : false,
    }));
    setCohortDropDownList(updatedSelection);
  }

  return (
    <>
      <Grid item container xs={12} justifyContent={"space-between"}>
        {fetchingPatientSet ? (
          <TabSkeletonLoader1 />
        ) : (
          <>
            <Grid item xs={10} className="cohort-tabs">
              <TabContext value={patientSetInfo?.patient_set_id}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    variant={"scrollable"}
                    scrollButtons={true}
                    onChange={(e, v) => handleTabChange(e, v)}
                    className="cohort-tab-list"
                  >
                    {cohortsList.map((tab, index) => {
                      if (tab.selected) {
                        const tabLabel =
                          tab?.name?.length < 40
                            ? tab?.name
                            : `${tab?.name?.substring(0, 40)}...`;
                        const tooltipTitle = tab.name || ""; // Ensure tab.name is defined
                        return (
                          <TabWithToolTip
                            tooltipTitle={tooltipTitle}
                            sx={{ fontSize: "medium" }}
                            label={tabLabel}
                            value={tab.patient_set_id}
                            key={index}
                          />
                        );
                      } else return null;
                    })}
                  </TabList>
                </Box>
              </TabContext>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <div>
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  startIcon={<SettingsIcon />}
                  endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  size={"large"}
                  variant="outlined"
                  color={"inherit"}
                >
                  {toCapitalize(patientSetInfo?.cohort_name)}s
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: 9 }}
                >
                  {({ TransitionProps, placement }) => {
                    const disableButtons = cohortDropDownList.every(i => !i.selected || i.patient_set_id === patientSetInfo?.patient_set_id)
                    return (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper sx={{ maxHeight: "80vh" }}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden' }}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                dense
                                sx={{ overflowY: 'scroll', maxHeight: '60vh' ,  flex: '1 1 auto', padding: 0, paddingTop: 1 }}
                              >
                                {cohortDropDownList.map((tab, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleChange(tab)}
                                    disabled={tab.patient_set_id === patientSetInfo?.patient_set_id}
                                    sx={{ padding: '0 16px' }}
                                  >
                                    <Checkbox style={{color: '#007BFF'}} checked={tab.selected} />
                                    <ListItemText primary={tab?.name} />
                                  </MenuItem>
                                ))}
                              </MenuList>
                              {disableButtons && <Box sx={{ padding: '10px 16px !important' }}>
                                <Typography variant="body2" color="textSecondary" display={'flex'} alignItems={'center'} >
                                  <InfoOutlinedIcon style={{ fontSize: "16px", marginRight: '5px' }} />
                                  Please select {toLowerCase(patientSetInfo?.cohort_name)}s from above list
                                </Typography>
                              </Box>}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 16px !important', borderTop: '1px solid #ccc' }}>
                                <Button disabled={disableButtons} 
                                  onClick={handleClear} variant="outlined" sx={{color: '#007BFF', borderColor: '#007BFF'}} >Clear All</Button>
                                <Button disabled={disableButtons} 
                                  onClick={handleSave} variant="contained" sx={{background: '#007BFF'}}>Apply</Button>
                              </Box>
                            </Box>
                          
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}}
                </Popper>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container xs={12} spacing={4}>
        <Grid item xs={12} md={9}>
          <CohortOverview selectedTimeFrame={selectedTimeFrame} cohort_id={patientSetInfo?.patient_set_id} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={3}
          sx={{ marginTop: "35px" }}
          justifyContent={"space-around"}
        >
          <Grid item>
            <FormControl fullWidth>
              <Select
                value={selectedTimeFrame}
                onChange={handleDateChange}
                size={"small"}
                sx={{ background: "#fff" }}
              >
                <MenuItem value={2}>Last 3 Month</MenuItem>
                <MenuItem value={5}>Last 6 Month</MenuItem>
                <MenuItem value={11}>Last 12 Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleExportClick}
              variant={"contained"}
              endIcon={<ArrowDropDownRounded sx={{ ml: "10px" }} />}
              size={"large"}
              sx={{
                height: "38px",
                background: "#1988FE",
              }}
              disabled
            >
              Export
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={exportAnchorEl}
              open={openExport}
              onClose={handleExportClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ width: "100% !important" }}
            >
              <MenuItem onClick={handleExportClose}>PNG</MenuItem>
              <MenuItem onClick={handleExportClose}>PDF</MenuItem>
              <MenuItem onClick={handleExportClose}>SVG</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CohortTabs;
