import React, { useContext, useEffect, useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import Spinner from "../../Components/Spinner/Spinner";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import services from "../../Services/patientServices";
import CustomButton from "../../Components/Button/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import HistoryIcon from "@mui/icons-material/History";
import SaveComparison from "./SaveComparison";
import LoadFilters from "./LoadFilters";
import { toCapitalize } from "../../Utils/stringOprations";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import ComparisonStats from "./ComparisonStats";
import Sidebar from "../../Components/Sidebar/Sidebar";
import cohortLabels from "./CohortLabels";
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import "./ComparisonAnalytics.scss";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import { CSVLink } from "react-csv";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import { TablePagination } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      backgroundColor: "#0080B2 !important",
      color: "#fff",
      "& svg": {
        fill: "#fff !important",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#D1F2FF !important",
      color: "#0080B2",
    },
    "&.MuiMenuItem-dense": {
      padding: "0px 16px",
    },
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      borderColor: "#0080B2",
    },
  },
  checkItem: {
    fill: "#0080B2 !important",
  },
  selectIcon: {
    "&.MuiSelect-iconOpen": {
      fill: "#0080B2 !important",
    },
  },
  backdrop: {
    zIndex: 200,
    color: "#78cbf2",
  },
}));

const ComparisonAnalytics = () => {
  const DEFAULT_TIMEFRAME = window.config.environment === "demo-a" ? 11 : 0
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [comparisonTable, setComparisonTable] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showAllColumns, setShowAllColumns] = useState(true);
  const [showAllRows, setShowAllRows] = useState(true);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(DEFAULT_TIMEFRAME);
  const [filteredTable, setFilteredTable] = useState([]);
  const [showSave, setShowSave] = useState(false);
  const [showLoadFilters, setShowLoadFilters] = useState(false);
  const [selectedComparison, setSelectedComparison] = useState(null);
  const [openStatsModal, setOpenStatsModal] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedCohortLabels, setSelectedCohortLabels] = useState(["All"]);
  const { patientSetInfo, allPatientSets, fetchAllPatientSets } = useContext(PatientSetContext);
  const { event } = useContext(AnalyticsContext);
  const [exportData, setExportData] = useState([]);
  const exportComparisonRef = useRef();
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const { setPageTitle } = useContext(MetaDetailsContext);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const [showTotalCount, setShowTotalCount] = useState(1);
  
  useEffect(() => {
    setPageTitle("FHS: Comparative Analytics");
    getCohortComparison();
    if (!allPatientSets.length) {
      fetchAllPatientSets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onPageChange = (event, selectedPage) => {
    setPage(selectedPage);
  };

  const getCohortComparison = async (timeframe = selectedTimeFrame, pageN = page) => {
    try {
      setIsFetching(true);
      const result = await services.comparisonAnalytics({
        timeframe,
        page: pageN,
      });
      filterColumns(result.data.columns, result.data.data);
      setComparisonTable(result.data.data);
      filterTableByCohort(result.data.data);
      selectedComparison && setSelectedComparison(null);
      console.log("data => ", result.data.data);
      setTotalCount(result.data.totalCount);
      setShowTotalCount(result.data.totalCount)
      setIsFetching(false);
      return result.data.data;
    } catch (error) {
      console.log("Error while fetching cohort comparison", error);
      setIsFetching(false);
    }
  };

  // Filter columns and update the state with the show/hide status
  const filterColumns = (columns, rowData) => {
    // Clone the original columns array and set the default show and disable properties for each column
    let filteredColumns = columns.map((col) => ({
      ...col,
      show: true,
      disable: false,
    }));

    // Iterate through each column and check if it should be disabled (if all values in that column are '-' or null)
    filteredColumns = filteredColumns.map((col) => {
      col.disable = rowData.every(
        (row) => row[col.key] === "-" || row[col.key] == null
      );
      col.show = !col.disable; // Update the show property based on the disable status
      return col;
    });

    setColumns(filteredColumns);
  };

  const handleTimeChange = (event) => {
    setSelectedTimeFrame(event.target.value);
    getCohortComparison(event.target.value);
  };

  // Handler for column filtering (show/hide columns)
  const handleColumnFilter = (key) => {
    let filterColumns = columns;
    // When individual column option is clicked, toggle the show/hide status
    if (key !== "all") {
      filterColumns = columns.map((col) => {
        if (col.key === key) {
          col.show = !col.show;
        }
        return col;
      });
      setShowAllColumns(
        filterColumns.every(
          (col) => (col.show && !col.disable) || (col.disable && !col.show)
        )
      );
    } // When 'All' option is clicked, toggle the show/hide status for all columns
    else {
      const show = !showAllColumns;
      if (show) {
        // if status is checked
        filterColumns = columns.map((col) =>
          col.disable ? { ...col } : { ...col, show: true }
        );
      } else {
        // if status is unchecked
        filterColumns = columns.map((col) => ({ ...col, show: false }));
      }
      setShowAllColumns(show);
    }
    setColumns(filterColumns);
  };

  const filterTableByCohort = (comparisonTable) => {
    const filteredData = comparisonTable.map((data) => {
      const tempData = { ...data, show: true };
      if (filteredTable.length) {
        filteredTable.forEach((i) => {
          if (i.cohort_id === data.cohort_id) {
            tempData.show = i.show === undefined ? true : i.show;
          }
        });
      }
      return tempData;
    });

    setShowAllRows(filteredData.every((row) => row.show));
    setFilteredTable(filteredData);
  };

  // Handler for table row filtering (show/hide columns)
  const handleCohortChange = (rowId) => {
    let filterRows = filteredTable;
    // When individual cohort option is clicked, toggle the show/hide status of the row
    if (rowId !== "all") {
      filterRows = filteredTable.map((row) => {
        if (row.cohort_id === rowId) {
          row.show = !row.show;
        }
        return row;
      });
      setShowAllRows(filterRows.every((row) => row.show));
    } // When 'All' option is clicked, toggle the show/hide status for all rows
    else {
      const show = !showAllRows;
      if (show) {
        // if status is checked
        filterRows = filteredTable.map((col) => ({ ...col, show: true }));
      } else {
        // if status is unchecked
        filterRows = filteredTable.map((col) => ({ ...col, show: false }));
      }
      setShowAllRows(show);
    }
    const showCount = filterRows.filter((row) => row.show)
    showCount.length === 10 ? setTotalCount(showTotalCount) : setTotalCount(showCount.length);
    setFilteredTable(filterRows);
  };

  const handleOpenModal = (item, type) => {
    setOpenStatsModal(true);
    setSelectedChart(item);
  };

  const handleCohortTypeChange = (e) => {
    const { value } = e.target;
    let newCohortLabels = [];
    if (value.includes("All")) {
      const data = value.filter((item) => item !== "All");
      // Unselect any value when all is selected
      if (
        selectedCohortLabels.includes("All") &&
        data.length < cohortLabels.length + 1
      ) {
        newCohortLabels = data;
      } else {
        // Select all value
        newCohortLabels = ["All", ...cohortLabels, "No Labels"];
      }
    } else {
      // Unselect All
      if (selectedCohortLabels.includes("All")) {
        newCohortLabels = [];
      } else {
        // check if selected values are same length as all items
        if (value.length === cohortLabels.length + 1) {
          newCohortLabels = ["All", ...cohortLabels, "No Labels"];
        } else {
          // select and deselect any item
          newCohortLabels = value;
        }
      }
    }
    setSelectedCohortLabels(newCohortLabels);

    const compData = comparisonTable.map((item) => {
      return { ...item, show: true };
    });

    if (value.includes("All")) {
      console.log("all cohorts => ", filteredTable);
      setFilteredTable(compData);
      return;
    } else if (newCohortLabels.includes("No Labels")) {
      const selectedCohorts = compData.filter(({ cohort_labels }) => {
        if (!cohort_labels || !cohort_labels.length) {
          return true;
        }
        return false;
      });
      setFilteredTable(selectedCohorts);
    } else {
      const selectedCohorts = compData.filter(({ cohort_labels }) => {
        if (
          cohort_labels &&
          cohort_labels.some((item) => newCohortLabels.includes(item))
        ) {
          return true;
        }
        return false;
      });

      setFilteredTable(selectedCohorts);
    }
  };

  const getCustomLabel = (labelText) => {
    const labelStyle = {
      color: "#a1a4a6",
      fontSize: "14px",
      marginBottom: "4px",
    };

    return <label style={labelStyle}>{toCapitalize(labelText)}</label>;
  };

  const getAllComparison = async (timeframe = selectedTimeFrame) => {
    const BATCH_SIZE = 10;
    const MAX_ITEMS = allPatientSets.length;
    const MAX_CONCURRENT_REQUESTS = 5;
    let batchPromises = [];
    let offset = 0;
    let totalCoordinates = 0;
    let isExhausted = false;
    let allData = [];

    const fetchBatch = async (offset) => {
      try {
        const result = await services.comparisonAnalytics({
          timeframe,
          page: offset,
          limit: BATCH_SIZE
        });    
        return result.data;
      } catch (error) {
        return []
      }
    }

    while (totalCoordinates < MAX_ITEMS && !isExhausted) {
      while (batchPromises.length < MAX_CONCURRENT_REQUESTS && totalCoordinates < MAX_ITEMS) {
        batchPromises.push(fetchBatch(offset));
        offset++;
      }
  
      const results = await Promise.all(batchPromises);
      batchPromises = []; // Reset batchPromises for the next set of requests

      // eslint-disable-next-line no-loop-func
      results.forEach((item) => {
        const { data } = item;
        if (data.length === 0) {
          isExhausted = true; // No more records available
        } else {
          allData.push(...data)
          totalCoordinates += data.length;           
        }
      });
  
      if (totalCoordinates >= MAX_ITEMS || isExhausted) {
        console.log('Request complete.');
        return allData;        
      }
    }
  
  }

  const downloadCSV = async (type) => {
    try {
      setIsDownloading(true);
      setConfirmationModal(false);
      const finalArray = [];
      const setData = (row) => {
        let rowData = { "Cohort Name": row.cohort_name };
        columns.forEach((column) => {
          const title = column.title;
          rowData = { ...rowData, [title]: row[column.key] };
        });
        finalArray.push(rowData);
      };
      if (type === "all") {
        const allCohortData = await getAllComparison();
        allCohortData.forEach((row) => {
          setData(row);
        });
      } else if (type === "selected") {
        filteredTable.forEach((row) => {
          row.show && setData(row);
        });
      } else {
        filteredTable.forEach((row) => {
          setData(row);
        });
      }
      setExportData(finalArray);
      setTimeout(() => {
        exportComparisonRef.current.link.click();
        setIsDownloading(false);
      }, 100);

      // GA - Track click on Downlaod Comparison
      event("downlaod_cohort_comparison", {});
    } catch (error) {
      setIsDownloading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Sidebar pageTitle="Comparison Analytics">
        <div className="comparisonPageOuter app-bg-color">
          <div className="comparisonOuter widget-bg-shadow">
            <div className="comparisonTableOuter">
              <div justifycontent="space-between" className="header__container">
                <span className="header-title">
                  Comparative Analytics{" "}
                  {selectedComparison
                    ? `(${selectedComparison?.filter_name})`
                    : ""}
                </span>
                {!isFetching ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "60%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {getCustomLabel(`Metrics:`)}
                      <Select
                        className={classes.select}
                        displayEmpty
                        classes={{
                          icon: classes.selectIcon,
                        }}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#0080B2",
                            border: "1px solid #0080B2",
                          },
                          "&.MuiSelect-icon": {
                            fill: "#0080B2 !important",
                          },
                        }}
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            style: { border: "1px solid #0080B2" },
                          },
                        }}
                        renderValue={() => <em>Show/Hide Columns</em>}
                        multiple
                        value={columns}
                        style={{ minWidth: 165, maxWidth: 180 }}
                      >
                        <MenuItem
                          onClick={() => handleColumnFilter("all")}
                          dense
                          className={classes.menuItem}
                        >
                          <Checkbox
                            checked={showAllColumns}
                            icon={
                              <CheckBoxOutlineBlankOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                            checkedIcon={
                              <CheckBoxOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                          />
                          <Typography sx={{ fontSize: "16px !important" }}>
                            All
                          </Typography>
                        </MenuItem>
                        {columns.map((col) => (
                          <MenuItem
                            key={col.key}
                            value={col.key}
                            disabled={col.disable}
                            onClick={() => handleColumnFilter(col.key)}
                            dense
                            className={classes.menuItem}
                          >
                            <Checkbox
                              checked={col.show}
                              icon={
                                <CheckBoxOutlineBlankOutlined
                                  className={classes.checkItem}
                                  fontSize="small"
                                />
                              }
                              checkedIcon={
                                <CheckBoxOutlined
                                  className={classes.checkItem}
                                  fontSize="small"
                                />
                              }
                            />
                            <Typography sx={{ fontSize: "16px !important" }}>
                              {col.title}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {getCustomLabel(`${patientSetInfo.cohort_name} Labels:`)}
                      <Select
                        className={classes.select}
                        displayEmpty
                        classes={{
                          icon: classes.selectIcon,
                        }}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#0080B2",
                            border: "1px solid #0080B2",
                          },
                          "&.MuiSelect-icon": {
                            fill: "#0080B2 !important",
                          },
                        }}
                        size="small"
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            style: { border: "1px solid #0080B2" },
                          },
                        }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select Labels</em>;
                          }
                          return (
                            <Typography
                              className="create-cohort-typography"
                              sx={{
                                overflow: "hidden",
                              }}
                            >
                              {selected.includes("All")
                                ? "All"
                                : selected.join(",")}
                            </Typography>
                          );
                        }}
                        multiple
                        onChange={handleCohortTypeChange}
                        value={selectedCohortLabels}
                        style={{ minWidth: 165, maxWidth: 180 }}
                      >
                        <MenuItem
                          value={"All"}
                          dense
                          className={classes.menuItem}
                        >
                          <Checkbox
                            checked={selectedCohortLabels.includes("All")}
                            icon={
                              <CheckBoxOutlineBlankOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                            checkedIcon={
                              <CheckBoxOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                          />
                          <Typography sx={{ fontSize: "16px !important" }}>
                            All
                          </Typography>
                        </MenuItem>
                        {cohortLabels.map((item) => (
                          <MenuItem
                            key={item}
                            dense
                            value={item}
                            className={classes.menuItem}
                          >
                            <Checkbox
                              checked={selectedCohortLabels.includes(item)}
                              icon={
                                <CheckBoxOutlineBlankOutlined
                                  className={classes.checkItem}
                                  fontSize="small"
                                />
                              }
                              checkedIcon={
                                <CheckBoxOutlined
                                  className={classes.checkItem}
                                  fontSize="small"
                                />
                              }
                            />
                            <Typography sx={{ fontSize: "16px !important" }}>
                              {item}
                            </Typography>
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={"No Labels"}
                          dense
                          className={classes.menuItem}
                        >
                          <Checkbox
                            checked={selectedCohortLabels.includes("No Labels")}
                            icon={
                              <CheckBoxOutlineBlankOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                            checkedIcon={
                              <CheckBoxOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                          />
                          <Typography sx={{ fontSize: "16px !important" }}>
                            No Labels
                          </Typography>
                        </MenuItem>
                      </Select>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {getCustomLabel(`${patientSetInfo.cohort_name}(s):`)}
                      <Select
                        className={classes.select}
                        classes={{
                          icon: classes.selectIcon,
                        }}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#0080B2",
                            border: "1px solid #0080B2",
                          },
                          "&.MuiSelect-icon": {
                            fill: "#0080B2 !important",
                          },
                        }}
                        size="small"
                        multiple
                        value={filteredTable}
                        renderValue={(selected) =>
                          selected.every((r) => r.show) ? (
                            "All"
                          ) : (
                            <Typography
                              className="create-cohort-typography"
                              sx={{
                                overflow: "hidden",
                              }}
                            >
                              {selected
                                .filter((r) => r.show)
                                .map((r) => r.cohort_name)
                                .join(", ")}
                            </Typography>
                          )
                        }
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            style: { border: "1px solid #0080B2" },
                          },
                        }}
                        style={{ minWidth: 165, maxWidth: 180 }}
                      >
                        <MenuItem
                          dense
                          className={classes.menuItem}
                          onClick={() => handleCohortChange("all")}
                        >
                          <Checkbox
                            checked={showAllRows}
                            icon={
                              <CheckBoxOutlineBlankOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                            checkedIcon={
                              <CheckBoxOutlined
                                className={classes.checkItem}
                                fontSize="small"
                              />
                            }
                          />
                          <Typography sx={{ fontSize: "16px !important" }}>
                            All
                          </Typography>
                        </MenuItem>
                        {filteredTable.map((row) => (
                          <MenuItem
                            key={row.cohort_id}
                            value={row.cohort_id}
                            onClick={() => handleCohortChange(row.cohort_id)}
                            dense
                            className={classes.menuItem}
                          >
                            <Checkbox
                              checked={row.show}
                              icon={
                                <CheckBoxOutlineBlankOutlined
                                  className={classes.checkItem}
                                  fontSize="small"
                                />
                              }
                              checkedIcon={
                                <CheckBoxOutlined
                                  className={classes.checkItem}
                                  fontSize="small"
                                />
                              }
                            />
                            <Typography sx={{ fontSize: "16px !important" }}>
                              {row.cohort_name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {getCustomLabel(`Time Frame:`)}
                      <Select
                        size="small"
                        defaultValue="1"
                        className={classes.select}
                        classes={{
                          icon: classes.selectIcon,
                        }}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#0080B2",
                            border: "1px solid #0080B2",
                          },
                          "&.MuiSelect-icon": {
                            fill: "#0080B2 !important",
                          },
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            style: { border: "1px solid #0080B2" },
                          },
                        }}
                        value={selectedTimeFrame}
                        onChange={handleTimeChange}
                        style={{ minWidth: 165, maxWidth: 180 }}
                      >
                        <MenuItem value="0">Current month</MenuItem>
                        <MenuItem value="2">Last Quarter</MenuItem>
                        <MenuItem value="5">Last 6 Months</MenuItem>
                        <MenuItem value="11">Last 12 months</MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "60%",
                    }}
                  >
                    {new Array(4).fill(0).map((_, index) => (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        key={index}
                      >
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={150}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {!isFetching ? (
                <>
                  <div className="com_table_container">
                    <table
                      className="outer__table"
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      width="100%"
                    >
                      <thead>
                        <tr className="tRow" style={{ textAlign: "center" }}>
                          {columns.map((item, index) => {
                            if (item.show && !item.disable) {
                              return (
                                <>
                                  <th key={index}>
                                    <span className="table-head">
                                      {item.title}
                                      {item.statsAvailable && (
                                        <span
                                          className="statsIcon"
                                          onClick={() => {
                                            handleOpenModal(item);
                                          }}
                                        >
                                          <HistoryIcon />
                                        </span>
                                      )}
                                    </span>
                                  </th>
                                </>
                              );
                            }
                            return null;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {isFetching && !filteredTable?.length ? (
                          <tr className="tRow">
                            <td className="mt-4" colSpan="42">
                              <Spinner />
                            </td>
                          </tr>
                        ) : !isFetching && !filteredTable?.length ? (
                          <tr className="tRow">
                            <td colSpan={10}>
                              <Alert
                                severity="warning"
                                className="my-4 text-center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Comparison data for this organization is not
                                available yet. Please check back later.
                              </Alert>
                            </td>
                          </tr>
                        ) : filteredTable &&
                          filteredTable.every((row) => !row.show) ? (
                          <tr className="tRow">
                            <td colSpan={10}>
                              <Alert
                                severity="warning"
                                className="my-4 text-center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                No record found for the applied filter.
                              </Alert>
                            </td>
                          </tr>
                        ) : (
                          filteredTable?.map((item, index) => {
                            return item.show ? (
                              <>
                                <tr
                                  className="tRow entryHasNotes"
                                  key={index}
                                  style={{ textAlign: "right" }}
                                >
                                  {columns.map((column, i) => {
                                    if (
                                      column.key === "cohort_id" &&
                                      column.show
                                    )
                                      return (
                                        <td key={i} width={"14%"}>
                                          {item.cohort_name}
                                        </td>
                                      );
                                    else if (column.show && !column.disable)
                                      return (
                                        <td key={i}>{item[column.key]}</td>
                                      );
                                    return null;
                                  })}
                                </tr>
                              </>
                            ) : null;
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {filteredTable.length ? (
                    <div className={classes.root}>
                      <TablePagination
                        component="div"
                        count={totalCount}
                        page={Number(page)}
                        rowsPerPage={10}
                        rowsPerPageOptions={[]}
                        onPageChange={onPageChange}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="com_table_container p-3">
                  {new Array(8).fill(0).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      height={40}
                      sx={{ margin: "15px" }}
                    />
                  ))}
                </div>
              )}
              <div className="d-flex flex-row-reverse p-4">
                <div className="tblBtn">
                  <CustomButton
                    className="table-btn"
                    disabled={isFetching}
                    handleClick={() => setConfirmationModal(true)}
                  >
                    <span className="table-btn-span">
                      <span>Download</span>
                      <GetAppIcon className="table-icon-1" />
                    </span>
                  </CustomButton>
                  <CSVLink
                    data={exportData}
                    className="table-btn"
                    filename={`Cohort_Comparison`}
                    hidden={true}
                    ref={exportComparisonRef}
                    target="_blank"
                  />
                  <CustomButton
                    className="table-btn"
                    handleClick={(e) => {
                      e.preventDefault();
                      setShowLoadFilters(true);
                    }}
                    disabled={isFetching}
                  >
                    <span className="table-btn-span">
                      <span>Load Comparison</span>
                    </span>
                  </CustomButton>
                  <CustomButton
                    className="table-btn"
                    handleClick={(e) => {
                      e.preventDefault();
                      setShowSave(true);
                    }}
                    disabled={isFetching}
                  >
                    <span className="table-btn-span">
                      <span>Save Comparison</span>
                    </span>
                  </CustomButton>
                </div>
              </div>
              <SaveComparison
                open={showSave}
                setOpen={setShowSave}
                cohorts={filteredTable}
                time_frame={selectedTimeFrame}
                page={page} 
              />
              <LoadFilters
                open={showLoadFilters}
                setTotalCount={setTotalCount}
                setOpen={setShowLoadFilters}
                setTimeFrame={setSelectedTimeFrame}
                setFilterTable={setFilteredTable}
                getCohortComparison={getCohortComparison}
                setSelectedComparison={setSelectedComparison}
              />
              {openStatsModal && (
                <ComparisonStats
                  open={openStatsModal}
                  setOpen={setOpenStatsModal}
                  timeframe={selectedTimeFrame}
                  selectedChart={selectedChart}
                  datafilters={filteredTable}
                />
              )}
              <Backdrop className={classes.backdrop} open={isDownloading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
        </div>
      </Sidebar>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openConfirmationModal}
        onClose={() => setConfirmationModal(false)}
      >
        <DialogTitle>Download CSV Confirmation</DialogTitle>
        <DialogContent>
          Do you want to download:
          {selectedComparison
            ? " All cohorts or Loaded Comparison?"
            : !filteredTable.every((row) => row.show)
            ? " All cohorts or Selected Cohorts"
            : " All cohorts?"}
        </DialogContent>
        <DialogActions sx={{ p: "15px" }}>
          <Button onClick={() => setConfirmationModal(false)}>Cancel</Button>
          <Button variant={"contained"} onClick={() => downloadCSV("all")}>
            All
          </Button>
          {selectedComparison ? (
            <Button variant={"contained"} onClick={() => downloadCSV()}>
              Loaded Comparison
            </Button>
          ) : !filteredTable.every((row) => row.show) ? (
            <Button
              variant={"contained"}
              onClick={() => downloadCSV("selected")}
            >
              Selected
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ComparisonAnalytics;
