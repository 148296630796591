import React from 'react';
import {
    Card,
    CardContent,
    Popover,
    CardHeader,
    IconButton,
    Button,
    Box,
    TextField
  } from "@mui/material";

  import './ReportTable.scss'

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DatePicker2 from '../../../Components/DatePicker/DatePicker2';


const ClientReportFilter = (props) => {

    const {
        id, 
        anchorEl, 
        open, 
        handleClose, 
        heading, 
        searchValue, 
        setSearchValue,
        applyFilter,
        resetForm,
        onFromDateChange,
        onToDateChange,
        fromDate,
        toDate
    } = props;

    const convertToTitleCase = (str) =>{
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');                    // Join with spaces
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}        
        >
            <Card className="client-report-filter">
                <CardHeader
                    title={`Filter by ${convertToTitleCase(heading)}`}
                    disableTypography
                    sx={{ color: "#47a3c7", fontWeight: 500 }}
                    action={
                        <IconButton aria-label="cancel">
                            <CancelOutlinedIcon color="primary" onClick={handleClose}/>
                        </IconButton>
                    }
                />
                <CardContent sx={{ "&:last-child": { pb: 2 }, pt: 0 }}>
                <Box display="flex" justifyContent="space-between" paddingTop={2} paddingBottom={2}>
                    {
                        heading === "date" ?
                        <Box className="client-report-filter-container">
                            <DatePicker2 
                                onChange={() => false}
                                onAccept={onFromDateChange}
                                label="From"
                                value={fromDate}
                            />
                            <DatePicker2 
                                onChange={() => false}
                                onAccept={onToDateChange}
                                label="To"
                                value={toDate}
                            />
                        </Box>
                        :
                        <TextField 
                        value={searchValue}
                        onChange={(e)=>setSearchValue(e?.target?.value)}
                        
                        />
                    }

                    </Box>
                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                        <Button size={"medium"} variant="outlined" onClick={resetForm}>Clear</Button>
                        <Button size={"medium"} variant="contained" color="primary" onClick={applyFilter}>Apply</Button>
                    </Box>
                </CardContent>
            </Card>
        </Popover>
    )
}

export default ClientReportFilter