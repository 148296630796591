import React , {useState, useContext}from "react";
import { Box, Typography,   MenuItem,
  Select, } from "@mui/material";
import { CalendarMonth} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import SdohAnalysis from "./SdohAnalysis";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import { healthModalDropDownList } from "../../constants";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: (props) => props.height,
    backgroundColor: "#fff",
    borderRadius: "12px",
    marginTop: "20px"
  },
  header: {
    padding: "11px 12px 9px 18px !important",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerRightContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "28px",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "20px !important",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.03em",
    color: "#0080b2",
  },
  headerIcon: {
    fill: "#0080b2",
    cursor: "pointer",
  },
  detailsContainer: {
    padding: "0px 20px !important",
    maxHeight: "calc(100% - 100px)",
    overflow: "hidden",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "baseline",
    borderBottom: "1px solid #adb2b6",
    gap: "10px",
  },
  selectInput: {
    width: "130px",
    fontSize: "14px",
    color: "#141414",
    fontFamily: "Roboto",
    fontWeight: 400,
  },
}));

const SdohAnalysisDemo = () => {
  const [duration, setDuration] = useState("12");
  const { state } = useContext(WcchcPatientSummaryContext);
  let height = state.tablesDataLength.sdohAnalysis ? "500px" : "300px";
  const classes = useStyles({height});  


  return (
      <Box className={classes.mainContainer}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>SDoH Analysis</Typography>
          <Box className={classes.headerRightContainer}>
          <Box className={classes.filterContainer}>
            <CalendarMonth />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={duration}
              label="Age"
              variant="standard"
              onChange={(e) => setDuration(e?.target?.value)}
              className={classes.selectInput}
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              renderValue={(value) => 
                <Typography className={classes.selectedText}>
                  {healthModalDropDownList(true).find(item => Number(item.value) === Number(value)).label || ""}
                </Typography>
              }
            >
              {healthModalDropDownList(true).map((item, index) => (
                <MenuItem
                  className={classes.menuItem}
                  value={item?.value}
                  key={index}
                >
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        </Box>
        <Box className={classes.detailsContainer}>
            <SdohAnalysis duration={duration} demo={true}/>
        </Box>
      </Box>
  );
};

export default SdohAnalysisDemo;
