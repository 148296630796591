import apiClient from "./apiClient";
import userClient from "./userClient";

const login = async (accessToken) => {
  return await userClient(accessToken).get(`login`);
};

const caseLoad = async (pid, body) => {
  return await apiClient().post(`patientset/${pid}/stats`, body);
};

const serviceDate = async (data) => {
  return await apiClient().post(`patientset/servicedate`,data);
}

const details = async (statusval, pid) => {
  return await apiClient().get(
    `patientset/${pid}/details?comp_stratus=eq:${statusval}&depth=3&sort_order=%2Blast_name&limit=25&offset=0`,
  );
};

const contributions = async (body, pid) => {
  return await apiClient().post(`patientset/${pid}/contributors`, body);
};

const interventions = async (body, pid) => {
  return await apiClient().post(`patientset/${pid}/interventions`, body);
};

const histogramData = async (body) => {
return await apiClient().post('patientset/histogram/stats', body);
}

const newSimulation = async (requestbody, pid) => {
  return await apiClient().post(`sim/${pid}`, requestbody);
};

const allSimulations = async () => {
  return await apiClient().get(`sim/all`);
};

const patientsList = async (body, pid) => {
  return await apiClient().post(`patientset/${pid}/patient-list`, body);
};

const patientsListTotalCount = async (body, pid) => {
  return await apiClient().post(`patientset/${pid}/patient-list-total-count`, body);
};

const patientListFactors = async (body, pid) => {
  return await apiClient().post(`patientset/${pid}/patient-list-factors`, body);
};

const patientsDetails = async (pid, body) => {
  return await apiClient().post(`patient/${pid}`, body);
};

const patientsNotes = async (body) => {
  return await apiClient().post(`patient/emrnotes`, body);
};

const patientListView = async (pid) => {
  return await apiClient().get(`patientset/${pid}/patient-list-view`);
};

const patientSets = async (params) => {
  return await apiClient().get(`patient_sets`, { params: params });
};

const createPatientSet = async (body) => {
  return await apiClient().post(`create_patient_set`, body);
};

const initiateCohort = async (body) => {
  return await apiClient().post(`initiate_cohort`, body);
};

const createCohort = async (body) => {
  return await apiClient().post(`create_cohort`, body);
};

const patientSet = async (pid) => {
  return await apiClient().get(`patientset/${pid}`);
};

const editPatientSet = async (body) => {
  return await apiClient().post(`patientset/edit`, body);
};

const icdCodesGenerateCSV = async (body) => {
  return await apiClient().post(`patient/icdcodecsv`, body);
}

const patientStats = async (body) => {
  return await apiClient().post(`patientset/patient-stats-histogram`, body);
};

const patientCoordinator = async (body) => {
  return await apiClient().post(`patient/coordinator`, body);
};

const generateCsv = async (body) => {
  return await apiClient().post(`patientset/generate-csv`, body);
};

const geoMap = async (pid) => {
  return await apiClient().get(`patientset/${pid}/geo/geoid-map-us`);
};

const icdCodes = async ( body) => {
  return await apiClient().post(`patient/saveicdcodes`, body);
};

const icdCodesListView = async (pid, body) => {
  return await apiClient().post(`patient/icdcodes_list_view/${pid}`, body);
}

const logout = async (accessToken, data) => {
  return await userClient(accessToken).post(`token/revoke`, data);
};

const akaAccess = async (data) => {
  return await apiClient().post(`admin/akaaccess`, data);
};

const createUserApi = async (data) => {
  return await apiClient().post(`admin/createnewuser`, data)
}

const reInviteUser = async (data) => {
  return await apiClient().post(`admin/reinviteuser`, data)
}

const listOrganizations = async () => {
  return await apiClient().get(`admin/listorganizations`)
}

const listUsersAkaAccess = async () => {
  return await apiClient().get(`admin/listUsers`)
}

const createOrganization = async (body) => {
  return await apiClient().post(`admin/create`, body);
};
const createSimutation = async (data) => {
  return await apiClient().post(`create-simulation`, data);
};

const listSimulationSet = async (cohort_id,page) => {
  return await apiClient().get(`simulations/${cohort_id}/list/${page}/simulations`);
};

const createMultipartUpload = async (data) => {
  return await apiClient().post(`upload/create`, data);
};

const markUploadAsComplete = async (data) => {
  return await apiClient().post(`upload/complete`, data);
};

const careActivityReport = async (data) => {
  return await apiClient().post(`report/careActivity`, data);
};

const patientCoordinators = async (pid) => {
  return await apiClient().get(`patientCoordinator/${pid}/list`);
};

//patient coordinator patient list
const patientCoordinatorPatientList = async (body) => {
  return await apiClient().post(`patientCoordinator/patientList`, body);
};

const customAttributeChartData = async (body) => {
  return await apiClient().post(`/patientset/custom-attribute-chart`, body)
}

const listCoordinates = async (body, pid, cancelToken) => {
  return await apiClient().post(`coordinates/${pid}/geographic`, body,  { cancelToken });
};

const getSidebarFeatures = async () => {
  return await apiClient().get("sidebar-features");
};

const saveFilter = async (data) => {
  return await apiClient().post(`save_filter`, data);
}

const deleteSavedFilter = async (data) => {
  return await apiClient().post(`delete_saved_filter`, data);
}

const editSavedFilter = async (data) => {
  return await apiClient().post(`edit_saved_filter`, data);
}

const getSavedFilter = async () => {
  return await apiClient().get(`saved_filters`);
}

const comparisonAnalytics = async (data) => {
  return await apiClient().post(`comparative-analytics`, data);
};

const cohortOverView = async (data) => {
  return await apiClient().post(`cohort-overview/summary`, data);
};

const riskImpact = async (data) => {
  return await apiClient().post(`risk-impact/summary`, data);
};

const providerProfiling = async (data) => {
  return await apiClient().post(`provider-profiling`, data);
};

const comparisonStatsChart = async (data) => {
  return await apiClient().post(`comparison/stats`, data);
};

const saveComparisonFilter = async (data) => {
  return await apiClient().post(`comparison/save_filter`, data);
};

const getComparisonFilter = async () => {
  return await apiClient().get(`comparison/get_filter`);
};

const getOrgFileList = async (startDate, endDate) => {
  return await apiClient().get(`dca/list_files?startDate=${startDate}&endDate=${endDate}`);
}

// ------------- Iframe APIs ---------------
const patientScore = async (patientMrn, iframe) => {
  return await apiClient("node", iframe).get(
    `patient/score?id=${patientMrn}`,
    {}
  );
};

const iframePatientDetails = async (patientMrn, iframe) => {
  return await apiClient("node", iframe).get(
    `iframe/patient-details?id=${patientMrn}`,
    {}
  );
};

// ------------- Iframe APIs end --------------

const generateDcaAnalysis = async (data) => {
  return await apiClient().post(`dca/generate_dca_analysis`, data);
}

const getPreviousDcaOutput = async (params,fileName) => {
  if (params) {
    return await apiClient().get(`dca/get_previous_output?file=${params}&filename=${fileName}`);
  }
  return await apiClient().get(`dca/get_previous_output`);
}

//Dismiss most recent patient note contributor
const dismissPatientNote = async (data) => { 
  return await apiClient().post(`patient/dismisscontributor`, data);
}

//retain dismissed patient note contributor (Undo)
const retainPatientNote = async (data) => {
  return await apiClient().post(`patient/undo-dismisscontributor`, data);
}

let combinedServices = {
  login,
  caseLoad,
  serviceDate,
  details,
  contributions,
  interventions,
  newSimulation,
  allSimulations,
  patientsList,
  patientsListTotalCount,
  patientListView,
  createPatientSet,
  initiateCohort,
  createCohort,
  patientSet,
  editPatientSet,
  icdCodesGenerateCSV,
  patientStats,
  geoMap,
  icdCodes,
  icdCodesListView,
  patientSets,
  logout,
  patientsDetails,
  patientsNotes,
  generateCsv,
  patientCoordinator,
  akaAccess,
  listUsersAkaAccess,
  createUserApi,
  reInviteUser,
  listOrganizations,
  createOrganization,
  createSimutation,
  listSimulationSet,
  createMultipartUpload,
  markUploadAsComplete,
  careActivityReport,
  patientCoordinators,
  patientCoordinatorPatientList,
  listCoordinates,
  customAttributeChartData,
  getSidebarFeatures,
  saveFilter,
  deleteSavedFilter,
  editSavedFilter,
  getSavedFilter,
  comparisonAnalytics,
  cohortOverView,
  riskImpact,
  providerProfiling,
  comparisonStatsChart,
  saveComparisonFilter,
  getComparisonFilter,
  getOrgFileList,
  patientScore,
  generateDcaAnalysis,
  iframePatientDetails,
  getPreviousDcaOutput,
  patientListFactors,
  dismissPatientNote,
  retainPatientNote,
  histogramData
};

export default combinedServices;