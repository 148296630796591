import services from "../Services/patientServices";

export class Uploader {
  constructor(options) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    // number of parallel uploads
    this.threadsQuantity = 15;
    this.file = options.file;
    this.fileName = options.fileName;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.isCompleteFn = () => { };
    this.onProgressFn = () => { };
    this.onErrorFn = () => { };
  }

  async createMultipartUpload() {
    try {
      // Retrieving the pre-signed URLs
      let numberOfparts = Math.ceil(this.file.size / this.chunkSize);

      // When number of chunks are greater than 130 than file upload complete gives forbidden error.
      // so when number of chunks are greater than 130 than we are limiting the number of chunks by 130.
      if (numberOfparts > 130) {
        this.chunkSize = this.file.size / 130;
        numberOfparts = Math.ceil(this.file.size / this.chunkSize);
      } 

      const createMultipartUpload = await services.createMultipartUpload({
        file_name: this.fileName,
        parts: numberOfparts,
      });

      this.fileId = createMultipartUpload.data.fileId;
      this.fileKey = createMultipartUpload.data.fileKey;
      this.parts.push(...createMultipartUpload.data.signedUrlList);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }


  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) return;

    if (!this.parts.length) {
      if (!activeConnections) this.complete();
      return;
    };

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext()
      };

      this.sendChunk(chunk, part, sendChunkStarted)
      .then(() => {
        this.sendNext();
      })
      .catch((error) => {
        this.parts.push(part);
        this.complete(error);
      });
    }
  }

  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      if (this.fileId && this.fileKey && !this.aborted) {
        const user = JSON.parse(localStorage.getItem("user"));
        const user_id = user.user && user.user.user_id;
        await services.markUploadAsComplete({
          fileId: this.fileId,
          fileKey: this.fileKey,
          parts: this.uploadedParts,
          user_id
        });
        this.isCompleteFn({ complete: true });
      }
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  handleProgress(part, event) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);
      const total = this.file.size;
      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        );

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replaceAll('"', ""),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject();
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  start() {
    this.createMultipartUpload();
  }


  isComplete(isComplete) {
    this.isCompleteFn = isComplete
    return this;
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
