import React, { useContext } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProblemsIdentifiedTable from "../Tables/WcchcDashboard/ProblemsIdentifiedTable";
import Header from "./Header";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    width: "100%",
    height: (props) => props.height,
    overflow: "hidden",
  },
}));

const ProblemsIdentified = ({ setProblemsIdentifiedModal,showBorder }) => {
  const { state } = useContext(WcchcPatientSummaryContext);
  let height = state.tablesDataLength.diagnosesandsymptoms ? "450px" : "200px";
  const classes = useStyles({height});

  return (
    <Box className={`${classes.mainContainer} ${showBorder ? "selected-border" : ""}`} id="problems-identified">
      <Header
        title="Active Diagnoses"
        onClick={setProblemsIdentifiedModal}
        disable={state.isFetchingProblems}
      />
      <ProblemsIdentifiedTable />
    </Box>
  );
};

export default ProblemsIdentified;
