import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";
import ExpandIcon from "../../../Assets/Images/expand-icon.png";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import { useState, useEffect } from "react";
import "../_Dashboard.scss";
import CohortComparisonChart from "./CohortComparisonChart";
import services from "../../../Services/patientServices";
// import CohortPerformanceGraphs from "./CohortPerformanceGraphs";
import ComparisonChartsModal from "./Modals/ComparisonChartsModal";
import ComparisonTableModal from "./Modals/ComparisonTableModal";
import ProgramBarChart from "./ProgramBarChart";
import AT_RISK_DATA from "./Data/At_Risk.json";
import {
  CohortComparisionSkeleton,
  GraphSkeleton,
} from "../../../Components/SkeletonLoader/DashboardSkeleton";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toLowerCase } from "../../../Utils/stringOprations";

const PROGRAMS = [
  {
    key: "atRisk",
    title: "Re-admit and Risk Management",
    data: AT_RISK_DATA,
  },
];

const COLUMNS = [
  {
    key: "Claims_Sum",
    title: "Total Claims Cost ($)",
    type: "currency-string-million",
  },
  { key: "Avg", title: "PMPM Cost ($)", type: "currency-string" },
  { key: "Emergency Room", title: "ER Visits", type: "comma-separated" },
  {
    key: "Hospital Inpatient",
    title: "Inpatient Visits",
    type: "comma-separated",
  },
];

export default function CohortComparisonTable({selectedTimeFrame}) {
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [columns, setColumns] = useState([]);
  const [openChartsModal, setOpenChartsModal] = useState(false);
  const [openTableModal, setOpenTableModal] = useState(false);
  const { patientSetInfo, selectedCohorts } = useContext(PatientSetContext);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const environment = window.config.environment;
  const history = useNavigate();

  useEffect(() => {
    if (patientSetInfo) {
      const selectedCohortIds = selectedCohorts
        ? selectedCohorts
            .filter((cohort) => cohort.selected)
            .map((cohort) => cohort.patient_set_id)
        : [patientSetInfo.patient_set_id];
      const limit = selectedCohorts && selectedCohortIds.length > 10 ? 10 : selectedCohortIds.length >= 5 ? selectedCohortIds.length : 5;
      getCohortComparison(selectedTimeFrame, selectedCohortIds, limit);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedCohorts, selectedTimeFrame]);

  useEffect(() => {
    if (patientSetInfo) {
      setSelectedProgram(patientSetInfo.patient_set_id);
    }
  }, [patientSetInfo]);

  const getCohortComparison = async (timeframe, filter_cohort, limit) => {
    try {
      setIsFetching(true);
      const result = await services.comparisonAnalytics({ timeframe, page, limit, filterCohort : filter_cohort});
      const { data } = result.data;
      const columns = result.data.columns.filter(
        (col) =>
          !data.every((row) => row[col.key] === "-" || row[col.key] == null)
      );
      setTotalCount(result.data.totalCount)
      setTableData(data);
      setColumns(columns);
      setIsFetching(false);
    } catch (error) {
      console.log("Error while fetching cohort comparison", error);
      setIsFetching(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = (event, id) => {
    setSelectedProgram(id);
  };

  const handleCloseChartModal = () => {
    setOpenChartsModal(false);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Card
          sx={{
            height: 400,
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
          }}
          className="comparison-table"
        >
          {isFetching ? (
            <CohortComparisionSkeleton />
          ) : (
            <>
              <CardHeader
                title={`How do your ${toLowerCase(
                  patientSetInfo?.cohort_name
                )}s compare?`}
                titleTypographyProps={{ fontWeight: 700, fontSize: 20 }}
                action={
                  <>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      {/* <Grid item>
                      <TextField
                        label={`Search ${toCapitalize(patientSetInfo?.cohort_name)} Name`}
                        size={"small"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid> */}
                      {/* <Grid item>
                      <FormControl>
                        <Select
                          value={selectedTimeFrame}
                          onChange={handleDateChange}
                          size={"small"}
                        >
                          <MenuItem value={0}>Current month</MenuItem>
                          <MenuItem value={2}>Last Quarter</MenuItem>
                          <MenuItem value={5}>Last 6 Months</MenuItem>
                          <MenuItem value={11}>Last 12 months</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> */}
                      <Grid item>
                        <IconButton onClick={() => setOpenTableModal(true)}>
                          <img src={ExpandIcon} alt="" style={{ right: 0 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                }
              />
              <CardContent sx={{ p: 0, pt: 1.5, overflow: 'hidden'}}>
                <ComparisonTable
                  columns={columns}
                  tableData={tableData}
                  handleRowClick={handleRowClick}
                  openTableModal={openTableModal}
                />
              </CardContent>
              <CardActions
                sx={{
                  justifyContent: "space-between",
                  marginTop: "auto",
                  p: "0 20px 0 0",
                }}
              >
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={tableData.length}
                  page={Number(page)}
                  onPageChange={handlePageChange}
                  rowsPerPageOptions={[]}
                />
                <Link
                  onClick={() => history("/comparative-analytics")}
                  sx={{ display: "flex", cursor: "pointer" }}
                >
                  View Details
                  <KeyboardDoubleArrowRightSharpIcon />
                </Link>
              </CardActions>
            </>
          )}
        </Card>
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <CohortPerformanceGraphs />
      </Grid> */}
      {isFetching ? (
        <GraphSkeleton />
      ) : (
        <Grid item xs={12}>
          <Card
            sx={{ height: 600, borderRadius: "15px" }}
            className="comparison-table"
          >
            <CardHeader
              title={`How are ${toLowerCase(
                patientSetInfo?.cohort_name
              )} costs & metrics trending?`}
              titleTypographyProps={{ fontWeight: 700, fontSize: 20 }}
              action={
                <IconButton
                  onClick={() => {
                    setOpenChartsModal(true);
                  }}
                >
                  <img src={ExpandIcon} alt="" style={{ right: 0 }} />
                </IconButton>
              }
            />
            <CardContent>
              <Box sx={{ height: 500, overflowY: "scroll" }}>
                {environment === "demo-a" || environment === "demo" ? (
                  COLUMNS.map((col) => (
                    <Card
                      sx={{
                        height: 450,
                        borderRadius: "15px",
                        backgroundColor: "#CCE0FF",
                        mb: 1,
                      }}
                    >
                      <CardHeader
                        title={
                          col.title.includes("$")
                            ? col.title.replace("in ($)", "")
                            : col.title
                        }
                        titleTypographyProps={{
                          fontWeight: 600,
                          fontSize: 15,
                        }}
                        sx={{ pb: "5px" }}
                      />
                      <CardContent sx={{ p: 0 }}>
                        <ProgramBarChart
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                          column={col}
                        />
                      </CardContent>
                    </Card>
                  ))
                ) : (
                      <Grid container spacing={2}>
                        {columns.map((col) =>
                          col.statsAvailable ? (
                            <CohortComparisonChart
                              selectedChart={col}
                              selectedProgram={patientSetInfo?.patient_set_id}
                              timeframe={selectedTimeFrame}
                              tableData={tableData}
                            />
                          ) : null
                        )}
                  </Grid>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
      {openChartsModal && <ComparisonChartsModal
          openModal={openChartsModal}
          handleCloseModal={handleCloseChartModal}
        columns={environment === "demo-a" || environment === "demo" ? COLUMNS : columns.filter(i => i.statsAvailable)}
          timeframe={selectedTimeFrame}
          setSelectedProgram={setSelectedProgram}
          selectedProgram={"atRisk"}
          programs={PROGRAMS}
          tableData={tableData}
      />}
      {openTableModal && <ComparisonTableModal
          openModal={openTableModal}
          handleCloseModal={() => {
            setOpenTableModal(false);
          }}
          columns={columns}
          totalCount={totalCount}
          selectedTimeFrame={selectedTimeFrame}
      />}
    </React.Fragment>
  );
}

export const ComparisonTable = ({
  columns,
  tableData,
}) => {
  const { patientSetInfo } = useContext(PatientSetContext);

  return (
    <TableContainer sx={{ maxHeight: '100%', overflowY: 'scroll' }}>
      <Table stickyHeader size={"small"}>
        <TableHead>
          <TableRow>
            {columns.length &&
              columns.map((column) => (
                <TableCell
                  sx={{ lineHeight: "1.3rem", fontWeight: 600 }}
                  key={column.key}
                >
                  {column.key === "cohort_id"
                    ? "Population/Program"
                    : column.title}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length &&
            tableData.map((row) => {
              const isItemSelected =
                patientSetInfo?.patient_set_id === row.cohort_id;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                  key={row.cohort_id}
                  onClick={() => {}}
                >
                  {columns.map((col, i) => {
                    if (col.key === "cohort_id") {
                      return (
                        <TableCell>
                          {row.cohort_name}
                        </TableCell>
                      );
                    } else return <TableCell>{row[col.key]}</TableCell>;
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
