import React, { useContext, useEffect, Suspense } from "react";
import "./CustomBootstrap.css";
import "./App.scss";
import { AuthContext } from "./Contexts/authContext";
import checkAuth from "./Services/checkAuth";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import PatientSets from "./Pages/PatientSets/PatientSets";
import PrivateRoute from "./Components/Routes/PrivateRoute";
import PublicRoute from "./Components/Routes/PublicRoute";
import Aka from "./Pages/Aka/Aka";
import FileUpload from "./Pages/FileUpload/FileUpload";
import HeatMap from "./Pages/Heatmap/Heatmap";
import ContributorAnalysis from "./Pages/Contributors/Contributors";
import InterventionAnalysis from "./Pages/Interventions/Interventions";
import PatientsList from "./Pages/PatientsList/_PatientsList";
import ExistingSimulation from "./Pages/ExistingSimulation/ExistingSimulation";
import GeneratePatientReport from "./Pages/GenerateReport/GeneratePatientReport";
import PatientReport from "./Pages/PatientReport/PatientReport";
import SummaryReport from "./Pages/SummaryReport/SummaryReport";
import ReportSelection from "./Pages/ReportSelection/ReportSelection";
import GenerateClinicalStaffReport from "./Pages/GenerateReport/Genesis/GenerateClinicalStaffReport";
import Report from "./Pages/Report/Report";
import GenerateClientReport from "./Pages/GenerateReport/Genesis/GenerateClientReport";
import GenerateCareActivityReport from "./Pages/GenerateReport/IF/GenerateCareActivityReport";
import PatientScores from "./Components/PatientScores/PatientScores";
import PatientDetailsTab from "./Components/PatientDetailsTab/PatientDetailsTab";
import IframeReports from "./Pages/ReportSelection/IframeReports";
import GenerateCarePlanReport from "./Pages/GenerateReport/Genesis/GenerateCarePlanReport";
import IFGenerateCarePlanReport from "./Pages/GenerateReport/IF/GenerateCarePlanReport";
import GenerateCaseloadReport from "./Pages/GenerateReport/IF/GenerateCaseloadReport";
import IFGenerateReferralReport from "./Pages/GenerateReport/IF/GenerateReFerralReport";
import IFGenerateCareActivityReport from "./Pages/GenerateReport/IF/GenerateCareActivityReport2";
import ComparisonAnalytics from "./Pages/ComparisonAnalytics/ComparisonAnalytics";
import WcchcPatientSummary from "./Pages/WcchcPatientSummary/WcchcPatientSummary";
import DataContentAnalysis from "./Pages/DCA/DataContentAnalysis";
import DcaOutput from "./Pages/DCA/DcaOutput";
import ForesightsIframe from "./Components/Iframe/Foresights";
import DcaPreviousOutput from "./Pages/DCA/DcaPreviousOutput";
import FileDetails from "./Pages/DCA/FileDetails";
import FlowDiagram from "./Pages/DCA/FlowDiagram";
import GenesisIframe from "./Pages/GenesisIframe/GenesisIframe";
import CumulativeTrendChart from "./Pages/GenesisIframe/CumulativeTrendChart";
import LearnerTypeProgress from "./Pages/GenesisIframe/LearnerTypeProgress";
import LearnerVsClientProgress from "./Pages/GenesisIframe/LearnerVsClientProgress";
import CohortAnalysisChart from "./Pages/GenesisIframe/CohortAnalysisChart";
import DisparityAnalytics from "./Pages/DisparityAnalytics/DisparityAnalytics";
import ProviderProfiling from "./Pages/ProviderProfiling/ProviderProfiling";
import DemoPatientSummary from "./Pages/WcchcPatientSummary/DemoPatientSummary";
import GenesisIframeComponent from "./Pages/GenesisIframe/GenesisIframeComponent";
import Dashboard from "./Pages/Dashboard/_Dashboard";
import CommunityResourcesList from "./Components/PatientRecommendations/CommunityResourcesList";
import DashboardOverview from "./Pages/CohortOverview/DashboardOverview";

const App = () => {
  const auth = useContext(AuthContext);
  const reports = [
    "/genesis/report/clinical-staff",
    "/genesis/report/client-report",
    "/genesis/report/care-plan-report",
    "/if/report/caseload-report",
    "/if/report/referral-report",
    "/if/report/care-plan-report",
    "/if/report/care-activity-report"
  ];

  useEffect(() => {
    console.log("calling check auth function from app.js")
    checkAuth(auth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
        <Suspense fallback={<span>Loading..</span>}>
          <Routes>
            <Route
              path="/"
              element={<PublicRoute element={Home} />}
            />
            <Route exact path="/summary-dashboard" element={<PrivateRoute element={Dashboard} />} />
            <Route exact path="/patient-sets" element={<PrivateRoute element={PatientSets} />} />
            <Route exact path="/overview" element={<PrivateRoute element={DashboardOverview} />} />
            <Route exact path="/aka" element={<PrivateRoute element={Aka} />} />
            <Route exact path="/upload" element={<PrivateRoute element={FileUpload} />} />
            {/* Data Content Assessment Start */}
            <Route
              exact
              path="/data-content-analysis"
              element={<PrivateRoute element={DataContentAnalysis} />}
            />
            <Route
              exact
              path="/data-content-analysis/output/:file_name"
              element={<PrivateRoute element={DcaOutput} />}
            />
            <Route
              exact
              path="/data-content-analysis/previous-output"
              element={<PrivateRoute element={DcaPreviousOutput} />}
            />
            <Route
              exact
              path="/data-content-analysis/dca-new-output"
              element={<PrivateRoute element={FlowDiagram} />}
            />
            <Route
              exact
              path="/data-content-analysis/file-details/:file_key/:file_name"
              element={<PrivateRoute element={FileDetails} />}
            />
            {/* Data Content Assessment End */}
            <Route
              exact
              path="/contributors"
              element={<PrivateRoute element={ContributorAnalysis} />}
            />
            <Route
              exact
              path="/interventions"
              element={<PrivateRoute element={InterventionAnalysis} />}
            />
            <Route exact path="/patient-list" element={<PrivateRoute element={PatientsList} />} />
            <Route exact path="/patient-list/community-resources" element={<PrivateRoute element={CommunityResourcesList} />} />
            <Route
              exact
              path="/simulations"
              element={<PrivateRoute element={ExistingSimulation} />}
            />
            <Route exact path="/geographic" element={<PrivateRoute element={HeatMap} />} />
            <Route exact path="/reports" element={<PrivateRoute element={ReportSelection} />} />
            <Route
              exact
              path="/if/report/care-activity"
              element={<PrivateRoute element={SummaryReport} />}
            />

            {/* Iframe components Start */}
            <Route
              exact
              path="/iframe/reports"
              element={<PrivateRoute element={IframeReports} />}
            />
            <Route
              exact
              path="/launch/foresights"
              element={<PublicRoute element={ForesightsIframe} />}
            />
            <Route
              exact
              path="/patient-scores"
              element={<PrivateRoute element={PatientScores} />}
            />
            <Route
              exact
              path="/patient-details"
              element={<PrivateRoute element={PatientDetailsTab} />}
            />
            {/* Iframe components End */}

            {/* Reports */}

            <Route
              exact
              path="/genesis/generate/clinical-staff-report"
              element={<PrivateRoute element={GenerateClinicalStaffReport} />}
            />
            <Route
              exact
              path="/generate/care-activity-report"
              element={<PrivateRoute element={GenerateCareActivityReport} />}
            />
            <Route
              exact
              path="/if/generate/care-activity-report"
              element={<PrivateRoute element={IFGenerateCareActivityReport} />}
            />
            <Route
              exact
              path="/genesis/generate/client-report"
              element={<PrivateRoute element={GenerateClientReport} />}
            />
            <Route
              exact
              path="/patient/report"
              element={<PrivateRoute element={GeneratePatientReport} />}
            />
            <Route
              exact
              path="/if/generate/caseload-report"
              element={<PrivateRoute element={GenerateCaseloadReport} />}
            />
            <Route
              exact
              path="/if/generate/care-plan-report"
              element={<PrivateRoute element={IFGenerateCarePlanReport} />}
            />
            <Route
              exact
              path="/if/generate/referral-report"
              element={<PrivateRoute element={IFGenerateReferralReport} />}
            />

            <Route
              exact
              path="/report/interventions"
              element={<PrivateRoute element={SummaryReport} />}
            />
            <Route
              exact
              path="/report/patient"
              element={<PrivateRoute element={PatientReport} />}
            />
            <Route
              exact
              path="/genesis/generate/care-plan-report"
              element={<PrivateRoute element={GenerateCarePlanReport} />}
            />
            <Route
              exact
              path="/dash-disparity-analytics"
              element={<PrivateRoute element={DisparityAnalytics} />}
            />

            {reports.map((element, index) => (
              <Route
                key={index}
                exact
                path={element}
                element={<PrivateRoute element={Report} />}
              />
            ))}

            <Route
              exact
              path="/comparative-analytics"
              element={<PrivateRoute element={ComparisonAnalytics} />}
            />
            <Route
              exact
              path="/provider-profiling"
              element={<PublicRoute element={ProviderProfiling} />}
            />
            <Route
              exact
              path="/wcchc/dashboard/:id"
              element={<PrivateRoute element={WcchcPatientSummary} />}
            />
            <Route
              exact
              path="/wcchc/dashboard"
              element={<PrivateRoute element={WcchcPatientSummary} />}
            />
            <Route
              exact
              path="/dashboard/:id"
              element={<PrivateRoute element={DemoPatientSummary} />}
            />
            <Route
              exact
              path="/dashboard"
              element={<PrivateRoute element={DemoPatientSummary} />}
            />
            <Route
              exact
              path="/genesis/launch"
              element={<PublicRoute element={GenesisIframe} />}
            />
            <Route
              exact
              path="/genesis/cumulative-trend-chart"
              element={<PrivateRoute element={CumulativeTrendChart} />}
            />
            <Route
              exact
              path="/genesis/learner-type-progress"
              element={<PrivateRoute element={LearnerTypeProgress} />}
            />
            <Route
              exact
              path="/genesis/learner-vs-client-progress"
              element={<PrivateRoute element={LearnerVsClientProgress} />}
            />
            <Route
              exact
              path="/genesis/cohort-analysis"
              element={<PrivateRoute element={CohortAnalysisChart} />}
            />
            <Route
              exact
              path="/genesis/iframe-component"
              element={<PublicRoute element={GenesisIframeComponent} />}
            />
          </Routes>
        </Suspense>
    </div>
  );
};

export default App;
