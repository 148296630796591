import {
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TablePagination,
} from "@mui/material";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { ComparisonTable } from "../CohortComparisonTable";
import { useNavigate } from "react-router-dom";
import { toCapitalize } from "../../../../Utils/stringOprations";
import { useContext, useEffect, useState } from "react";
import { PatientSetContext } from "../../../../Contexts/patientSetContext";
import services from "../../../../Services/patientServices";
import { ComparisionTableLoader } from "../../../../Components/SkeletonLoader/DashboardSkeleton";

const ComparisonTableModal = ({
  openModal,
  handleCloseModal,
  columns,
  totalCount,
  selectedTimeFrame
}) => {
  const { patientSetInfo } = useContext(PatientSetContext);
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const history = useNavigate();

  useEffect(() => {
    getCohortComparison();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  

  const getCohortComparison = async (timeframe = selectedTimeFrame) => {
    try {
      setIsFetching(true);
      const result = await services.comparisonAnalytics({ timeframe, page, limit: 10 });
      const { data } = result.data;
      setTableData(data);
      setIsFetching(false);
    } catch (error) {
      console.log("Error while fetching cohort comparison", error);
      setIsFetching(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xl"}
      open={openModal}
      onClose={handleCloseModal}
    >
      {
        isFetching ?
        <ComparisionTableLoader />
        :
        <>
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {`${toCapitalize(patientSetInfo?.cohort_name)} costs & Metrics`}
            <IconButton aria-label="close" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ pb: 0, pt: 2 }}>
          <ComparisonTable columns={columns} tableData={tableData} />
        </DialogContent>
        <DialogActions
          sx={{ p: 2, pt: 0, justifyContent: "space-between", alignItems: "end" }}
        >
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={Number(page)}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[]}
          />
          <Link
            onClick={() => history("/comparative-analytics")}
            sx={{ display: "flex", cursor: "pointer" }}
          >
            View Details
            <KeyboardDoubleArrowRightSharpIcon />
          </Link>
        </DialogActions>
        </>
      }
    </Dialog>
  );
}

export default ComparisonTableModal;
