import React, { createContext, useReducer, useState } from "react";
import services from "../Services/patientServices";
import getCognitoToken from "../Utils/getCognitoToken";
import redirectToLogin from "../Utils/redirectToLogin";

const AuthContext = createContext();

const initialState = {
  isLoggedIn: false,
  isError: false,
  user: null,
  isFetching: true
};

const reducer = (state, action) => {

  switch (action.type) {
    case "LOGIN_START":

      return {
        isLoggedIn: false,
        isError: false,
        isFetching: true
      };

    case "LOGIN_SUCCESS":

      return {
        isError: false,
        isLoggedIn: true,
        user: action.payload,
        isFetching: false
      };

    case "LOGIN_FAILURE":
      return {
        isError: true,
        isLoggedIn: false, 
        user: null,
        isFetching: false
      };

    case "GENESIS_LOGIN_SUCCESS":
      return {
        isError: false,
        isLoggedIn: true,
        user: null,
        isFetching: false
      };

    default:
      return state;
  }
};

const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [authLoading, setAuthLoading] = useState(false);

  const setAuthSuccess = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: user?.user
    });
  };

  const setAuthFailure = () => {
    dispatch({
      type: "LOGIN_FAILURE"
    });
  };

  const login = async () => {
    try {
      const accessToken = await getCognitoToken('accessToken');
      dispatch({
        type: "LOGIN_START",
      });
      const loginResponse = await services.login(accessToken);
      console.log('User found => ', loginResponse);
    
      const { access_token, refresh_token, ...otherData } = loginResponse.data.data;
      localStorage.setItem(
        "user",
        JSON.stringify({
          isLoggedIn: true,
          user: otherData
        })
      );
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
  
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: loginResponse.data.data
      });
    }
    catch(error) {
      console.log(error);
      localStorage.clear();
      // redirectToLogin();
      // throw new Error(error);
    }
  };

  const logout = async () => {
    try {
      setAuthLoading(true);
      const accessToken = await getCognitoToken('accessToken');
      const logoutResponse = await services.logout(accessToken, {
        refresh_token: localStorage.getItem("refresh_token")
      });
      console.log('User logged out => ', logoutResponse.data);
      localStorage.clear();
      if(logoutResponse?.data?.time_since_logged_out) {
        localStorage.setItem("time_since_logged_out", logoutResponse.data.time_since_logged_out);
      };
      setAuthLoading(false);
      redirectToLogin();
    }
    catch(error) {
      console.log(error);
      localStorage.clear();
      setAuthLoading(false);
      redirectToLogin();
      throw new Error(error);
    }
  };

  return (
    <AuthContext.Provider value={{ state, login, setAuthSuccess, setAuthFailure, logout, dispatch, authLoading }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
