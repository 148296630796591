import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import SmartAlertTabs from './SmartAlertTabs';
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      width: "100%",
      height: "365px",
      overflow: "hidden",
    },
  }));

const SmartAlertDemo = ({ value, setValue,selectedNotification, setSelectedNotification }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
        <Header title={"Smart Alerts"} showExpandIcon={false}/>
        <SmartAlertTabs value={value} setValue={setValue} selectedNotification={selectedNotification} setSelectedNotification={setSelectedNotification} />
    </Box>
  )
}

export default SmartAlertDemo