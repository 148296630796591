import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Backdrop,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Snackbar,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import MuiAlert from "@mui/material/Alert";
import services from "../../Services/patientServices";

const Hr = styled.hr({
  margin: "0.5rem 0rem",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
};

const timeFrameLabels = {
  0: 'Current month',
  2: 'Last Quarter',
  5: 'Last 6 Months',
  11: 'Last 12 months'
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SaveComparison = ({ open, setOpen, cohorts, time_frame, page }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [filterName, setFilterName] = useState({ value: "", error: "" });
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    severity: "",
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const handleClose = () => {
    setFilterName({ state: "", error: "" });
    setOpen(false);
  };

  const handleSaveFilter = async () => {
    if (!filterName.value) {
      const data = filterName;
      data.error = "Filter set name is required";
      setFilterName((prev) => ({
        value: prev.value,
        error: "Filter set name is required",
      }));
      return;
    }
    const selectedCohorts = cohorts.filter(cohot => cohot.show).map(({ cohort_id }) => cohort_id);
    if (!selectedCohorts.length) {
      setAlertMessage({ message: 'No cohort selected', severity: 'warning' });
      setOpen(false);
      setShowToast(true);
      return;
    }
    try {
      setIsFetching(true);
      const { data } = await services.saveComparisonFilter({
        filter_name: filterName.value,
        selected_cohorts: selectedCohorts,
        selected_timeframe: String(time_frame),
        filter_page: page || 0
      });
      setAlertMessage({ message: data?.message, severity: 'success' });
      setIsFetching(false);
      setShowToast(true);
      setOpen(false);
      setFilterName({ state: "", error: "" });
    } catch (error) {
      console.log("Error in saving comparison filter => ", error);
      setIsFetching(false);
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-save-comparison"
        aria-describedby="modal-save-comparison"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid
              item
              container
              xs={12}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <Typography variant="h6">Save comparison</Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end">
                <IconButton onClick={() => handleClose()}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Grid item xs={4}>
                <Typography variant="body1">Filter set name:</Typography>
              </Grid>
              <Grid item xs={8}>
                <input
                  className="form-control save-filter-input"
                  type="text"
                  value={filterName.value}
                  placeholder="Enter filter name"
                  onChange={(e) =>
                    setFilterName({ value: e.target.value, error: "" })
                  }
                />
                {filterName?.error && (
                  <FormHelperText error sx={{ marginLeft: "10px" }}>
                    {filterName.error}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Grid item xs={4}>
                <Typography variant="body1">Selected Cohorts</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                display="flex"
                flexDirection="column"
                marginLeft={1}
                sx={{
                  maxHeight: '100px',
                  overflowY: 'scroll',
                  scrollBehavior: 'smooth',
                  flexWrap: 'nowrap'
                }}
              >
                {cohorts?.length && cohorts.map((cohort, index) => (
                  cohort.show ? <Typography key={index} variant="body1">{cohort.cohort_name}</Typography> : null
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Grid item xs={4}>
                <Typography variant="body1">Selected Time Frame</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                display="flex"
                flexDirection="column"
                marginLeft={1}
              >
                {timeFrameLabels[time_frame]}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="end">
              <button
                className="submit-form-btn"
                onClick={() => handleClose()}
                style={{ width: "150px" }}
              >
                Cancel
              </button>
              <button
                disabled={isFetching}
                className="submit-form-btn"
                onClick={() => handleSaveFilter()}
                style={{ width: "150px" }}
              >
                Save
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 200 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="toaster">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleAlertClose}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert
            onClose={handleAlertClose}
            variant="outlined"
            severity={alertMessage?.severity}
          >
            <span>{alertMessage?.message}</span>
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default SaveComparison;
