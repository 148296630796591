import React, { useEffect } from "react";
import services from "../../Services/patientServices";

import "./DisparityAnalytics.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";

const DisparityAnalytics = () => {
  let accessToken = "";
  const env = window.config.environment;
  const { org_guid } = JSON.parse(localStorage.getItem("user")).user;
  const patientSet = localStorage.getItem("patient_set");
  const { selected_date = null, patient_set_id = null } = patientSet ? JSON.parse(patientSet) : {};

  // Is aka access available
  const enabled = JSON.parse(localStorage.getItem("aka_session_enabled"));

  // Replace 'yourAccessToken' with the actual access token
  if (enabled) {
    accessToken = localStorage.getItem("aka_id");
  } else {
    accessToken = localStorage.getItem("access_token");
  }

  const feature = "dash-disparity-analytics";
  const dash_base_url = window.config.dash_url;

  // Construct the URL with the access token as a query parameter
  const dashAppUrl = `${dash_base_url}?access_token=${accessToken}&feature_name=${feature}&env=${env}&cohort_id=${patient_set_id}&selected_date=${selected_date}&org_guid=${org_guid}`;
  // const dashAppUrl = `http://localhost:5000?access_token=${accessToken}&feature_name=${feature}&env=${env}&cohort_id=${patient_set_id}&selected_date=${selected_date}&org_guid=${org_guid}`;

  console.log("dash app", dashAppUrl)

  useEffect(() => {
    // Debounce function
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const receiveMessage = async (event) => {
      if (event.data && event.data.check_auth) {
        console.log("Received message from dash app:", event.data);

        try {
          // Hitting sidebar api to check access token validity
          await services.getSidebarFeatures();
          // Handle the response from the API
        } catch (error) {
          console.error("Error fetching sidebar features:", error);
          // Handle the error
        }
      }
    };

    const debouncedReceiveMessage = debounce(receiveMessage, 2000); // 2000ms debounce

    window.addEventListener("message", debouncedReceiveMessage);

    return () => {
      window.removeEventListener("message", debouncedReceiveMessage);
    };
  }, []);

  const content = (
    <div style={{ width: "100%", height: "100vh", overflowX: "hidden" }}>
      <iframe
        src={dashAppUrl}
        title="Disparity Analytics"
        className="analytics-container"
      ></iframe>
    </div>
  );

  // As HMSA dont have any cohorts available so we have to remove the sidebar for disparity dashboard
  return org_guid === "8608ec8d-0d22-4b79-a50f-99cd6c8f1a60" ? (
    content
  ) : (
    <Sidebar pageTitle="Disparity Dashboard">{content}</Sidebar>
  );
};
export default DisparityAnalytics;
