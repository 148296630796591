import React, { createContext, useEffect, useReducer, useState } from "react";

let reducer = (info, newInfo) => {
  return { ...info, ...newInfo };
};

const initialState = {
  currentui: "stats",
  prevui: "",
  pyramidvalue: 0,
  pyramidlevel: 0,
  pyramidRiskStatus: '',
  uititle: 'Dashboard',
  newTtitle: "",
  interventionName: '',
  key: '',
  abbr: '',
  time: '',
  showToast: false,
  toastMessage: "",
  open: false,
  riskFilter : []
};

const localState = JSON.parse(localStorage.getItem("pyramidcontent"));
const PyramidContext = createContext();

function PyramidProvider(props) {
  const [info, setInfo] = useReducer(reducer, localState || initialState);

  const [patientData, setPatientData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    localStorage.setItem("pyramidcontent", JSON.stringify(info));
  }, [info]);
  return (
    <PyramidContext.Provider
      value={{
        info,
        setInfo,
        patientData,
        setPatientData,
        filterData,
        setFilterData
      }}
    >
      {props.children}
    </PyramidContext.Provider>
  );
}

export { PyramidContext, PyramidProvider };
