import React, { useContext } from 'react'
import './ExistingSimulation.scss'
import SimulateTable from '../../Components/SimulateTable/SimulateTable'
import { useNavigate } from 'react-router-dom'
import { MetaDetailsContext } from '../../Contexts/metaDetailsContext'
import { useEffect } from 'react'
import { toCapitalize } from '../../Utils/stringOprations'
import Sidebar from '../../Components/Sidebar/Sidebar';
import { PatientSetContext } from '../../Contexts/patientSetContext'

export default function ExistingSimulation() {
  const history = useNavigate();
  const { setPageTitle } = useContext(MetaDetailsContext);
  const { patientSetInfo } = useContext(PatientSetContext);

  useEffect(() => {
    setPageTitle("FHS: Simulations");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (!patientSetInfo) {   
    history("/")
    return <></>
  } 

  return (
    <Sidebar pageTitle={`Simulations For This ${toCapitalize(patientSetInfo.cohort_name)}`}>
      <div className="emulationOuter">
        <div className="SimulateTableOuter widget-bg-shadow">
          <div className="simutableOuter">
            <SimulateTable />
          </div>
        </div>
      </div>
    </Sidebar>
  )
}




