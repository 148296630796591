import React, { useContext, useEffect } from "react";
import "./FileUpload.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";
import FileUploadWidget from "./FileUploadWidget";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";

const FileUpload = () => {
  const { setPageTitle } = useContext(MetaDetailsContext);
  
  useEffect(() => {
    setPageTitle("FHS: Data Upload");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sidebar>
      <div className="uploadpage DashboardOuter app-bg-color">
        <div className="">
          <div className="upload-outer">
            <div className="upload-inner widget-bg-shadow">
              <FileUploadWidget />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default FileUpload;
