import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  CssBaseline,
  Divider,
  Box,
  Toolbar,
  List,
  IconButton,
  MenuItem,
  Avatar,
  Menu,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Link,
  Badge,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import dashlogo from "../../../Assets/Images/dashboard-logo.png";

//Icons
import ContentAssessmentIcon from "../../../Assets/Images/sidebar-content.png";
import StatsIcon from "../../../Assets/Images/sidebar-overview.png";
import SummaryIcon from "../../../Assets/Images/sidebar-summary.png";
import ContributorsIcon from "../../../Assets/Images/sidebar-contributor.png";
import InterventionsIcon from "../../../Assets/Images/sidebar-intervention.png";
import PatientListIcon from "../../../Assets/Images/sidebar-patients.png";
import SimulationsIcon from "../../../Assets/Images/sidebar-simulation.png";
import FinanceForesightsIcon from "../../../Assets/Images/sidebar-dollar.png";
import GeographicIcon from "../../../Assets/Images/sidebar-geographic.png";
import NLPReviewIcon from "../../../Assets/Images/sidebar-nlp.png";
import HelpIcon from "../../../Assets/Images/sidebar-help.png";
import ReportsIcon from "../../../Assets/Images/sidebar-report.png";
import ComparativeAnalyticsIcon from "../../../Assets/Images/sidebar-comparison.png";
import ProviderProfilingIcon from "../../../Assets/Images/sidebar-providers.png";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UploadIcon from "../../../Assets/Images/sidebar-upload.png";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import BetaIcon from "../../../Assets/Images/beta-icon.png";
import PremiumIcon from "../../../Assets/Images/sidebar-premium.png";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DisparityDashboardIcon from "../../../Assets/Images/disparityDashboardIcon.png"

import { sidebarContext } from "../../../Contexts/sidebarContext";
import { toCapitalize } from "../../../Utils/stringOprations";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import { useLocation, useNavigate } from "react-router-dom";
import { PyramidContext } from "../../../Contexts/uiContext";
import { red } from "@mui/material/colors";
import { AuthContext } from "../../../Contexts/authContext";
import { AnalyticsContext } from "../../../Contexts/analyticsContext";
import { DropdownFilterContext } from "../../../Contexts/dropdownContext";
import { makeStyles } from "@mui/styles";
import "../_Dashboard.scss";
// import DateSelect from "../../../Components/DateSelect/DateSelect";
import { PatientsNumberContext } from "../../../Contexts/totalPatientsContext";
// import mergeLocalStorage from "../../../Utils/mergeLocalStorage";
import withRouter from "../../../Utils/withRouterHOC";

const DrawerFooter = styled("div")(({ open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginBottom: "5px",
  ...(open && {
    justifyContent: "flex-end",
  }),
}));

const icon = {
  DisparityDashboardIcon,
  SummaryIcon,
  StatsIcon,
  ContributorsIcon,
  InterventionsIcon,
  HelpIcon,
  PatientListIcon,
  SimulationsIcon,
  ReportsIcon,
  GeographicIcon,
  ComparativeAnalyticsIcon,
  ProviderProfilingIcon,
  NLPReviewIcon,
  ContentAssessmentIcon,
  FinanceForesightsIcon,
  UploadIcon,
};

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    fill: "#fff !important",
    fontSize: "13px !important",
  },
}));

const AppBar = (props) => {
  const classes = useStyles();
  const aka_session_enabled = localStorage.getItem("aka_session_enabled");
  const { logout, state, authLoading } = useContext(AuthContext);
  const { event } = useContext(AnalyticsContext);
  const { patientSetInfo } = useContext(PatientSetContext);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [expand, setExpand] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("persistent");
  const { navData } = useContext(sidebarContext);
  const [selectedRoute, setSelectedRoute] = useState(0);
  const location = useLocation();
  const { info, setInfo } = useContext(PyramidContext);
  const [initials, setInitials] = useState("");
  const [username, setUsername] = useState("");
  const [adminUser, setAdminUser] = useState("");
  const [uuid, setUuid] = useState("");
  const [orgName, setOrgName] = useState("");
  const { state: filterState } = useContext(DropdownFilterContext);
  const [time, setTime] = useState("");
  const { selectableDates: statsDates } = useContext(PatientsNumberContext);
  const [selectableDates, setSelectedDates] = useState([]);
  const history = useNavigate();
  console.log(time,selectableDates)

  useEffect(() => {
    if (state && state.user) {
      const init = userDetails(state.user);
      setInitials(init);
    }
    if (patientSetInfo && !authLoading) {
      setTime(patientSetInfo.selected_date);
      setSelectedDates(
        !patientSetInfo.selectable_dates ? statsDates : patientSetInfo.selectable_dates
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSetInfo, filterState, authLoading]);

  const userDetails = (user) => {
    const first_name = user.first_name.toUpperCase();
    const last_name = user.last_name.toUpperCase();
    const username = toTitleCase(first_name) + " " + toTitleCase(last_name);
    const uuid = user.user_id;
    const orgName = user.org_name ? user.org_name : "None";
    const admin = JSON.parse(localStorage.getItem("backup_user"));
    setUuid(uuid);
    setUsername(username);
    if (admin) {
      setAdminUser(
        toTitleCase(admin.first_name) + " " + toTitleCase(admin.last_name)
      );
    }
    setOrgName(orgName);
    return first_name[0] + last_name[0];
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    const { route } =
      navData.find((item) => item.route === location.pathname) || {};
    setSelectedRoute(route);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navData]);

  const handleDrawerOpen = (open) => {
    props.setOpen(!open);
    if (!open) {
      setExpand(false);
    }
  };

  const handleDrawerExpand = () => {
    const showAll = !expand;
    if (showAll) {
      setDrawerVariant("temporary");
      props.setDrawerWidth(240);
    } else {
      setDrawerVariant("persistent");
      props.setDrawerWidth(60);
    }
    setExpand(showAll);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleDateChange = (value) => {
  //   setTime(value);
  //   mergeLocalStorage("patient_set", {
  //     selected_date: value,
  //   });
  //   window.location.reload();
  // };

  const coutumizedRoute = (data) => {
    switch (data.featureName) {
      case "stats":
        return `${toCapitalize(patientSetInfo?.cohort_name)} Overview`;
      case "patient-list":
        return `${toCapitalize(patientSetInfo?.patient_name)} List`;
      default:
        return data.title;
    }
  };

  const handleMenuClick = ({ route, pageName }) => {
    let currentLocation = `${location.pathname}`.slice(1);

    if (route !== "/help") {
      const infoData = {
        ...info,
        currentui: pageName,
        prevui: currentLocation,
        pyramidRiskStatus: "none",
      };

      setInfo(infoData);
      history(route);
    } else {
      window.location.href = "mailto:app-support@foresighthealthsolutions.com";
    }
  };

  const exitAkaSubmit = () => {
    //Remove aka id and aka session enabled params from local storage
    localStorage.removeItem("aka_id");
    localStorage.removeItem("aka_session_enabled");
    localStorage.removeItem("aka_alert_open");
    localStorage.removeItem("aka_session_id");
    // Removing last patient set used in aka session
    localStorage.removeItem("patient_set");
    localStorage.removeItem('cohort_list_tabs');
    //Get the backup user (admin) and replace with the current aka user
    const backup_user = JSON.parse(localStorage.getItem("backup_user"));
    localStorage.setItem(
      "user",
      JSON.stringify({
        isLoggedIn: true,
        user: backup_user,
      })
    );
    localStorage.removeItem("backup_user");
    window.location.href = "/";
    setTimeout(() => {
      //Reload the page to load the changes in effect
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <CssBaseline />
      <MuiAppBar position="fixed" sx={{ zIndex: "1202" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleDrawerOpen(props.open)}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <img src={dashlogo} className="dash-logo pl-3" alt="logo" />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {/* <DateSelect
              time={time}
              selectableDates={selectableDates}
              handleDateChange={handleDateChange}
            /> */}
            <IconButton sx={{ pr: 3 }}>
              <SettingsRoundedIcon />
            </IconButton>
            <Link to="#" className="profileName">
              <div>
                {aka_session_enabled ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <Tooltip title="Exit AKA" arrow>
                        <SmallAvatar
                          sx={{ bgcolor: red[700] }}
                          onClick={exitAkaSubmit}
                        >
                          <CloseIcon className={classes.closeIcon} />
                        </SmallAvatar>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      onClick={(e) => handleOpenUserMenu(e)}
                      style={{
                        backgroundColor: "#0082b2",
                        padding: "24px",
                      }}
                    >
                      AKA
                    </Avatar>
                  </Badge>
                ) : (
                  <Avatar
                    onClick={(e) => handleOpenUserMenu(e)}
                    style={{ backgroundColor: "#0082b2" }}
                  >
                    {initials}
                  </Avatar>
                )}

                <Menu
                  className="header__menu"
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  disableScrollLock={true}
                >
                  {aka_session_enabled ? (
                    <>
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="menu__uuid">
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              textTransform: "uppercase",
                            }}
                          >
                            Logged-in User
                          </span>
                          <span>{adminUser}</span>
                        </div>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="menu__uuid">
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              textTransform: "uppercase",
                            }}
                          >
                            Viewing UI as User
                          </span>
                          <span>{username}</span>
                        </div>
                      </MenuItem>{" "}
                    </>
                  ) : (
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {username}
                    </MenuItem>
                  )}
                  <Divider></Divider>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="menu__uuid">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        UUID
                      </span>
                      <span>{uuid}</span>
                    </div>
                  </MenuItem>
                  <Divider></Divider>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="menu__uuid">
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        ORGANIZATION NAME
                      </span>
                      <span>{orgName}</span>
                    </div>
                  </MenuItem>
                  <Divider></Divider>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      logout();
                      event("logout_manual", {});
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Link>
            {/* <Avatar sx={{ bgcolor: "#000" }}>OP</Avatar>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <ArrowDropDownRounded sx={{ height: 32, width: 32 }} />
            </IconButton>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Drawer
        sx={{
          width: props.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: props.drawerWidth,
            boxSizing: "border-box",
            marginTop: "64px",
          },
        }}
        variant={drawerVariant}
        anchor="left"
        open={props.open}
      >
        <Divider />
        <List sx={{ height: "100vh", backgroundColor: "#fff" }}>
          {navData.map((item, index) => (
            <Tooltip key={index} title={item.title} placement="right" arrow>
              <ListItem
                // button
                className={`MuiListItem-button ${
                  item.route === "#" ? "disabled-image" : null
                }`}
                onClick={() => (patientSetInfo ? handleMenuClick(item) : "")}
                sx={{ cursor: patientSetInfo ? "pointer" : "not-allowed" }}
                disabled={patientSetInfo ? false : true}
                selected={selectedRoute === item.route}
              >
                <ListItemIcon>
                  {
                    <span style={{ position: "relative" }}>
                      <img
                        src={icon[item.icon]}
                        className={item.className}
                        alt={item.title}
                      />
                      {item.state === "premium" ? (
                        <img
                          src={PremiumIcon}
                          className="premiumIcon"
                          alt={"Premium Icon"}
                        />
                      ) : item.state === "locked" ? (
                        <LockOutlinedIcon className="lockIcon" />
                      ) : item.state === "beta" ? (
                        <img
                          src={BetaIcon}
                          className="betaIcon"
                          alt="beta-icon"
                        />
                      ) : null}
                    </span>
                  }
                </ListItemIcon>
                {expand && (
                  <ListItemText
                    primary={coutumizedRoute(item)}
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: "wrap",
                        overflow: "hidden",
                      },
                    }}
                  />
                )}
              </ListItem>
            </Tooltip>
          ))}
        </List>

        <DrawerFooter
          open={expand}
          className={`${expand ? "openDrawer" : "closeDrawer"}`}
        >
          {expand ? (
            <IconButton
              onClick={() => handleDrawerExpand(props.open)}
              sx={{ height: "unset" }}
            >
              <ArrowBackIosNewIcon className="expand-collapse-icon" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleDrawerExpand(props.open)}
              sx={{ height: "unset" }}
            >
              <ArrowForwardIosIcon className="expand-collapse-icon" />
            </IconButton>
          )}
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export default withRouter(AppBar);
