import { useEffect, useState } from "react";
import { Badge, Box, IconButton, Popover, Typography, Button, Skeleton } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { notification_type, notification_type_tab_value } from "../../constants";
import wcchcServices from "../../Services/wcchcServices";
import dayjs from "dayjs";
import ViewAllAlertsModal from "./ViewAllAlertsModal";

const NotificationPopOver = ({ notificationsData = [], identifier_id, org_id, refreshAlerts, isFetchingAlert, setValue, setSelectedNotification }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSmartAlertClick = (type, isIp) => {
    setValue(isIp === true ? notification_type_tab_value['IP_VISIT'] : isIp === false ? notification_type_tab_value['ER_VISIT'] : notification_type_tab_value[type]);
    setAnchorEl(null);
    setOpenModal(false);
  }

  const markAsReadNotifications = async (noteId, notification) => {    
    if (noteId !== 'ALL') {
      handleSmartAlertClick(notification.type, notification.is_ip)
      setSelectedNotification({...notification, newType: notification.is_ip === true ? "IP_VISIT" : notification.is_ip === false ? "ER_VISIT" : notification.type });
    }
    if (noteId === 'ALL' || !notification.isViewed) {
      setIsFetching(true);
      const alertNotifications = await wcchcServices.markAsViewedNotifications(identifier_id, org_id, {noteId, viewAll: noteId === 'ALL', patient_id: notification?.patient_id || ''});
      await refreshAlerts(identifier_id, org_id)
      alertNotifications.data.status && !isFetchingAlert && setIsFetching(false);
    }    
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const groupByType = (notifications) => {
    return notifications.reduce((acc, notification) => {
      const { type } = notification;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(notification);
      return acc;
    }, {});
  };

  const parseToBold = (text) => {
    const parts = text.split(/(\*[^*]+\*)/g); // Split by *...*
    return parts.map((part, index) => {
      if (part.startsWith('*') && part.endsWith('*')) {
        return <b key={index}>{part.slice(1, -1)}</b>; // Remove * and wrap in <b>
      } else {
        return part; // Return the unformatted text
      }
    });
  };

  useEffect(() => { 
    if (notificationsData.length) {
      setNotifications(groupByType(notificationsData));
    }    
  }, [notificationsData]);

  const notificationTypesOrder = Object.keys(notification_type);
  
  return (
    <Box className="notification-container">
      <IconButton onClick={handleClick}>
        <Badge badgeContent={notificationsData.length}>
          <NotificationsOutlinedIcon
            className="notification-icon"
            sx={{ fill: "#24292E !important", fontSize: 28 }}
          />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={{ maxWidth: 440 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock
      >
        <Box className="notification-box" sx={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
          <Box sx={{ borderBottom: "1px solid #D9D9D9" }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "16px !important",
                color: "#000000 !important",
                padding: "10px", display: "flex", alignItems: "center"
              }}
            >
              <NotificationsOutlinedIcon
                className="notification-icon"
                fontSize="small"
                sx={{ fill: "#24292E !important", marginRight: "2px" }}
              />
              Notifications ({notificationsData.length})
            </Typography>
          </Box>
          <Box className="notification-body" sx={{ overflowY: 'auto', flex: 1 }}>
            {isFetching ? (
              <Box className="">
                <div className="filter-loader-container">
              {[...Array(5)].map((_, index) => (
                <Skeleton key={index} height={50} sx={{ width: "80%" }} />
              ))}
            </div>
              </Box>
            ) : notificationsData.length ? (
              notificationTypesOrder.map((type) => {
                if (!notifications[type]) return null;
                return (
                  <Box
                    key={type}
                    sx={{ 
                      borderBottom: Object.keys(notifications).length === (notificationTypesOrder.indexOf(type) + 1) ? "none" : "1px solid #D9D9D9", 
                      paddingTop: "5px !important", 
                      paddingBottom: "5px !important"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "13px",
                        fontWeight: 700,
                        paddingTop: "5px !important"
                      }}  
                    >{notification_type[type]} ({notifications[type].length})</Typography>
                    {notifications[type].map((notification, index) => {
                      const dateTime = dayjs(notification.note_date, 'YYYY-MM-DD HH:mm:ss');
                      const formattedDate = dateTime.format('MM/DD/YYYY');
                      return (
                        <Box
                          className="notification"
                          key={notification.note_id}
                          sx={{
                            backgroundColor: !notification.isViewed ? "#DBF5FF" : "#fff",
                            borderBottom: (notifications[type].length - 1 === index) ? "none" : "1px solid #D9D9D9"
                          }}
                          onClick={() => markAsReadNotifications(notification.note_id, notification)}
                        >
                          <Box className="notification-content">
                            <Typography className="text main">
                              {parseToBold(notification.note_text)}
                              &nbsp; Click to view <span onClick={() => handleSmartAlertClick(type, notification.is_ip)} className="text smart_alert">Smart Alerts</span> 
                            </Typography>
                            <Typography className="text date">
                              <div className="date_time" >
                                <CalendarMonthOutlinedIcon fontSize="small" sx={{ width: "16px", marginRight: "5px" }} />
                                {formattedDate}                               
                              </div>
                              {notification.priority && <Typography className="text priority" >{notification_type[notification.priority]}</Typography>}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}                  
                  </Box>
                );
              })
            ) : (
              <Box className="notification">
                <Typography className="text main">No Notifications</Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ padding: "15px !important", borderTop: "1px solid #D9D9D9", display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" className="clear-all-button" disabled={isFetching || notificationsData.filter((n) => !n.isViewed).length === 0} onClick={() => markAsReadNotifications('ALL')}>Clear All</Button>
            <Button variant="contained" className="view-all-button" onClick={() => {setOpenModal(true); setAnchorEl(null)}}>View All</Button>
          </Box>
        </Box>
      </Popover>

      {openModal && (
        <ViewAllAlertsModal 
          open={openModal} 
          handleClose={() => setOpenModal(false)}
          notifications={notifications}
          notificationsData={notificationsData}
          parseToBold={parseToBold}
          markAsReadNotifications={markAsReadNotifications}
          handleSmartAlertClick={handleSmartAlertClick}
        />
      )}
    </Box>
  );
};

export default NotificationPopOver;
