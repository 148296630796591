import axios from "axios";

const apiClientGenesis = (runtime = "node", iframeStatus = false) => {
  runtime = window.config.environment === "local" ? "" : runtime;
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };

  const enabled = JSON.parse(localStorage.getItem("aka_session_enabled"));
  const aka_id = localStorage.getItem("aka_id");
  const aka_session_id = localStorage.getItem("aka_session_id");
  if(enabled) {
    headers['aka-id'] = aka_id;
    headers['aka-session-id'] = aka_session_id;
  };

  const instance = axios.create({
    baseURL: window.config.apiUrl + "/" + runtime,
    responseType: "json",
    headers,
    withCredentials: true
  });

  // Function to send token expiration message to First path app
  const postMessageToParent = (message) => {
    window.parent.postMessage(message, '*');
  };

  // Use interceptor to catch api errors globally
  instance.interceptors.response.use((response) => response, (error) => {
    console.log('Api error catched by axios interceptor');
    // No need to inform the user if access token is expired,
    // silent refresh will be attempted bts
    if(error.response.status !== 401 && error.response.status !== 400) {
      console.log(error.response.data.message);

      // send a request to parent to get the new access token
      postMessageToParent({
        type: 'tokenExpiration',
        message: 'Access token expired, please provide a new access token.'
      });
    }
    throw error;
  });

  // Obtain the fresh token each time the function is called
  const getAccessToken = () => {
    return localStorage.getItem('access_token');
  }

  // Use interceptor to inject the token to stalled requests
  instance.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return request;
  });

  return instance;
};

export default apiClientGenesis;