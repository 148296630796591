import React, { useState, useContext, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ChartMenu from "./ChartMenu";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Card,
  CardContent,
  ClickAwayListener,
  Fade,
  Popper,
  CardHeader,
  IconButton,
} from "@mui/material";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import FilterDropdown from "../FiltersDropdown/FilterDropdown";

const FilterMenu = ({
  entity,
  keyName,
  sortDataFunc,
  enableSorting,
  statsAvailable,
  custAttrVal,
}) => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(DropdownFilterContext);
  let minValue = custAttrVal && parseInt(custAttrVal[0]?.min);
  let maxValue = custAttrVal && parseInt(custAttrVal[0]?.max);
  const [value, setValue] = useState([minValue, maxValue]);

  useEffect(() => {
    setValue([minValue, maxValue]);
  }, [minValue, maxValue, setValue]);

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl2(null);
    dispatch({ type: "reset-from-to" });
  };

  // Function to toggle & update filter options as selected from checkboxes
  const handleFilterChange = (
    item,
    checkboxes = state.identifiers_checkbox.options_checkbox
  ) => {
    const { name, selected } = item;

    let selected_checkboxes;

    // If 'Indeterminate' state i.e 2, then skip adding data to the existing selected checkboxes array;
    if (selected === 2) {
      selected_checkboxes = [...state.identifiers_checkbox.selected_checkboxes];
    } else {
      const selectedFilters =
        state.identifiers_checkbox.selected_checkboxes.find(
          (obj) => obj === name
        );

      selected_checkboxes = selectedFilters
        ? state.identifiers_checkbox.selected_checkboxes.filter(
            (obj) => obj !== name
          )
        : [...state.identifiers_checkbox.selected_checkboxes, name];
    }

    const toggled_checkboxes = {
      selected_checkboxes,
      options_checkbox: [
        ...checkboxes.map((filter) => {
          return {
            ...filter,
            options: filter.options.map((option) => {
              return name === option.name
                ? {
                    ...option,
                    selected,
                  }
                : option;
            }),
          };
        }),
      ],
    };

    dispatch({
      type: "update-checkboxes",
      payload: {
        checkboxSelected: true,
        selected_checkboxes: toggled_checkboxes.selected_checkboxes,
        options_checkbox: toggled_checkboxes.options_checkbox,
      },
    });

    if (state.identifiers_dropdown.qf) {
      dispatch({
        type: "reset-state",
        payload: { id: state.identifiers_dropdown.key },
      });
    }

    const selectedFiltersCheckbox = toggled_checkboxes.options_checkbox
      .flatMap((m) => m.options)
      .filter((n) => n.selected === 1 || n.selected === 2)
      .map((o) => {
        return {
          key: o.key,
          dropdownSelected: false,
          checkboxSelected: o.selected === 1 || o.selected === 2 ? true : false,
          queried: o.selected === 2 ? false : o.selected === 1 ? true : null,
          apply: false,
          qf: false,
          individual: o.value?.toString(),
          selected: o.selected,
          to: "",
          from: "",
          fromToMode: false,
        };
      });

    dispatch({
      type: "set-filters-checkbox",
      payload: selectedFiltersCheckbox,
    });

    // change 'checkboxTriggered' to false once the checkbox has been selected
    dispatch({ type: "reverse-checkbox", payload: false });
    window.scrollTo(0, 0);

    return {
      filterCheckboxes: selectedFiltersCheckbox,
      filterOptions: toggled_checkboxes.options_checkbox,
    };
  };

  return (
    <div className="filter-three-dots">
      <div className="menu-flex-start">
        <span className="table-column-title">{entity}</span>
        <span className="table-column-icons">
          {!enableSorting || keyName === 'rec' ? null : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowDropUpIcon
                className="three-dots-icon"
                style={{ marginBottom: "-10px", height: "23px", width: "23px" }}
                onClick={() => sortDataFunc(entity, keyName, "ascending")}
              />
              <ArrowDropDownIcon
                className="three-dots-icon"
                style={{ height: "23px", width: "23px" }}
                onClick={() => sortDataFunc(entity, keyName, "descending")}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {keyName === "pn" || keyName === "readmission" || keyName === "rec" || keyName.startsWith("cust_") ? null : (
              <FilterAltIcon className="three-dots-icon" onClick={handleOpen} />
            )}

            <Popper
              sx={{ zIndex: 1202, width: "25%" }}
              open={open}
              anchorEl={anchorEl2}
              placement="bottom-start"
              transition
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClose}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Card>
                      <CardHeader
                        title={`Filter by ${entity}`}
                        disableTypography
                        sx={{ color: "#47a3c7", fontWeight: 500 }}
                        action={
                          <IconButton aria-label="cancel" onClick={handleClose}>
                            <CancelOutlinedIcon color="primary" />
                          </IconButton>
                        }
                      ></CardHeader>
                      <CardContent sx={{ "&:last-child": { pb: 2 }, pt: 0 }}>
                        <FilterDropdown
                          handleCheckboxChange={handleFilterChange}
                          minValue={minValue}
                          maxValue={maxValue}
                          value={value}
                          setValue={setValue}
                          keyName={keyName}
                          title={entity}
                          handleClose={handleClose}
                        />
                      </CardContent>
                    </Card>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>

            {statsAvailable ? (
              <>
                <AssessmentIcon
                  className="chart-icon"
                  onClick={(e) => setAnchorEl1(e.currentTarget)}
                />
                {anchorEl1 ? (
                  <ChartMenu
                    keyName={keyName}
                    anchorEl={anchorEl1}
                    setAnchorEl={setAnchorEl1}
                    entity={entity}
                  />
                ) : null}
              </>
            ) : null}
          </div>
        </span>
      </div>
    </div>
  );
};

export default FilterMenu;
