import React, { useEffect } from 'react';
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
    mainBox: {
      maxWidth: "450px",
      margin: "5px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
    },
    listContainer: {
      backgroundColor: "#E7E7E7",
      width: "100%",
      maxWidth: "440px",
      borderRadius: "5px",
      padding: "5px 10px !important",
    },
    selectedListContainer: {
      backgroundColor: "#E3F5FF !important",
    },
    triangle: {
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "20px solid transparent",
      transform: "rotate(90deg)",
    },
    selectedTriangle: {
      borderBottom: "20px solid #E3F5FF !important",
    },
    detailsContainer: {},
    descriptionText: {
      fontFamily: "Lato",
      fontWeight: 500,
      fontSize: "14px !important",
      lineHeight: "21px",
    },
    sourceContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sourceFont: {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "12px !important",
      lineHeight: "20px",
    },
    sourceDate: {
      color: "#797979",
    },
    sourceName: {
      color: "#0066CB",
    },
    recommendedContainer:{
      marginTop: "10px",
      marginRight: "10px"
    },
    recommendedDetailsTitle:{
        padding: '7px 10px'
    },
    recommendedDetailsInnerContainer1:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: "#F0F0F0",
    },
    recommendedDetailsInnerContainer2:{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 10px !important'
    },
    toggleButton: {
      border: "0px !important",
      margin: "5px",
      borderRadius: "5px !important",
    },
    upTrue: {
      backgroundColor: "rgb(104, 235, 141) !important",
    },
    upFalse: {
      backgroundColor: "rgba(104, 235, 141, 0.3) !important",
    },
    downTrue: {
      backgroundColor: "rgb(235, 104, 104) !important",
    },
    downFalse: {
      backgroundColor: "rgba(235, 104, 104, 0.3) !important",
    },
    gridContainer:{
      height: "260px",
      overflow: "auto",
      marginTop: "0"
    },
    skeletonContainer:{
      padding: "0 2% !important"
    }
  }));

  const RecommendedList = ({
    id,
    title,
    date,
    index,
    selectedIndex,
    handleClick,
  }) => {
    const classes = useStyles();
  
    return (
      <Box className={classes.mainBox} onClick={() => handleClick(index)}>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedListContainer : ""
          } ${classes.listContainer}`}
        >
          <Box className={classes.detailsContainer}>
            <Typography className={classes.descriptionText}>
              {title}
            </Typography>
          </Box>
          <Box className={classes.sourceContainer}>
            <Typography className={`${classes.sourceFont} ${classes.sourceDate}`}>
              {date}
            </Typography>
          </Box>
        </Box>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedTriangle : ""
          } ${classes.triangle}`}
        ></Box>
      </Box>
    );
  };

const RecommendedDetails = ({index,title, providedDate}) =>{
  const classes = useStyles();
  const date = providedDate ? dayjs(providedDate).format('MM/DD/YYYY') : '';
   return(
    <Box className={classes.recommendedContainer}>
      <Box className={classes.recommendedDetailsInnerContainer1}>
        <Typography className={classes.recommendedDetailsTitle}>Patient needs {title}</Typography>
      </Box>
      <Box className={classes.recommendedDetailsInnerContainer2}>
          <Typography variant='caption' key={index}>Last Provided Date: <b>{date}</b></Typography>
      </Box>
    </Box>
   )
}
  
const RecommendServices = ({selectedNotification}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [allAlerts, setAllAlerts] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const { state } = useContext(WcchcPatientSummaryContext);

  const handleClick = (index, alert) => {
    setSelectedIndex(index);
    setSelectedItem(alert);
  };

  useEffect(() => {
    if (state.allSmartAlerts.length) {
      const recommended_data = state.allSmartAlerts.filter(item => item.type === "RECOMMENDED_SERVICE");

      setAllAlerts(recommended_data);
      setSelectedItem((Object.keys(selectedNotification).length ? selectedNotification : null) || recommended_data[0] || {});
      
      selectedNotification?.recommended_data?.label && setSelectedIndex(recommended_data.findIndex((dk) => dk?.recommended_data.label === selectedNotification?.recommended_data?.label))
    }
  }, [state.allSmartAlerts, selectedNotification]);  
    
    return(
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.gridContainer}
      >
        {
          state.isFetchingSmartAlerts ? (
            <Grid item xs={12}>
              {new Array(5).fill(0).map((_, index) => (
                <Box className={classes.skeletonContainer}>
                  <Skeleton key={index} height={50} sx={{ width: "100%" }} />
                </Box>
              ))}
            </Grid>
          ) :
            allAlerts.length === 0 ? (
              <>
                <Grid item xs={4}>
                  <Box className={classes.mainBox}>
                    <Typography className={classes.descriptionText}>
                      No Recommended Services
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={8}></Grid>
              </>
            ) :
              <>
                <Grid item xs={4}>
                  {allAlerts.map((alert, index) => {
                    const title = alert.recommended_data.label;
                    return (
                      <RecommendedList
                        key={index}
                        index={index}
                        selectedIndex={selectedIndex}
                        handleClick={(idx) => handleClick(idx, alert)}
                        title={title}
                        date={state.lastUpdatedDate}
                      />
                    );
                  })}
                </Grid>
                <Grid item xs={8}>
                  <RecommendedDetails
                    title={selectedItem?.recommended_data?.label}
                    providedDate={selectedItem?.recommended_data?.last_provided_date}
                  />
                </Grid>
              </>
        }
      </Grid>
    )
};

export default RecommendServices