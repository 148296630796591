import React, { useState, useEffect } from "react";
import "./CollapsibleTable.scss"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CollapsibleTableRow from "./CollapsibleTableRow";
import { makeStyles } from "@mui/styles";
import formatHeading from "../../../Utils/formatHeading";

const useStyles = makeStyles({
    stickyFirst: {
        position: "sticky",
        left: 0,
        background: "white",
        minWidth: "5px",
        width: "50px",
    },
    stickySecond: {
        position: "sticky",
        left: 50,
        background: "white",
    },
});

const CollapsibleTable = (props) => {
    const classes = useStyles();
    const rows = props.tableData;
    const { data } = props
    const { cohortId, startDate, endDate } = data;
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        if (rows?.length) {
            const headers = Object.keys(rows[0]);
            setHeaders(headers);
        }
    }, [rows])

    return (
        <div className="CollapsibleTable">
            <section className="section__container">
                <div className="tableWrap">
                    {rows?.length ?
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.stickyFirst} />
                                        {
                                            headers.map((heading, index) => (
                                                heading !== "patient_coordinator"
                                                    ? <TableCell className={`tableHeading ${index === 1 ? classes.stickySecond : ""}`} key={index} >{formatHeading(heading)}</TableCell>
                                                    : null
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <CollapsibleTableRow key={index} row={row} headers={headers} classes={classes} cohortId={cohortId} startDate={startDate} endDate={endDate} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : <div className="no-result">No Data Available!</div>}
                </div>
            </section>

        </div>
    );
};
export default CollapsibleTable;