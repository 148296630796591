import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  Box,
  Button,
  Skeleton,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import WarningIcon from "@mui/icons-material/Warning";
import SortButton from "../../WcchcPatientSummary/SortButton";

import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";
import formatHeading from "../../../Utils/formatHeading";
import NoDataMessage from "../../WcchcPatientSummary/NoDataMessage";
import { sortData } from "../../../Utils/wcchcPatientSummary";

const useStyles = makeStyles((theme) => ({
  tableMainContainer: {
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
  },
  tableContainer: {
    boxShadow: "none",
    maxHeight: "270px",
  },
  tableContainerModal: {
    boxShadow: "none",
    maxHeight: "calc(100vh - 165px)",
  },
  table: {
    paddingLeft: "20px !important",
    "& > thead > tr > th:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "left !important"
    },
    "& > thead > tr > th:nth-child(2)": {
      paddingLeft: "30px",
      textAlign: "left"
    },
    "& > tbody > tr > td:nth-child(1)": {
      paddingLeft: "0px !important",
      textAlign: "left",
    },
    "& > tbody > tr > td:nth-child(2)": {
      paddingLeft: "30px",
    },
    "& > tbody > tr:nth-child(even)": {
      backgroundColor: "#f3f3f3",
    },
    "& > thead > tr > th:last-child": {
      textAlign: "left",
    },
    "& > tbody > tr > td:last-child": {
      textAlign: "left",
    },
  },
  tableRow: {
    "& > td:nth-child(1)": {
      fontWeight: 600,
    },
  },
  tableHeaderCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#7D7B7B",
    padding: "10px",
    textAlign: "center",
  },
  tableHeaderCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#060606",
    padding: "8px 6px",
    borderBottom: "0.5px solid #000000 !important",
    textAlign: "center",
  },
  tableDataCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "10px 10px",
    borderBottom: "none",
    textAlign: "center",
  },
  tableDataCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "10px 10px",
    textAlign: "center",
  },
  WarningIcon: {
    width: "0.6em",
    height: "0.6em",
    // fontSize: "1rem",
    fill: "#FD0808 !important",
    margin: "0px 0px 1px 5px",
  },
  tooltip: {
    fontFamily: "Lato",
    fontSize: "12px",
    color: "#000",
  },
  skeletonDataCell1: {
    width: "40%",
    borderBottom: "none",
    padding: "6px !important",
  },
  skeletonDataCell2: {
    borderBottom: "none",
    padding: "6px !important",
  },
  tableEmptyCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "10px 10px",
    borderBottom: "none",
    textAlign: "center",
  },
  footerBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px !important",
  },
  footerButton: {
    marginRight: "10px",
    textTransform: "none",
    backgroundColor: "#0080B2",
    color: "#fff",
    padding: "20px 30px",
    fontFamily: "Lato",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#0080B2",
    },
    "&:disabled": {
      color: "#fff",
      backgroundColor: "#0080B2",
      opacity: 0.5,
    },
  },
  label:{
    fontWeight: "700",
    fontFamily: "Lato"
  }
}));

const columns = [
  {label: "Name", sort: false, name: "name"},
  {label: "Status", sort: false, name: "status"},
  {label: "Start Date", sort: true, name: "startDate"},
  {label: "End Date", sort: true, name: "expiryDate"},
  {label: "Prescription", sort: false, name: "prescription"},
];

const MedicationsTable = ({ modal = false, setAttentive }) => {
  const { state, dispatch } = useContext(WcchcPatientSummaryContext);
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [priorData, setPriorData] = useState(false);
  const [currentSorting, setCurrentSorting] = useState({});
  const [sortConfig, setSortConfig] = useState([
    { key: 'startDate', direction: 'desc', active: false },
    { key: 'expiryDate', direction: 'desc', active: false },
  ]);

  const sortHandler = (key, sortType) => {
    setSortConfig((prevConfig) => {
      const existingConfig = prevConfig.find((config) => config.key === key);
      if (existingConfig) {
        const newDirection = sortType;
        return prevConfig.map((config) =>
          config.key === key ? { ...config, direction: newDirection } : config
        );
      } else {
        return [...prevConfig, { key, direction: sortType }];
      }
    });
    setCurrentSorting({key,sortType, direction: sortType})
  };

  const sortTableData = () => {
    let sortedData = [...finalData];
    const {direction,key} = currentSorting;
    let data = sortData(sortedData, key, direction);
    setData([...data]);
  };

  useEffect(() => { 
    let newData = [];
    if (state.medications) {
      if (state.medications.activeItems && state.medications.activeItems.length) {
        newData = [...state.medications.activeItems]
      }
      if (state.medications.inactiveItems && state.medications.inactiveItems.length) {
        newData = [...newData, ...state.medications.inactiveItems]
      }
    }
    if (priorData) {
      if (state.medications.priorActiveItems && state.medications.priorActiveItems.length) {
        newData = [...newData, ...state.medications.priorActiveItems]
      }
      if (state.medications.priorInactiveItems && state.medications.priorInactiveItems.length) {
        newData = [...newData, ...state.medications.priorInactiveItems]
      }
    }
    let data = JSON.parse(JSON.stringify(newData));
    // let data = []
    dispatch({ type: "UPDATE_TABLE_LENGTH", payload: {medications: data.length} });
    setData(data);
    setFinalData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.medications), priorData]);

  useEffect(() =>{
    if(Object.keys(currentSorting)?.length){
        sortTableData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sortConfig])

  if (state.isFetchingMedications) {
    return (
      <Paper className={classes.tableMainContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                {new Array(5).fill(0).map((_, i) => (
                  <TableCell key={i} className={classes.tableHeaderCell}>
                    <Skeleton variant="rounded" width="100%" height={20} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {new Array(10).fill(0).map((_, index) => (
                <TableRow key={index}>
                  {new Array(5).fill(0).map((_, i) => (
                    <TableCell
                      key={i}
                      className={
                        i
                          ? classes.skeletonDataCell2
                          : classes.skeletonDataCell1
                      }
                    >
                      <Skeleton variant="rounded" width="100%" height={20} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Paper className={classes.tableMainContainer}>
      <TableContainer
        className={modal ? classes.tableContainerModal : classes.tableContainer}
      >
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(({label, sort, name}, index) => (
                <TableCell
                  key={index}
                  className={
                    modal
                      ? classes.tableHeaderCellModal
                      : classes.tableHeaderCell
                  }
                >
                  {
                    sort ?
                      <Box sx={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                        <Typography className={classes.label}>{label}</Typography>
                        <SortButton onClick={sortHandler} name={name} sortConfig={sortConfig}/>
                      </Box>
                      :
                      label
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length ? (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={classes.tableRow}
              >
                <TableCell className={classes.tableEmptyCell} colSpan={5}>
                  <NoDataMessage 
                      message={"No data available"}
                  />   
                </TableCell>
              </TableRow>
            ) : (
              data.map((row, index) => {
                return (
                  <>                  
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={classes.tableRow}
                  >
                    {Object.keys(data[0]).map((column, i) => {
                      const value = row[column];
                      return (
                        <TableCell
                          key={i}
                          className={
                            modal
                              ? classes.tableDataCellModal
                              : classes.tableDataCell
                          }
                        >
                          <span>
                            {formatHeading(value)}
                          </span>
                          {value === "Choose seven method speech." &&
                            setAttentive(true)}
                          {value === "Choose seven method speech." ? (
                            <Tooltip
                              title="Non-Compliant"
                              arrow
                              placement="top"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: "#000",
                                    bgcolor: "#A19F9D",
                                    borderRadius: "0",
                                    fontSize: "12px !important",
                                    fontWeight: 400,
                                    fontFamily: "Lato",
                                  },
                                },
                              }}
                            >
                              <WarningIcon className={classes.WarningIcon} />
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {/* <WarningIcon /> */}
                          {/* <Typography> {value}</Typography> */}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  </>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(modal && state.tablesDataLength.medications) ? <Box className={classes.footerBox}>
        <Button
          variant="contained"
          className={classes.footerButton}
          onClick={() => setPriorData(!priorData)}
          disableRipple
        >
          { priorData ? 'Hide' : 'Show' } {"5 years prior medications"}
        </Button>
      </Box>
      :
      null
      }
    </Paper>
  );
};

export default MedicationsTable;
