import React, { useState, useEffect } from "react";
import services from "../../Services/patientServices";
import { makeStyles, withStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import CreateUserFeedback from "../../Components/Modal/CreateUserFeedback";
import "./CreateUser.scss";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  backdrop: {
    zIndex: 500,
    color: "#78cbf2",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    textAlign: "left",
    color: "#666",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 20,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStylesName = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 10px 7px 10px !important",
    textAlign: "left",
    color: "#666",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      borderRadius: 20,
      borderColor: "#80bdff",
      backgroundColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  focused: {},
}));

const NameTextField = (props) => {
  const classes = useStylesName();
  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
};

const CreateNewUser = () => {
  const [organisationList, setOrganisationList] = useState([]);
  const classes = useStyles();
  const [firstname, setFirstName] = useState(null);
  const [lastname, setLastName] = useState(null);
  const [useremail, setUserEmail] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [toggle, setToggle] = useState(false);

  const getOrganisationList = async () => {
    try {
      const data = await services.listOrganizations();
      setOrganisationList(data.data.organizations);
    } catch (error) {
      console.log("error organization list =>", error);
    }
  };

  useEffect(() => {
    getOrganisationList();
  }, []);

  const checkValidity = (params) => {
    const { first_name, last_name, email, org_id } = params;
    let emailRegex = new RegExp('[a-zA-Z0-9]+@[a-z]+.[a-z]{2,3}');

    if (typeof first_name !== "string" || /[0-9]+/g.test(first_name)) {
      return { isValid: false, message: "Invalid first name." }; 
    } 
    else if (typeof last_name !== "string" || /[0-9]+/g.test(last_name)) {
      return { isValid: false, message: "Invalid last name." };
    } 
    else if (!emailRegex.test(email)) {
      return { isValid: false, message: "Invalid email." };
    }
    else if (!org_id) {
      return { isValid: false, message: "Please select an organization." };
    }  

    return { isValid: true };
  };

  const createUser = async () => {
    let body = {
      first_name: firstname,
      last_name: lastname,
      email: useremail,
      org_id: organization,
    };

    const validity = checkValidity(body);
    if(!validity.isValid) {
      swal(validity.message, "", "error");
      return;
    };

    try {
      setLoading(true);
      const response = await services.createUserApi(body);
      const { data: { feedback, message }, status } = response;
      if (status === 200) {
        setFeedback({ ...feedback, message });
        setToggle(true);
        setLoading(false);
      }
    } 
    catch (error) {
      setLoading(false);
      const { message, feedback } =  error.response.data;
      setFeedback({ ...feedback, message });
      setToggle(true);
    }
  };

  return (
    <div className={classes.paper}>
      <div className="patient-create-set-title">
        {" "}
        Invite a new user to an organization{" "}
      </div>

      <form className={classes.root} noValidate autoComplete="off">
        <div className="mui-textfield-form">
          <label className="form-label">User's first name </label>
          <NameTextField
            className="input-field-size"
            defaultValue={firstname}
            multiline
            variant="filled"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            inputProps={{ maxLength: 100 }}
            name="firstName"
          />
        </div>
        <div className="mui-textfield-form">
          <label className="form-label">User's last name </label>
          <NameTextField
            multiline
            className="input-field-size"
            defaultValue={lastname}
            variant="filled"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            inputProps={{ maxLength: 100 }}
            name="lastName"
          />
        </div>{" "}
        <div className="mui-textfield-form">
          <label className="form-label">User's business email address </label>
          <NameTextField
            multiline
            className="input-field-size"
            defaultValue={useremail}
            variant="filled"
            onChange={(e) => {
              setUserEmail(e.target.value);
            }}
            inputProps={{ maxLength: 100 }}
            name="userEmail"
          />
        </div>
        <div className="mui-textfield-form">
          <label className="form-label">Organization</label>
          <Select
            defaultValue={"none"}
            disabled={false}
            onChange={(e) => setOrganization(e.target.value)}
            input={<BootstrapInput />}
            className="input-field-size"
            name="organization"
            MenuProps={{disableScrollLock: true}}
          >
            <MenuItem value="none">Select Organization</MenuItem>
            {organisationList.map((item, id) => {
              return (
                <MenuItem value={item.org_id} key={id}>
                  {`${item.name}`}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </form>
      <div className="d-flex flex-row-reverse">
          <button
            className="tblBtn mt-4"
            onClick={() => {
              createUser();
            }}
          >
            Create User
          </button>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {toggle ? (
        <CreateUserFeedback open={toggle} setOpen={setToggle} feedback={feedback} />
      ) : null}
    </div>
  );
};

export default CreateNewUser;
