import React, { useState, useEffect, useContext } from "react";
import withRouter from "../../Utils/withRouterHOC";
import services from "../../Services/patientServices";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/lab/Alert";
import CoordinatorDropdown from "../FiltersDropdown/CoordinatorDropdown";
import Skeleton from "@mui/lab/Skeleton";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toCapitalize } from "../../Utils/stringOprations";

const PatientCoordinator = ({ patientId }) => {

  const { patientSetInfo} = useContext(PatientSetContext);
  const { selected_date, patient_set_id } = patientSetInfo;

  const [isFetching, setIsFetching] = useState(false);
  const [patientData, setPatientData] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [coordinator, setCoordinator] = useState("");

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const setData = async () => {
    setIsFetching(true);
    const result = await services.patientsDetails(patientId, {
      selected_date,
      cohort_id: patient_set_id
    });
    setPatientData(result.data);
    setCoordinator(result.data.coordinator.id || "unassigned");
    setIsFetching(false);
  };

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCoordinatorChange = (value) => {
    console.log("Coordinator changed => ", value);
    const id = value.id || "unassigned";
    setCoordinator(id);
    saveData(id);
  };

  const saveData = async (id) => {
    setIsFetching(true);
    const result = await services.patientCoordinator({
      cohort_id: patient_set_id,
      patient_id: patientId,
      pcid: id,
    });
    console.log("Coordinator changed => ", result.data);
    await setData();
    setShowToast(true);
    setIsFetching(false);
  };

  const arrayUniqueByKey = (arr) => {
    if (!arr || !arr.length) return [];
    const uniqueArr = arr.filter(
      (value, index, self) => self.map((x) => x.id).indexOf(value.id) === index
    );
    return uniqueArr;
  };

  return (
    <div>
      <div className="patient-set-body">
        {!isFetching ? (
          <CoordinatorDropdown
            handleCoordinatorChange={handleCoordinatorChange}
            selected={coordinator || "unassigned"}
            options={arrayUniqueByKey(patientData.coordinator_list)}
          />
        ) : (
          <Skeleton className="mt-2" variant="rect" height={7} />
        )}
      </div>
      <div className="toaster">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert
            onClose={handleAlertClose}
            variant="outlined"
            severity="success"
          >
            <span>{toCapitalize(patientSetInfo.patient_name)} coordinator has been changed successfully.</span>
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default withRouter(PatientCoordinator);
