import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadIcon from "@mui/icons-material/CloudUpload";
import { toLowerCase, toPluralize } from "../../Utils/stringOprations";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "60px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "rgb(134 117 117)",
  transition: "border .3s ease-in-out",
  cursor: "pointer",
  marginTop: "0px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropzoneComponent = ({ setFile, setComplete, value }) => {
  const [invalidFile, setInvalidFile] = useState(false);
  const [multipleFilesSelected, setMultipleFilesSelected] = useState(false);
  const { patient_name, cohort_name } = JSON.parse(localStorage.getItem("user")).user;


  const onDrop = (acceptedFiles) => {
    console.log("File dropped => ", acceptedFiles);
    if (!acceptedFiles.length) {
      setInvalidFile(true);
      setMultipleFilesSelected(false);
    }
    else if(acceptedFiles.length > 1) {
      setMultipleFilesSelected(true);
      setInvalidFile(false);
    } 
    else {
      setInvalidFile(false);
      setMultipleFilesSelected(false);
      setFile(acceptedFiles[0]);
      setComplete(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: value ? { "text/csv": [".csv"] } :
      {
        "text/csv": [".csv"],
        "application/zip": [".zip"],
        "application/vnd.rar": [".rar"],
        "application/gzip": [".gz"]
  },
    multiple: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(invalidFile ? rejectStyle : {}),
      ...(multipleFilesSelected ? rejectStyle : {}),
    }),
    [isDragActive, invalidFile, multipleFilesSelected]
  );

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <UploadIcon style={{ fontSize: "1.8rem" }} />
        <div>
          Drag file here or click to <span>browse</span>.
        </div>
        {invalidFile && (
          <span style={{ fontSize: "12px", color: "red" }}>
            Invalid file format.
          </span>
        )}
        {multipleFilesSelected && (
          <span style={{ fontSize: "12px", color: "red" }}>
            Multiple files not allowed.
          </span>
        )}
      </div>
      <span className="upload-limits">
        {
            value ? `Create a new ${cohort_name} by uploading a text file (CSV) with a single column containing ${toLowerCase(patient_name)} identifiers. The ${toPluralize(toLowerCase(patient_name))} with these identifiers must exist in this Web App already; no new ${toPluralize(toLowerCase(patient_name))} will be created or added.` :
            "Please upload one file at a time, preferably a single compressed archive of all files."
        }
      </span>
    </section>
  );
};

export default DropzoneComponent;
