import React, { useEffect } from "react";
import { useState } from "react";
import ReportFooter from "./ReportFooter/ReportFooter";
import ReportHeader from "./ReportHeader/ReportHeader";
import CollapsibleTable from "./CollapsibleTable/CollapsibleTable";

const SummaryReport = () => {
    const reportData = JSON.parse(localStorage.getItem("report_data"));
    const [headerData, setHeaderData] = useState("");
    const [tableData, setTableData] = useState([]);
    const {user} = JSON.parse(localStorage.getItem("user"));

    const getHeaderData = () => {
        const userName = `${user.last_name}, ${user.first_name}`
        const headerData = {
            selected_date: reportData?.selected_date,
            title: "Care Activity Report",
            cohortName: reportData?.cohort_name,
            cohortId: reportData?.cohort_id,
            userName: userName,
            endDate: reportData?.end_date,
            startDate: reportData?.start_date
        }
        setHeaderData(headerData);
    }

    const getTableData = ()=>{
        const list = reportData?.pc_list;
        setTableData(list);
    }

    useEffect(() => {
        getHeaderData()
        getTableData()
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <ReportHeader data={headerData} />
            <CollapsibleTable tableData={tableData} data={headerData}/>
            <ReportFooter />
        </div>
    );
};
export default SummaryReport;