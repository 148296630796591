import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IframeContext } from "../../Contexts/iframeContext";
import { AuthContext } from "../../Contexts/authContext";

const ForesightsIframe = () => {
  const location = useLocation();
  const history = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { setIframeStatus, updatePatientMrn } = useContext(IframeContext);
  const { dispatch } = useContext(AuthContext);

  // Function to access  query params and update patientMrn
  const handleQueryParamsAndNavigate = () => {
    // Access and parse query parameters
    const user = JSON.parse(decodeURIComponent(queryParams.get("user")));
    const page = JSON.parse(decodeURIComponent(queryParams.get("page")));
    const patientMrnId =
      JSON.parse(decodeURIComponent(queryParams.get("patientMrnId"))) || [];

    localStorage.clear();
    // Store items in localStorage
    localStorage.setItem(
      "user",
      JSON.stringify({
        user: user,
        isLoggedIn: false,
      })
    );

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: user,
    });

    // Update patientMrn
    updatePatientMrn(patientMrnId);

    const routes = {
      report: "/iframe/reports",
      patient_details: "/patient-details",
      default: "/patient-scores",
    };

    // Using a default value if page doesn't match any routes
    const targetRoute = routes[page] || routes.default;

    history(targetRoute);
  };

  // Main useEffect
  useEffect(() => {
    setIframeStatus(true);

    // Check if there are query parameters
    if (queryParams.size) {
      handleQueryParamsAndNavigate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default ForesightsIframe;
