import React, { useContext, useState, useEffect } from "react";
import {
  CardActionArea,
  Card,
  CardContent,
  Typography,
  Tab,
  Box,
  Tabs,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import withRouter from "../../Utils/withRouterHOC";
import "./ReportSelection.scss";
import { sidebarContext } from "../../Contexts/sidebarContext";
import DashboardOverview from "../CohortOverview/DashboardOverview";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    width: "100%",
    marginBottom: "10px",
  },
  tab: {
    textTransform: "none !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    fontWeight: "600 !important",
    color: "#637381 !important",
    "&.Mui-selected": {
      color: "#212b36 !important",
      borderBottom: "2px solid #00ab55 !important",
      textTransform: "none",
    },
  },
}));

const ReportComponent = ()  => {
  const history = useNavigate();
  const changeRoute = (route) => {
    history(route);
  };

  const reportList = [
    // {
    //   title: "Care Activity Report",
    //   description:
    //     "A provider-focused report of interventions provided, with insights into the the caseload complexity.",
    //   path: "/if/generate/care-activity-report",
    // },
    {
      title: "Caseload Report",
      description:
        "A provider-focused report about the caseload progression over time.",
      path: "/if/generate/caseload-report",
    },
    // {
    //   title: "Care Plan Outcomes Summary Report",
    //   description:
    //     "A report for management and supervisors that provides at a glance the raw and risk-adjusted status and final outcomes of interventions and care activities.",
    //   path: "/if/generate/care-plan-report",
    // },
    // {
    //   title: "Referral Report",
    //   description:
    //     "A report for management and supervisors that shows the Patient Coordinator (PC) or institution assignment history of a patient.",
    //   path: "/if/generate/referral-report",
    //   feature: "if-referral-report",
    // },
  ];

  return (
    <div className="reportItemContainer">
      {reportList.map((element, index) => (
        <Card
          className="reportItem"
          key={index}
          onClick={() => changeRoute(element.path)}
        >
          <CardActionArea sx={{ padding: 0, height: "100%" }}>
            <CardContent sx={{ padding: 0, height: "100%" }}>
              <Typography
                className="reportTitle ccsReportTitle"
                gutterBottom
                variant="h5"
                component="div"
              >
                {element.title}
              </Typography>
              <Typography
                className="reportDescription"
                variant="body2"
                color="text.secondary"
              >
                {element.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </div>
  );
};

const DashboardComponent = () => {
  const { fetchPatientSets, patientSetInfo } = useContext(PatientSetContext);

  useEffect(() => {
    if (!patientSetInfo) {
      fetchPatientSets();
    }
    // eslint-disable-next-line
  }, [patientSetInfo]);

  return (
    <div id="report-dashboard-overview">
      <DashboardOverview reportIframe={true} />
    </div>
  );
};

const IframeReports = ()  => {
  const { showSidebar } = useContext(sidebarContext);
  const [tabValue, setTabValue] = useState("report");
  const classes = useStyles();

  const handleOnTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div
        className={`rs-overflow p-20 ${
          showSidebar ? "report-background" : ""
        } pb-5`}
      >
        <div className="reportWrapper">
          <Box className={classes.tabContainer}>
            <Tabs
              value={tabValue}
              onChange={handleOnTabChange}
              textColor="secondary"
              indicatorColor=""
              aria-label="secondary tabs example"
            >
              <Tab
                value="report"
                label="Reports"
                className={classes.tab}
                disableRipple
              />
              {/* <Tab
                value="dashboard"
                label="Dashboard"
                className={classes.tab}
                disableRipple
              /> */}
            </Tabs>
          </Box>
          {tabValue === "report" && <ReportComponent />}
          {tabValue === "dashboard" && <DashboardComponent />}
        </div>
      </div>
    </>
  );
};

export default withRouter(IframeReports);
