import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import { Visibility } from "@mui/icons-material";
import { Tooltip, IconButton } from "@mui/material";
import genesisServices from "../../Services/genesisServices";
import "./GenesisIframe.scss";
import Filter from "../../Components/Genesis/Filter";
import { calculateStandardDeviation } from "../../Utils/standardDeviation";
import {
  LearnerDescription1,
  LearnerDescription2,
} from "../../constants/Learners";
import LoaderWithTimer from "./LoaderWithTimer";
import ErrorPage from "./ErrorPage";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    borderBottom: "5px solid #eaeaea",
  },
  title: {
    color: "#e85e1e",
  },
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  descriptionContainer2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  heading1: {
    fontSize: "18px",
    lineHeight: "27px",
    marginBottom: "0px",
  },
  heading2: {
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "0px",
  },
  numberText: {
    marginBottom: "0px",
  },
  circle: {
    width: "15px",
    height: "15px",
    backgroundColor: "#2E93fA",
    borderRadius: "50%",
  },
  line: {
    width: "20px",
    height: "5px",
    backgroundColor: "#66DA26",
  },
}));
//4088
//3919
const LearnerVsClientProgress = ({clientId = 3919}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [treatmentType, setTreatmentType] = useState("All");
  const [fromMonthNumber, setFromMonthNumber] = useState("");
  const [toMonthNumber, setToMonthNumber] = useState("");
  const [currentSelectedData, setCurrentSelectedData] = useState({});
  const [finalData, setFinalData] = useState({});
  const [clientGroup, setClientGroup] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [chartData, setChartData] = useState({
    series: [
      { name: "Client", data: [] },
      { name: "Learner Group", data: [] },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        },
      },
      colors: ["#2E93fA", "#66DA26"],
      dataLabels: {
        enabled: true,
        formatter: (val) => Math.trunc(val),
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 3,
          borderRadius: 0,
          borderWidth: 0,
          borderColor: "transparent",
          opacity: 0.9,
        },
      },
      markers: {
        size: [17, 17],
        shape: ["circle", "square"],
        hover: {
          sizeOffset: 0,
        },
      },
      stroke: {
        curve: "straight",
        width: 1.5,
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
        padding: {
          right: 30
        }
      },
      xaxis: {
        type: "category",
        title: {
          text: "Treatment month Number",
        },
        labels: { showDuplicates: false },
      },
      yaxis: {
        labels: {
          formatter: (val) => Math.trunc(val),
        },
        title: {
          text: "Total Objective %",
        },
        min: 0,
        max: 100,
        tickAmount: 2,
      },
      legend: {
        show: false,
      },
      tooltip: {
        shared: false,
        intersect: true,
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          let name = w.globals.initialSeries[seriesIndex]["name"];
          return (
            "<div>" +
            '<div class="toolTipHeader"><span>' +
            name +
            "</span><span> Treatment Month: " +
            data?.x +
            '<span></div><div class="tooltipInnerContainer"><div class="itemRowInToolTip"><span class="dot"></span><span>Total Objective%</span>' +
            "<span>" +
            data?.y?.toFixed(2) +
            "</span>" +
            '</div><div class="itemRowInToolTip"><span class="dot"></span><span>Total Goals Achieved</span>' +
            "<span>" +
            data?.met +
            "</span>" +
            '</div><div class="itemRowInToolTip"><span class="dot"></span><span>Total Goals Assigned</span>' +
            "<span>" +
            data?.total_objectives +
            "</span>" +
            "</div></div></div>"
          );
        },
      },
    },
  });

  const calculateDropDownArrayLength = () => {
    if (Object.keys(finalData)?.length !== 0) {
      let selectData = finalData["All"];
      return Object.values(selectData)[0]?.length;
    } else {
      return 10;
    }
  };

  const getLeanerDescription = (learner_group) => {
    if (learner_group === "") return "";
    return learner_group === 0 ? LearnerDescription1 : LearnerDescription2;
  };

  const handleSetTreatmentType = (value) => {
    setTreatmentType(value);
  };

  const onFromMonthNumberChange = (value) => {
    setFromMonthNumber(value);
  };

  const onToMonthNumberChange = (value) => {
    setToMonthNumber(value);
  };

  const convertArrayDataIntoGraphData = (data) => {
    let finalData = [];
    for (let [key, value] of Object.entries(data)) {
      let obj = {};
      obj["name"] = key === "client" ? "Client" : "Learner Group";
      data = value.map(
        ({ total_percentage, treatement_month_number, ...restData }) => {
          return {
            y: total_percentage,
            x: treatement_month_number,
            ...restData,
          };
        }
      );
      obj["data"] = data;
      finalData.push(obj);
    }
    return [...finalData];
  };

  const applyFilter = () => {
    let data = { ...finalData[treatmentType] };
    // to filter in from date is selected
    if (fromMonthNumber) {
      for (let [key, value] of Object.entries(data)) {
        let filteredData = [];
        filteredData = value.filter(
          ({ treatement_month_number }) =>
            treatement_month_number >= fromMonthNumber
        );
        data[key] = filteredData;
      }
    }
    // to filter if end date is selected
    if (toMonthNumber) {
      for (let [key, value] of Object.entries(data)) {
        let filteredData = [];
        filteredData = value.filter(
          ({ treatement_month_number }) =>
            treatement_month_number <= toMonthNumber
        );
        data[key] = filteredData;
      }
    }
    let convertedData = convertArrayDataIntoGraphData({ ...data });
    setChartData({
      ...chartData,
      series: [...convertedData],
    });
    setCurrentSelectedData({ ...data });
  };

  const calcDeviation = (name) => {
    if (Object.keys(currentSelectedData).length === 0) {
      return 0;
    }
    let data = { ...currentSelectedData };
    let data2 = data[name];
    let filteredData = data2?.map(({ total_percentage }) => total_percentage);
    return calculateStandardDeviation(filteredData);
  };

  const getClientVsLearnerProgressTrend = async (clientId) => {
    try {
      setLoader(true);
      const {data : {data : result}} = await genesisServices.getLearnerVsClientProgresTrend(clientId);
      let data2 = result["All"]
      let data = convertArrayDataIntoGraphData({ ...data2 });
      setChartData({
        ...chartData,
        series: [...data],
      });
      setClientGroup(result?.client_group);
      setFinalData({ ...result });
      setCurrentSelectedData({ ...data2 });
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getClientVsLearnerProgressTrend(clientId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(finalData).length !== 0) {
      applyFilter();
    }
  }, [fromMonthNumber, toMonthNumber, treatmentType]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("Chart Data in Learner Vs Client", chartData);
  return (
    <div>
      {loader ? (
        <LoaderWithTimer />
      ) : error ? (
        <ErrorPage errorMessage={errorMessage} />
      ) : (
            <div className="main-container">
              <div className="chart-container">
                <div className={classes.titleContainer}>
                  <h4 className={classes.title}>
                    Monthly Objective Assigned and Accomplished
                  </h4>
                </div>
                <Filter
                  dateRangeFilter={true}
                  fromMonthNumber={fromMonthNumber}
                  onFromMonthNumberChange={onFromMonthNumberChange}
                  toMonthNumber={toMonthNumber}
                  onToMonthNumberChange={onToMonthNumberChange}
                  treatmentType={treatmentType}
                  handleSetTreatmentType={handleSetTreatmentType}
                  monthFilterLength={calculateDropDownArrayLength()}
                />
                {
                  chartData?.series[0]?.data?.length === 0 ?
                    <div className="empty-container">
                      <h6 className="empty-container-text">No Data Available.</h6>
                    </div>
                    :
                    <>
                      <div className={classes.descriptionContainer}>
                        <h5 className={classes.heading1}>
                          Total Objective % variation -
                        </h5>
                        <div className={classes.descriptionContainer2}>
                          <span className={classes.circle}></span>
                          <h6 className={classes.heading2}>Client</h6>
                          <span>:</span>
                          <p className={classes.numberText}>{calcDeviation("client")}</p>
                        </div>
                        <div className={classes.descriptionContainer2}>
                          <span className={classes.line}></span>
                          <h6 className={classes.heading2}>
                            Learner Type {Number(clientGroup) + 1}
                          </h6>
                          <Tooltip
                            title={getLeanerDescription(Number(clientGroup))}
                            placement="top"
                          >
                            <IconButton>
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                          <span>:</span>
                          <p className={classes.numberText}>
                            {calcDeviation("learner_group")}
                          </p>
                        </div>
                      </div>
                      <ReactApexChart
                            options={chartData?.options}
                            series={chartData?.series}
                            type="line"
                            height={400}
                      />
                    </>
                }
              </div>
            </div>
          )}
    </div>
  );
};

export default LearnerVsClientProgress;
