import React, { createContext, useState } from "react";

// Create an iframe context
const IframeContext = createContext();

const IframeProvider = (props) => {
  // Define the state for the iframe
  const [iframe, setIframe] = useState(false);
  const [iframePatientId, setIframePatientId] = useState("");
  const [iframePatientMrn, setPatientMrn] = useState([]);
  const [isGenesisReport, setIsGenesisReport] = useState(false);
  const [chartName, setChartName] = useState("cumulativetrend");

  // Set the iframe state to true/false
  const setIframeStatus = (status) => {
    setIframe(status);
  };

  const updateIframePatientId = (patientId) => {
    setIframePatientId(patientId);
  };

  const updatePatientMrn = (mrnIds) => {
    setPatientMrn(mrnIds);
  };

  // Pass the iframe state and functions to the context provider
  return (
    <IframeContext.Provider
      value={{
        iframe,
        iframePatientId,
        iframePatientMrn,
        updatePatientMrn,
        setIframeStatus,
        updateIframePatientId,
        setIsGenesisReport,
        isGenesisReport,
        setChartName,
        chartName
      }}
    >
      {props.children}
    </IframeContext.Provider>
  );
};

export { IframeContext, IframeProvider };
