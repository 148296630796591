import Cookies from "js-cookie";
import fetchAuthToken from "./fetchAuthToken";

const getCognitoToken = async (tokenType) => {
  const userpool_client_id = window.config.userpool_client_id;

  //Extract user_id of the user obtained from cognito
  let user_id = Cookies.get(
    `CognitoIdentityServiceProvider.${userpool_client_id}.LastAuthUser`
  );
  console.log("User_id for client id %s => %s", userpool_client_id, user_id);

  //Extract token from cookies set by cognito authorization
  let token = Cookies.get(
    `CognitoIdentityServiceProvider.${userpool_client_id}.${user_id}.${tokenType}`
  );
  console.log("Token Length => ", token ? token.length : 0);

  if (!token) {
    if (window.config.environment === "local") return "no-auth-required";
    else if (process.env.REACT_APP_ENVIRONMENT === "dev" ||
      process.env.REACT_APP_ENVIRONMENT === "qa" ||
      process.env.REACT_APP_ENVIRONMENT === "demo-a" ||
      process.env.REACT_APP_ENVIRONMENT === "uat") {
      token = await fetchAuthToken();
    } 
    else return null;
  }
  return token;
};

export default getCognitoToken;
