import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import MultiProvider from "./Contexts/multiProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#47a3c7", // Green color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'white', // Set the text color for the contained variant
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MultiProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </MultiProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
