import React, { useContext, useState, useRef, useEffect } from "react";
import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";
import { IframeContext } from "../../../Contexts/iframeContext";

import "./DownloadReportCsv.scss";
import formatHeading from "../../../Utils/formatHeading";
import reportServices from "../../../Services/reportServices";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadReportCsv = ({ data }) => {
  const { iframe } = useContext(IframeContext);
  const {
    list,
    isCsvAvailable,
    csvFilename,
    column_list,
    csv_api_url,
    csv_api_params,
    runtime
  } = data;
  const [headers, setHeaders] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const exportCsvRef = useRef();
  console.log("runtime", runtime)

  // Generate csv from existing data
  const generateCSVData = (dataList, headerList) => {
    return dataList.map((item) => {
      const row = {};
      headerList.forEach((header) => {
        row[header.key] = item[header.key];
      });
      return row;
    });
  };

  // Include only headers which are available
  const generateHeaders = (columnList) => {
    return columnList
      .filter((column) => column.isAvailable)
      .map(({ title }) => ({
        label: formatHeading(title),
        key: title,
      }));
  };

  const exportCsv = async () => {
    if (!isCsvAvailable || list.length === 0) return;

    try {
      if (csv_api_url) {  
      setIsFetching(true);

      const BATCH_SIZE = 5000;
      const MAX_ITEMS = 250000;
      const MAX_CONCURRENT_REQUESTS = 5;
      let batchPromises = [];
      let offset = 0;
      let totalCoordinates = 0;
      let isExhausted = false;
      let allData = [];    

        const fetchBatch = async (offset) => {
          csv_api_params['page'] = offset;
          csv_api_params['limit'] = BATCH_SIZE
          try {
            const responseData = await reportServices.reportPostRequest(
              csv_api_url,
              csv_api_params, 
              runtime
            );
            const { data } = responseData.data;
            return data
          } catch (error) {
            console.log("ERROR IN API CALL", error);
          }
        }            

      while (totalCoordinates < MAX_ITEMS && !isExhausted) {
        while (batchPromises.length < MAX_CONCURRENT_REQUESTS && totalCoordinates < MAX_ITEMS) {
          batchPromises.push(fetchBatch(offset));
          offset += BATCH_SIZE;
        }
    
        const results = await Promise.all(batchPromises);
        batchPromises = []; // Reset batchPromises for the next set of requests
        // eslint-disable-next-line no-loop-func
        results.forEach((item) => {
          const { list } = item;
          if (list.length === 0) {
            isExhausted = true; // No more records available
          } else {
            allData.push(...list)
            totalCoordinates += list.length;                
          }
        });
    
        if (totalCoordinates >= MAX_ITEMS || isExhausted) {
          setCSVData(allData);  
          setIsFetching(false);          
          console.log('Request complete.');
          await exportCsvRef.current.link.click();
        }
      } 
    }      
    } catch (error) {
      setIsFetching(false);
      console.error("Error fetching CSV data:", error);
    }
  };

  useEffect(() => {
    // No need to proceed if CSV isn't available or list is empty
    if (!isCsvAvailable || list.length === 0) return;
    // No need to set headers and csv data when api is available
    if (csv_api_url) return;
    const headers = generateHeaders(column_list);
    const csvData = generateCSVData(list, headers);
    setHeaders(headers);
    setCSVData(csvData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isCsvAvailable ? (
        <div
          className={`download-csv-container ${
            iframe ? "ccs_iframe_active" : ""
          }`}
        >
          <div className="csv-button-wrapper">
            <Button
              variant="contained"
              component="label"
              color="primary"
              className="csv-btn"
              onClick={exportCsv}
            >
              export csv
              {isFetching ? (
                <CircularProgress
                  style={{ marginLeft: "5px" }}
                  color="inherit"
                  size={25}
                />
              ) : null}
            </Button>
            {!csv_api_url ? (
              <CSVLink
                data={csvData}
                headers={headers}
                filename={csvFilename + ".csv"}
                hidden={true}
                ref={exportCsvRef}
                target="_blank"
              />
            ) : (
              <CSVLink
                data={csvData}
                filename={csvFilename + ".csv"}
                hidden={true}
                ref={exportCsvRef}
                target="_blank"
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DownloadReportCsv;
