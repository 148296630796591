import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import React from 'react';
import { notification_type } from "../../constants";
import dayjs from "dayjs";
import { Button, IconButton } from "@mui/material";

const ViewAllAlertsModal = ({ open, handleClose, notificationsData, parseToBold, markAsReadNotifications, notifications, handleSmartAlertClick }) => {

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: 800,
        bgcolor: "background.paper",
        boxShadow: 24,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        outline: "none",
    };

    const notificationTypesOrder = Object.keys(notification_type);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-view-all-alerts"
                aria-describedby="modal-view-all-alerts"
            >
                <Box sx={{ ...style, borderRadius: "10px"}} className="notification-box">
                    <Box sx={{ borderBottom: "1px solid #D9D9D9", width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: "16px !important",
                                color: "#000000 !important",
                                padding: "10px", display: "flex", alignItems: "center"
                            }}
                        >
                            <NotificationsOutlinedIcon
                                className="notification-icon"
                                fontSize="small"
                                sx={{ fill: "#24292E !important", marginRight: "2px" }}
                            />
                            Notifications ({notificationsData.length})
                        </Typography>

                        <IconButton onClick={() => handleClose()}>
                            <Close sx={{ fill: "#000000 !important", width: 18 }} />
                        </IconButton>                        
                    </Box>

                    <Box className="notification-body" sx={{ overflowY: 'auto', flex: 1, width: "100%" }}>
                        {notificationsData?.length ? (
                            notificationTypesOrder.map((type) => {
                                if (!notifications[type]) return null;
                                return (
                                  <Box
                                    key={type}
                                    sx={{ 
                                      borderBottom: Object.keys(notifications).length === (notificationTypesOrder.indexOf(type) + 1) ? "none" : "1px solid #D9D9D9", 
                                      paddingTop: "5px !important", 
                                      paddingBottom: "5px !important"
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Lato",
                                        fontSize: "13px",
                                        fontWeight: 700,
                                        paddingTop: "5px !important"
                                      }}  
                                    >{notification_type[type]} ({notifications[type].length})</Typography>
                                    {notifications[type].map((notification, index) => {
                                      const dateTime = dayjs(notification.note_date, 'YYYY-MM-DD HH:mm:ss');
                                      const formattedDate = dateTime.format('YYYY/MM/DD');
                                      return (
                                        <Box
                                          className="notification"
                                          key={notification.note_id}
                                          sx={{
                                            backgroundColor: !notification.isViewed ? "#DBF5FF" : "#fff",
                                            borderBottom: (notifications[type].length - 1 === index) ? "none" : "1px solid #D9D9D9"
                                          }}
                                          onClick={() => markAsReadNotifications(notification.note_id, notification)}
                                        >
                                          <Box className="notification-content" sx={{width: "100%"}} >
                                            <Typography className="text main">
                                              {parseToBold(notification.note_text)}
                                              &nbsp; Click to view <span onClick={() => handleSmartAlertClick(type, notification.is_ip)} className="text smart_alert">Smart Alerts</span> 
                                            </Typography>
                                            <Typography className="text date">
                                              <div className="date_time" >
                                                <CalendarMonthOutlinedIcon fontSize="small" sx={{ width: "16px", marginRight: "5px" }} />
                                                {formattedDate}                               
                                              </div>
                                              {notification.priority && <Typography className="text priority" >{notification_type[notification.priority]}</Typography>}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      );
                                    })}                  
                                  </Box>
                                );
                              })
                        ) : (
                            <Box className="notification">
                                <Typography className="text main">No Notifications</Typography>
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ padding: "15px !important", borderTop: "1px solid #D9D9D9", display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                        <Button variant="outlined" disabled={notificationsData.filter((n) => !n.isViewed).length === 0} className="clear-all-button" onClick={() => markAsReadNotifications('ALL')}>Clear All</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ViewAllAlertsModal