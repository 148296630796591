import React from "react";
import "./ReportTable.scss";
import ReportOuterTableRow from "./ReportOuterTableRow";
import formatHeading from "../../../Utils/formatHeading";
import ReportColumnHeading from "./ReportColumnHeading";

import AdjustIcon from '@mui/icons-material/Adjust';
import {Tooltip} from '@mui/material';


const ReportOuterTable = (props) => {
  const { list, columnList } = props;

  const getClassNames = (item) => {
    return [
      "tableHeading",
      item.title === "name" ? "sticky_first" : "",
      item.title === "payer" ? "last-heading-col" : "",
      item.haveTooltip ? "icon-align" : ""
    ].filter(Boolean).join(" ");
  };

  
  return (
    <div className="ReportOuterTable">
      <section className="section__container">
        <div className="tableWrap">
          {list.length ? (
            <div className="tableScroll">
              <div></div>
              <table className="outer__table">
                <thead>
                  <tr className="tRow">
                    {columnList.map((item, index) =>
                      item.isAvailable ? (
                        <th
                          className={getClassNames(item)}
                          key={index}
                        >
                          <ReportColumnHeading heading={formatHeading(item.title)}></ReportColumnHeading>
                          {item?.haveTooltip &&
                            <Tooltip title="% of indirect time spent" arrow>
                              <AdjustIcon fontSize="0.7rem"/>
                            </Tooltip>
                          }
                        </th>
                      ) : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {list.map((row, index) => (
                    <ReportOuterTableRow
                      key={index}
                      row={row}
                      columnList={columnList}
                      reportTitle={props?.reportTitle}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="no-result">No Data Available!</div>
          )}
        </div>
      </section>
    </div>
  );
};
export default ReportOuterTable;
