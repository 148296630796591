import axios from "axios";

const fetchAuthToken = async () => {
  const config = window.config;
  const api_url = config["apiUrl"] + "/node/login";
  const cognito_userpool_id = config["awsCognitoUserpoolId"];
  const cognito_region = config["awsCognitoRegion"];
  const cognito_url = `https://cognito-idp.${cognito_region}.amazonaws.com/${cognito_userpool_id}`;
  const username = process.env.REACT_APP_TEST_USERNAME;
  const password = process.env.REACT_APP_TEST_PASSWORD;
  const clientId = config["userpool_client_id"];

  //Obtain authentication token
  try {
    const body = {
      AuthParameters: { USERNAME: username, PASSWORD: password },
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: clientId,
    };
    const authTokenHeaders = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    };
    const authTokenResult = await axios.post(cognito_url, body, { headers: authTokenHeaders });
    const access_token = authTokenResult["data"]["AuthenticationResult"]["AccessToken"];

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    
    //Wait if response says service unavailable (db inactivity timeout)
    var attempt = 0;
    while(attempt < 1) {
      const result = await axios.get(api_url, { headers: { Authorization: "Bearer " + access_token } });
      if(result.status === "503") {
        attempt = attempt + 1;
        console.log('Failure: Error code (503). Retrying api call..');
        await delay(2000);
      }
      else break;
    };

    return access_token;
  } 
  catch (error) {
    console.log("API request failed :", error);
  }
};

export default fetchAuthToken;
