import {
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  DialogActions,
} from "@mui/material";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";
import { Chart } from "../PatientsComparisonChart";
import { toCapitalize } from "../../../../Utils/stringOprations";
import { useContext } from "react";
import { PatientSetContext } from "../../../../Contexts/patientSetContext";

function PatientsComparisonModal({
  openModal,
  handleCloseModal,
  impactabilityData,
  comprehensiveData,
  setCompKey,
  qualityData,
  handlePatientListRedirect,
}) {
  const { patientSetInfo } = useContext(PatientSetContext);
  const history = useNavigate();

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={openModal}
      onClose={handleCloseModal}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          display={"flex"}
        >
          <Grid item>{`${toCapitalize(patientSetInfo?.cohort_name)} Costs & Metrics`}</Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Chart
          impactabilityData={impactabilityData}
          comprehensiveData={comprehensiveData}
          setCompKey={setCompKey}
          handlePatientListRedirect={handlePatientListRedirect}
          qualityData={qualityData}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Link
          onClick={() => history("/patient-list")}
          sx={{ display: "flex", cursor: 'pointer' }}
        >
          View Details
          <KeyboardDoubleArrowRightSharpIcon />
        </Link>
      </DialogActions>
    </Dialog>
  );
}

export default PatientsComparisonModal;
