export const LearnerDescription1 = [
       "Age (yrs) : 8.7",<br />,
       "Total Objective % : 36.1%", <br />,
       "Consultation Staff Count : 10", <br />,
       "Treatment Staff Count : 57", <br />,
       "Virtual Consultation Staff Count : 3", <br />,
       "Consultation Time(Hr) : 374.4", <br />,
       "Treatment Time(Hr): 2054", <br />,
       "Virtual Consultation Time(Hr): 23.3", <br />,
]

export const LearnerDescription2 = [
        "Age (yrs) : 6.5",<br />,
        "Total Objective % : 25.7%", <br />,
        "Consultation Staff Count : 4", <br />,
        "Treatment Staff Count : 16", <br />,
        "Virtual Consultation Staff Count : 1", <br />,
        "Consultation Time(Hr) : 99.2", <br />,
        "Treatment Time(Hr): 451.8", <br />,
        "Virtual Consultation Time(Hr): 6.9", <br />,
]