import React, { useContext, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Radio, Select, MenuItem, FormLabel, Grid, TextField, RadioGroup, Button } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { DropdownFilterContext } from "../../Contexts/dropdownContext";

const CostFilter = ({ label_2, filter, onChange }) => {

    const [expanded, setExpanded] = useState("");
    const [radioValue, setRadioValue] = useState("");
    const [timeFrameValue, setTimeFrameValue] = useState(filter.options[2]?.options[0]?.value);

    const handleExpand = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded(panel);
        } else {
            setExpanded("");
        }
    };

    const handleOnSelectChange = (event) => {
        const { value } = event.target;
        setTimeFrameValue(value);
        if (radioValue) {
            const radioLabel = filter.options.find((doc)=>(doc.value === radioValue)).label;
            const timeFrameLabel = filter.options[2].options.find((doc)=>(doc.value === value)).label;
            onChange({ mnemo: filter.value, timeFrame: value, value: radioValue, msg: `${label_2 ? label_2 + " : " : ""}${filter.label} : ${radioLabel} : ${timeFrameLabel}` });
        }

    }

    const handleOnRadioChange = (event) => {
        const { value } = event.target;
        setRadioValue(value);
        const radioLabel = filter.options.find((doc)=>(doc.value === value)).label;
        const timeFrameLabel = filter.options[2].options.find((doc)=>(doc.value === timeFrameValue)).label;
        onChange({ mnemo: filter.value, timeFrame: timeFrameValue, value: value, msg: `${label_2 ? label_2 + " : " : ""}${filter.label} : ${radioLabel} : ${timeFrameLabel}` });

    }

    return (<><Accordion
        key={filter.value}
        expanded={expanded === filter.value}
        onChange={handleExpand(filter.value)}
        className="outlier-accordion"
    >
        <AccordionSummary>
            <div className="expand-icon-label">
                {expanded === filter.value 
                ? <IndeterminateCheckBoxOutlinedIcon color={"info"}/> 
                : <CheckBoxOutlineBlankIcon color={"info"}/>} {filter.label}
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container>
            <Grid item xs={6}>
                    <FormLabel component="legend" >{filter.options[2]?.label} </FormLabel>
                </Grid>
                <Grid item xs={6}>
                    <Select
                        size="small"
                        value={timeFrameValue}
                        onChange={handleOnSelectChange} >
                        {filter.options[2]?.options.map((_option, index) => (<MenuItem key={index} value={_option.value} >{_option.label}</MenuItem>))}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup
                        value={radioValue}
                        onChange={handleOnRadioChange}
                    >
                        <Grid item xs={12}>
                            <FormControlLabel value={filter.options[0].value} control={<Radio />} label={filter.options[0].label} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel value={filter.options[1].value} control={<Radio />} label={filter.options[1].label} />
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion></>)
}

const VolumeFilter = ({ label_2, filter, onChange }) => {

    const [expanded, setExpanded] = useState("");
    const [timeFrameValue, setTimeFrameValue] = useState(filter.options[0].options[0].value);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [radioValue, setRadioValue] = useState("");
    const [volumeCount, setVolumeCount] = useState("");

    const handleExpand = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded(panel);
        } else {
            setExpanded("");
        }
    };

    const handleOnSelectChange = (event) => {
        const { value } = event.target;
        setTimeFrameValue(value);
    }

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        if (name === "from") {
            setFrom(value);
        }

        if (name === "to") {
            setTo(value);
        }

        if (name === "volumeCount") {
            setVolumeCount(value);
        }
    }

    const handleOnRadioChange = (event) => {
        const { value } = event.target;
        setRadioValue(value);

    }

    const handleOnClick = () => {
        if (from && to) {
            const timeFrameLabel = filter.options[0].options.find((doc)=>(doc.value === timeFrameValue)).label;
            onChange({ mnemo: filter.value, timeFrame: timeFrameValue, value: { from, to, fromToMode:true }, msg: `${label_2} : ${filter.label} : from ${from} to ${to} : ${timeFrameLabel}` });

        }

        if (radioValue && volumeCount) {
            const timeFrameLabel = filter.options[0].options.find((doc)=>(doc.value === timeFrameValue)).label;
            const radioLabel = {
                "grater_then" : "Greater Than",
                "less_then" : "Less Than",
            }
            onChange({ mnemo: filter.value, timeFrame: timeFrameValue, value: { radioValue, volumeCount, fromToMode:false }, msg: `${label_2} : ${filter.label} : ${radioLabel[radioValue]} ${volumeCount} : ${timeFrameLabel}` });

        }

    }

    // Handle Reset for the volume local states
    const handleReset = () =>{
        setTimeFrameValue(filter.options[0].options[0].value);
        setFrom("");
        setTo("");
        setRadioValue("");
        setVolumeCount("");
    }

    return (<><Accordion
        key={filter.value}
        expanded={expanded === filter.value}
        onChange={handleExpand(filter.value)}
        className="outlier-accordion"
    >
        <AccordionSummary>
            <div className="expand-icon-label">
                {expanded === filter.value 
                ? <IndeterminateCheckBoxOutlinedIcon color={"info"}/> 
                : <CheckBoxOutlineBlankIcon color={"info"}/>} {filter.label}
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container>
                <Grid item xs={4}>
                    <FormLabel component="legend" >{filter.options[0].label} </FormLabel>
                </Grid>
                <Grid item xs={8} marginBottom={1}>
                    <Select
                        size="small"
                        value={timeFrameValue}
                        onChange={handleOnSelectChange}
                    >
                        {filter.options[0].options.map((_option, index) => (<MenuItem key={index} value={_option.value} >{_option.label}</MenuItem>))}
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <FormLabel component="legend" >{filter.options[1].label} </FormLabel>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1} columns={12}>
                        <Grid item xs={6} marginBottom={1}>
                            <TextField size="small" disabled={radioValue || volumeCount ? true : false} autoComplete="off" label="From" variant="outlined" name="from" value={from} onChange={handleOnChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField size="small" disabled={radioValue || volumeCount ? true : false} autoComplete="off" label="To" variant="outlined" name="to" value={to} onChange={handleOnChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} marginTop={1} marginBottom={1} columns={12} justifyContent="center"><span>-- OR --</span></Grid>
                    <Grid container spacing={1} columns={12}>
                        <RadioGroup
                            value={radioValue}
                            onChange={handleOnRadioChange}
                            row
                        >
                            <FormControlLabel disabled={from || to ? true : false} value={"grater_then"} control={<Radio />} label={"Greater Than"} />
                            <FormControlLabel disabled={from || to ? true : false} value={"less_then"} control={<Radio />} label={"Less Than"} />
                        </RadioGroup>
                    </Grid>
                    <Grid container spacing={1} columns={12}>
                        <Grid item xs={6}>
                            <TextField size="small" disabled={from || to ? true : false} autoComplete="off" name="volumeCount" value={volumeCount} onChange={handleOnChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={() => { handleOnClick() }} >Apply</Button>
                            <Button onClick={() => { handleReset() }} >Reset</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion></>)
}

const OutlierFilter = ({ id, name, selected, filter, onChange }) => {
    const { mnemo } = filter;
    const { dispatch } = useContext(DropdownFilterContext);

    const [expanded, setExpanded] = useState("");

    const handleExpand = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded(panel);
        } else {
            setExpanded("");
        }
    };

    const onChangeHandler = (filterObject) => {
        // save filter msg with key, msg 
        dispatch({ 
            type: "applied-outlier-filter", 
            payload: { [name] : filterObject}
        });
        onChange({ id, name: name, selected: 1, mnemo: mnemo, data: filterObject });
        };

    return (<>
        {filter.filterType === "dropdown" && <CostFilter filter={filter} onChange={onChangeHandler} />}
        {filter.filterType === "outer_dropdown" &&
            (<Accordion key={filter.value} expanded={expanded === filter.value} onChange={handleExpand(filter.value)} className="outlier-accordion">
                <AccordionSummary>
                    <div className="expand-icon-label">
                        {expanded === filter.value 
                        ? <IndeterminateCheckBoxOutlinedIcon color={"info"}/> 
                        : <CheckBoxOutlineBlankIcon color={"info"}/>} {filter.label}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <CostFilter label_2={filter.label} filter={filter.options[0]} onChange={onChangeHandler} />
                        </Grid>
                        <Grid item xs={12}>
                            <VolumeFilter label_2={filter.label} filter={filter.options[1]} onChange={onChangeHandler} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>)}
    </>);

}

export default OutlierFilter;