import React from "react";
import Chart from "react-apexcharts";

const MixedChart = (props) => {

    return (
        <div id="chart">
            <Chart
                options={props.data.options}
                series={props.data.series}
                plotOptions= {props.data.plotOptions}
                type="bar"
                height={400}
                max-width={500}
            />
        </div>
    );
}

export default MixedChart;
