import React, { useEffect, useState, useContext } from "react";
import services from "../../Services/patientServices";
import InfiniteScroll from "react-infinite-scroll-component";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toCapitalize, toLowerCase, toPluralize } from "../../Utils/stringOprations";
import { Skeleton } from "@mui/material";

const InterventionTable = (props) => {
  const { patientSetInfo } = useContext(PatientSetContext);

  const [patientList, setPatientList] = useState([]);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [endMessage, setEndMessage] = useState('');
  const [patientCount, setPatientCount] = useState(0);
  const [currentPatientCount, setCurrentPatientCount] = useState(0);
  const [columns, setColumns] = useState([
    {
      title: `${patientSetInfo.patient_name} ID`,
      isAvailable: true,
      key: "org_patient_id",
      enableSorting: true,
      isSelected: true,
      quickFilter: false
    },
    {
      title: "Last Name",
      isAvailable: true,
      key: "last_name",
      enableSorting: true,
      isSelected: true,
      quickFilter: false,
    },
    {
      title: "First Name",
      isAvailable: true,
      key: "first_name",
      enableSorting: true,
      isSelected: true,
      quickFilter: false,
    },
    {
      title: "Age",
      isAvailable: true,
      key: "age",
      enableSorting: true,
      isSelected: true,
    },
    {
      title: "Sex",
      isAvailable: true,
      key: "sex",
      enableSorting: true,
      isSelected: true,
    },
  ]);

  useEffect(() => {
    fetchPatientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPatientList = async () => {
    setIsFetching(true);
    const { patient_set_id: pid, selected_date } = patientSetInfo;

    

    //Get patients
    const newPage = page + 1;
    setPage(newPage);

    const body = {
      key : props.type,
      individual : props.contr_type,
      is_individual : props.is_individual,
      selected_date : selected_date,
      src_label : props.src_label,
      page : newPage
  }

    const filteredPatients = await services.patientListFactors(body, pid);
    const newPatients = filteredPatients.data.result.patients;
    const totalPatientsCount = filteredPatients.data.result.count;
    setPatientCount(totalPatientsCount);
    setCurrentPatientCount(prevCount => prevCount = prevCount + newPatients.length);
    setPatientList([...patientList, ...newPatients]);

    setIsFetching(false);

    //Set available columns
    if(!newPatients.length) {
      if(!patientList.length) {
        setEndMessage("No data found");
      }
      else {
        const count = filteredPatients.data.patientset.count;
        setEndMessage(`All ${count} ${toPluralize(toLowerCase(patientSetInfo.patient_name))} shown from this selection.`);
      }
      setHasMore(false);
      return;
    };
    const newColumns = columns.filter((item) => {
      if (item.key in newPatients[0]) {
        return item;
      }
      return false;
    });
    console.log("Columns => ", newColumns);
    setColumns(newColumns);
  };

  return (
    <div>
      <div
        className="tableviewOuter"
        style={{ maxHeight: 355, overflow: "auto" }}
        id="scroll-max"
      >
        <InfiniteScroll
          dataLength={patientList.length}
          next={fetchPatientList}
          hasMore={hasMore}
          loader={isFetching && <div className="tableviewOuter"
            style={{ maxHeight: 355, overflow: "hidden" }}
          >
            {new Array(5).fill(0).map((_, index) => <Skeleton key={index} height={50} />)}
            <div className="mt-3 mb-3 d-flex flex-row-reverse"><Skeleton height={50} width={200} /></div>
          </div>
          }
          scrollableTarget="scroll-max"
          endMessage={endMessage}
        >
          <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="table">
            <thead>
              <tr>
                {patientList.length
                  ? columns.map((ele,index) => <th key={ele.key}>{ele.title}</th>)
                  : null}
              </tr>
            </thead>
            <tbody>
              {patientList.map((patient, index) => {
                return (
                  <tr key={index}>
                    {columns.map((column) => {
                      return <td>{column.key ==="sex" ? toCapitalize(patient[column.key]):patient[column.key]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      {patientCount ? <div className="mt-3 mb-3 d-flex flex-row-reverse">Showing {1}-{currentPatientCount} of {patientCount}</div> : <div></div>}
    </div>
  );
};

export default InterventionTable;
