import { Breadcrumbs, Chip, Container, Divider, Grid, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Xarrow from "react-xarrows";
import "./DataContentAnalysis.scss";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/lab/Alert";
import withRouter from "../../Utils/withRouterHOC";
import Sidebar from "../../Components/Sidebar/Sidebar";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import services from "../../Services/patientServices";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '100% !important',
    },
    gridContainer1: {
        margin: '10px 0',
    },
    item: {
        display: 'flex',
        justifyContent: 'center',
        width: '200px'
    },
    gridContainer2: {
        margin: '20px 0',
    },
    bigChips: {
        padding: '40px !important',
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '24px',
    }
}));

const FlowDiagram = ()  => {
    const classes = useStyles();

    const [links, setLinks] = useState([])
    const [isFetching, setIsFetching] = useState(true);
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [analytics, setAnalytics] = useState([]);
    const history = useNavigate();

    const getPreviousOutputs = async () => {
        try {
            setIsFetching(true);
            const { data } = await services.getPreviousDcaOutput();
            setFiles(data.files);
            setCategories(data.categories);
            setAnalytics(data.analytics);
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            console.log(error);
        }
    };


    useEffect(() => {
        const _links = [];

        files.forEach(file => {
            file.categoryId.forEach(id => {
                _links.push({ from: file.id, to: id, highlight: false })
            })
        })
        categories.forEach(cat => {
            cat.analyticsIds.forEach(id => {
                _links.push({ from: cat.id, to: id, highlight: false })
            })
        })
        setLinks(_links)

    }, [isFetching, files, categories, analytics])

    useEffect(() => {
        getPreviousOutputs();
    }, [])

    const highlightLines = (id) => {
        const _links = links.slice();
        const data = _links.map(lnk => {
            if (lnk.from === id || lnk.to === id) {
                lnk.highlight = true
            } else {
                lnk.highlight = false
            }
            return lnk
        })
        setLinks([...data])
    }

    return (
        <Sidebar>
            <div className="breadcrums">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link to="/data-content-analysis">
                        <HomeIcon />
                    </Link>
                    <Typography key="3" color="#2993CE">
                        {"View Previous Assessments"}
                    </Typography>
                </Breadcrumbs>
            </div>
            <div className="patient-set-container mr-5 ml-5">
                <Container className={classes.container}>
                    <Divider />
                    <Grid container direction="row"
                        justifyContent="space-between"
                        alignItems="center" className={classes.gridContainer1} >
                        {['Files', 'Content Category', 'Possible Analytics'].map((columnHead, index) => (
                            <Grid key={index} item className={classes.item} ><Typography fontWeight={500} fontSize={'25px'}>{columnHead}</Typography></Grid>
                        ))}
                    </Grid>
                    <Divider />
                    {isFetching ? (<Grid container direction="row"
                        justifyContent="space-between"
                        alignItems="center" className={classes.gridContainer2} >
                        <Grid item>
                            <Grid container rowSpacing={1} direction="column" justifyContent="center"
                                alignItems="center">
                                {new Array(10).fill(0).map((file, index) =>
                                    <Grid key={index} item xs={6} className={classes.item}>
                                        <Skeleton variant="rounded" width={200} height={30} sx={{ margin: "2px" }} />
                                    </Grid>)}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container rowSpacing={5} direction="column" justifyContent="center"
                                alignItems="center">
                                {new Array(4).fill(0).map((category, index) =>
                                    <Grid key={index} item xs={6} className={classes.item}>
                                        <Skeleton variant="rectangular" width={200} height={80} />
                                    </Grid>)}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container rowSpacing={5} direction="column" justifyContent="space-evenly"
                                alignItems="center">
                                {new Array(5).fill(0).map((analytic, index) =>
                                    <Grid key={index} item xs={6} className={classes.item}>
                                        <Skeleton variant="rectangular" width={200} height={60} />
                                    </Grid>)}
                            </Grid>
                        </Grid>
                    </Grid>) : !isFetching && !files.length ? (<Grid container direction="row" alignItems="center" >
                        <Grid item xs={12} >
                            <Alert icon={false} severity="warning">
                                There are no assessments available.
                            </Alert>
                        </Grid>
                    </Grid>) : (<Grid container direction="row"
                        justifyContent="space-between"
                        alignItems="center" className={classes.gridContainer2} >
                        <Grid item>
                            <Grid container rowSpacing={1} direction="column" justifyContent="center"
                                alignItems="center">
                                {files.map((file, index) =>
                                    <Grid key={index} item xs={6} className={classes.item}>
                                        <div id={file.id} onMouseOver={() => highlightLines(file.id)} onClick={() => history(`/data-content-analysis/file-details/${encodeURIComponent(file.fileKey)}/${encodeURIComponent(file.id)}`)}>
                                            <Chip label={file.title} style={{ color: '#0080B2', backgroundColor: '#D9D9D9', cursor: 'pointer' }} />
                                        </div>
                                    </Grid>)}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container rowSpacing={5} direction="column" justifyContent="center"
                                alignItems="center">
                                {categories.map((category, index) =>
                                    <Grid key={index} item xs={6} className={classes.item}>
                                        <div id={category.id} onMouseOver={() => highlightLines(category.id)}>
                                            <Chip label={category.title} className={classes.bigChips} style={{ backgroundColor: category.color, color: '#fff' }} />
                                        </div>
                                    </Grid>)}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container rowSpacing={5} direction="column" justifyContent="space-evenly"
                                alignItems="center">
                                {analytics.map((analytic, index) =>
                                    <Grid key={index} item xs={6} className={classes.item}>
                                        {/* <Tooltip arrow placement="left-end" title='To calculate the FHS Comprehensive Risk Score, the following data types are needed: Demographics, SDoH, Diagnostic Risk'> */}
                                            <div id={analytic.id} onMouseOver={() => highlightLines(analytic.id)}>
                                                <Chip label={analytic.title} className={classes.bigChips} style={{ backgroundColor: analytic.color, color: '#000' }} />
                                            </div>
                                        {/* </Tooltip> */}
                                    </Grid>)}
                            </Grid>
                        </Grid>
                    </Grid>)}
                </Container>
                {!isFetching && links.length > 0 && files.length > 0 && links.map((lnk, i) => (<div key={i} className={`arrow ${lnk.highlight ? 'hover' : ''}`}>
                    <Xarrow
                        start={lnk.from}
                        end={lnk.to}
                        showHead={false}
                        curveness={0.9}
                        strokeWidth={0.5}
                        color="#141414"
                        hover
                    />
                </div>
                ))}
            </div>
        </Sidebar>
    );
}

export default withRouter(FlowDiagram);