import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import SmartAlertDetails from "./SmartAlertDetails";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    maxWidth: "450px",
    margin: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  listContainer: {
    backgroundColor: "#E7E7E7",
    width: "100%",
    maxWidth: "440px",
    borderRadius: "5px",
    padding: "5px 10px !important",
  },
  selectedListContainer: {
    backgroundColor: "#E3F5FF !important",
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "20px solid transparent",
    transform: "rotate(90deg)",
  },
  selectedTriangle: {
    borderBottom: "20px solid #E3F5FF !important",
  },
  detailsContainer: {},
  descriptionText: {
    fontFamily: "Lato",
    fontWeight: 500,
    fontSize: "14px !important",
    lineHeight: "21px",
  },
  sourceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sourceFont: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "12px !important",
    lineHeight: "20px",
  },
  sourceDate: {
    color: "#797979",
  },
  sourceName: {
    color: "#0066CB",
  },
  gridContainer:{
    height: "260px",
    overflow: "auto",
    marginTop: "0 !important"
  },
  skeletonContainer:{
    padding: "0 2% !important"
  }
}));

const AlertList = ({
  id,
  description,
  hccCode,
  date,
  source,
  index,
  selectedIndex,
  handleClick,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainBox} onClick={() => handleClick(index, id)}>
      <Box
        className={`${
          index === selectedIndex ? classes.selectedListContainer : ""
        } ${classes.listContainer}`}
      >
        <Box className={classes.detailsContainer}>
          <Typography className={classes.descriptionText}>
            {description} {source === "Past Claims" ? ` (HCC ${hccCode})` : ``}
          </Typography>
        </Box>
        <Box className={classes.sourceContainer}>
          <Typography className={`${classes.sourceFont} ${classes.sourceDate}`}>
            {date}
          </Typography>
          <Typography className={`${classes.sourceFont} ${classes.sourceName}`}>
            {source}
          </Typography>
        </Box>
      </Box>
      <Box
        className={`${
          index === selectedIndex ? classes.selectedTriangle : ""
        } ${classes.triangle}`}
      ></Box>
    </Box>
  );
};

const SdohData = ({ selectedNotification }) => {
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [allAlerts, setAllAlerts] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);

  const { state } = useContext(WcchcPatientSummaryContext);

  const createUniqueMapping = (dataArray) => {
    const result = {};

    dataArray.forEach(item => {
        if (item.type === "SDOH_IDENTIFIED") {
          const label = item.sdoh_data?.label;
          if (label) {
              if (!result[label]) {
                  result[label] = [];
              }
              result[label].push(item);
          }
        }
    });

    return result;
};

  useEffect(() => { 
    if (state.allSmartAlerts.length) {
      const newData = createUniqueMapping(state.allSmartAlerts);
      setAllAlerts(newData)
      const dataKeys = Object.keys(newData);
      setSelectedItems(newData[selectedNotification?.sdoh_data?.label || dataKeys[0]] || [])
      selectedNotification?.sdoh_data?.label && setSelectedIndex(dataKeys.findIndex((dk) => dk === selectedNotification?.sdoh_data?.label))
    }    
  }, [state.allSmartAlerts, selectedNotification]);  


  const handleClick = (index, details) => {
    setSelectedIndex(index);
    setSelectedItems(details);
  };

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      className={classes.gridContainer}
    >
      {
        state.isFetchingAllSmartAlerts ? (
          <Grid item xs={12}>
            {new Array(5).fill(0).map((_, index) => (
              <Box className={classes.skeletonContainer}>
                <Skeleton key={index} height={50} sx={{width: "100%"}} />
              </Box>
            ))}
          </Grid>
      ): Object.entries(allAlerts).length === 0 ?(
          <>
            <Grid item xs={4} >
              <Box className={classes.mainBox} sx={ { width: "100% !important", textAlign: "center !important" }} >
                <Typography className={classes.descriptionText}>
                  No SDoH Data available
                </Typography>
              </Box>
            </Grid>
          </>
      )
      :
      <>
        <Grid item xs={4}>
          {Object.entries(allAlerts).map((alert, index) => {
            const title = alert[0];
            const details = alert[1];
            const source = details[0]?.sdoh_data.source?.replace(/_/g, " ");

            return (
              <AlertList
                key={index}
                description={title?.replace(/_/g, " ")}
                hccCode={alert[0]}
                date={state.lastUpdatedDate}
                source={source}
                id={alert.id}
                index={index}
                selectedIndex={selectedIndex}
                handleClick={(idx) => handleClick(idx, alert[1])}
              />
            )
          })}
        </Grid>
        <Grid item xs={8}>
          {selectedItems?.map((si, index) => {
            return (
              <SmartAlertDetails
                key={index}
                alert={si}
                selectedIndex={selectedIndex}
              />
            )
          })}
        </Grid>
      </>
      }
    </Grid>
  );
};

export default SdohData;
