import React, { useContext } from "react";
import CancelIcon from '@mui/icons-material/CancelRounded';
import { DropdownFilterContext } from "../../Contexts/dropdownContext";

const PatientSetFilters = () => {
  const { state, dispatch } = useContext(DropdownFilterContext);

  return (
    <div className="modifiers-section mt-2 mb-2 pl-0">
      {
        state.identifiers_checkbox["options_checkbox"].map((filter, id) => {
          if (
            !filter.options.some((opt) =>
              state.identifiers_checkbox["selected_checkboxes"].includes(
                opt.name
              )
            )) {
            return null;
          }

          return (
            <div key={id} className="inner-wrapper">
              <small className="inner-tag">{filter.title}</small>
              <ul>
                {filter.options.map((opt, index) => {
                  if (state.identifiers_checkbox["selected_checkboxes"].includes(opt.name)
                  ) {
                    return (
                      <li key={index}>
                        {
                          opt.selected === 2 ?
                            <small><s>{opt.label}</s></small>
                            :
                            <small>{opt.label}</small>
                        }
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>

          );
        })
      }
      {state.selectedFilters.map((filter) => {
        return !filter.apply ? null : filter.qf ? (
          <div key={filter.key} className="inner-wrapper">
            <small className="inner-tag">{filter.title}</small>
            <ul>
              <li>
                <small>
                  <CancelIcon
                    className="cancel-icon"
                    alt="close"
                    onClick={() =>
                      dispatch({
                        type: "reset-state",
                        payload: { id: filter.key },
                      })
                    }
                  />
                </small>
              </li>
            </ul>
          </div>
        ) : (
            <div key={filter.key} className="inner-wrapper">
              <small className="inner-tag">{filter.title}</small>
              <ul>
                <li>
                  <small>
                    {filter.key === 'cs'
                      ? filter.stratus
                      : filter.to === ""
                        ? `${filter.from}`
                        : filter.from === ""
                          ? `${filter.to}`
                          : filter.key === "dob"
                            ? `${filter.from} to ${filter.to}`
                            : `${filter.from} - ${filter.to}`}
                    {filter.individual === "" ? "" : `${filter.individual}`}
                  </small>
                </li>
              </ul>
            </div>
          );
      })}

    </div>
  );
};

export default PatientSetFilters;
