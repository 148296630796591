import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import Alert from '@mui/lab/Alert';
import Spinner from '../../Components/Spinner/Spinner';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import filterIcon from "../../Assets/Images/filterIcon-black.svg";
import { PyramidContext } from "../../Contexts/uiContext";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toCapitalize, toPluralize, toLowerCase } from "../../Utils/stringOprations";
import EditFilter from "../Modal/EditFilter";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import withRouter from "../../Utils/withRouterHOC";
import { useNavigate } from "react-router-dom";

const FilterTable = ({ patientSets, isFetching, refreshData, savedFilters }) => {
  const { setInfo } = useContext(PyramidContext);
  const { patientSetInfo, updatePatientSetInfo } = useContext(PatientSetContext);
  const { dispatch } = useContext(DropdownFilterContext);
  const history = useNavigate();

  const { patient_name, cohort_name } = JSON.parse(localStorage.getItem("user")).user;

  const [filteredPatientSets, setFilteredPatientSets] = useState(patientSets);
  const [searchInput, setSearchInput] = useState("");
  const [openEditFilter, setOpenEditFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [action, setAction] = useState("");

  const setPatientSet = (patient) => {
    updatePatientSetInfo(patient);
  };

  useEffect(() => {
    setFilteredPatientSets(
      patientSets.filter((patient) =>
        patient.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, patientSets]);

  const setFilter = (filter) => {
    const patient = patientSets?.find((item) => item.patient_set_id === filter.patient_set_id);
    updatePatientSetInfo(patient);
    const filters = JSON.parse(filter?.filters);

    const payload = {
      selectedFilters: filters.selectedFilters,
      selectedFiltersCheckbox: filters.common_fhs_filters,
      nlp_filter: filters.nlp_filter,
      appliedFilterCheckboxes: filters.applied_checkbox,
      inactivePatients: filters.inactivePatients
    };

    dispatch({ type: "load-filters", payload: { ...payload, filterName: filter.name, isSavedFilterApplied: true } });
  };

  const redirectToDashboard = () => {
    setInfo({
      currentui: "/",
      nextui: "",
      prevui: ""
    });
    history('/');
  };

  return (
    <>
      <div className="db-table-ar" style={{ minHeight: "350px" }}>
      <div className="d-flex justify-content-between" >
        <div className="patient-create-set-title mb-2">{toPluralize(toCapitalize(cohort_name))}</div>
        <div className="patient-create-set-title mb-2">
        <div className="searchBar">
          <SearchIcon className="searchIcon" />
          <input
            className="searchInput"
            placeholder="Search by cohort name"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>

        </div> 
      </div>
        <table className="table table-light">
          <thead style={{ borderBottom: '15px solid #FFF'}}>
            <tr>
              <th width = "35%" style={{backgroundColor: '#0082B2', color: '#FFF'}}>
                <div className="headings-wrapper">
                  Name
              </div>
              </th>
              <th width = "18%" style={{backgroundColor: '#0082B2', color: '#FFF'}}>
                <div className="headings-wrapper">
                  Created By
              </div>
              </th>
              <th width = "18%" style={{backgroundColor: '#0082B2', color: '#FFF'}}>
                <div className="headings-wrapper">
                  Created Date
              </div>
              </th>
              <th width = "18%" style={{backgroundColor: '#0082B2', color: '#FFF'}}>
                <div className="headings-wrapper">
                  {toCapitalize(patientSetInfo?.cohort_name)} Coordinator
              </div>
              </th>
              <th style={{backgroundColor: '#0082B2', color: '#FFF'}}>
                <div className="headings-wrapper">
                  Edit
              </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              isFetching
                ? <tr>
                  <td colSpan="5"><Spinner /></td>
                </tr>
                : !isFetching && !filteredPatientSets.length
                  ? <tr>
                      <td colSpan="5">
                        <Alert icon={false} severity="warning">
                          Please inform your management that you have successfully accessed this platform.
                          They can now assign {toPluralize(toLowerCase(patient_name))} to you, which you will be able to access from this page.
                        </Alert>
                      </td>
                    </tr>
                  :<>
                  {
                  filteredPatientSets.map((patient, index) => {
                    return (
                      <tr key={index}>
                        <td width = "35%">
                          <span
                            onClick={() =>  { setPatientSet(patient); redirectToDashboard(); }}
                            className="patient-table-link">
                            <Tooltip 
                              placement="right"
                              title={
                              patient.name
                                ? <span className="mui-tooltip">{patient.name}</span>
                                : <span className="mui-tooltip">{patient.patient_set_id}</span>}
                              interactive="true" arrow>
                              <span>
                                {patient.name
                                  ? patient.name
                                  : patient.patient_set_id}
                              </span>
                            </Tooltip>
                          </span>
                        </td>
                        <td width = "18%">{
                          patient.creator_id === "0x1"
                            ? `All ${toPluralize(patientSetInfo.patient_name)}`
                            : `${patient.creator_name}`
                          }
                        </td>
                        <td width = "18%">{new Date(patient.created)
                          .toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                        
                        <td width = "18%">
                        <Tooltip
                              className="tooltip-container-xl"
                              interactive="true"
                              arrow
                              //classes={{ tooltip: classes.customWidth }}
                              title={
                                patient.care_coordinator_list.length 
                                ? patient.care_coordinator_list.map((item) => {
                                  return (
                                    <span className="mui-tooltip">{item.name}</span>
                                  );
                                }) : ""
                              }
                            >
                              {
                                patient.care_coordinator_list.length > 1
                                  ? <span className="click-hover">Multiple...</span>
                                  : <span className="click-hover">{
                                    patient.care_coordinator_list.length 
                                    ? patient.care_coordinator_list[0].name
                                    : ""}
                                  </span>
                              }
                            </Tooltip>
                        </td>
                        
                        <td>
                        <IconButton 
                          edge="end" 
                          aria-label="edit" 
                            onClick={() => {
                              setPatientSet(patient);
                              history(`/patient-sets?cohort=${patient?.patient_set_id}`)
                            }}
                        >
                          <EditIcon style={{ color: "#0080b2", fontSize: "1.3rem", cursor: "pointer" }} />
                        </IconButton>
                        </td>
                      </tr>
                    );
                  })
                    }
                    {savedFilters.map((filter, index) => {
                  return (
                    <tr key={index}>
                      <td width="35%">
                        <span
                          onClick={() => {
                            setFilter(filter)
                            redirectToDashboard();
                          }}
                          className="patient-table-link"
                        >
                          <Tooltip
                            placement="right"
                            title={filter.name}
                            interactive="true"
                            arrow
                          >
                            <span>{filter.name}</span>
                          </Tooltip>
                        </span>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {}}
                          disabled
                        >
                          <img src={filterIcon} alt="filterIcon" />
                        </IconButton>
                      </td>
                      <td width="18%">{filter.creator_name}</td>
                      <td width="18%">
                        {new Date(filter.created).toLocaleString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>

                      <td width="18%">
                        <span>n/a</span>
                      </td>

                      <td>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {
                            setSelectedFilter(filter);
                            setAction("edit");
                            setOpenEditFilter(true);
                          }}
                        >
                          <EditIcon className="patient-set-edit" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {
                            setSelectedFilter(filter);
                            setAction("delete");
                            setOpenEditFilter(true);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon className="patient-set-trash" />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-row-reverse">
        <div>
            <button
              className="export-Btn"
              onClick={() => {
                refreshData();
              }}
            >
              Refresh
              <span className="ps-table-refresh">
                <RefreshIcon />
              </span>
            </button>
        </div>
      </div>
      <EditFilter open={openEditFilter} setOpen={setOpenEditFilter} filter={selectedFilter} refresh={refreshData} action={ action } />
    </>
  );
};

export default withRouter(FilterTable);
