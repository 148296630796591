import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";

const useStyles = makeStyles((theme) => ({
  mainModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "900px",
    height: "850px",
    maxWidth: "90vw",
    maxHeight: "95vh",
    backgroundColor: "#fff",
    outline: "none",
  },
  header: {
    padding: "11px 12px 9px 18px !important",
    borderBottom: "1px solid #000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "20px !important",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.03em",
    color: "#0080b2",
  },
  headerIcon: {
    fill: "#0080b2 !important",
    cursor: "pointer",
  },
  container: {
    maxHeight: "calc(100% - 70px)",
    overflow: "auto",
  },
  details: {
    padding: "20px 31px 0px 28px !important",
  },
  detailsTitle: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.42px",
    color: "#1B2632",
  },
  detailsDescription: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "-0.42px",
    color: "#1B2632",
  },
  contributorsSection: {
    padding: "0px 9px 0px 18px !important",
    // maxHeight: "275px",
    overflow: "auto",
  },
  contributorTitle: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.42px",
    color: "#1B2632",
    margin: "25px 0px 0px 25px",
  },
  tableMainContainer: {
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
  },
  tableContainer: {
    boxShadow: "none",
    maxHeight: "275px",
  },
  table: {
    // paddingLeft: "15px !important",
    "& > tbody > tr:nth-child(even)": {
      backgroundColor: "#f3f3f3",
    },
    "& > tbody > tr > td:nth-child(even)": {
      width: "60%",
    },
    "& > tbody > tr > td:nth-child(odd)": {
      width: "20%",
    }
  },
  tableRow: {
    "& > td:nth-child(1)": {
      fontWeight: 600,
    },
  },
  tableHeaderCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#060606",
    padding: "9px 6px",
    borderBottom: "0.5px solid #000000 !important",
  },
  tableDataCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "9px 6px",
  },
}));

const ComprehensiveRiskScoreModal = ({
  open = true,
  setComprehensiveRiskModal,
}) => {
  const classes = useStyles();

  const { state } = useContext(WcchcPatientSummaryContext);

  const sdohColumns = ["Category", "Contributor", "Risk Coefficient", "Last Claim Date"];

  const diagColumns = ["HCC Code", "Contributor", "Risk Coefficient", "Last Claim Date"];

  const [sdohData, setSdohData] = useState([]);
  const [diagData, setDiagData] = useState([]);
  const [riskValue, setRiskValue] = useState("");

  useEffect(() => {
    if (state.comprehensiveRiskScore) {
      state?.comprehensiveRiskScore?.comp_risk_score &&
        setRiskValue(state.comprehensiveRiskScore.comp_risk_score);

      const sdohData = state.comprehensiveRiskScore.sdoh_data.map((item) => ({
        category: item.abbr,
        contributor: item.label,
        coefficient: item.coefficient,
        last_claim_date: item.identified_on_date,
      }));

      const diagData = state.comprehensiveRiskScore.diag_data.map((item) => ({
        hcc_code: item.abbr,
        contributor: item.label,
        coefficient: item.coefficient,
        last_claim_date: item.identified_on_date,
      }));

      setSdohData(sdohData);
      setDiagData(diagData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.comprehensiveRiskScore)]);

  return (
    <Modal
      open={open}
      onClose={() => setComprehensiveRiskModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
      sx={{
        outline: "none",
      }}
    >
      <Box className={classes.mainModalContainer} sx={{ border: "none" }}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>
            Comprehensive Risk Score {riskValue ? riskValue : "N/A"}
          </Typography>
          <Close
            onClick={() => setComprehensiveRiskModal(false)}
            className={classes.headerIcon}
          />
        </Box>
        <Box className={classes.container}>
          <Box className={classes.details}>
            <Typography className={classes.detailsTitle}>
              Comprehensive Risk
            </Typography>
            <Typography className={classes.detailsDescription}>
              An organized method of identifying possible risks involved while
              carrying out a particular activity.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.contributorTitle}>
              SDoH Contributors{" "}
            </Typography>
            <Box className={classes.contributorsSection}>
              <Paper className={classes.tableMainContainer}>
                {/* SDOH Contributors table */}
                <TableContainer
                  // sx={{ maxHeight: 459 }}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        {sdohColumns.map((column, index) => (
                          <TableCell
                            key={index}
                            className={classes.tableHeaderCellModal}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sdohData.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            className={classes.tableRow}
                          >
                            {Object.keys(row).map((column) => {
                              return (
                                <TableCell
                                  key={column}
                                  className={classes.tableDataCellModal}
                                >
                                  {row[column]}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.contributorTitle}>
              Diagnostic Contributors{" "}
            </Typography>
            <Box className={classes.contributorsSection}>
              <Paper className={classes.tableMainContainer}>
                {/* DIAG Contributors table */}
                <TableContainer
                  // sx={{ maxHeight: 459 }}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        {diagColumns.map((column, index) => (
                          <TableCell
                            key={index}
                            className={classes.tableHeaderCellModal}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {diagData.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            className={classes.tableRow}
                          >
                            {Object.keys(row).map((column) => {
                              return (
                                <TableCell
                                  key={column}
                                  className={classes.tableDataCellModal}
                                >
                                  {row[column]}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ComprehensiveRiskScoreModal;
