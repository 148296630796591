import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";

import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";
import NoDataMessage from "../../WcchcPatientSummary/NoDataMessage";
import { sortData} from "../../../Utils/wcchcPatientSummary";
import SortButton from "../../WcchcPatientSummary/SortButton";

const useStyles = makeStyles((theme) => ({
  tableMainContainer: {
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
    height: "100%"
  },
  tableContainer: {
    boxShadow: "none",
    maxHeight: "392px",
  },
  tableContainerModal: {
    boxShadow: "none",
    maxHeight: "calc(100% - 24px)",
  },
  table: {
    paddingLeft: "20px !important",
    "& > tbody > tr:nth-child(even)": {
      backgroundColor: "#f3f3f3",
    },
    "& > thead > tr > th:nth-child(1)":{
      paddingLeft: "0px !important"
    },
    "& > tbody > tr > td:nth-child(2)": {
      textAlign: "center",
      paddingRight: "8%"
    },
    "& > tbody > tr > td:nth-child(3)": {
      textAlign: "center",
      paddingRight: "8%"
    },
  },
  tableRow: {
    "& > td:nth-child(1)": {
      fontWeight: 600,
      paddingLeft: "0px !important",
      textAlign:"left !important"
    },
  },
  tableHeaderCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#7D7B7B",
    padding: "8px 6px",
  },
  tableDataCell: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "10px 8px",
    borderBottom: "none",
    textAlign:"left",
  },
  tableHeaderCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#060606",
    padding: "8px 6px",
    borderBottom: "0.5px solid #000000 !important",
  },
  tableDataCellModal: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.03em",
    color: "#1b2632",
    padding: "10px 8px",
    textAlign: "left"
  },
  skeletonDataCell1: {
    width: "40%",
    borderBottom: "none",
    padding: "6px !important",
  },
  skeletonDataCell2: {
    borderBottom: "none",
    padding: "6px !important",
  },
  table2:{
    height: "145px",
    overflow: "hidden"
  },
  label:{
    fontWeight: "700",
    fontFamily: "Lato"
  }
}));

const columns = [
  {label: "Name", sort: false, name: "name"},
  {label: "Diagnosis Date", sort: true, name: "Diagnosis Date"},
  {label: "Last Addressed Date", sort: true, name: "Last Addressed Date"},
  {label: "ICD-10", sort: false, name: "ICD-10 Code"},
  {label: "HCC", sort: false, name: "HCC Code"},
];

const ProblemsIdentifiedTable = ({ modal = false }) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(WcchcPatientSummaryContext);

  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [currentSorting, setCurrentSorting] = useState({});
  const [sortConfig, setSortConfig] = useState([
    { key: 'Diagnosis Date', direction: 'desc', active: false },
    { key: 'Last Addressed Date', direction: 'desc', active: false },
  ]);

  const sortHandler = (key, sortType) => {
    setSortConfig((prevConfig) => {
      const existingConfig = prevConfig.find((config) => config.key === key);
      if (existingConfig) {
        const newDirection = sortType;
        return prevConfig.map((config) =>
          config.key === key ? { ...config, direction: newDirection } : config
        );
      } else {
        return [...prevConfig, { key, direction: sortType }];
      }
    });
    setCurrentSorting({key,sortType, direction: sortType})
  };

  const sortTableData = () => {
    let sortedData = [...finalData];
    const {direction,key} = currentSorting;
    let data = sortData(sortedData, key, direction);

    setData([...data]);
  };

  useEffect(() => { 
    let newData = [];
    if (state.problemsIdentified.results && state.problemsIdentified.results.length) {
      const problems = state.problemsIdentified.results.map(problem => { 
        return {
          "label": problem.label,
          "Diagnosis Date": problem.diagnosis_date,
          "Last Addressed Date": problem.last_addressed_date,
          "ICD-10 Code": problem.icd10_code,
          "HCC Code": problem.hcc_code,
        }
      });
      newData = [...newData, ...problems];
    }
    let data_2 = sortData(newData, "Last Addressed Date", 'desc')
    let data = JSON.parse(JSON.stringify(data_2));
    // let data = [];
    dispatch({ type: "UPDATE_TABLE_LENGTH", payload: {diagnosesandsymptoms: data.length} });
    setData(data);
    setFinalData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.problemsIdentified), JSON.stringify(state.smartAlerts)]);

  useEffect(() =>{
    if(Object.keys(currentSorting)?.length){
        sortTableData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sortConfig])

  if (state.isFetchingProblems) {
    return (
      <Paper className={classes.tableMainContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                {new Array(3).fill(0).map((_, i) => (
                  <TableCell key={i} className={classes.tableHeaderCell}>
                    <Skeleton variant="rounded" width="100%" height={25} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {new Array(12).fill(0).map((_, index) => (
                <TableRow key={index}>
                  {new Array(3).fill(0).map((_, i) => (
                    <TableCell
                      key={i}
                      className={
                        i
                          ? classes.skeletonDataCell2
                          : classes.skeletonDataCell1
                      }
                    >
                      <Skeleton variant="rounded" width="100%" height={25} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Paper className={classes.tableMainContainer}>
      <TableContainer
        className={modal ? classes.tableContainerModal : classes.tableContainer}
      >
        {(data.length) ? (
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                {columns.map(({label, sort, name}, index) => (
                  <TableCell
                    key={index}
                    className={
                      modal
                        ? classes.tableHeaderCellModal
                        : classes.tableHeaderCell
                    }
                  >
                    {
                      sort ?
                      <Box sx={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                        <Typography className={classes.label}>{label}</Typography>
                        <SortButton onClick={sortHandler} name={name} sortConfig={sortConfig}/>
                      </Box>
                      :
                      label
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={classes.tableRow}
                    >
                      {Object.keys(row).map((column, i) => {
                        const value = row[column];
                        return (
                          <TableCell
                            key={i}
                            className={
                              modal
                                ? classes.tableDataCellModal
                                : classes.tableDataCell
                            }
                          >
                            {value ? value : "-"}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        ) : (
            <Table className={classes.table2}>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell className={classes.tableEmptyCell} colSpan={5}>
                    <NoDataMessage 
                        message={"No data available"}
                    />   
                  </TableCell>
                </TableRow>
            </Table>
        )}
      </TableContainer>
    </Paper>
  );
};

export default ProblemsIdentifiedTable;
