import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const CreateUserFeedback = ({ open, setOpen, feedback }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{feedback.message}</DialogTitle>
        <DialogContent>
          <DialogContentText
            className="feedback-icons"
            id="alert-dialog-description"
          >
            <div className="feedback-icons-flex">
              <span>
                {feedback.valid_text ? (
                  <DoneIcon className="done" />
                ) : (
                  <CloseIcon className="close" />
                )}
              </span>
              <span>Sufficient user information for requested action</span>
            </div>
            <div className="feedback-icons-flex">
              <span>
                {feedback.new_email ? (
                  <DoneIcon className="done" />
                ) : (
                  <CloseIcon className="close" />
                )}
              </span>
              <span>User's email was not registered in Cognito yet</span>
            </div>
            <div className="feedback-icons-flex">
              <span>
                {feedback.success_cognito ? (
                  <DoneIcon className="done" />
                ) : (
                  <CloseIcon className="close" />
                )}
              </span>
              <span>Cognito create-user request and send invitation email</span>
            </div>
            <div className="feedback-icons-flex">
              <span>
                {feedback.success_db ? (
                  <DoneIcon className="done" />
                ) : (
                  <CloseIcon className="close" />
                )}
              </span>
              <span>CoreDB actions</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="primary"
            autoFocus
            className="confirm-edit-button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateUserFeedback;
