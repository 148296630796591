import React, { createContext, useState, useEffect } from "react";

const PatientsViewContext = createContext();

const initialState = {
    selected_filter: ''
} 

function PatientViewProvider(props) {
    const [filterOptions, setFilterOptions] = useState(initialState);

    useEffect(() => {
    }, [filterOptions.selected_filter]);

    return (
        <PatientsViewContext.Provider
            value={{
                filterOptions,
                setFilterOptions
            }}
        >
            {props.children}
        </PatientsViewContext.Provider>
    )
}

export { PatientsViewContext, PatientViewProvider };

