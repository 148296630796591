import apiClient from "./apiClient";

const careActivityReport = async (data) => {
  return await apiClient("python").post(`report/careActivity`, data);
};

/* ------------ IF REPORTS API ------------- */

const getCaseloadReportFilter = async (iframe) => {
  return await apiClient("node", iframe).get(
    `if/filters/if-caseload-report`
  );
};

const getCaseloadReportData = async (data, iframe) => {
  return await apiClient("node", iframe).post(`if/if-caseload-report`, data);
};

const getCarePlanReportData = async (data, iframe) => {
  return await apiClient("python", iframe).post(`if/if-care-plan-report`, data);
};

const getCarePlanReportFilter = async (iframe) => {
  return await apiClient("python", iframe).get(
    `if/filters/if-care-plan-report`
  );
};

const getReferralReportFilter = async (iframe) => {
  return await apiClient("python", iframe).get(
    `if/filters/if-referral-report`
  );
};

const getReferralReportData = async (data, iframe) => {
  return await apiClient("python", iframe).post(`if/if-referral-report`, data);
};

const getCareActivityReportData = async (data, iframe) => {
  return await apiClient("node", iframe).post(`if/if-care-activity-report`, data);
};

const getCareActivityReportFilter = async (iframe) => {
  return await apiClient("node", iframe).get(
    `if/filters/if-care-activity-report`
  );
};


/* ------------ IF REPORTS END ------------- */


/* ------------ GENESIS REPORTS API ------------- */

const getClinicalStaffFilter = async (id) => {
  return await apiClient("python").get(
    `genesis/filters/genesis-clinical-staff-service-report?org_guid=${id}`
  );
};

const getStaffServiceReport = async (data) => {
  return await apiClient("python").post(`genesis/genesis-clinical-staff-service-report`, data);
};

const reportPostRequest = async (apiUrl, data, runtime) => {
  runtime = runtime ? runtime : "python";
  return await apiClient(runtime).post(apiUrl, data);
};

const getClientReport = async (data) => {
  return await apiClient("python").post(`genesis/genesis-client-report`, data);
};

const getClientFilter = async (id) => {
  return await apiClient("python").get(
    `genesis/filters/genesis-client-report?org_guid=${id}`
  );
};

// Genesis report api end

const getReportList = async () => {
  return await apiClient().get("organization/report-list");
};

let reportServices = {
  careActivityReport,
  getClinicalStaffFilter,
  getStaffServiceReport,
  reportPostRequest,
  getClientReport,
  getReportList,
  getCaseloadReportFilter,
  getCaseloadReportData,
  getCarePlanReportData,
  getReferralReportData,
  getReferralReportFilter,
  getClientFilter,
  getCarePlanReportFilter,
  getCareActivityReportData,
  getCareActivityReportFilter
};

export default reportServices;
