import React from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
         padding: "10px 15px",
         color: "#7F3C00",
         backgroundColor: "#FFF4E5",
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         fontSize: "14px",
         lineHeight: "20px",
         fontWeight: 700,
    }
}));

const NoDataMessage = ({message, sx}) => {
    const classes = useStyles();
    return (
        <div className={classes.mainContainer} style={sx}>{message}</div>
    )
}

export default NoDataMessage