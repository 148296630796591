import React, { useState } from "react";
import { makeStyles,withStyles } from "@mui/styles";
import services from "../../Services/patientServices";
import TextField from "@mui/material/TextField";
import "./CreateUser.scss";
import swal from "sweetalert";
import { FormControl, MenuItem, Select, InputBase, Autocomplete } from "@mui/material";
const { v4: uuidv4 } = require('uuid');

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    minWidth: "100%",
    borderRadius: 20,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '7px 10px !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 20,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  backdrop: {
    zIndex: 500,
    color: "#78cbf2",
  },
}));

const useStylesName = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 10px 7px 10px !important",
    textAlign: "left",
    color: "#666",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      borderRadius: 20,
      borderColor: "#80bdff",
      backgroundColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  focused: {},
}));

const NameTextField = (props) => {
  const classes = useStylesName();
  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
};

const CreateOrganization = (props) => {
  const classes = useStyles();
  const classes1 = useStylesName();
  const [org_guid, setOrgGuid] = useState("");
  const [selectID, setSelectID] = useState("guid");
  const [fullName, setFullName] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [cohortName, setCohortName] = useState("Cohort");
  const [patientName, setPatientName] = useState("Patient");

  const checkValidity = (params) => {
    const { full_name, display_name, org_patient_id_type, cohort_name, patient_name } = params;
    if (typeof full_name !== "string" || /[0-9]+/g.test(full_name) || "") {
      return { isValid: false, message: "Invalid full name." };
    } else if (typeof display_name !== "string" || /[0-9]+/g.test(display_name) || "") {
      return { isValid: false, message: "Invalid display name." };
    } else if (org_patient_id_type === "int") {
      if (org_guid === "") {
        return { isValid: true };
      } else {
        if (!checkOrgGuid(org_guid)) {
          if (!/^[0-9]+$/.test(org_guid)) {
            return { isValid: false, message: "Invalid org guid." };
          }
        }
      }
    } else if (org_patient_id_type === "guid") {
      if (!checkOrgGuid(org_guid)) {
        if (org_guid === "") {
          return {isValid: true }
        }
        else
          return { isValid: false, message: "Invalid org guid." };
      }
    }
    else if (org_patient_id_type === "") {
      return { isValid: false, message: "Invalid Patient ID Type" };
    }
    // Check here Cohort Custumize Name is required.
    else if (cohort_name === "") {
      return { isValid: false, message: "Invalid Cohort Name" };
    }
     // Check here Patient Custumize Name is required.
     else if (patient_name === "") {
      return { isValid: false, message: "Invalid Patient Name" };
    }
    return { isValid: true };
  };

  function checkOrgGuid(uuid) {
    const isUuid = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return !!isUuid;
  }

  const handleChange = (e) => {
    setSelectID(e.target.value);
  }

  const createOrganization = async () => {
    try {
      let body = {
        full_name: fullName,
        display_name: displayName,
        org_guid: org_guid ? org_guid : uuidv4(),
        org_patient_id_type: selectID,
        cohort_name : cohortName,
        patient_name: patientName
      };
      const validity = checkValidity(body);
      if (!validity.isValid) {
        swal(validity.message, "", "error");
        return;
      };
      await services.createOrganization(body);
      swal("Success!", "Organization created successfully!", "success");
      setOrgGuid("");
      setSelectID("");
      setFullName("");
      setDisplayName("");
      setCohortName("Cohort");
      setPatientName("Patient");
    }
    catch (error) {
      swal("Oops!", error.response.data.message, "error");
    }
  }
  
  return (
      <div className={classes.paper}>
        <div className="patient-create-set-title">Create Organization</div>
      <form className={classes.root} noValidate autoComplete="off">
        <div className="mui-textfield-form">
          <label className="form-label">Organization Patient ID Type *</label>
          <FormControl className="input-field-size patientid-select">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={selectID}
              onChange={handleChange}
              input={<BootstrapInput />}
              displayEmpty
              MenuProps={{disableScrollLock: true}}
            >
              <MenuItem value="guid">GUID</MenuItem>
              <MenuItem value="int">Integer</MenuItem>
            </Select>
          </FormControl>
          </div>
          <div className="mui-textfield-form">
          <label className="form-label">Organization ID</label>
            <NameTextField
              className="input-field-size"
              defaultValue={org_guid}
              multiline
              variant="filled"
              onChange={(e) => {
                setOrgGuid(e.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              name="org_guid"
              value={org_guid}
            />
          </div>
          <div className="mui-textfield-form">
            <label className="form-label">Full Name *</label>
            <NameTextField
              className="input-field-size"
              defaultValue={fullName}
              multiline
              variant="filled"
              onChange={(e) => {
                setFullName(e.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              name="fullName"
              value={fullName}
            />
          </div>
          <div className="mui-textfield-form">
            <label className="form-label">Display Name *</label>
            <NameTextField
              multiline
              className="input-field-size"
              defaultValue={displayName}
              variant="filled"
              onChange={(e) => {
                setDisplayName(e.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              name="displayName"
              value={displayName}
            />
          </div>{" "}
          <div className="mui-textfield-form">
            <label className="form-label">Cohort Name *</label>
            <Autocomplete
              id="cohort-name"
              value={cohortName}
              onChange={(event, newValue) => {
                if(newValue){
                  setCohortName(newValue)
                }
            }}
            freeSolo
              options={["Cohort", "Patient Set", "Caseload"]}
              renderInput={(params) => {
                return <TextField {...params}
                  variant="filled"
                  className="input-field-size"
                  onChange={(event)=>{
                    setCohortName(event.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    classes: classes1,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 15
                  }}
                />
              }}
            />
          </div>
           {/* Custumizable Patient name input field */}
           <div className="mui-textfield-form">
            <label className="form-label">Patient Name *</label>
            <Autocomplete
              id="patient-name"
              value={patientName}
              onChange={(event, newValue) => {
                if(newValue){
                  setPatientName(newValue)
                }
            }}
            freeSolo
              options={["Patient", "Member"]}
              renderInput={(params) => {
                return <TextField {...params}
                  variant="filled"
                  className="input-field-size"
                  onChange={(event)=>{
                    setPatientName(event.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    classes: classes1,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 15
                  }}
                />
              }}
            />
          </div>
        </form>
        <div className="d-flex flex-row-reverse">
            <button className="tblBtn mt-4" onClick={() => { createOrganization() }}>
              Create Organization
            </button>
        </div>
      </div>
  );
};

export default CreateOrganization;
