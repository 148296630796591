import React from "react";
import "./ReportFooter.scss";

const ReportFooter = () => {
  return (
    <div>
      <div className="reportContainer" style={{ paddingBottom: "25px" }}>
        <div className="footerHead">
          Powered by
          <a
            className="logo"
            href="https://www.foresighthealthsolutions.com/"
            target="_blank"
            rel="noreferrer"
          >
            FHS
          </a>
        </div>
      </div>
    </div>
  );
};
export default ReportFooter;
