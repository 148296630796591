import React from 'react';
import { Box, Typography } from "@mui/material";
import ExpandButton from "./ExpandButton";

const SdohAnalysisCard = ({setSdohAnalysisModal}) => {
  return (
    <Box className="sdoh-analysis-card">
        <Box className="left-container">
        <Typography className="smart-alert-text">
            SDoH Analysis
        </Typography>
        </Box>
        <Box className="right-container">
        <ExpandButton
            onClick={() => {
                setSdohAnalysisModal(true);
            }}
        />
        </Box>
    </Box>
  )
}

export default SdohAnalysisCard