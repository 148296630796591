// filter progression chart data to get 1 year data as selected by user
const progressionChartValues = (data, type, selected_date) => {
    const chartData = data;
    const startDate = new Date(selected_date)
    const endDate = createDate(selected_date, 0, 0, 1)
    chartData[type].riskData = chartData[type].riskData = chartData[type].riskData.filter((item) => {
        if (new Date(item.date).getTime() <= startDate.getTime() && new Date(item.date).getTime() >= endDate.getTime()) {
            return true;
        }
        return false;
    })
    return chartData;
}

const createDate = (dateString, days, month, year) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - days);
    date.setMonth(date.getMonth() - month);
    date.setFullYear(date.getFullYear() - year);
    return date;
} 

export default progressionChartValues;
