import React, { useContext, useEffect, useCallback, memo } from "react";
import { PyramidContext } from "../../../Contexts/uiContext";
import "../Charts.css";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

const PyramidChart = (props) => {
  const history = useNavigate();
  const { type, pyramidValues, reportIframe } = props;
  const { info, setInfo } = useContext(PyramidContext);


  useEffect(() => {
  }, [props, pyramidValues, info]);

  const updateRequestDetailed = useCallback((ele) => {
    setInfo({
      ...info,
      pyramidvalue: ele.label,
      pyramidlevel: ele.level,
    });
    props.fetchdata(ele.level);
  }, [info, setInfo, props]);

  const updateRequestOverview = useCallback((ele) => {
    let riskLabel;
    if(props?.riskKeyName === "imp"){
      riskLabel = ele.risk === 'low' ? 'lowest' : ele.risk === 'critical' ? 'highest' : ele.risk;
    }else{
      riskLabel =  ele.risk;
    }
    setInfo({
      key: props.riskKeyName,
      currentui: "patientview",
      prevui: "stats",
      interventionName: '',
      pyramidvalue: ele.label,
      pyramidlevel: ele.level,
      pyramidRiskStatus: riskLabel,
    });
    history('/patient-list', {
      state: { uititle: "Patients in " + (getTitle(ele)) + " Stratus" }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInfo, info.currentui]);

  let renderPyramid;

  const getTitle = (data) => {
    let title = `${data.risk} risk`;
    if (props.riskKeyName === "imp") {
      title = `${data.risk === 'low' ? 'lowest' : data.risk === 'critical' ? 'highest' : data.risk} impactability`
    }
    return title;
  }

  if (type === "overview") {
    renderPyramid = pyramidValues.map((ele,key) => (
      
      <Tooltip
        key={key}
        title={getTitle(ele)}
        placement="top"
        arrow
      >
        <div
          key={key}
          className={ele.name}
          onClick={reportIframe ? null : () => updateRequestOverview(ele)}
        >
          {ele.label}
        </div>
      </Tooltip>
    ));
  } else {
    renderPyramid = pyramidValues.map((ele, key) => (
      <div
        key={key}
        className={ele.name}
        onClick={() => updateRequestDetailed(ele)}
      >
        {ele.label}
      </div>
    ));
  }

  return (
    <>
      <div className={type === "overview" ? "triangle_ov" : "triangle_detail"}>
        {renderPyramid}
      </div>
    </>
  );
};
export default memo(PyramidChart);
