import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "../GenerateReport.scss";
import Sidebar from "../../../Components/Sidebar/Sidebar";
// import {MenuItem, Select} from "@mui/material";
import {FormControl, TextField, Button, Autocomplete  } from "@mui/material";
import reportServices from "../../../Services/reportServices";
import BackdropLoader from "../../../Components/Backdrop/Backdrop";
import { formateDateWithTimezone } from "../../../Utils/formatDate";
import DatePicker2 from "../../../Components/DatePicker/DatePicker2";
import BackButton from "../../../Components/BackButton/BackButton";

const GenerateClinicalStaffReport = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [staff, setStaff] = useState("All");
  const [staffList, setStaffList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateError, setDateError] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  // const [program, setProgram] = useState("");
  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;

  const history = useNavigate();

  useEffect(() => {
    compareDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    getReportFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var getDayOfMonth = (date, no, day) => {
    const prevMonth = date.getMonth() - no;
    const dayOfMonth = day === "firstDay" ? 1 : 0;
    return new Date(date.getFullYear(), prevMonth, dayOfMonth);
  };

  const onStartDateChange = (date) => {
    setStartDate(new Date(date));
  };

  const onEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const compareDate = () => {
    if (endDate < startDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const getOptionLabel = (option) => {
    if (typeof option === "string") {
      return option;
    }
    return ` ${option.last_name}, ${option.first_name}`;
  };

  const getReportFilters = async () => {
    try {
      setIsFetching(true);
      const response = await reportServices.getClinicalStaffFilter(org_guid);
      const { data } = response.data;
      setStaffList(data.staff_list);
      const max_date = new Date(data.max_date);
      const one_year_back_date = getDayOfMonth(max_date, 12, "firstDay");
      setStartDate(one_year_back_date);
      setEndDate(max_date);
      setMaxDate(max_date);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false)
    }
  };

  const generateReport = async () => {
    try {
      if (dateError) return;
      const todayDate = formateDateWithTimezone(new Date());
      setIsFetching(true);
      const requestBody = {
        end_date: endDate,
        start_date: startDate,
        staff_id: staff === "All" ? "" : staff ? staff.id : "",
        staff_name: staff === "All" ? "" : staff ? staff.name : "",
        program: "",
        org_guid: org_guid,
        created_at: todayDate,
      };
      const response = await reportServices.getStaffServiceReport(requestBody);
      const { data } = response.data;
      localStorage.setItem("report_data", JSON.stringify(data));
      setIsFetching(false);
      history("/genesis/report/clinical-staff");
    } catch (error) {
      setIsFetching(false);
    }
  };

  return (
    <>
      <Sidebar>
        <div className="uploadpage app-bg-color DashboardOuter rp-overflow">
          <div className="btn-back">
            <BackButton color={"#fff"} backgroundColor={"#0082b2"}/>
          </div>
          <div className="upload-outer">
            <div className="upload-inner widget-bg-shadow">
              <div className="report-title">Clinical Staff Service Report</div>
              <div className="query-container">
                <div>
                  <div className="query-label">Start Date:</div>
                  <div className="query-value">
                    <DatePicker2
                      onChange={() => false}
                      onAccept={onStartDateChange}
                      value={startDate}
                      inputFormat="MM/DD/YYYY"
                      className="datepicker"
                      maxDate={maxDate}
                    />
                  </div>
                </div>
                <div>
                  <div className="query-label">End Date:</div>
                  <div className="query-value">
                    <DatePicker2
                      onChange={() => false}
                      onAccept={onEndDateChange}
                      value={endDate}
                      inputFormat="MM/DD/YYYY"
                      className="datepicker"
                      maxDate={maxDate}
                    />
                    {dateError ? (
                      <div className="error">
                        End Date should be greater than Start Date
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className="query-label">Staff (Optional):</div>
                  <div className="query-value">
                    <FormControl
                      sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["All"].concat(staffList.map((item) => item))}
                        getOptionLabel={getOptionLabel}
                        className="select-box"
                        renderInput={(params) => <TextField {...params} />}
                        value={staff}
                        defaultValue="All"
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          setStaff(newValue);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="upload-submit">
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  disabled={isFetching}
                  onClick={generateReport}
                >
                  Generate Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
      <BackdropLoader isFetching={isFetching} />
    </>
  );
};

export default GenerateClinicalStaffReport;
