import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  Typography,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import arraySupport from "dayjs/plugin/arraySupport";
import "./DataContentAnalysis.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useParams } from "react-router-dom";
import services from "../../Services/patientServices";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(arraySupport);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflow: "auto",
  },
  tableContainer: {
    maxHeight: 400, // Set the maximum height for the table container
    overflowY: "auto", // Enable vertical scrolling
  },
  tableHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
  },
}));

const DcaOutput = () => {
  const history = useNavigate();
  const [fileDetails, setFileDetails] = useState(null);
  const [showQuery, setShowQuery] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const classes = useStyles();
  const params = useParams();

  const getDcaOutput = async () => {
    setIsFetching(true);
    try {
      const { data } = await services.getPreviousDcaOutput(params.file_name);
      if (data.length) {
        setFileDetails(data[0]?.dca_output);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getDcaOutput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("dca", fileDetails);

  useEffect(() => {
    if (fileDetails) {
      const showQueryData = Object.assign(
        ...fileDetails["Analyzed Files"].map((file, index) => ({
          [index]: false,
        }))
      );
      setShowQuery(showQueryData);
    }
  }, [fileDetails]);

  if (isFetching || !fileDetails) {
    return (
      <Sidebar>
        <div className="dashMidCont">
          <div
            className="container clearfix d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <div>
              <Backdrop
                open={isFetching}
                style={{
                  backgroundColor: "#f4f6f8",
                  zIndex: 500,
                  color: "#6baaed",
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
        </div>
      </Sidebar>
    );
  }
  return (
    <Sidebar>
      <div className="uploadpage file-outer">
        <div className="app-bg-color">
          <div className="list-outer">
            <div
              className="upload-inner widget-bg-shadow"
              style={{ height: "580px", overflow: "scroll" }} //border-radius
            >
              <div className="upload-home mt-2">
                <BackIcon />
                <span onClick={() => history(-1)}>
                  Back to Previous Assessments
                </span>
              </div>
              <div className="upload-title mt-2 bold">DCA Output</div>
              <div
                className="upload-subtitle"
                hidden={Boolean(
                  Object.keys(showQuery).filter(
                    (key) => showQuery[key] === true
                  ).length
                )}
              >
                Please select any file for detailed assessment.
              </div>
              <Paper className={classes.paper}>
                {fileDetails["Analyzed Files"] &&
                  fileDetails["Analyzed Files"].map((file, fileIndex) => {
                    let fileUploadDateArray = "";
                    if (file?.flag === "Error") {
                      fileUploadDateArray = fileDetails["Input Files"].find(({ filename }) =>
                        filename === file.Filename)["upload date"].split("_")
                      fileUploadDateArray[1] = fileUploadDateArray[1] - 1;
                    } else {
                      fileUploadDateArray = fileDetails["Input Files"].find(({ filename }) =>
                        filename === file.table.Filename)["upload date"].split("_")
                      fileUploadDateArray[1] = fileUploadDateArray[1] - 1;
                    }
                    return (
                      <Accordion
                        key={fileIndex}
                        onChange={(e, expanded) => {
                          if (expanded) {
                            setShowQuery({ ...showQuery, [fileIndex]: true });
                          } else {
                            setShowQuery({ ...showQuery, [fileIndex]: false });
                          }
                        }}
                      >
                        <AccordionSummary>
                          <div className="dca-accordion-summary">
                            {file?.table?.["file_flag"] === "Right Tick" && (
                              <Tooltip title={file.table.file_flag_message} arrow>
                                <CheckCircleIcon className="dca-accordion-success" />
                              </Tooltip>
                            )}
                            {file?.table?.["file_flag"] === "Warning" && (
                              <Tooltip title={file.table.file_flag_message} arrow>
                                <WarningIcon className="dca-accordion-warning" />
                              </Tooltip>
                            )}
                            {(file["flag"] || file?.table["file_flag"]) === "Error" && (
                              <Tooltip title={file?.flag ? file?.flag_message : file.table.file_flag_message} arrow>
                                <ErrorIcon className="dca-accordion-error" />
                              </Tooltip>
                            )}
                            <Typography
                              sx={{ fontWeight: "bold", marginLeft: "10px" }}
                            >
                              {file?.Filename ? file?.Filename : file.table.Filename}
                            </Typography>
                            {/* <Typography sx={{ marginLeft: "10px" }}>
                              {dayjs(fileUploadDateArray)
                                .utc()
                                .tz(dayjs.tz.guess())
                                .format("MM/DD/YYYY HH:mm z")}
                            </Typography> */}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <Typography style={{ marginRight: "25px" }}>
                                Uploaded On:{" "}
                                {dayjs(fileUploadDateArray)
                                  .utc()
                                  .tz(dayjs.tz.guess())
                                  .format("MM/DD/YYYY HH:mm z")}
                              </Typography>
                              <Typography>
                                Uploaded By: {fileDetails["Uploaded by User"]}
                              </Typography>
                            </div>
                            {!((file?.["flag"] || file?.table?.file_flag) === "Error") ? (
                              <Typography>
                                Column: 7 Rows:{" "}
                                {Object.keys(file["variables"]).length}
                              </Typography>
                            ) : null}

                            {/* <Typography>
                              Service Dates: {fileDetails["Data Drop Start Date"]}{" "}
                              to {fileDetails["Data Drop End Date"]}
                            </Typography> */}
                          </div>
                          {!((file?.["flag"] || file?.table?.file_flag) === "Error") ? (<TableContainer
                            className={classes.tableContainer}
                            component={Paper}
                            sx={{ m: 1 }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow className={classes.tableHeader}>
                                  <TableCell>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      Column
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>Completeness</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>Data Type</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>Min</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>Max</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>Unique Values</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>NULL Count</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography>Not NULL Count</Typography>
                                  </TableCell>
                                  {/* <TableCell><Typography>Common Values</Typography></TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(file["variables"]).map(
                                  (key, index) => {
                                    const attribute = file.variables;
                                    return (
                                      <TableRow key={index}>
                                        <TableCell width="25%">
                                          <Typography>{key}</Typography>
                                        </TableCell>
                                        <TableCell>{`${Number(
                                          100 - attribute[key]["p_missing"] * 100
                                        ).toFixed(0)}%`}</TableCell>
                                        <TableCell>
                                          {attribute[key]["type"]}
                                        </TableCell>
                                        <TableCell>
                                          {attribute[key]["type"] === "Numeric"
                                            ? attribute[key]["min"]
                                            : "-"}
                                        </TableCell>
                                        <TableCell>
                                          {attribute[key]["type"] === "Numeric"
                                            ? attribute[key]["max"]
                                            : "-"}
                                        </TableCell>
                                        <TableCell>
                                          {attribute[key]["n_distinct"]}
                                        </TableCell>
                                        <TableCell>
                                          {attribute[key]["n_missing"]}
                                        </TableCell>
                                        <TableCell>
                                          {attribute[key]["count"]}
                                        </TableCell>
                                        {/* <TableCell>
                                        <div style={{display:'flex'}}>
                                        {
                                            Object.keys(attribute[key]["common values"][0]).map((commonValueKey, i) => (
                                                <Typography key={i} component="span">
                                                    {commonValueKey}: {attribute[key]["common values"][0][commonValueKey]}{i === ([Object.keys(attribute[key]["common values"][0]).length]-1) ? "" : ","}&nbsp;
                                                </Typography>
                                            ))
                                        }
                                    </div>
                                    </TableCell> */}
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>) : null}
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default DcaOutput;
