import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/DashboardHeader";
import services from "../../Services/patientServices";
import { makeStyles, withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Checkbox from "@mui/material/Checkbox";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import ConfirmEdit from "../../Components/Modal/ConfirmEdit";
import PatientSetTable from "../../Components/Tables/PatientSetTable";
import CreateCohort from "../CreateUser/CreateCohort";
import "./PatientSets.scss";
import { toCapitalize, toPluralize } from "../../Utils/stringOprations";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import FileUploadWidget from "../FileUpload/FileUploadWidget";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  heading: {
    fontWeight: "600",
    fontSize: "0.9rem",
    marginBottom: "10px",
    textAlign: "left",
  },
  row: {
    padding: "10px",
  },
  chip: {
    margin: theme.spacing(0.5),
    "&.MuiChip-clickableColorPrimary": {
      backgroundColor: "#0080b2",
    },
  },
  chipFlex: {
    display: "flex",
    justifyContent: "center",
  },
  backdrop1: {
    zIndex: 500,
    color: "#78cbf2",
  },
  backdrop2: {
    zIndex: 500,
    color: "#78cbf2",
    backgroundColor: "#fff",
  },
  fab: {
    backgroundColor: "#0080b2",
    marginTop: "15px",
    width: "50px !important",
    height: "50px",

    "&:hover": {
      backgroundColor: "#0080b2",
    },
  },
  formControl: {
    width: "300px",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    textAlign: "left",
    color: "#666",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 20,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStylesName = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "11px !important",
    textAlign: "left",
    color: "#666",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      borderRadius: 20,
      borderColor: "#80bdff",
      backgroundColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  focused: {},
}));

const NameTextField = (props) => {
  const classes = useStylesName();
  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
};

var options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};


const PatientSets = ()  => {
  const params = new URLSearchParams(typeof window !== "undefined" ? window.location.search.substring(1) : " ");
  const cohortId = params.get("cohort") || "";
  const {patientSetInfo, updatePatientSetInfo} = useContext(PatientSetContext);
  const history = useNavigate();

  const [patientSetInfoLocal, setPatientSetInfoLocal] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isFetching, setisFetching] = useState(false);
  const [name, setName] = useState("");
  const [fetchingState, setFetchingSate] = useState(false);
  const [refreshingPatientSet, setRefreshingPatientSet] = useState(false);
  const [patientSets, setPatientSets] = useState([]);
  const [savedFilters, setSavedFilters] = useState([]);
  const classes = useStyles();
  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;

  const fetchData = async (cohortId) => {
    // const { patient_set_id } = JSON.parse(localStorage.getItem("patient_set"));
    const data = await services.patientSet(cohortId);
    console.log("Patient set data => ", data);

    //Merge patient set data into localstorage
    const pset = data.data.patient_set;
    pset.selected_date = new Date(pset.last_updated).toISOString().slice(0, 19).replace('T', ' ');
    pset.last_updated = new Date(pset.last_updated).toISOString().slice(0, 19).replace('T', ' ');
    updatePatientSetInfo({...patientSetInfo, ...pset});
    // localStorage.setItem('patient_set', JSON.stringify(pset));

    //Set patient set metadata
    setPatientSetInfoLocal(data.data.patient_set);
    setName(data.data.patient_set.name);

    //Set selected i.e displayed users in the table
    setSelectedUsers(data.data.selected_users);

    //Set available i.e optional users for the drop-down
    setAvailableUsers(data.data.available_users);
  };

  const refreshData = async () => { 
    fetchPatientSets();
    fetchPatientSetFilters();
  }

  const fetchPatientSets = async () => {
    try {
      setRefreshingPatientSet(true);
      const result = await services.patientSets();
      console.log("Data => ", result);
      let { data } = result.data;
      if(!data.length) {
        setRefreshingPatientSet(false)
        return []
      }
      data = data.map((item) => {
        item.selectable_dates = item.selectable_dates.map(el => el.service_date_end.slice(0, 10));
        return item;
      })
      setPatientSets(data);
      setRefreshingPatientSet(false);
      console.log("Patient sets data => ", data);
      return result;
    } catch (err) {
      console.log(err);
      console.log('Error message => ', err.message);
    }
  };

  const fetchPatientSetFilters = async () => {
    try {
      setFetchingSate(true);
      const result = await services.getSavedFilter();
      const { saved_filters } = result.data;
      setSavedFilters(saved_filters);
      setFetchingSate(false);
      return result;
    } catch (err) {
      console.log('Error message => ', err.message);
    }
  }

  const addRow = () => {
    if (selectedUsers.find((item) => !item.user_id)) return;
    const newRows = [
      ...selectedUsers,
      {
        user_id: null,
        first_name: "",
        last_name: "",
        care_coordinator: "N",
        view_all: "N",
        view_no_personal: "N",
        edit_all: "N",
        manage: "N"
      },
    ];
    setSelectedUsers(newRows);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleNameChange(name);
    }
  };

  const handleNameChange = () => {
    if(name === patientSetInfoLocal.name) return;
    editPatientSet('name', { name });
  };

  const handleDropdownChange = (e) => {
    //Find the selected user in the available user list
    const findUser = availableUsers.find(
      (item) => item.user_id === e.target.value
    );

    //Add the selected user to the selected user list
    const newData = selectedUsers.map((item) => {
      if (item.user_id) return item;
      return {
        ...item,
        ...findUser,
      };
    });
    setSelectedUsers(newData);
  };

  const mapPermissionType = {
    view_all: "viewing",
    view_no_personal: "viewing",
    edit_all: "editing",
    manage: "management",
    is_care_coordinator: "care coordinator"
  };

  const managePermissionChange = (e, user_id) => {
    const type = e.target.name;
    const findUser = selectedUsers.find((item) => item.user_id === user_id);
    const check = findUser[type] === "Y" ? false : true;
    const user = JSON.parse(localStorage.getItem("user"));
    const current_user_id = user.user.user_id;
    if (!check && user_id === current_user_id) {
      const name = mapPermissionType[type];
      setModalData({ name, type, user_id });
      setOpen(true);
    } else {
      handlePermissionChange(type, user_id);
    }
  };

  const handlePermissionChange = (type, user_id) => {
    const user = selectedUsers.find(item => item.user_id === user_id);
    const check = user[type] === "Y" ? false : true;
    const checkboxes = filterCheckboxes(type, check);
    var updatedUser = {};
    Object.keys(user).forEach((key) => {
      if (checkboxes.includes(key)) {
        updatedUser[key] = check ? "Y" : "N";
      } else {
        updatedUser[key] = user[key];
      }
    });
    const { is_care_coordinator, view_all, view_no_personal, edit_all, manage } = updatedUser;
    const permissions = { is_care_coordinator, view_all, view_no_personal, edit_all, manage };
    editPatientSet('update_user_permission', { cohort_user_id: updatedUser.user_id, permissions });
  };

  const filterCheckboxes = (type, check) => {
    // 1. Selecting “edit all” automatically selects the other two.
    // 2. Selecting “view all” automatically selects “view, no PD”.
    // 3. De-selecting “view, no PD” de-selects the other two.
    // 4. De-selecting “view all” de-selects “edit all”.
    console.log(type, check);

    if (type === "edit_all" && check) {
      return ["view_no_personal", "view_all", "edit_all"];
    } else if (type === "view_all" && check) {
      return ["view_no_personal", "view_all"];
    } else if (type === "view_no_personal" && !check) {
      return ["view_no_personal", "view_all", "edit_all"];
    } else if (type === "view_all" && !check) {
      return ["view_all", "edit_all"];
    } else {
      return [type];
    }
  };

  const deleteRow = (user_id) => {
    if (selectedUsers.length === 1) return;

    const findSelectedUser = selectedUsers.find(item => item.user_id === user_id);
    const findAvailableUser = availableUsers.find((item) => item.user_id === user_id);

    if(findAvailableUser && findAvailableUser.user_id) {
      if(!findSelectedUser.user_id || (findSelectedUser.user_id === findAvailableUser.user_id)) {
        const newData = selectedUsers.filter((item) => item.user_id !== user_id);
        setSelectedUsers(newData);
      }
      else {
        editPatientSet('delete_user_permission', { cohort_user_id: findSelectedUser.user_id });
      }
    }
    else if(findSelectedUser.user_id) {
      editPatientSet('delete_user_permission', { cohort_user_id: findSelectedUser.user_id });
    }
    else {
      const newData = selectedUsers.filter((item) => item.user_id !== user_id);
      setSelectedUsers(newData);
    }
  };

  useEffect(() => {
    if (cohortId) {
      fetchData(cohortId);
    } else {
      fetchPatientSets();
      fetchPatientSetFilters();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId]);

  const editPatientSet = async (type, body) => {
    //type => name/update_user_permission/delete_user_permission
    try {
      setisFetching(true);
      body.pid = patientSetInfoLocal.patient_set_id;
      body.type = type;
      const data = await services.editPatientSet(body);
      console.log("Edit patient set api response => ", data);
  
      //Update metadata and table data
      await fetchData(patientSetInfoLocal.patient_set_id);
  
      setisFetching(false);
    }
    catch(error) {
      console.log(error);
      setisFetching(false);
    }
  };

  const handleBackToPatientSet = () => {
    setPatientSetInfoLocal(null);
    setName("");
    setSelectedUsers([]);
    setAvailableUsers([]);
    history("/patient-sets");
  }

  return (
    <div className="homepage editPage DashboardOuter app-bg-color">
      <div>
        <Header editPage={true} />
      </div>
      {cohortId && patientSetInfoLocal ? (
        <div className="patient-set-outer">
          <div className="patient-set-inner">
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="patient-set-title">
                <EditIcon className="mr-1" />
                <span style={{ fontSize: 18 }}>
                  Edit {toCapitalize(patientSetInfoLocal.cohort_name)}
                </span>
              </div>
              <div className="tblBtn mt-0 mb-3">
                <button
                  className="backToPatientSet"
                  onClick={() => handleBackToPatientSet()}
                >
                  <span className="ps-table-refresh">
                    <ArrowBackIcon />
                  </span>
                  Back To{" "}
                  {toPluralize(toCapitalize(patientSetInfo.cohort_name))}
                </button>
              </div>
            </Grid>
            <Divider className="mb-4" />
            <div className="mt-2">
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Paper className={classes.paper} variant="outlined">
                    <div>
                      <List className={classes.root}>
                        <ListItem>
                          <ListItemText
                            primary={`${toCapitalize(
                              patientSetInfo.cohort_name
                            )} Name`}
                            secondary={
                              <div className="mui-textfield-form">
                                <NameTextField
                                  multiline
                                  //rowsMax={Infinity}
                                  defaultValue={name}
                                  variant="filled"
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                  onKeyDown={handleKeyDown}
                                  onBlur={handleNameChange}
                                  inputProps={{ maxLength: 100 }}
                                />
                              </div>
                            }
                          />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={`${toCapitalize(
                              patientSetInfoLocal.cohort_name
                            )} ID`}
                            secondary={patientSetInfoLocal.patient_set_id}
                          />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary="Creation Date"
                            secondary={new Date(
                              patientSetInfoLocal.created
                            ).toLocaleDateString("en", options)}
                          />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary="Creator Name"
                            secondary={patientSetInfoLocal.creator_name}
                          />
                        </ListItem>
                      </List>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={9}>
                  <Paper className={classes.paper} variant="outlined">
                    <div className={classes.row}>
                      <table className="edit-table">
                        <thead>
                          <tr>
                            <th width="40%">User</th>
                            <th width="12%">
                              {toCapitalize(patientSetInfoLocal.cohort_name)}{" "}
                              Coordinator
                            </th>
                            <th width="12%">View, No Personal Data</th>
                            <th width="12%">View All</th>
                            <th width="12%">Edit All</th>
                            <th width="12%">Manage</th>
                            <th width="12%">Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedUsers.map((item) => {
                            return (
                              <tr className="mt-1" key={item.user_id}>
                                <td width="40%">
                                  {item.user_id ? (
                                    <div>
                                      <span className="edit-table-user">{`${item.first_name} ${item.last_name}`}</span>
                                    </div>
                                  ) : (
                                    <div>
                                      <FormControl
                                        className={classes.formControl}
                                      >
                                        <Select
                                          defaultValue={"none"}
                                          disabled={false}
                                          onChange={handleDropdownChange}
                                          input={<BootstrapInput />}
                                          MenuProps={{
                                            disableScrollLock: true,
                                          }}
                                        >
                                          {!availableUsers.length ? (
                                            <MenuItem value="none">
                                              No additional users in your
                                              organization.
                                            </MenuItem>
                                          ) : (
                                            <MenuItem value="none">
                                              Select User
                                            </MenuItem>
                                          )}
                                          {availableUsers.map((item) => {
                                            return (
                                              <MenuItem value={item.user_id}>
                                                {`${item.first_name} ${item.last_name}`}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  )}
                                </td>
                                <td width="12%">
                                  <Checkbox
                                    checked={
                                      item.is_care_coordinator === "Y"
                                        ? true
                                        : false
                                    }
                                    disabled={item.user_id ? false : true}
                                    onChange={(e) => {
                                      managePermissionChange(e, item.user_id);
                                    }}
                                    name={"is_care_coordinator"}
                                    style={{
                                      color: "#0080b2",
                                    }}
                                  />
                                </td>
                                <td width="12%">
                                  <Checkbox
                                    checked={
                                      item.view_no_personal === "Y"
                                        ? true
                                        : false
                                    }
                                    disabled={item.user_id ? false : true}
                                    onChange={(e) => {
                                      managePermissionChange(e, item.user_id);
                                    }}
                                    name={"view_no_personal"}
                                    style={{
                                      color: "#0080b2",
                                    }}
                                  />
                                </td>
                                <td width="12%">
                                  <Checkbox
                                    checked={
                                      item.view_all === "Y" ? true : false
                                    }
                                    disabled={item.user_id ? false : true}
                                    onChange={(e) => {
                                      managePermissionChange(e, item.user_id);
                                    }}
                                    name={"view_all"}
                                    style={{
                                      color: "#0080b2",
                                    }}
                                  />
                                </td>
                                <td width="12%">
                                  <Checkbox
                                    checked={
                                      item.edit_all === "Y" ? true : false
                                    }
                                    disabled={item.user_id ? false : true}
                                    onChange={(e) => {
                                      managePermissionChange(e, item.user_id);
                                    }}
                                    name={"edit_all"}
                                    style={{
                                      color: "#0080b2",
                                    }}
                                  />
                                </td>
                                <td width="12%">
                                  <Checkbox
                                    checked={item.manage === "Y" ? true : false}
                                    disabled={item.user_id ? false : true}
                                    onChange={(e) => {
                                      managePermissionChange(e, item.user_id);
                                    }}
                                    name={"manage"}
                                    style={{
                                      color: "#0080b2",
                                    }}
                                  />
                                </td>
                                <td width="12%">
                                  <ClearIcon
                                    className="clear-icon"
                                    onClick={() => deleteRow(item.user_id)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Grid
                      container
                      direction="row"
                      sx={{ justifyContent: "flex-end" }}
                    >
                      <div className={classes.row}>
                        <Fab
                          className={classes.fab}
                          color="primary"
                          onClick={() => addRow()}
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div className="patient-set-outer">
          <div className="patient-set-container">
            <PatientSetTable
              patientSets={patientSets}
              isFetching={refreshingPatientSet || fetchingState}
              refreshData={refreshData}
              savedFilters={savedFilters}
            />
          </div>

          <div className="d-flex patient-set-create-cohort-container disparity-wrapper">
            {org_guid === "8608ec8d-0d22-4b79-a50f-99cd6c8f1a60" || org_guid === "50cc73a6-23c1-49ba-8276-eaf6589c789f" ? (
              <div className="patient-set-container item w-50 d-flex flex-column">
                <div className="upload-title">Disparity Analytics</div>
                <div className="upload-subtitle">
                  Track disparity amongst various population sub-groups by
                  monitoring quality measures based on HEDIS
                </div>
                <div className="upload-submit">
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    disabled={isFetching}
                    onClick={() => {
                      history("/dash-disparity-analytics");
                    }}
                  >
                    View Disparity Analytics
                  </Button>
                </div>
              </div>
            ) : null}

            {!patientSets.length && !isFetching && (
              <div className="w-50 upload-inner item widget-bg-shadow file-upload-widget">
                <FileUploadWidget />
              </div>
            )}

            <div className="patient-set-container item w-50">
              <CreateCohort
                patientSets={patientSets}
                isFetchings={refreshingPatientSet}
                refreshData={fetchPatientSets}
              />
            </div>
          </div>
        </div>
      )}
      {open ? (
        <ConfirmEdit
          open={open}
          setOpen={setOpen}
          modalData={modalData}
          handlePermissionChange={handlePermissionChange}
        />
      ) : null}
      <Backdrop className={classes.backdrop1} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default PatientSets;
