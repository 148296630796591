import React from "react";
import Chart from "react-apexcharts";
import "./Charts.css";

const PieChart = (props) => {
  return (
    <div id="pie_chart">
      <Chart
        options={props.data.options}
        series={props.data.series}
        type="pie"
        width={props.data.width || 500}
      />
    </div>
  );
};

export default PieChart;
