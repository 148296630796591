import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandButton from "./ExpandButton";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "58px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 28px 13px 20px !important",
    borderBottom: "1px solid #595959b3",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "19px !important",
    fontWeight: 600,
    lineHeight: "29px",
    letterSpacing: "-0.03em",
    color: "#0080b2 !important",
  },
  headerIcon: {
    width: "14px !important",
    height: "16px !important",
    cursor: "pointer",
  },
}));

const Header = ({ title, onClick, showExpandIcon = true, disable = false }) => {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      <Typography className={classes.headerTitle}>{title}</Typography>
      {showExpandIcon ? (
        <ExpandButton onClick={() => onClick(true)} disable={disable} />
      ) : null}
    </Box>
  );
};

export default Header;
