import React, { useContext, useState, useEffect } from "react";
import Alert from "@mui/lab/Alert";
import { AnalyticsContext } from "../../../Contexts/analyticsContext";
import { Tooltip } from "@mui/material";
import { PatientSetContext } from "../../../Contexts/patientSetContext";

const RiskStats = ({ hccData }) => {
  const [thisMonth, setThisMonth] = useState([]);
  const [lastMonth, setLastMonth] = useState([]);
  var { event } = useContext(AnalyticsContext);
  const { patientSetInfo } = useContext(PatientSetContext);
  const { selected_date } = patientSetInfo;

  const format = (item) => {
    return item ? parseFloat(item.toFixed(2)) : 0
  };

  useEffect(() => {
    let filteredstats = []
    if (hccData.length) {
      filteredstats = hccData?.filter(item => item.date?.slice(0, 10) === selected_date);
      if (filteredstats.length) {
        const index = hccData.findIndex(x => x.calculation_time === filteredstats[0].calculation_time && x.date === filteredstats[0].date);
        filteredstats.push(hccData[index + 1]);
      }
    }

    setThisMonth(filteredstats[0]);
    setLastMonth(filteredstats[1]);

    if (!hccData[0] && !hccData[1]) {
      event("cohort_empty", {
        cohort_id: patientSetInfo.patient_set_id.slice(-12),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hccData]);

  return (
    <div>
      <div className="HccRisk" style={{ alignItems: "initial" }}>
        <div className="HccMid">
          {thisMonth ? (
            <span>
              <div className="thisMonth">
                <Tooltip
                  placement="top"
                  title={`Includes data upto ${thisMonth.date?.slice(0, 10)}`}
                  interactive
                  arrow
                >
                  <strong>This month:</strong>
                </Tooltip>
              </div>
              <div>
                <span>{format(thisMonth.max)} Max</span>
                <span>{format(thisMonth.avg)} Average</span>
                <span>{format(thisMonth.median)} Median</span>
                <span>{format(thisMonth.min)} Min</span>
              </div>
            </span>
          ) : null}
        </div>
        <div className="HccRight">
          <span>
            <div className="prevMonth">
              <Tooltip
                placement="top"
                title={lastMonth ? `Includes data upto ${lastMonth.date?.slice(0, 10)}` : "Previous Month date is not available"}
                interactive
                arrow
              >
                <strong>Previous month:</strong>
              </Tooltip>
            </div>
            {lastMonth ? (
              <div>
                <span>{format(lastMonth.max)} Max</span>
                <span>{format(lastMonth.avg)} Average</span>
                <span>{format(lastMonth.median)} Median</span>
                <span>{format(lastMonth.min)} Min</span>
              </div>
            ) : "Previous Month data is not available"}
          </span>
        </div>
      </div>
      {!thisMonth && !lastMonth ? (
        <div className="text-center">
          <Alert icon={false} severity="warning">
            Summary statistics for this {patientSetInfo.cohort_name} are not available yet.
            Please check back later.
          </Alert>
        </div>
      ) : null}
    </div>
  );
};

export default RiskStats;
