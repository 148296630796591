import React, { useState, useEffect } from "react";
import combinedServices from "../../../Services/patientServices";
import formatHeading from "../../../Utils/formatHeading";
import Spinner from "../../../Components/Spinner/Spinner";
import { useLocation } from "react-router-dom";
import { serviceData, patientServiceData } from "../../../Utils/clinicalReportData";


const InsideTable = (props) => {
    const [headers, setHeaders] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [page] = useState(1);
    const { patient_coordinator_id, cohortId, startDate, endDate } = props;
    const { pathname } = useLocation();

    useEffect(() => {
        getPatientList(page);
        // eslint-disable-next-line 
    }, [page]);

    const getPatientList = async () => {
        if (pathname === "/report/clinical") {
            const data = serviceData;
            const headers = Object.keys(data[0]);
            setHeaders(headers);
            setPatientList(data);
            return
        }
        if (pathname === "/report/patient") {
            const data = patientServiceData;
            const headers = Object.keys(data[0]);
            setHeaders(headers);
            setPatientList(data);
            return
        }
        setIsFetching(true);
        const body = {
            patient_coordinator_id: patient_coordinator_id,
            cohort_id: cohortId,
            startDate,
            endDate,
            page: page
        }
        const responseData = await combinedServices.patientCoordinatorPatientList(body);
        const { patient_list } = responseData.data.data
        const headers = Object.keys(patient_list[0]);
        setPatientList(patient_list);
        setHeaders(headers);
        setIsFetching(false);
    };

    return (
        <div className="InsideTable" style={{ minHeight: "100px" }}>
            {
                isFetching && !patientList.length
                    ? <Spinner />
                    : !isFetching && !patientList.length
                        ? <div className="mt-5"><strong>No Data Available</strong></div>
                        : <div className="tableScroll">
                            <div></div>
                            <table className="table">
                                <thead>
                                    <tr className="tRow">
                                        {
                                            headers.map((heading, index) => (
                                                <th key={index}>{formatHeading(heading)}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {patientList.map((patient, index) => (
                                        <tr key={index} className="entryHasNotes tRow">
                                            {headers.map((element, index) => (
                                                <td key={index} className={element === "risk_level" ? patient[element] : ""} > {patient[element]} </td>
                                            ))}
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {/* <div className="d-flex ">
                                <TablePagination
                                    component="div"
                                    count={count}
                                    page={Number(page) - 1}
                                    rowsPerPage={10}
                                    rowsPerPageOptions={[]}
                                    onPageChange={handleChangePage}
                                    labelDisplayedRows={({ from, to, count }) => `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`}
                                    style={{ overflow: 'hidden' }}
                                />
                            </div> */}
                        </div>
            }
        </div>
    )
}


export default InsideTable;