import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { Box, Grid, Typography } from "@mui/material";
import "./WcchcPatientSummary.scss";
import Logo from "../../Assets/Images/logo-1.png";
import LogoText from "../../Assets/Images/logo-text-1.png";
// import ShareIcon from "../../Assets/Images/share-blue.png";

// components
import ProblemsIdentified from "../../Components/WcchcPatientSummary/ProblemsIdentified";
import Medications from "../../Components/WcchcPatientSummary/Medications";
import RecentLabs from "../../Components/WcchcPatientSummary/RecentLabs";
import HealthTrends from "../../Components/WcchcPatientSummary/HealthTrends";
import RiskCard from "../../Components/WcchcPatientSummary/RiskCard";
import PatientDetailsCard from "../../Components/WcchcPatientSummary/PatientDetailsCard";
import HospitalAdmitsAndErVisit from "../../Components/WcchcPatientSummary/HospitalAdmitsAndErVisit";
import Referrals from "../../Components/WcchcPatientSummary/Referrals";
// import SdohAnalysisCard from "../../Components/WcchcPatientSummary/SdohAnalysisCard";
import PatientSummaryCard from "../../Components/WcchcPatientSummary/PatientSummaryCard";
import SdohAnalysisDemo from "../../Components/WcchcPatientSummary/SdohAnalysisDemo";
import SmartAlertDemo from "../../Components/WcchcPatientSummary/SmartAlertDemo";

// modals
import ProblemsIdentifiedModal from "../../Components/Modal/WcchcDashboard/ProblemsIdentifiedModal";
import MedicationsModal from "../../Components/Modal/WcchcDashboard/MedicationsModal";
import ReferralsModal from "../../Components/Modal/WcchcDashboard/ReferralsModal";
import HospitalAdmitsAndErVisitModal from "../../Components/Modal/WcchcDashboard/HospitalAdmitsAndErVisitModal";
import RecentLabsModal from "../../Components/Modal/WcchcDashboard/RecentLabsModal";
import ComprehensiveRiskScoreModal from "../../Components/Modal/WcchcDashboard/ComprehensiveRiskScoreModal";
// import SdohAnalysisModal from "../../Components/Modal/WcchcDashboard/SdohAnalysisModal";

//services
import wcchcServices from "../../Services/wcchcServices";

import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import NotificationPopOver from "../../Components/WcchcPatientSummary/NotificatonPopOver";

// Patients Data
import { ORG_ID, PATIENTS_DATA_DEV, PATIENTS_DATA_DEMO,SDOH_DEMO_DATA, 
  EHR_VISITS_DEMO_DATA, DEMO_SUMMARY, MISSING_CODES } from "../../constants";
import PatientDetailsModal from "../../Components/Modal/WcchcDashboard/PatientDetailsModal";

const DemoPatientSummary = () => {
  const history = useNavigate();
  const environment = window.config.environment;
  const { id } = useParams();
  const {  dispatch, state } = useContext(WcchcPatientSummaryContext);
  // const [currentSelectedNotification, setCurrentSelectedNotification] = useState("");
  const [indentifierID, setIndentifierID] = useState('');
  const [value, setValue] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState({});

  // health trends
  const [selectedDisease, setSelectedDisease] = useState("vitals");
  const [duration, setDuration] = useState("12");
  const [labResultsDuration, setlabResultsDuration] = useState("12");

  // modal values
  const [problemsIdentifiedModal, setProblemsIdentifiedModal] = useState(false);
  const [medicationsModal, setMedicationsModal] = useState(false);
  const [referralsModal, setReferralsModal] = useState(false);
  const [recentLabModal, setRecentLabModal] = useState(false);
  const [healthTrendsModal, setHealthTrendsModal] = useState(false);
  const [comprehensiveRiskModal, setComprehensiveRiskModal] = useState(false);
  const [patientDetailsModal, setPatientDetailsModal] = useState(false);
  const [hospitalAdmitsAndErVisits, setHospitalAdmitsAndErVisits] = useState(false);
  // const [sdohAnalysisModal, setSdohAnalysisModal] = useState(false);

  //medication attentive value
  const [medicationAttentive, setMedicationAttentive] = useState(false);

  const handleHealthTrendsModal = () => {
    setHealthTrendsModal(!healthTrendsModal);
  };

  const handleLabResultsModal = () => {
    setRecentLabModal(!recentLabModal);
  };

  const fetchLabResults = async (identifier_id, org_id) => {
    const labResults = await wcchcServices.getLabResults(identifier_id, org_id);
    dispatch({
      type: "LAB_RESULTS",
      payload: { labResults: labResults.data.labResults },
    });
  };

  const fetchAlertNotifications = async (identifier_id, org_id) => {
    const alertNotifications = await wcchcServices.getAlertNotifications(identifier_id, org_id);
    dispatch({
      type: "ALERT_NOTIFICATIONS",
      payload: { alertNotifications: alertNotifications.data.notifications },
    });
  };

  const getAllSmartAlerts = async (identifier_id, org_id) => {
    const allSmartAlerts = await wcchcServices.getAllSmartAlerts(identifier_id, org_id);
    dispatch({
      type: "LIST_SMART_ALERTS",
      payload: { allSmartAlerts: allSmartAlerts.data.notifications },
    });
  };

  const fetchMedications = async (identifier_id, org_id) => {
    const medications = await wcchcServices.medications(identifier_id, org_id);
    dispatch({
      type: "MEDICATIONS",
      payload: { medications: medications.data.medications },
    });
  };

  const fetchVitals = async (identifier_id, org_id) => {
    const vitals = await wcchcServices.vitals(identifier_id, org_id);
    dispatch({
      type: "VITALS",
      payload: {
        vitals: vitals.data.vitalInformation,
        healthTrends: vitals.data.healthTrends,
      },
    });
  };

  const fetchProblemsIdentified = async (identifier_id, org_id) => {
    const problemsIdentified = await wcchcServices.problemsIdentified(
      identifier_id,
      org_id
    );
    dispatch({
      type: "PROBLEMS_IDENTIFIED",
      payload: {
        problemsIdentified: problemsIdentified.data.problemIdentified,
      },
    });
  };

  const fetchComprehensiveRisk = async (identifier_id, org_id) => {
    const comprehensiveRiskScore = await wcchcServices.comprehensiveRisk(
      identifier_id,
      org_id
    );
    dispatch({
      type: "COMPREHENSIVE_RISK_SCORE",
      payload: {
        comprehensiveRiskScore: comprehensiveRiskScore.data.comprehensiveRisk,
      },
    });
  };

  const fetchPatientDetails = async (identifier_id, org_id) => {
    const patientDetails = await wcchcServices.patientDetails(
      identifier_id,
      org_id
    );
    dispatch({
      type: "PATIENT_DETAILS",
      payload: {
        patientDetails: patientDetails.data.patientDetails,
      },
    });
  };

  const fetchVisits = async (identifier_id, org_id) => {
    await wcchcServices.patientVisits(identifier_id, org_id);
    dispatch({
      type: "PATIENT_VISITS",
      payload: {
        visits: EHR_VISITS_DEMO_DATA,
      },
    });
  };

  const fetchSdoh = async (identifier_id, org_id) => {
    await wcchcServices.sdohAnalysis(
      identifier_id,
      org_id
    );
    dispatch({
      type: "SDOH_ANALYSIS",
      payload: {
        sdohAnalysis: SDOH_DEMO_DATA,
      },
    });
  };

  const fetchSmartAlerts = async (identifier_id, org_id) => {
    await wcchcServices.smartAlerts(
      identifier_id,
      org_id
    );
    dispatch({
      type: "SMART_ALERTS",
      payload: {
        smartAlerts: MISSING_CODES,
      },
    });
  };

  const fetchReferrals = async (identifier_id, org_id) => { 
    const referralsData = await wcchcServices.referrals(
      identifier_id,
      org_id
    );
    dispatch({
      type: "REFERRALS",
      payload: {
        referrals: referralsData.data.referralsResults,
      },
    });
  };

  const fetchPatientSummary = async (identifier_id, org_id) => {
    // await wcchcServices.patientSummary(
    //   identifier_id,
    //   org_id
    // );
    dispatch({
      type: "PATIENT_SUMMARY",
      payload: {
        patientSummary: { summary: DEMO_SUMMARY },
      },
    });
  };

  useEffect(() => {
    if(id){
    if(Number(id)>6 || Number(id)<1 ){
      history("/dashboard/1");
    }else{
      let identifier_id = null, org_id = ORG_ID[environment];
      if (environment === "uat") {
        // const patinet = PATIENTS_DATA_UAT.find((doc)=>(doc.id === Number(id)));
        // identifier_id = patinet.patinetId;
        identifier_id = id;
      }
  
      if (environment === "dev") {
        const patinet = PATIENTS_DATA_DEV[0];
        identifier_id = patinet.patinetId;
      }

      if (environment === "demo-a" || environment === "demo") {
        const patinet = PATIENTS_DATA_DEMO[0];
        identifier_id = patinet.patientId;
      }

      identifier_id="IF2020-123-1";
      setIndentifierID(identifier_id);

      dispatch({ type: "RESET" });
      fetchLabResults(identifier_id, org_id);
      fetchAlertNotifications(identifier_id, org_id);
      getAllSmartAlerts(identifier_id, org_id);
      fetchMedications(identifier_id, org_id);
      fetchVitals(identifier_id, org_id);
      fetchProblemsIdentified(identifier_id, org_id);
      fetchComprehensiveRisk(identifier_id, org_id);
      fetchPatientDetails(identifier_id, org_id);
      fetchVisits(identifier_id, org_id);
      fetchSdoh(identifier_id, org_id);
      fetchSmartAlerts(identifier_id, org_id);
      fetchReferrals(identifier_id, org_id);
      fetchPatientSummary(identifier_id, org_id);
      }
    }else{
      history("/dashboard/1");
    }
    //to-do pass patient id(identifier-id) in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box className="patient-summary-container">
      <Box className="main-container">
        <Box className="header-container">
        <div>
              <img src={Logo} alt="summary-logo" />
              <img src={LogoText} alt="summary-logo-text" />
            </div>
          <Box className="header-right">
            <Box className="last-update-box">
              <Typography className="detail-text" sx={{ marginRight: "3px" }}>
                Last Updated On:
              </Typography>
              <Typography className="detail-text">07/11/2024</Typography>
            </Box>
             <NotificationPopOver
              notificationsData={state.alertNotifications}
              isFetchingAlert={state.isFetchingAlertNotifications}
              identifier_id={indentifierID} 
              org_id={ORG_ID[environment]}
              refreshAlerts={fetchAlertNotifications}
              setValue={setValue}
              setSelectedNotification={setSelectedNotification}
            />
          </Box>
        </Box>
        <Box className="patient-summary-details-container">
          <Box className="patient-details-container">
            <PatientDetailsCard demo={true} setPatientDetailsModal={setPatientDetailsModal}/>
          </Box>
          <Box className="risk-section-main-container">
            <Box className="left-details">
              <RiskCard 
                setComprehensiveRiskModal={setComprehensiveRiskModal} 
                demo={true}
                // showBorder = {currentSelectedNotification === "comprehensive-risk" ? true: false} 
              />
            </Box>
          </Box>
          <Box className="summary-container">
              <PatientSummaryCard showFeedbackIcons={true} demo={true} />
          </Box>
          <Box className="summary-container">
            <SmartAlertDemo value={value} setValue={setValue} selectedNotification={selectedNotification} setSelectedNotification={setSelectedNotification} />
          </Box>
          <Box className="grid-container">
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <Box className="grid-container-left">
                  <ProblemsIdentified
                    setProblemsIdentifiedModal={setProblemsIdentifiedModal}
                    // showBorder = {currentSelectedNotification === "problems-identified" ? true : false }
                  />
                  <Medications
                    attentive={medicationAttentive}
                    setAttentive={setMedicationAttentive}
                    setMedicationsModal={setMedicationsModal}
                    // showBorder = {currentSelectedNotification === "medications" ? true : false }
                  />
                  <HospitalAdmitsAndErVisit
                      setHospitalAdmitsAndErVisits={setHospitalAdmitsAndErVisits}
                      // showBorder = {currentSelectedNotification === "referrals" ? true : false }
                    />
                </Box>
              </Grid>
              <Grid item md={6} sm={12}>
                <Box className="grid-container-right">
                  <HealthTrends
                    handleHealthTrendsModal={handleHealthTrendsModal}
                    selectedDisease={selectedDisease}
                    setSelectedDisease={setSelectedDisease}
                    duration={duration}
                    setDuration={setDuration}
                    open={healthTrendsModal}
                    // showBorder = {currentSelectedNotification === "health-trends" ? true : false }
                  />
                  <RecentLabs
                    handleModal={handleLabResultsModal}
                    duration={labResultsDuration}
                    setDuration={setlabResultsDuration}
                    open={recentLabModal}
                    // showBorder = {currentSelectedNotification === "lab-results" ? true : false }
                    />
                  <Referrals 
                    setReferralsModal={setReferralsModal}
                  />
                  {/* SDoH section */}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="sdoh-analysis-demo">
              <SdohAnalysisDemo />
          </Box>
          {/* <Box className="sdoh-analysis-card">
              <SdohAnalysisCard 
                setSdohAnalysisModal={setSdohAnalysisModal}
              />
          </Box> */}
        </Box>
        {problemsIdentifiedModal && (
          <ProblemsIdentifiedModal
            open={problemsIdentifiedModal}
            setProblemsIdentifiedModal={setProblemsIdentifiedModal}
          />
        )}
        {medicationsModal && (
          <MedicationsModal
            open={medicationsModal}
            setMedicationsModal={setMedicationsModal}
            setAttentive={setMedicationAttentive}
          />
        )}
        {referralsModal && (
          <ReferralsModal
            open={referralsModal}
            setReferralsModal={setReferralsModal}
          />
        )}
        {hospitalAdmitsAndErVisits && (
          <HospitalAdmitsAndErVisitModal
            open={hospitalAdmitsAndErVisits}
            setHospitalAdmitsAndErVisits={setHospitalAdmitsAndErVisits}
          />
        )}
        {recentLabModal && (
          <RecentLabsModal
            open={recentLabModal}
            handleModal={handleLabResultsModal}
            duration={labResultsDuration}
            setDuration={setlabResultsDuration}
          />
        )}
        {comprehensiveRiskModal && (
          <ComprehensiveRiskScoreModal
            open={comprehensiveRiskModal}
            setComprehensiveRiskModal={setComprehensiveRiskModal}
          />
        )}
        {patientDetailsModal && (
            <PatientDetailsModal
              open={patientDetailsModal}
              setPatientDetailsModal={setPatientDetailsModal}
              demo={true}
            />
          )}
        {/* {sdohAnalysisModal && (
            <SdohAnalysisModal
              open={sdohAnalysisModal}
              setSdohAnalysisModal={setSdohAnalysisModal}
            />
          )} */}
      </Box>
    </Box>
  );
};

export default DemoPatientSummary;
