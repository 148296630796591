import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import services from "../../Services/patientServices";

const Hr = styled.hr({
  margin: "0.25rem 0rem",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
};

const LoadFilters = ({
  open,
  setOpen,
  setTimeFrame,
  setFilterTable,
  getCohortComparison,
  setSelectedComparison,
  setTotalCount
}) => {
  const [savedFilters, setSavedFilters] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getSavedFilters();
    }
  }, [open]);

  const getSavedFilters = async () => {
    try {
      setIsFetching(true);
      const { data } = await services.getComparisonFilter();
      setSavedFilters(data?.saved_filters);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log("Error while fetching saved filters => ", error);
    }
  };

  const handleLoadFilter = async (filter) => {
    setTimeFrame(parseInt(filter?.selected_timeframe));
    const data = await getCohortComparison(filter?.selected_timeframe, filter.filter_page)
    let filteredCohorts = data.filter(({ cohort_id }) =>
    filter?.selected_cohorts?.includes(cohort_id)
    );
    filteredCohorts = filteredCohorts.map(row => {
      row.show = !row.show;
      return row
    });
    setFilterTable(filteredCohorts);
    setTotalCount(filteredCohorts.length);
    setOpen(false);
    setSelectedComparison(filter);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-save-comparison"
        aria-describedby="modal-save-comparison"
      >
        <Box sx={style}>
          <Grid container spacing={0}>
            <Grid
              item
              container
              xs={12}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <Typography variant="h6">Load comparison</Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end">
                <IconButton onClick={() => handleClose()}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Hr />
            </Grid>
            <Grid item container xs={12}>
              {savedFilters?.length ? (
                savedFilters.map((filter) => (
                  <>
                    <Grid item xs={12} display="flex" marginBottom={1} marginTop={1} sx={{borderBottom: '1px solid #F9F9FB', alignItems: 'center'}}>
                      <Grid item xs={7}>
                        <Typography variant="subtitle1">
                          {filter?.filter_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <button
                          className="submit-form-btn"
                          onClick={() => handleLoadFilter(filter)}
                          style={{ width: "169px", height: "29px" }}
                        >
                          Load Comparison
                        </button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Hr />
                    </Grid>
                  </>
                ))
              ) : (
                <Alert severity="warning" sx={{ width: "100%" }}>
                  There is no comparison saved.
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 200 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LoadFilters;
