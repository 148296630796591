import React from "react";
import "./ReportHeader.scss";
import logo from "../../../Assets/Images/logo-white.png";
import formatDate from "../../../Utils/formatDate";
import BackButton from "../../../Components/BackButton/BackButton"

const ReportHeader = (props) => {
  const todayDate = new Date().toUTCString();
  const { title, startDate, endDate, cohortName, userName } = props.data;
  return (
    <div className="ReportHeader">
      <header className="heroHeader">
        <nav className="navBar">
          <div className="logoContainer">
            <img src={logo} className="logoImg" alt="logo"></img>
            <span className="logo">Foresight Health Solutions</span>
          </div>
          <BackButton></BackButton>
        </nav>
        <div className="subHeading">
          <h1 className="reportTitle">{title}</h1>
          <div className="dateRange">
            <span>
              Service date range: {formatDate(startDate)} -{" "}
              {formatDate(endDate)}
            </span>
          </div>
        </div>
        <section className="section__container">
          <div className="detailedHeader">
            <div className="colLeft">
              <div>
                <span className="infoLeft">Report created by:</span>
                <span className="infoRight">{userName}</span>
              </div>
              <div>
                <span className="infoLeft">Created on:</span>
                <span className="infoRight">{todayDate}</span>
              </div>

              <div>
                <span style={{ margin: "0px" }} className="infoRight">
                  Report based on all {`${cohortName} `}
                </span>
              </div>
            </div>
            {/* <div className="timeInfo">
                            <div className="label">Org Name</div>
                            <div className="value">IF</div>
                        </div> */}
          </div>
        </section>
      </header>
    </div>
  );
};
export default ReportHeader;
