import React, { useState } from "react";
import services from "../../Services/patientServices";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  backdrop: {
    zIndex: 500,
    color: "#78cbf2",
  },
}));

const useStylesName = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 10px 7px 10px !important",
    textAlign: "left",
    color: "#666",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      borderRadius: 20,
      borderColor: "#80bdff",
      backgroundColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  focused: {},
}));

const NameTextField = (props) => {
  const classes = useStylesName();
  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
};

const ReInviteUser = () => {
  const classes = useStyles();
  const [useremail, setUserEmail] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const checkValidity = (params) => {
    const {  email } = params;
    let emailRegex = new RegExp('[a-zA-Z0-9]+@[a-z]+.[a-z]{2,3}');
  
    if (!emailRegex.test(email)) {
      return { isValid: false, message: "Invalid email." };
    }

    return { isValid: true };
  };

  /**
   * User Re-Invite functionality by the admin
   * @returns 
   */
  const reInviteUser = async () => {
    let body = {
      email: useremail.replace(/\s/g, ""),
    };

    const validity = checkValidity(body);
    if(!validity.isValid) {
      swal(validity.message, "", "error");
      return;
    };

    try {
      setLoading(true);
      console.log({body})
      const response = await services.reInviteUser(body);
      const { data: {  message }, status } = response;
      if (status === 200) {
        swal("Success!", message, "success");
        setLoading(false);
      }
    } 
    catch (error) {
      setLoading(false);
      const { message } =  error.response.data;
      swal("Oops!", message, "error");
    }
  };

  return (
    <div className={classes.paper}>
      <div className="patient-create-set-title">
        {" "}
       Re-Invite User
      </div>

      <form className={classes.root} noValidate autoComplete="off">
        <div className="mui-textfield-form">
          <label className="form-label">User email address </label>
          <NameTextField
            multiline
            className="input-field-size"
            defaultValue={useremail}
            variant="filled"
            onChange={(e) => {
              setUserEmail(e.target.value);
            }}
            inputProps={{ maxLength: 100 }}
            name="userEmail"
          />
        </div>
      </form>
      <div className="d-flex flex-row-reverse">
          <button
            className="tblBtn mt-4"
            onClick={() => {
              reInviteUser();
            }}
          >
            Re-Invite
          </button>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ReInviteUser;
