import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  button: {
    border: (props) => `1px solid ${props.color} !important`,
    color: (props) => `${props.color} !important`,
    background: (props) => `${props.backgroundColor} !important`,
    '&:hover': {
      borderColor: (props) => props.color,
      background: (props) => props.backgroundColor,
    },
    textTransform: 'none'
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: (props) => `${props.color} !important`,
    fontSize: '1.1rem !important'
  },
}));


const BackButton = ({ color, backgroundColor = '#fff' }) => {
  const classes = useStyles({ color, backgroundColor });
  let history = useNavigate();

  return (
    <Button 
      variant="outlined" 
      className={classes.button} 
      onClick={() => history(-1)}
    >
      <ArrowBack className={classes.icon} />
      Back
    </Button>
  );
};
export default BackButton;
