import React, { useState } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InsideTable from "./InsideTable";
import TrendUpIcon from "../../../Assets/Images/trend-up.png";
import { Tooltip } from "@mui/material";
import popupIcon from "../../../Assets/Images/popupIcon.png";


const CollapsibleTableRow = (props) => {
    const { row, headers, classes, cohortId, startDate, endDate } = props;
    const [open, setOpen] = useState(false);

    const onTableOpen = () => {
        setOpen(!open);
    }
    const tableData = ["Insurance", "Grant", "Donor"]

    console.log('Data => ', row, headers);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell className={classes.stickyFirst}>
                    {/* <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={onTableOpen}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton> */}
                </TableCell>
                {headers.map(((element, index) => (
                    element === "patient_coordinator"
                        ? null
                        : (
                            <>
                                <TableCell className={`tableData ${index === 1 ? classes.stickySecond : ""}`} key={index}>
                                    {element === "session_hours_provided_(authorized)" ? (
                                        <span> <span style={{ color: "red" }}> 8:00h </span>(11:30h)</span>
                                    ) : (
                                        <span>{row[element]}</span>
                                    )}
                                    {["service_hours_provided_(available)", "client_and_sessions", "session_hours_provided_(authorized)"].includes(element) && (
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={onTableOpen}
                                        >
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    )}
                                    {element === "funding_source" && (
                                        <Tooltip
                                            placement="top"
                                            interactive
                                            arrow
                                            title={
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Funding Source</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td className="active">
                                                                        {item}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            }>
                                            <span><img
                                                style={{ height: "40px", width: "40px", cursor:"pointer" }}
                                                src={popupIcon}
                                                alt="popup icon"
                                            /></span>
                                        </Tooltip>
                                    )}
                                    {element === "trend" && (
                                        <img src={TrendUpIcon} alt="trend-up" className="trend-up-icon" />
                                    )}
                                </TableCell>
                            </>
                        )
                )))}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <InsideTable patient_coordinator_id={row.patient_coordinator} cohortId={cohortId} startDate={startDate} endDate={endDate}></InsideTable>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default CollapsibleTableRow;