import React, { useEffect } from "react";
import { useState } from "react";
import { patientTableData } from "../../Utils/clinicalReportData";
import CollapsibleTable from "../SummaryReport/CollapsibleTable/CollapsibleTable";
import ReportFooter from "../SummaryReport/ReportFooter/ReportFooter";
import ReportHeader from "../SummaryReport/ReportHeader/ReportHeader";

const PatientReport = () => {
  const reportData = JSON.parse(localStorage.getItem("clinical_report_data"));
  const [headerData, setHeaderData] = useState("");
  const [tableData, setTableData] = useState([]);
  const { user } = JSON.parse(localStorage.getItem("user"));

  const getHeaderData = () => {
    const userName = `${user.last_name}, ${user.first_name}`;
    const headerData = {
      selected_date: reportData?.selected_date,
      title: "Client Report",
      cohortName: reportData?.cohort_name,
      cohortId: reportData?.cohort_id,
      userName: userName,
      endDate: reportData?.end_date,
      startDate: reportData?.start_date,
    };
    setHeaderData(headerData);
  };

  const getTableData = () => {
    let list = patientTableData;
    const user_id = reportData.user_id;
    if (user_id) {
      list = patientTableData.filter((item) => item.id === user_id);
    }
    setTableData(list);
  };

  useEffect(() => {
    getHeaderData();
    getTableData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <ReportHeader data={headerData} />
      <CollapsibleTable tableData={tableData} data={headerData} />
      <ReportFooter />
    </div>
  );
};
export default PatientReport;
