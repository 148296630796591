import React, { useContext } from "react";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CalendarMonth, Close } from "@mui/icons-material";

import {
  health_trends_radio_list,
  healthModalDropDownList,
} from "../../constants";
import ExpandButton from "./ExpandButton";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 28px 13px 20px !important",
    borderBottom: "1px solid rgba(89, 89, 89, 0.7)",
    height: "58px",
  },
  headerTitle: {
    fontFamily: "Lato",
    fontSize: "19px !important",
    fontWeight: 600,
    lineHeight: "28.5px",
    letterSpacing: "-0.03em",
    color: "#0080b2",
  },
  headerRightContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "28px",
  },
  radioButtonContainer: {
    padding: "0px 10px 0px 19px !important",
    margin: "20px 0px 14px 0px !important",
  },
  groupContainer: {
    display: "flex",
    gap: "10px",
  },
  label: {
    margin: "0",
    "& .checked": {
      color: "#4B8DF8 !important",
    },
    "& .MuiRadio-root": {
      padding: "0",
    },
    "& .MuiFormControlLabel-label": {
      fontFamily: "lato",
      fontSize: "14px !important",
      fontWeight: 600,
      lineHeight: "21px",
      letterSpacing: "-0.03em",
      color: "#000000",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "baseline",
    borderBottom: "1px solid #adb2b6",
    gap: "10px",
  },
  selectInput: {
    width: "130px",
    fontSize: "14px",
    color: "#141414",
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  headerIcon: {
    fill: "#0080b2 !important",
    cursor: "pointer",
  },
  menuItem: {
    color: "#141414",
    fontFamily: "Lato",
    fontWeight: 400,
  },
  radio: {},
  radioChecked: {
    "& span > svg": {
      fill: "#0080b2 !important",
    },
  },
  selectedText: {
    fontSize: "14px",
    color: "#141414",
    fontFamily: "Lato",
    fontWeight: 400,
  },
}));

const HeathTrendsHeader = ({
  modal,
  handleHealthTrendsModal,
  selectedDisease,
  setSelectedDisease,
  setDuration,
  duration,
}) => {
  const classes = useStyles();
  const { state } = useContext(WcchcPatientSummaryContext);

  return (
    <div>
      <Box className={classes.header}>
        <Typography className={classes.headerTitle}>Health Trends </Typography>
        <Box className={classes.headerRightContainer}>
          <Box className={classes.filterContainer}>
            <CalendarMonth />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={duration}
              label="Age"
              variant="standard"
              onChange={(e) => setDuration(e?.target?.value)}
              className={classes.selectInput}
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              renderValue={(value) => 
                <Typography className={classes.selectedText}>
                  {healthModalDropDownList(true).find(item => Number(item.value) === Number(value)).label || ""}
                </Typography>
              }
            >
              {healthModalDropDownList(true).map((item, index) => (
                <MenuItem
                  className={classes.menuItem}
                  value={item?.value}
                  key={index}
                >
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {modal ? (
            <Close
              onClick={handleHealthTrendsModal}
              className={classes.headerIcon}
            />
          ) : (
            <ExpandButton
              onClick={handleHealthTrendsModal}
              disable={state.isFetchingVitals}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.radioButtonContainer}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedDisease}
            row
            onChange={(e) => setSelectedDisease(e?.target?.value)}
            className={classes.groupContainer}
          >
            {health_trends_radio_list?.map((item, index) => (
              <FormControlLabel
                value={item?.value}
                control={
                  <Radio
                    className={
                      selectedDisease === item?.value
                        ? classes.radioChecked
                        : classes.radio
                    }
                  />
                }
                label={item?.label}
                key={index}
                className={classes.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </div>
  );
};

export default HeathTrendsHeader;
