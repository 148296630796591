import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import services from "../Services/patientServices";
import getCognitoToken from "../Utils/getCognitoToken";
import swal from 'sweetalert';

const apiClient = (runtime = "node", iframeStatus = false) => {
  runtime = window.config.environment === "local" ? "" : runtime;
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };

  const enabled = JSON.parse(localStorage.getItem("aka_session_enabled"));
  const aka_id = localStorage.getItem("aka_id");
  const aka_session_id = localStorage.getItem("aka_session_id");
  if(enabled) {
    headers['aka-id'] = aka_id;
    headers['aka-session-id'] = aka_session_id;
  };

  const instance = axios.create({
    baseURL: window.config.apiUrl + "/" + runtime,
    responseType: "json",
    headers,
    withCredentials: true
  });

  //Use interceptor to catch api errors globally
  instance.interceptors.response.use((response) => response, (error) => {
    console.log('Api error catched by axios interceptor');
    //No need to inform the user if access token is expired,
    // silent refresh will be attempted bts
    if(error?.code === "ERR_CANCELED") {  
      console.log("Request canceled duee to component mount")
    } else if(error.response.status !== 401 && error.response.status !== 400) {
      console.log(error.response.data.message);
      if(!iframeStatus){
        swal(
          "Oops! Something went wrong.", 
          `HTTP Response Code ${error.response.status}: ${error.response.data.message}`, 
          "error"
        );
      }
    }
    throw error;
  });

  const refreshAuthLogic = failedRequest => {
    console.log('Failed response 401 intercepted by axios');
    return instance
      .post("token/refresh", {
        refresh_token: localStorage.getItem("refresh_token")
      }, { skipAuthRefresh: true })
      .then(tokenRefreshResponse => {
        console.log('Token refresh response => ', tokenRefreshResponse);
        localStorage.setItem("access_token", tokenRefreshResponse.data.data.access_token);
        localStorage.setItem("refresh_token", tokenRefreshResponse.data.data.refresh_token);
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + tokenRefreshResponse.data.data.access_token;
        return Promise.resolve();
      })
      .catch(async error => {
        console.log('Refresh auth failed => ', error);
        const accessToken = await getCognitoToken('accessToken');
        var logoutResponse;
        logoutResponse = services.logout(accessToken, {
          refresh_token: localStorage.getItem("refresh_token")
        })
        .then(() => {
          console.log('User logged out => ', logoutResponse);
        })
        .catch((error) => {
          console.log('User logged out => ', error);
        })
        .finally(() => {
          localStorage.clear();
          localStorage.setItem("user_logged_out", true);
          console.log('logout response => ', logoutResponse.data);
          if(logoutResponse?.data?.time_since_logged_out) {
            localStorage.setItem("time_since_logged_out", logoutResponse.data.time_since_logged_out);
          };
          if(window.parent === window){
            window.location = '/';
          }
        })
      });
  };

  // Obtain the fresh token each time the function is called
  const getAccessToken = () => {
    return localStorage.getItem('access_token');
  }

  // Use interceptor to inject the token to stalled requests
  instance.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return request;
  });

  createAuthRefreshInterceptor(instance, refreshAuthLogic);

  return instance;
};

export default apiClient;