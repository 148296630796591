import React from 'react';
import { withStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    zIndex: 2,
    cursor: "pointer"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#88d478',
        borderColor: '#88d478',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    borderColor: '#88d478'
  },
  track: {
    border: `1px solid #e8e8e8`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#e8e8e8'
  },
  checked: {},
}))(Switch);

const MemberCountToggle = ({ checked, setChecked }) => {
  return (
    <AntSwitch checked={checked} onChange={(e) => { setChecked(e.target.checked); }} />  
  );
};

export default MemberCountToggle;
