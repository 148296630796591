import React, { useState, createContext, useContext } from "react";
import { PatientSetContext } from "./patientSetContext";
import useUpdateEffect from "../CustomHooks/UseUpdateEffect";
import services from "../Services/patientServices";
import { DropdownFilterContext } from "./dropdownContext";
import { useToastContext } from "./ToastContext";

// Create a context
const PatientContext = createContext();

const PatientProvider = ({ children }) => {
  const { user } = JSON.parse(localStorage.getItem("user")) || {};

  const { showToast } = useToastContext();

  /* -------------------- Get selected id and cohort id (patient set id) -------------------- */
  const { patientSetInfo } = useContext(PatientSetContext) || {};
  const { selected_date, patient_set_id } = patientSetInfo || "";
  /* -------------------- Get selected id and cohort id (patient set id) -------------------- */

  const [isHalfScreen, setIsHalfScreen] = useState(true);

  const [patientDetails, setPatientDetails] = useState(null);
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const [patientId, setPatientId] = useState(null);

  /* -------------------- Patient Notes  component states -------------------- */
  const [isFetchingNotes, setIsFetchingNotes] = useState(false);
  const [emrNotesAvailable, setEmrNotesAvailable] = useState(false);
  const [notes, setNotes] = useState([]);
  const [patientNote, setPatientNote] = useState([]);

  const [contributorsFilter, setContributorsFilter] = useState([]);
  const [interventionFilter, setInterventionFilter] = useState([]);

  const [selectedContributors, setSelectedContributors] = useState(["All"]);
  const [selectedInterventions, setSelectedInterventions] = useState(["All"]);

  const [icdCodesList, setIcdCodeslist] = useState([]);
  const [dismissedInterventions, setDismissInterventions] = useState([]);
  const [dismissContributors, setDismissContributors] = useState([]);

  /* -------------------- Patient Notes states -------------------- */

  /* -------------------- Undo note  states -------------------- */
  const [undoDismissalInProgress, setUndoDismissalInProgress] = useState(false);
  /* -------------------- Undo note  states -------------------- */

  /* -------------------- For navigating between patients -------------------- */
  const { state } = useContext(DropdownFilterContext) || {};
  const [currentPatientIndex, setCurrentPatientIndex] = useState(0);

  const changeDialogScreen = (bool) => {
    setIsHalfScreen(bool);
  };

  const updatePatientId = (patientId) => {
    setPatientId(patientId);
  };

  /* -------------------- fetch patient details -------------------- */
  const fetchPatientDetails = async () => {
    setIsFetchingDetails(true);

    try {
      const response = await services.patientsDetails(patientId, {
        selected_date,
        cohort_id: patient_set_id,
      });
      setPatientDetails(response.data);
      const index = state?.filteredData?.findIndex(
        ({ pid }) => patientId === pid
      );
      setCurrentPatientIndex(index);
    } catch (error) {
      console.error("Error fetching patient details:", error);
    } finally {
      setIsFetchingDetails(false);
    }
  };
  /* -------------------- fetch patient details -------------------- */

  /* -------------------- fetch patient notes -------------------- */
  const FetchPatientNotes = async () => {
    setIsFetchingNotes(true);

    try {
      const response = await services.patientsNotes({
        cohort_id: patient_set_id,
        patient_id: patientId,
        selected_date,
      });

      const notesData = response.data;
      const notes = notesData?.notes || [];
      const emrNotesAvailable = notes.length > 0;

      const dismissedNotes = notesData.dismissedNotes || [];
      const { contributorDimissNotes, interventionDismissNotes } =
        getDismissNotesByType(dismissedNotes) || [];

      setDismissContributors(contributorDimissNotes);
      setDismissInterventions(interventionDismissNotes);

      // set filters for notes
      setContributorsFilter(notesData?.contributorsFilter || []);
      setInterventionFilter(notesData?.interventionFilter || []);

      setSelectedContributors(notesData?.contributorsFilter.map((doc)=>doc.abbr) || []); 
      setSelectedInterventions(notesData?.interventionFilter.map((doc)=>doc.abbr) || []);

      // set notes
      setNotes(notes); // for global state which will be used to filter notes
      setPatientNote(notes); // for showing filtered notes

      setEmrNotesAvailable(emrNotesAvailable);
      setIsFetchingNotes(false);
    } catch (error) {
      console.error("Error fetching patient notes:", error);
      setIsFetchingNotes(false);
    }
  };

  /* -------------------- fetch patient notes -------------------- */

  /* -------------------- fetch icd codes -------------------- */
  const FetchIcdCodes = async () => {
    try {
      const { data } = await services.icdCodesListView(patientId, {
        patient_id: patientId,
        user_id: user.user_id,
      });

      const icdCodes = data?.icdCodes || [];
      setIcdCodeslist(icdCodes);

    } catch (error) {
      console.error(error);
    }
  };
  /* -------------------- fetch icd codes -------------------- */

  /* -------------------- undo dismiss patient note -------------------- */

  const undoNoteDismissal = async (note_id) => {
    try {
      setUndoDismissalInProgress(true);
      const payload = {
        patient_id: patientId,
        note_id,
      };
      await services.retainPatientNote(payload);
      setUndoDismissalInProgress(false);
      showToast("Note retained successfully", "success");
      FetchPatientNotes();
    } catch (error) {
      console.log(error);
      setUndoDismissalInProgress(false);
      showToast("Error while retaining note", "error");
    }
  };
  /* -------------------- undo dismiss patient note -------------------- */

  const handleSelectionChange = (title, selectedOptions) => {
    if (title === "Contributors") {
      setSelectedContributors(selectedOptions);
    } else if (title === "Intervention") {
      setSelectedInterventions(selectedOptions);
    }
  };
  /* -------------------- handle prev and next navigation -------------------- */
  const handleBackForthNavigation = (name) => {
    const newIndex =
      name === "next" ? currentPatientIndex + 1 : currentPatientIndex - 1;
    const patientId = state?.filteredData?.[newIndex]?.pid || "";

    setCurrentPatientIndex(newIndex);
    setPatientId(patientId);
  };

  /* -------------------- handle prev and next navigation -------------------- */

  // Use useEffect to fetch details when patientId changes
  useUpdateEffect(() => {
    console.log("fetching patient details for this patient id", patientId);
    if (patientId) {
      fetchPatientDetails();
      FetchPatientNotes();
      FetchIcdCodes();
    }
  }, [patientId]);

  useUpdateEffect(() => {
    if (
      selectedContributors.includes("All") &&
      selectedInterventions.includes("All")
    ) {
      setPatientNote(notes);
      return;
    }

    const newFilteredNotes = notes.filter((note) => {
      const contributorFilterCondition = selectedContributors.some(
        (contributor) => note.contributors_abbr.includes(contributor)
      );

      const interventionFilterCondition = selectedInterventions.some(
        (intervention) => note.intervention_abbr.includes(intervention)
      );

      return contributorFilterCondition || interventionFilterCondition;
    });

    setPatientNote(newFilteredNotes);
  }, [selectedContributors, selectedInterventions]);

  const getDismissNotesByType = (dismissedNotes) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    const interventionDismissNotes = dismissedNotes
      .filter((item) => item.type === "intervention")
      .map((item) => ({
        "Date/Time": new Date(item.dismissed_on).toLocaleDateString(
          "en",
          options
        ),
        dimissed_by_user: item.user_name,
        patient_note: item.note_id.split("/").slice(1).join("/"),
        intervention: item.note_id.split("/")[0],
        undo: "",
        id: item.note_id,
      }));

    const contributorDimissNotes = dismissedNotes
      .filter((item) => !item.type || item.type === "contributor")
      .map((item) => ({
        "Date/Time": new Date(item.dismissed_on).toLocaleDateString(
          "en",
          options
        ),
        dimissed_by_user: item.user_name,
        patient_note: item.note_id.split("/").slice(1).join("/"),
        contributor: item.note_id.split("/")[0],
        undo: "",
        id: item.note_id,
      }));

    return { interventionDismissNotes, contributorDimissNotes };
  };

  return (
    <PatientContext.Provider
      value={{
        patientId,
        patientDetails,
        isFetchingDetails,
        emrNotesAvailable,
        contributorsFilter,
        interventionFilter,
        notes,
        patientNote,
        isFetchingNotes,
        selectedContributors,
        selectedInterventions,
        icdCodesList,
        dismissedInterventions,
        dismissContributors,
        undoDismissalInProgress,
        isHalfScreen,
        currentPatientIndex,
        fetchPatientDetails,
        updatePatientId,
        FetchPatientNotes,
        handleSelectionChange,
        undoNoteDismissal,
        changeDialogScreen,
        handleBackForthNavigation,
        FetchIcdCodes,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

const usePatientContext = () => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error("usePatientContext must be used within a PatientProvider");
  }
  return context;
};

export { PatientProvider, usePatientContext };
