import React, { useState, useContext, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { IframeContext } from "../../Contexts/iframeContext";
import services from "../../Services/patientServices";
import { Button, CircularProgress } from "@mui/material";
import "./PatientDetails.scss";
import MultiSelectBar from "../MultiSelectBar/MultiSelectBar";
import useUpdateEffect from "../../CustomHooks/UseUpdateEffect";
import PatientRecommendations from "../PatientRecommendations/PatientRecommendations";
import TabPanel from "../TabPanel/TabPanel";

const useStyles = makeStyles({
  tabRoot: {
    "& .MuiTab-root": {
      fontSize: "16px",
      fontWeight: "600",
      fontFamily: "Public Sans, sans-serif",
      textTransform: "none",
    },
    boxShadow:
      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  },
  active: {
    color: "rgb(33, 43, 54) !important",
  },
  tableRoot: {
    border: "none",
    "& th": {
      border: "none",
    },
    "& td": {
      border: "none",
      width: "50%",
    },
  },
  menuItem: {
    fontSize: "12px",
  },
});

const PatientDetailsTab = () => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  const { iframePatientMrn, iframe } = useContext(IframeContext);
  const [patientData, setPatientData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [notes, setNotes] = useState([]);
  const [contributorsFilter, setContributorsFilter] = useState([]);
  const [interventionFilter, setInterventionFilter] = useState([]);
  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;
  const carolinaOrgGuid = "10a7319f-b662-4c79-85fd-7fc5c9cc1461"

  const handleChange = (e, newValue) => {
    setTab(newValue);
  };

  const getPatientScore = async () => {
    try {
      setIsFetching(true);
      const response = await services.iframePatientDetails(
        iframePatientMrn,
        iframe
      );
      const { data } = response;
      const notes = data?.notes || [];

      setPatientData(data);
      setNotes(notes);

      setContributorsFilter(data?.contributorsFilter || []);
      setInterventionFilter(data?.interventionFilter || []);

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log("error while fetching patient's scores", error);
    }
  };

  useEffect(() => {
    if (iframePatientMrn.length) {
      getPatientScore();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="patient-details-container">
      {isFetching ? (
        <div className="spinner-container">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div>
          <Tabs
            className={classes.tabRoot}
            value={tab}
            textColor="inherit"
            TabIndicatorProps={{
              sx: {
                backgroundColor: "rgb(0, 171, 85)",
              },
            }}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="disabled tabs example"
          >
            <Tab
              label="Patient Details"
              className={`${tab === 0 ? classes.active : ""}`}
            />
            <Tab
              label="Patient Notes"
              className={`${tab === 1 ? classes.active : ""}`}
            />
            <Tab
              label="Recommendations"
              className={`${tab === 2 ? classes.active : ""}`}
            />
          </Tabs>
          <TabPanel value={tab} index={0} className="inside-container">
            <Table aria-label="simple table" className={classes.tableRoot}>
              <TableBody>
                <TableRow>
                  <TableCell>Comprehensive Risk:</TableCell>
                  <TableCell>
                    {patientData.comp_score
                      ? patientData.comp_score
                      : `(Unavailable)`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{org_guid === carolinaOrgGuid ? "Diagnosis Risk:" : "Diagnostic Risk:"}</TableCell>
                  <TableCell>
                    {patientData.hcc_score
                      ? patientData.hcc_score
                      : `(Unavailable)`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SDoH Risk:</TableCell>
                  <TableCell>
                    {patientData.sdoh_score
                      ? patientData.sdoh_score
                      : `(Unavailable)`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contributors:</TableCell>
                  <TableCell>
                    {patientData?.contributors?.length ? (
                      <Select
                        variant="standard"
                        value="display"
                        width="170px"
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <MenuItem
                          style={{ cursor: "none" }}
                          value="display"
                          disabled
                        >
                          <span>
                            {patientData.contributors.length} Contributor(s)
                          </span>
                        </MenuItem>
                        {patientData.contributors.map((item, index) => {
                          return (
                            <MenuItem
                              className={classes.menuItem}
                              key={index}
                              disabled
                              style={{ cursor: "none", opacity: 1 }}
                            >{`${item.label} (${item.count})`}</MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      "None"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Impactability:</TableCell>
                  <TableCell>
                    {patientData.impactability
                      ? patientData.impactability
                      : `(Unavailable)`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Interventions (Services) Provided:</TableCell>
                  <TableCell>
                    {patientData?.services?.length ? (
                      <Select
                        variant="standard"
                        value="display"
                        width="200px"
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <MenuItem
                          style={{ cursor: "pointer" }}
                          value="display"
                          disabled
                        >
                          <span>
                            {patientData.services.length} Intervention Types
                          </span>
                        </MenuItem>
                        {patientData.services.map((item, index) => {
                          return (
                            <MenuItem
                              className={classes.menuItem}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >{`${item.label} (${item.count})`}</MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      "None"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel className="inside-container" value={tab} index={1}>
            <PatientNotes
              notes={notes}
              contributorsFilter={contributorsFilter}
              interventionFilter={interventionFilter}
            />
          </TabPanel>
          <TabPanel className="inside-container" value={tab} index={2}>
            <PatientRecommendations patientData={patientData} />
          </TabPanel>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsTab;

const PatientNotes = ({ notes, contributorsFilter, interventionFilter }) => {
  const emrNotesAvailable = notes.length > 0;
  const [patientNote, setPatientNote] = useState([...notes]);
  const [selectedContributors, setSelectedContributors] = useState([
    ...contributorsFilter.map((doc)=>doc.abbr),
  ]);
  const [selectedInterventions, setSelectedInterventions] = useState([
    ...interventionFilter.map((doc)=>doc.abbr),
  ]);

  const handleSelectionChange = (title, selectedOptions) => {
    if (title === "Contributors") {
      setSelectedContributors(selectedOptions);
    } else if (title === "Intervention") {
      setSelectedInterventions(selectedOptions); 
    }
  };

  useUpdateEffect(() => {
    if (
      selectedContributors.includes("All") &&
      selectedInterventions.includes("All")
    ) {
      setPatientNote(notes);
      return;
    }

    const newFilteredNotes = notes.filter((note) => {
      const contributorFilterCondition = selectedContributors.some(
        (contributor) => note.contributors_abbr.includes(contributor)
      );

      const interventionFilterCondition = selectedInterventions.some(
        (intervention) => note.intervention_abbr.includes(intervention)
      );

      return contributorFilterCondition || interventionFilterCondition;
    });

    setPatientNote(newFilteredNotes);
  }, [selectedContributors, selectedInterventions]);

  return (
    <>
      <div
        className="patient-notes"
        style={{ padding: "20px 20px", maxHeight: "none" }}
      >
        <div className="patient-notes-wrapper">
          <div>
            {emrNotesAvailable && (
              <div className="notes-dropdown-wrapper">
                <MultiSelectBar
                  list={contributorsFilter}
                  title={"Contributors"}
                  onSelectionChange={(selectedOptions) =>
                    handleSelectionChange("Contributors", selectedOptions)
                  }
                />
                <MultiSelectBar
                  list={interventionFilter}
                  title={"Intervention"}
                  onSelectionChange={(selectedOptions) =>
                    handleSelectionChange("Intervention", selectedOptions)
                  }
                />
              </div>
            )}

            <div className="notes-wrapper" style={{ maxHeight: "none" }}>
              {emrNotesAvailable ? (
                <div>
                  {patientNote.map((note, index) => (
                    <div key={index} className="note-row">
                      <div className="note-title-row">
                        <span>{note.date}</span>
                        <span>{note.title}</span>
                        {note.searchTerms.map(({ termSearched, type }) => (
                          <Button
                            variant="outlined"
                            className={
                              type === "contributor" ? "cont-btn" : "interv-btn"
                            }
                          >
                            {termSearched}
                          </Button>
                        ))}
                      </div>
                      <p>
                        <HighlightedButton note={note} />
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <span className="pn-unavailable">
                  <strong>{`No Notes available!`}</strong>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const HighlightedButton = ({ note }) => {
  const { note_text: text = "", searchTerms: highlightedText = [] } = note;

  if (highlightedText.length === 0) return <span>{text}</span>;

  const terms = highlightedText.map((item) => item.termSearched);
  const searchRegex = new RegExp(
    `(${terms.toString().replace(/,/g, "|")})`,
    "gi"
  );
  const textParts = text.split(searchRegex);

  const getClassName = (part) => {
    const isContributor = highlightedText.find((h) => h.termSearched === part);
    return isContributor.isDismissed
      ? "dismissed-btn"
      : isContributor.type === "contributor"
      ? "cont-btn"
      : "interv-btn";
  };

  return (
    <span>
      {textParts
        .filter((part) => part)
        .map((part, i) =>
          searchRegex.test(part) ? (
            <Button size="small" key={i} className={getClassName(part)}>
              {part}
            </Button>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  );
};
