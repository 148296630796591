import React, { createContext, useEffect, useState } from "react";
import GA4React from "ga-4-react";

const AnalyticsContext = createContext();

const AnalyticsProvider = (props) => {
  const [ga, setGa] = useState(null);

  const initialize = async () => {
    try {
      var trackingId = window.config.ga_tracking_id;
      if (trackingId) {
        const ga4react = new GA4React(trackingId, {}, [], 5000, {});
        setGa(ga4react);
        await ga4react.initialize();
        await ga4react.pageview(window.location.pathname);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const event = async (name, data) => {
    try {
      var trackingId = window.config.ga_tracking_id;
      if (trackingId) {
        await ga.gtag("event", name, data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{
        event,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext, AnalyticsProvider };
