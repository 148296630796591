import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import services from "../../Services/patientServices";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import Spinner from '../Spinner/Spinner';
import { TablePagination } from "@mui/material";
import { PyramidContext } from "../../Contexts/uiContext";
import { useNavigate } from "react-router-dom";
import { PatientSetContext } from "../../Contexts/patientSetContext";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 10,
    color: "#78cbf2",
  },
  table: {
    border: "none"
  },
  root: {
    display: "flex",
    justifyContent: "end",
  }
}));



const SimulateTable = () => {
  let history = useNavigate();
  const classes = useStyles();
  const { setInfo } = useContext(PyramidContext);
  const { patientSetInfo } = useContext(PatientSetContext);
  const { patient_set_id } = patientSetInfo;
  
  const [simulateTable, setSimulateTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false)
  const [totalCount, setTotalCount] = useState(1)

  const getSimulateTable = async (_page) => {
    try {      
      setIsFetching(true);
      const data = await services.listSimulationSet(patient_set_id, _page);
      if (data) setIsFetching(false);
      setSimulateTable(data.data.simulations);
      setTotalCount(data.data.simulations.length)
    }
    catch (error) {
      console.log("error simulations list =>", error);
      setIsLoading(false);
      setIsFetching(false);
    } finally {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  const onPageChange = (event, selectedPage) => {
    setPage(selectedPage);
  }

  const sendViewBack = (label, count) => {
    setInfo({
      currentui: label,
      nextui: "",
      prevui: "intervention",
    });
    history({
      pathname: "/interventions",
      state: { data: count },
    });
  };

  useEffect(()=>{
    getSimulateTable(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page]);

  return (
    <div>
      {!isLoading && (
        <div>
          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
            <thead>
              <tr style={{textAlign: "center"}}>
                <th>Simulation Name</th>
                <th>Count</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {
                isFetching && !simulateTable.length ? 
                  <tr>
                    <td className="mt-4" colSpan="42">
                      <Spinner />
                    </td>
                  </tr> :
                  !isFetching && !simulateTable.length ? 
                    <tr>
                      <td colSpan={3} >
                        <Alert severity="warning" className="my-4 text-center" style={{ display: "flex", justifyContent: "center" }}>
                            Simulation data for this {patientSetInfo.cohort_name} is not available yet. Please check back later.
                        </Alert>
                      </td>
                    </tr>
                  
                  :
                    simulateTable && simulateTable.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td onClick={() => sendViewBack("intervention",item.simulated_count)}>
                              <span className="patient-table-link">{item.name}</span>
                            </td>
                            <td><Tooltip
                              className="tooltip-container-xxl"
                              title={
                                <React.Fragment>
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      textAlign: "center",
                                      padding: "10px 3px 3px 10px"
                                    }}
                                  >
                                    <Grid item xs={6} style={{ border: "1px solid #ffffff", width: '50px' }}>
                                      <b>Label</b>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      style={{
                                        border: "1px solid #ffffff",
                                        borderLeft: "none",
                                        width: "50px",
                                      }}
                                    >
                                      <b>Count</b>
                                    </Grid>
                                    {item.simulated_count && JSON.parse(item.simulated_count).map(({ label, selected_count }) => (
                                      <>
                                        <Grid
                                          item
                                          xs={6}
                                          style={{
                                            border: "1px solid #ffffff",
                                            borderTop: "none",
                                          }}
                                        >
                                          <span>{label}</span>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          style={{
                                            border: "1px solid #ffffff",
                                            borderTop: "none",
                                            borderLeft: "none",
                                          }}
                                        >
                                          <span>{selected_count}</span>
                                        </Grid>
                                      </>
                                    ))}
                                  </Grid>
                                </React.Fragment>
                              }
                            >
                              <span>View Counts...</span>
                            </Tooltip>
                          </td>
                          <td>
                            <span>
                              {new Date(item.date).toLocaleString("en-US", {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </td>
                          </tr>
                        </>
                      );
                    })
              }
            </tbody>
        </table>
        </div>)
      }
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {simulateTable.length ? (
        <div className={classes.root}>
          <TablePagination
            component="div"
            count={totalCount}
            page={Number(page - 1)}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            onPageChange={onPageChange}
          />
        </div>
      ) : null}
     
    </div>
  );
};

export default SimulateTable;