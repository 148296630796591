import { v4 as uuidv4 } from 'uuid';

export const deepClone = (obj)  => {
    if(!obj) return
    return JSON.parse(JSON.stringify(obj));
}

// this function splits selected filters from its parent filter
export const splitFilters = (allFilters, data) =>{
    const { name: selectedFilterName , id: selectedFilterId} = data;

    // selected filter is which one being dragged from its parent
    // find the index of parent of current selected filter
    const currentFilterIndex = allFilters.findIndex(({ id }) => id === selectedFilterId);
    // if it is not available in list then return
    if (currentFilterIndex === -1) return allFilters;
    // selecteting parent list in which selected filter is present
    const currentFilter = allFilters[currentFilterIndex];
    // creating a new filter from selected filter
    const newFilter = {
        ...currentFilter,
        id: uuidv4(),
        options: currentFilter.options.filter(({ name }) => name === selectedFilterName)
    };

    // removing selected filter from its parent list
    const modifiedCurrentFilter = {
        ...currentFilter,
        options: currentFilter.options.filter(({ name }) => name !== selectedFilterName)
    };
    
    // updating parent list of selected filter with its updated list
    allFilters[currentFilterIndex] = modifiedCurrentFilter;

    // adding new filters to all filters 
    allFilters.push(newFilter);
    return allFilters;
}


export const findFiltersByKey = (arr, name) =>{
    return arr.find(obj => obj.options.some(option => option.name === name));
}


// this function update the options by using id with new options value
export const updateOptionsById = (options, id, newOptions) => {
    return options.map(item => {
        if (item.id === id) {
            return { ...item, options: [...item.options,newOptions] };
        }
        return item;
    });
};

const reorderList = (appliedFilters, filters) => {
  let list =[];
  appliedFilters.forEach((parent) => {
    list = [...list, parent.options.map((itemA) => {     
      return filters.find((itemB) => (itemB.individual.toString() === itemA.value.toString()) || (itemA?.options?.map((io) => io.value === itemB.value.toString())));
    })];
  });

  const arr = filters.filter(item => item.dropdownSelected)
  return arr.length ? [...arr, ...list.flat()] : list.flat();
};

// this function add join type in filters i.e and , or
export const addJoinTypeKeyInFilters = (appliedFilters, filters) => {
  if (!filters && !appliedFilters) return [];
  const sortedFilters = appliedFilters.length ? reorderList([...appliedFilters], filters) : filters;
  const filtersList =  sortedFilters?.map((filter, index) => {
    const data = appliedFilters?.find((appliedFilter) => {
      return appliedFilter?.options?.some((option) => {
        let val =
          typeof option.value === "number"
            ? Number(filter.individual)
            : filter.individual;
        return option.value === val;
      });
    });

    if (data?.options?.length === 1) {
      filter.joinType = "and";
    } else {
      const optionIndex = data?.options.findIndex(
        // eslint-disable-next-line
        (obj) => obj.key == filter.key && obj.value == filter.individual
      );
      filter.joinType = index === 0 ? "or" : optionIndex === 0 ? "and" : "or";
    }

    return filter;
  });
  return filtersList;
};

export const TooltipTitleDescription = `Filters within a gray chip are combined with a logical OR operator, 
whereas filters in different gray chips are combined with a logical AND operator. 
By default when you first select filters, AND operator is applied.To apply an OR operator 
between filters (white chip), drag the filter (white chip) inside a gray chip.`
