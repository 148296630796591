import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  FormControl,
  FormHelperText,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import swal from "sweetalert";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import DatePicker from "../DatePicker/DatePicker2";
import TextField from "@mui/material/TextField";
import { AnalyticsContext } from "../../Contexts/analyticsContext";

const useStyles = makeStyles({
  root: {
    width: "500px !important",
  },
  inputField: {
    "& label": {
      color: "#495057 !important",
    },
    "& fieldset": {
      borderColor: "#ced4da !important",
    },
  },
});

// function valuetext(value) {
//   return `${value}`;
// }

const FilterDropdown = (props) => {
  const classes = useStyles();
  const { minValue, maxValue, setValue, title, keyName, handleClose } = props;
  const { state, dispatch } = useContext(DropdownFilterContext);
  const { filterOptions } = state;
  const { handleCheckboxChange } = props;
  const [gender, setGender] = useState("none");
  const [genderError, setGenderError] = useState(false);
  const [fromRangeError, setFromRangeError] = useState(false);
  const [toRangeError, setToRangeError] = useState(false);
  const [valueType, setValueType] = React.useState("individual");
  const { event } = useContext(AnalyticsContext);

  useEffect(() => {
    setValue([minValue, maxValue]);
  }, [minValue, maxValue, setValue]);

  useEffect(() => {
    setColumnKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setColumnKeys = () => {
    dispatch({
      type: "set-column-key",
      payload: {
        title: title,
        key: keyName,
        qf: 0,
      },
    });
  };

  const onChange = (e, val) => {
    if (e.target.name === "individual") {
      dispatch({
        type: "individual-mode",
        payload: e.target.value,
      });
      dispatch({
        type: "read-only",
        payload: { fromto: true, individualVal: false, fromToMode: false },
      });
    } else if (e.target.name === "from" || e.target.name === "to") {
      setFromRangeError(false);
      setToRangeError(false);
      dispatch({
        type: "from-to-mode",
        payload: { name: e.target.name, value: e.target.value },
      });
      dispatch({
        type: "read-only",
        payload: { individualVal: true, fromto: false, fromToMode: true },
      });
    } else {
      dispatch({
        type: "date-mode",
        payload: { name: e.target.name, value: e.target.value },
      });
    }
  };

  const formatDate = (val) => {
    const { $D, $y: year, $M } = val;
    let day = $D >= 10 ? $D : `0${$D}`;
    let month = $M >= 9 ? $M + 1 : `0${$M + 1}`;
    let date = `${year}-${month}-${day}`;
    return date;
  };

  const handleDatePickerChange = (name, val) => {
    let value = formatDate(val);
    dispatch({
      type: "from-to-mode",
      payload: { name, value },
    });
  };

  const validator = useCallback(() => {
    let validated = true;

    if (state.identifiers_dropdown.key === "") {
      dispatch({ type: "error", payload: { msg: "* Select any option" } });

      setTimeout(() => {
        dispatch({ type: "error", payload: { msg: "" } });
      }, 3500);

      return (validated = false);
    }

    if (
      state.identifiers_dropdown.key !== "" &&
      !state.identifiers_dropdown.from &&
      !state.identifiers_dropdown.individual
    ) {
      swal(
        "Oops!",
        "Enter valid value for either from-to or individual.",
        "error"
      );
      return (validated = false);
    }

    if (state.identifiers_dropdown.from !== "") {
      validated = true;
    } else if (state.identifiers_dropdown.individual === "") {
      return (validated = false);
    } else {
      validated = true;
    }

    if (state.identifiers_dropdown.qf) {
      validated = true;
    }

    return validated;
  }, [state, dispatch]);

  //Transfer the current state value to DROPDOWN CONTEXT after checking availability of values
  const transferRanges = () => {
    dispatch({ type: "set-page", payload: 1 });
    const validation = validator();
    if (!validation) return false;

    let modifiedObject = {};
    if (state.identifiers_dropdown.qf) {
      if (state.identifiers_dropdown.key === "nlpcontri") {
        dispatch({ type: "set-nlp-quick-filter" });
      }
      modifiedObject = {
        apply: true,
        dropdownSelected: true,
        checkboxSelected: false,
        from: "",
        to: "",
        individual: "",
        key: state.identifiers_dropdown.key,
        fromToMode: state.identifiers_dropdown.fromToMode,
        title: state.identifiers_dropdown.title,
        qf: state.identifiers_dropdown.qf,
      };
      dispatch({ type: "set-filters-qf", payload: modifiedObject });
      state.identifiers_checkbox.options_checkbox.forEach((filter) => {
        filter.options.forEach((opt, index) => {
          if (opt.selected) {
            handleCheckboxChange({ id: index, name: opt.key, selected: 0 });
          }
        });
      });
    } else {
      modifiedObject = {
        apply: true,
        dropdownSelected: true,
        checkboxSelected: false,
        from: state.identifiers_dropdown.from,
        to: state.identifiers_dropdown.to,
        individual: state.identifiers_dropdown.individual,
        key: state.identifiers_dropdown.key,
        fromToMode: state.identifiers_dropdown.fromToMode,
        title: state.identifiers_dropdown.title,
        qf: state.identifiers_dropdown.qf,
      };
      dispatch({ type: "set-filters-other", payload: modifiedObject });
      event("patient_list_column_popup_filter", {
        column_name: modifiedObject.title,
      });
    }

    //create final object based on state here & then pass it to the reducer.
    dispatch({ type: "apply-dropdown", payload: state });
    handleClose();
  };

  const currentDate = useCallback(() => {
    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
      monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
      dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();

    const materialDateInput = `${year}-${month}-${date}`; // combining to format for defaultValue or value attribute of material <TextField>

    dispatch({
      type: "set-dates",
      payload: { from: materialDateInput, to: materialDateInput },
    });
  }, [dispatch]);

  useEffect(() => {
    currentDate();
  }, [filterOptions, currentDate]);

  const handleFilterTypeChange = (event) => {
    setValueType(event.target.value);
  };

  const resetForm = () => {
    handleClose();
  };

  return (
    <div className="filter-modal">
      <div>
        {keyName === "dob" ? (
          <div
            style={{
              display: "flex",
              paddingTop: "10px",
              paddingBottom: "20px",
            }}
          >
            <FormControl size="small" sx={{ mr: 5 }}>
              <DatePicker
                onChange={() => false}
                onAccept={(val) => handleDatePickerChange("from", val)}
                label="From"
                inputFormat="MM/DD/YYYY"
                name="from"
                value={state.identifiers_dropdown.from}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    className={classes.inputField}
                  />
                )}
              />
            </FormControl>
            <FormControl size="small" sx={{ mr: 5 }}>
              <DatePicker
                onChange={() => false}
                onAccept={(val) => handleDatePickerChange("to", val)}
                label="To"
                value={state.identifiers_dropdown.to}
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    className={classes.inputField}
                  />
                )}
              />
            </FormControl>
          </div>
        ) : //  keyName.startsWith("cust_") ? (
        //   <div className={classes.root}>
        //     <Typography id="range-slider" gutterBottom>
        //       Select range:
        //     </Typography>
        //     <Slider
        //       value={value}
        //       name="slider"
        //       onChange={handleChange}
        //       valueLabelDisplay="auto"
        //       aria-labelledby="range-slider"
        //       getAriaValueText={valuetext}
        //       min={minValue}
        //       max={maxValue}
        //     />
        //   </div>
        // ) :
        keyName === "sex" ? (
          <>
            <RadioGroup
              className="gender-radio-group"
              column
              name="individual"
              onChange={(e) => {
                onChange(e);
                setGender(e.target.value);
                setGenderError(false);
              }}
              value={gender}
              readOnly={state.identifiers_dropdown.readOnlyFromTo}
              sx={{ mb: "20px" }}
            >
              <FormControlLabel
                sx={{ m: 0, p: 0 }}
                value="male"
                control={<Radio />}
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
                sx={{ m: 0 }}
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
                sx={{ m: 0 }}
              />
            </RadioGroup>

            {genderError && (
              <FormHelperText style={{ color: "red" }}>
                Please select the gender
              </FormHelperText>
            )}
          </>
        ) : (
          <Grid conatiner direction={"column"} spacing={2}>
            <Grid item paddingTop={0}>
              <RadioGroup
                row
                defaultValue="individual"
                name="radio-buttons-group"
                onChange={handleFilterTypeChange}
              >
                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label="Individual"
                />
                <FormControlLabel
                  value="range"
                  control={<Radio />}
                  label="Range"
                />
              </RadioGroup>
            </Grid>
            <Grid item>
              {valueType === "range" ? (
                <div
                  style={{
                    display: "flex",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  <FormControl size="small" sx={{ mr: 5 }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="from"
                      value={state.identifiers_dropdown.from}
                      placeholder="From"
                      readOnly={state.identifiers_dropdown.readOnlyFromTo}
                      onChange={onChange}
                      disabled={keyName === "sex"}
                      onBlur={() =>
                        dispatch({
                          type: "reset-focus",
                          payload: { bool: false },
                        })
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Specify the inclusive-from start of the range limit"
                    />

                    {fromRangeError && (
                      <FormHelperText style={{ color: "red" }}>
                        Please enter valid range
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl size="small" sx={{ mr: 5 }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="to"
                      value={state.identifiers_dropdown.to}
                      placeholder="To"
                      readOnly={state.identifiers_dropdown.readOnlyFromTo}
                      disabled={keyName === "sex"}
                      onChange={onChange}
                      onBlur={() =>
                        dispatch({
                          type: "reset-focus",
                          payload: { bool: false },
                        })
                      }
                      data-toggle="tooltip"
                      title="Specify the exclusive-to end of the range limit"
                    />

                    {toRangeError && (
                      <FormHelperText style={{ color: "red" }}>
                        Please enter valid range
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              ) : (
                <FormControl sx={{ mb: 3 }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="individual"
                    value={state.identifiers_dropdown.individual}
                    readOnly={state.identifiers_dropdown.readOnlyIndividual}
                    placeholder="Individual Items"
                    onChange={onChange}
                    fullWidth
                    margin="normal"
                    onBlur={() =>
                      dispatch({
                        type: "reset-focus",
                        payload: { bool: false },
                      })
                    }
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <Box display="flex" justifyContent="space-between" paddingTop={2}>
        <Button size={"medium"} onClick={resetForm} variant="outlined">
          Clear
        </Button>

        <Button
          size={"medium"}
          onClick={() => {
            if (
              state.identifiers_dropdown?.from &&
              !state.identifiers_dropdown?.to
            ) {
              setToRangeError(true);
            } else if (
              !state.identifiers_dropdown?.from &&
              state.identifiers_dropdown?.to
            ) {
              setFromRangeError(true);
            } else if (keyName === "sex" && gender === "none") {
              setGenderError(true);
            } else {
              transferRanges();
            }
          }}
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
      </Box>
    </div>
  );
};

export default FilterDropdown;
