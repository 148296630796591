import React, { useState, useEffect } from "react";

const LoaderWithTimer = () => {
  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component unmounts or when the countdown reaches 0
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="loader-container">
      <div className={seconds > 0 ? "loader1" : "loader2"}></div>
      <div className="loader-text">
        {
          seconds > 0 ?
          <p className="time-count-1">{seconds}s</p>
          :
          <p className="time-count-2">
            Taking longer than usual to load. <br /> Please wait...
          </p>
        }
      </div>
    </div>
  );
};

export default LoaderWithTimer;
