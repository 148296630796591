import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getERChartData, getIPChartData, getReadmissionChartData } from "../../Utils/fetchReadmissionChartData";

const ReadmissionChart = (props) => {
  const {inputkey, label, cohort_id } = props;

  const [options, setOptions] = useState([]);
  const [xLabels, setXLabels] = useState([]);

  useEffect(() => {
    
    if(inputkey === "readmission"){
      let series = [];
    let labels = [];
      const readmissionData = getReadmissionChartData(cohort_id, 11);
      Object.entries(readmissionData[0][inputkey]).map(([month, value]) => {
          labels.push(month);
          series.push(value);
          return 0;
      });

      labels.reverse();
      series.reverse();
    
      setXLabels(labels);
      setOptions([{
          name : label,
          data : series
    }]);

    }else if(inputkey === "inpatient_visits"){
      let series = [];
    let labels = [];
      const readmissionData = getIPChartData(cohort_id, 11);
      Object.entries(readmissionData[0][inputkey]).map(([month, value]) => {
          labels.push(month);
          series.push(value);
          return 0;
      });

      labels.reverse();
      series.reverse();
    
      setXLabels(labels);
      setOptions([{
          name : label,
          data : series
    }]);

    }else if(inputkey === "er_visits"){
      let series = [];
      let labels = [];
      const readmissionData = getERChartData(cohort_id, 11);
      Object.entries(readmissionData[0][inputkey]).map(([month, value]) => {
          labels.push(month);
          series.push(value);
          return 0;
      });

      labels.reverse();
    series.reverse();
  
    setXLabels(labels);
    setOptions([{
        name : label,
        data : series
  }]);
    }

  }, [cohort_id, inputkey, label]);

  const optionsRef = {
    plotOptions: { bar: { columnWidth: "16%" } },
    chart: {
        id: "readmission",
        zoom: {
            enabled: false,
        },
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: "smooth",
      width: [5, 2],
      colors: ["#3B9CF8", "#EE7D31"],
    },
    markers: {
      size: 5,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          return y;
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.8,
      },
    },
    xaxis: {
        categories : xLabels,
        tooltip: {
            enabled: false,
        },
    },
    yaxis: {
      title: {
        text: label,
        style: {
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
  };

  return (
    <>
      <div id="chart" className="custom_attr_chart">
        {options && (
          <Chart
            options={optionsRef}
            series={options}
            type="line"
            height={230}
            max-width={500}
          />
        )}
      </div>
    </>
  );
};

export default ReadmissionChart;
