import React, { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import { PatientSetContext } from "../../../Contexts/patientSetContext";

const Histogram = (props) => {
  const { patientSetInfo } = useContext(PatientSetContext);

  const [series, setSeries] = useState([
    {
      data: [],
    }
  ]);

  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
      width: "500px",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        rangeBarOverlap: false,
        fill: "#ff0000",
        dataLabels: {
          position: "top"
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -15,
      style: {
        fontSize: "11px",
        colors: ["#666"],
      },
    },
    yaxis: {
      title: {
        text: "Member Count",
        style: {
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      title: {
        text: "Comprehensive Risk Score",
        style: {
          fontSize: '12px',
          fontWeight: 400,
        },
      },
      categories: [0, 0, 0, 0, 0, 0],
      convertedCatToNumeric: false,
      labels: {
        show: true,
        rotate: 360,
        hideOverlappingLabels: false,
        showDuplicates: true,
        formatter: function (value) {
          return value;
        },
        style: {
          colors: ["#000"],
          fontSize: "11px",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 1000,
    //     options: {
    //       plotOptions: {
    //         bar: {
    //           horizontal: false
    //         }
    //       },
    //       legend: {
    //         position: "bottom"
    //       }
    //     }
    //   }
    // ],
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const range0 = w.globals.labels[dataPointIndex];
        const range1 = w.globals.labels[dataPointIndex + 1] ? w.globals.labels[dataPointIndex + 1] : "";
        const count = series[seriesIndex][dataPointIndex];
        const lastIndex = series[seriesIndex].length - 1;

        let rangeText = range0;
        if (dataPointIndex === lastIndex) {
          rangeText = `>${range0}`;
        } else if (range1) {
          rangeText = `${range0} - ${range1}`;
        }

        return (
          '<div class="arrow_box2">' +
          "<div> <span><b>Risk score range</b>: " +
          rangeText +
          "</span> </div>" +
          "<br/>" +
          `<span><b>${patientSetInfo.patient_name} count</b>: ` +
          count +
          "</span>" +
          "</div>"
        );
      },
    },
    title: {
      text: "",
    },
  });


  useEffect(() => {
    setSeries(props.histoSeries);
    setOptions(prevState => ({
      ...prevState,
      xaxis: props.histoOptions.xaxis
    }));

  }, [props.histoSeries, props.histoOptions])

  return (
    <div className="mixed-chart">
      <Chart
        options={options}
        series={series}
        type="bar"
        width="95%"
      />
    </div>
  );
}

export default Histogram;
