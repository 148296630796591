const getDataToExport = (datatoadd, title = "") => {
  const patient_set = localStorage.getItem("patient_set");
  const { user } = JSON.parse(localStorage.getItem("user"));
  const { patient_set_id: pid, selected_date, name } = JSON.parse(patient_set);


  let Row = [
    [`# ${title} for cohort “${name}”`],
    [`# cohort_id = ${pid}, calculation_date = ${selected_date}, export date = ${new Date()}, exported by = ${user.user_id},`],
    [""],
  ];

  let header = [];
  Object.keys(datatoadd[0]).map((key) => {
    if(key === "data_sources"){
      return null;
    }
    header.push(`${key}`);
    return null;
  });

  Row.push(header);

  datatoadd.map((int) => {
    let newdata = [];
    Object.keys(int).map((key) => {
      if(key === "data_sources"){
        return null;
      }
      if (typeof int[key] === "number") {
        newdata.push(`${int[key]}`);
        return null;
      } else {
        newdata.push(int[key]);
        return null;
      }
    });
    Row.push(newdata);
    return null;
  });

  return Row;
};

export default getDataToExport;
