import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../Contexts/authContext";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const { state: { isFetching, isLoggedIn } } = useContext(AuthContext);
  const userLoggedOut = JSON.parse(localStorage.getItem("user_logged_out"));

  if (isFetching) {
    return null; // Optionally return a loading spinner
  }

  if (isLoggedIn || userLoggedOut) {
    return <Element {...rest} />;
  } else {
    return <Navigate to="/" state={{ from: rest.location }} />;;
  }
};

export default PrivateRoute;
