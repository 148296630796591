import React, { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import { PatientsNumberContext } from "../../Contexts/totalPatientsContext";
import { PatientSetContext } from "../../Contexts/patientSetContext";

const BarChart = (props) => {
  const {
    setFilteredDate,
    impCalculationTime,
    impServiceDateEnd,
    compCalculationTime,
    compServiceDateEnd
  } = useContext(PatientsNumberContext);
  const { patientSetInfo, updatePatientSetInfo } = useContext(PatientSetContext);
  const { selected_date } = patientSetInfo;

  let filterVal = selected_date;

  const selectable_date = props.type === "comp" ? compServiceDateEnd : impServiceDateEnd;
  const calculation_time = props.type === "comp" ? compCalculationTime : impCalculationTime;


  const handleDateChange = (value) => {
    if(props.reportIframe) return null;
    if(value === selected_date) return
    setFilteredDate(value);
    updatePatientSetInfo({...patientSetInfo, selected_date: value});
    window.location.reload();
  };


  const [series, setSeries] = useState([
    {
      name: "Low Risk",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "Moderate Risk",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "High Risk",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "Critical Risk",
      type: 'column',
      data: [0, 0, 0, 0],
    },
    {
      name: "Median",
      type: 'line',
      data: [0, 0, 0, 0],
    }
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 400,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          if (selectable_date[config.dataPointIndex] !== selected_date)
            event.target.style.cursor = "pointer";
          else event.target.style.cursor = "default"
        },
        dataPointSelection: (event, chartContext, config) => {
          handleDateChange(filterVal);
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        rangeBarOverlap: false,
      },
    },
    colors: ['#88d479', '#a6e7db', '#fcad60', '#f4755f', '#0082b2'],
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: "category",
      categories: [0, 0, 0, 0],
      tooltip: {
        enabled: true,
        formatter: function (value) {
          const filter = [value - 1];
          const filterDate = selectable_date.filter((el, i) => filter.some(j => i === j))
          const calculateDate = calculation_time.filter((el, i) => filter.some(j => i === j))
          filterVal = filterDate[0];
          return `Service dates up to including ${filterDate} <br/>  Last calculated on ${calculateDate}`;
        },
      },
    },
    yaxis: [
      {
        seriesName: 'Low Risk',
        title: {
          text: "Member Count",
          style: {
            fontSize: '12px',
            fontWeight: 400,
          },
        },
        labels: {
          formatter: function (value) {
            return value;
          }
        },
        axisBorder: {
          show: true,
          color: '#000'
        },
      }, 
      {
        seriesName: 'Low Risk',
        show: false,
      }, 
      {
        seriesName: 'Low Risk',
        show: false,
      }, 
      {
        seriesName: 'Low Risk',
        show: false,
      }, 
      {
        opposite: true,
        seriesName: 'Median',
        tickAmount: 6,
        min: 0,
        max: 3,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#0082b2'
        },
        labels: {
          style: {
            colors: '#0082b2',
          },
        },
        title: {
          text: props.type === "comp" ? "Median Risk" : "Median Impactability",
          style: {
            color: '#0082b2',
            fontWeight: 700,
            fontSize:' 12px'
          }
        },
      }
    ],
    legend: {
      show: false,
    },
    tooltip: {
      inverseOrder: true,
      followCursor: true,
      shared: true,
      fillSeriesColor: true,
      onDatasetHover: {
        highlightDataSeries: true,
      },
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetX: 0,
        offsetY: -60,
      },
      x: {
        show: false
      },
      y: {
        formatter: function(value) {
          return value;
        },
        title: {
          formatter: (seriesName) => {
            return seriesName + ":"
          },
        },
      }
    }
  });

  useEffect(() => {
    setSeries(props.series);
    setOptions(prevState => ({
      ...prevState,
      xaxis: props.options.xaxis
    }));
  }, [props.options, props.series]); 

  return (
    <div id="chart">
      <Chart
        options={options}
        series={series}
        type="line"
        height={240}
        max-width={500}
      />
    </div>
  );
}

export default BarChart;
