import READMISSION_CHART_DATA from "../constants/Readmission_chart_data.json";
import IP_CHART_DATA from "../constants/IP_chart_data.json";
import ER_CHART_DATA from "../constants/ER_chart_data.json";

const getReadmissionChartData = (cohort_id, time_frame) =>{

    if(cohort_id === "ALL"){
        let result = READMISSION_CHART_DATA.map((doc)=>{
            let _readmission = Object.fromEntries(Object.entries(doc["readmission"]).slice(0, time_frame+1));
            return {
                ...doc,
                readmission : _readmission
            }
        })

        return result;
    }else{
        let filterData = READMISSION_CHART_DATA.filter((doc)=>(doc.cohort_id.toLowerCase() === cohort_id.toLowerCase()));
        filterData[0]["readmission"] = Object.fromEntries(Object.entries(filterData[0]["readmission"]).slice(0, time_frame+1));
        return filterData;
    }

}

const getIPChartData = (cohort_id, time_frame) =>{

    if(cohort_id === "ALL"){
        let result = IP_CHART_DATA.map((doc)=>{
            let _readmission = Object.fromEntries(Object.entries(doc["inpatient_visits"]).slice(0, time_frame+1));
            return {
                ...doc,
                inpatient_visits : _readmission
            }
        })

        return result;
    }else{
        let filterData = IP_CHART_DATA.filter((doc)=>(doc.cohort_id.toLowerCase() === cohort_id.toLowerCase()));
        filterData[0]["inpatient_visits"] = Object.fromEntries(Object.entries(filterData[0]["inpatient_visits"]).slice(0, time_frame+1));
        return filterData;
    }

}

const getERChartData = (cohort_id, time_frame) =>{

    if(cohort_id === "ALL"){
        let result = ER_CHART_DATA.map((doc)=>{
            let _readmission = Object.fromEntries(Object.entries(doc["er_visits"]).slice(0, time_frame+1));
            return {
                ...doc,
                er_visits : _readmission
            }
        })

        return result;
    }else{
        let filterData = ER_CHART_DATA.filter((doc)=>(doc.cohort_id.toLowerCase() === cohort_id.toLowerCase()));
        filterData[0]["er_visits"] = Object.fromEntries(Object.entries(filterData[0]["er_visits"]).slice(0, time_frame+1));
        return filterData;
    }

}

export {
    getReadmissionChartData,
    getIPChartData,
    getERChartData
}