import React, { useState, useContext, useEffect, useCallback } from "react";
import CustomizedSlider from "../../Components/Sliders/CustomizedSlider/CustomizedSlider";
import formatPercent from "../../Utils/formatPercent";
import { InterventionColorContext } from "../../Contexts/interventionColor";
import { Tooltip } from "@mui/material";

const InterventionWhatIfScenario = ({
  interventions,
  setinterventions,
  actions,
  countType,
  newCount: newCountState,
  analysisType
}) => {
  useEffect(() => { }, [actions]);

  const { intervention } = useContext(InterventionColorContext);

  const [patientSet] = useState([]);

  const newCount = useCallback(
    (newValue, value, id) => {
      let percent_diff = 0;
      const updateddata = interventions.map((ele, index) => {
        var returnValue = { ...ele };
        if (index === id) {
          percent_diff = formatPercent(value, ele[countType]);
          returnValue.new_count = Number(value);
          returnValue.relative = Number(percent_diff);
          returnValue.selected_count = newValue;
        }
        return returnValue;
      });

      //localStorage.setItem(serviceName.key, JSON.stringify(updateddata));
      setinterventions(updateddata);
    },
    [interventions, setinterventions, countType]
  );

  let renderInterventionsData = interventions.map((ele, i) => {
    const current_count = ele.new_count ? ele.new_count : ele[countType];

    const calculateAdditionalPatients = () => {
      if(ele.new_count === 0) {
        return 0;
      }
      else {
        const diff = ele.new_count - ele[countType];
        const sign = diff===0?'':diff>0?'+':'-';
        return `${sign}${Math.abs(diff)}`;
      }
    };

    const calculateAnnualSavings = () => {
      const savings = (ele.cost_pre - ele.cost_post) * 12;
      const additionalPatients = ele.new_count === 0 ? 0 : ele.new_count - ele[countType];
      var annualSavings = savings * additionalPatients;
      return annualSavings===0?0:annualSavings;
    };

    const formatAnnualSavings = () => {
      const formattedCount = calculateAnnualSavings().toLocaleString('en-US', { 
        style: 'currency', 
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formattedCount 
    };

    return (
      <div
        key={i}
        className="diabetOut diabetOut_part filter-2"
      >
        <div
          className={
            intervention.active
              ? `diabet_left_active ca-flex`
              : `diabet_left_inactive ca-flex`
          }
        >
          <div className="diabMetr clearfix" key={i}>
            <Tooltip placement="top" title={ele.tooltip} interactive arrow>
              <span className="diabLabl intrv-label">{ele.label}</span>
            </Tooltip>
            <CustomizedSlider
              id={i}
              abbr={ele.abbr}
              type="intervention"
              label={ele.label}
              minElig={0}
              maxElig={ele[`max_${countType}`]}
              tabledata={patientSet}
              setSliderValue={newCount}
              sliderValue={ele[countType]}
              sliderPreviousValue={
                ele.new_count ? ele.new_count : ele[countType]
              }
              tooltip={ele.tooltip}
              intrv_type={ele.intrv_type}
              topWidget={true}
              mnemo={"inprov"}
              rtype={"interventionWhatIf"}
            />
          </div>
        </div>
        <div className="diabet_right">
          <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr key={i} style={{height: "30px"}}>
                {analysisType === "cost_analysis" ? 
                  <><td width="33.33%">{current_count}</td>
                    <td width="33.33%">
                      {calculateAdditionalPatients()}
                      {" "}
                      (
                      {ele.new_count
                        ? formatPercent(ele.new_count - ele[countType], ele[countType])
                        : ele.relative}
                      %)
                    </td>
                    <td width="33.33%">
                      {calculateAnnualSavings() === 0 ? null : calculateAnnualSavings() > 0 ?
                        (
                          <span className="incrementSaving" />
                        ) : (
                          <span className="decrementSaving" />
                        )}
                      {formatAnnualSavings()}
                    </td>
                  </> 
                  :
                  <><td width="25%">{ele.actively_targeted || '-'}</td>
                    <td width="25%">{ele.further_targeted ||'-'}</td>
                    <td width="25%">{ele.closure_percentage ||'-'}</td>
                    <td width="25%">{ele.gap_closure_effect ||'-'}</td>
                  </>}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });

  return <div className="intervention-scroll-container">{renderInterventionsData}</div>;
};

export default InterventionWhatIfScenario;
