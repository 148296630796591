import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Skeleton } from "@mui/material";
import "./ReportSelection.scss";
import withRouter from "../../Utils/withRouterHOC";
import reportServices from "../../Services/reportServices";
import { sidebarContext } from "../../Contexts/sidebarContext";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import { IframeContext } from "../../Contexts/iframeContext";
import { useNavigate } from "react-router-dom";


const ReportSelection = ({ isGenesisIframe }) => {
  const { showSidebar, setSidebarVisibility } = useContext(sidebarContext);
  const [reportList, setReportList] = useState([]);
  const { setPageTitle } = useContext(MetaDetailsContext);
  const { setIsGenesisReport, setChartName } = useContext(IframeContext);
  const history = useNavigate();

  const changeRoute = (route) => {
    history(route);
    if(isGenesisIframe){
      setIsGenesisReport(true)
      setChartName("reports")
    }
  };

  const fetchReports = async () => {
    try {
      const response = await reportServices.getReportList();
      const { list } = response.data.data;
      let updatedList = list;
      if(isGenesisIframe){
        let featuresList = ["genesis-client-report", "genesis-clinical-staff-service-report"];
        updatedList = list.filter(({feature}) => featuresList.includes(feature))
      }
      setReportList(updatedList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPageTitle("FHS: Reports");
    fetchReports();
    if(isGenesisIframe){
      setSidebarVisibility(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Sidebar pageTitle="Foresight  Reports">
        <div
          className={`emulationOuter ${
            showSidebar ? "report-background" : ""
            } pb-5`}
        >
          <div className="reportWrapper">
            <div className="reportItemContainer">
              {reportList.length ? reportList.map((element, index) => (
                <Card
                  className="reportItem"
                  key={index}
                  onClick={() => changeRoute(element.path)}
                >
                  <CardActionArea sx={{ padding: 0, height: "100%" }}>
                    <CardContent sx={{ padding: 0, height: "100%" }}>
                      <Typography
                        className="reportTitle"
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                        {element.title}
                      </Typography>
                      <Typography
                        className="reportDescription"
                        variant="body2"
                        color="text.secondary"
                        style={{ fontSize: '1rem' }}
                      >
                        {element.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )) :
                new Array(5).fill(0).map((_, index) => (
                  <Card
                    className="reportItem"
                    key={index}>
                    <CardContent sx={{ padding: 0, paddingBottom: '0 !important', height: "100%" }}>
                      <Skeleton
                        animation="wave"
                        height={12}
                        width="60%"
                        style={{ margin: '24px 12px' }}
                      />
                      <Skeleton sx={{ height: '100%' }} animation="wave" variant="rectangular" />
                    </CardContent>
                  </Card>
                ))
              }
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default withRouter(ReportSelection);
