import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#78cbf2",
  },
}));

const BackdropLoader = ({ isFetching, background }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={isFetching} sx={{
      background: background || "rgba(0, 0, 0, 0.5)"
    }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLoader;