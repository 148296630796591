import React, { createContext, useReducer } from "react";

const DropdownFilterContext = createContext();

const initialState = {
    identifiers_dropdown: {
        dropdownSelected: false,
        title: "",
        key: "",
        from: "",
        to: "",
        apply: false,
        qf: false,
        individual: "",
        errorMsg: "",
        dateFrom: "",
        dateTo: "",
        fromToMode: false,
        readOnlyFromTo: false,
        readOnlyIndividual: false,
    },
    identifiers_checkbox: {
        checkboxSelected: false,
        checkboxTriggered: false,
        options_checkbox: [
            {
                title: "",
                options: [
                    {
                        name: "",
                        key: "",
                        label: "",
                        checked: false,
                    },
                ],
            },
        ],
        selected_checkboxes: [],
    },
    selectedFilters: [],
    selectedFiltersCheckbox: [],
    filteredData: [],
    filteredCoordinates: {
        coordinates: [],
        markerList: [],
        count: 0
    },
    columns: [],
    selectedColumns: [],
    filterOptions: [
        // {
        //     title: 'NLP-identified-contributor',
        //     key: 'nlpcontri',
        //     quickFilter: true
        // }
    ],
    isFetching: true,
    page: 1,
    totalCount: 0,
    inactivePatients: false,
    nlp_filter: {
        patientsWithEMRNotes: {
            label: 'Patients with EMR notes only',
            name: 'patientsWithEMRNotes',
            value: 0,
            disabled: false
        },
        patientWithNLPContributor: {
            label: 'Patients with NLP-identified contributors only',
            name: 'patientWithNLPContributor',
            value: 0,
            disabled: false
        },
        patientWithNLPInterventions: {
            label: 'Patients with NLP-identified interventions only',
            name: 'patientWithNLPInterventions',
            value: 0,
            disabled: false
        },
        patientNLPVerifiedContributor: {
            label: 'Patients with verified NLP-identified contributors only',
            name: 'patientNLPVerifiedContributor',
            value: 0,
            disabled: false
        },
    },
    filterName: null,
    isSavedFilterApplied: false,
    appliedFilterCheckboxes:{
        isMergingFilters: false,
        appliedFilterCheckboxesList: []
    },
    outlierFilter : {}
};
const setInputValues = (state, action) => {

    switch (action.type) {
        case "set-checkboxes-options":
            return {
                ...state,
                identifiers_checkbox: {
                    ...state.identifiers_checkbox,
                    options_checkbox: action.payload.options,
                    selected_checkboxes: action.payload.checkBoxes,
                },
            };

        case "update-checkboxes":
            return {
                ...state,
                identifiers_checkbox: {
                    checkboxSelected: action.payload.checkboxSelected,
                    options_checkbox: action.payload.options_checkbox,
                    selected_checkboxes: action.payload.selected_checkboxes,
                },
            };

        case "reverse-checkbox":
            return {
                ...state,
                identifiers_checkbox: {
                    ...state.identifiers_checkbox,
                    checkboxSelected: action.payload,
                },
            };

        case "apply-dropdown":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    dropdownSelected: true,
                    apply: true,
                    from: action.payload.identifiers_dropdown.from,
                    to: action.payload.identifiers_dropdown.to,
                    individual: action.payload.identifiers_dropdown.individual,
                    key: action.payload.identifiers_dropdown.key,
                    title: action.payload.identifiers_dropdown.title,
                    qf: action.payload.identifiers_dropdown.qf,
                },
            };

        case "set-filters-checkbox":
            return {
                ...state,
                selectedFiltersCheckbox: [].concat(
                    state.selectedFiltersCheckbox.filter(
                        (ele) => ele.key === action.payload.key
                    ),
                    action.payload
                ),
            };

        case "set-filters-other":
            let filterExists = state.selectedFilters.filter(
                (ele) => ele.key !== action.payload.key
            );

            return {
                ...state,
                selectedFilters: [...filterExists, action.payload],
            };

        case "set-filters-qf":
            return {
                ...state,
                selectedFilters: [action.payload],
                selectedFiltersCheckbox: [],
            };

        case "set-patients":
            return {
                ...state,
                filteredData: action.payload,
            };

        case "set-geo-spatial":
            return {
                ...state,
                filteredCoordinates:{
                    ...state.filteredCoordinates,
                    coordinates: action.payload.count === 0 ? [] : [...state.filteredCoordinates.coordinates, ...action.payload.coordinates],
                    count: action.payload.count === 0 ? 0 : state.filteredCoordinates.coordinates.length + action.payload.coordinates.length,
                }
            };
        
        case "set-key-quick":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    apply: false,
                    title: action.payload.keyselected[0].title,
                    key: action.payload.keyselected[0].key,
                    qf: action.payload.keyselected[0].quickFilter,
                },
            };

        case "set-key-other":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    apply: false,
                    title: action.payload[0].title,
                    key: action.payload[0].key,
                    qf: action.payload[0].quickFilter,
                }, 
            };
        case "set-column-key": 
            return {
                    ...state,
                    identifiers_dropdown: {
                        ...state.identifiers_dropdown,
                        title: action.payload.title,
                        key: action.payload.key,
                        qf: action.payload.quickFilter,
                    },
            };
        case "error":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    errorMsg: action.payload.msg,
                },
            };

        case "from-to-mode":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    fromToMode: true,
                    individual: "",
                    [action.payload.name]: action.payload.value,
                },
            };

        case "individual-mode":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    fromToMode: false,
                    from: "",
                    to: "",
                    individual: action.payload,
                },
            };

        case "read-only":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    readOnlyFromTo: action.payload.fromto,
                    readOnlyIndividual: action.payload.individualVal,
                    fromToMode: action.payload.fromToMode,
                },
            };

        case "reset-focus":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    readOnlyFromTo: action.payload.bool,
                    readOnlyIndividual: action.payload.bool,
                },
            };

        case "set-dates":
            return {
                ...state,
                identifiers_dropdown: {
                    ...state.identifiers_dropdown,
                    dateFrom: action.payload.from,
                    dateTo: action.payload.to,
                },
            };

        case "reset-state":
            return {
                ...state,
                selectedFilters: state.selectedFilters.filter(
                    (filter) => filter.key !== action.payload.id
                ),
                nlp_filter: {
                    patientsWithEMRNotes: {
                        label: 'Patients with EMR notes only',
                        name: 'patientsWithEMRNotes',
                        value: 0
                    },
                    patientWithNLPContributor: {
                        label: 'Patients with NLP-identified contributors only',
                        name: 'patientWithNLPContributor',
                        value: 0
                    },
                    patientWithNLPInterventions: {
                        label: 'Patients with NLP-identified interventions only',
                        name: 'patientWithNLPInterventions',
                        value: 0
                    },
                    patientNLPVerifiedContributor: {
                        label: 'Patients with verified NLP-identified contributors only',
                        name: 'patientNLPVerifiedContributor',
                        value: 0
                    },
                }
            };

        case "reset-other-filters":
            return {
                ...state,
                ...initialState
            };

        case "reset-from-to":
            return {
                ...state,
                identifiers_dropdown: {
                    dropdownSelected: false,
                    title: "",
                    key: "",
                    from: "",
                    to: "",
                    apply: false,
                    qf: false,
                    individual: "",
                    errorMsg: "",
                    dateFrom: "",
                    dateTo: "",
                    fromToMode: false,
                    readOnlyFromTo: false,
                    readOnlyIndividual: false,
                },
            };
        
        case "reset-date":
            return {
                ...state,
                identifiers_dropdown: {
                    dateFrom: null,
                    dateTo: null
                },
            }
        case "set-patient-columns":  //Waimanalo org_guid - 3aae120b-0882-479f-88d9-be402dfb020d
            return {
              ...state,
              columns: action.payload,
              selectedColumns: action.payload
                .filter((item) => item.isAvailable && item.isSelected),
              filterOptions: [
                ...initialState.filterOptions,
                ...action.payload.filter(
                  (item) => item.isAvailable && item.isSelected
                ),
              ],
            };
        case "set-is-fetching":
            return {
                ...state,
                isFetching: action.payload
            };
        case "set-page":
        return {
            ...state,
            page: action.payload
        };
        case "set-total-count":
        return {
            ...state,
            totalCount: action.payload
        };
        case "set-inactive-patients":
            return {
                ...state,
                inactivePatients: action.payload
            };
        case "set-nlp-filter": 
            return {
                ...state,
                nlp_filter: {
                    ...state.nlp_filter, [action.payload.name]: {...state.nlp_filter[action.payload.name], value: action.payload.value}
                }
            }
        case "disable-nlp-filter":
            return {
                ...state,
                nlp_filter: action.payload
            }
        case "set-nlp-quick-filter":
            return {
                ...state,
                nlp_filter: {
                ...state.nlp_filter,
                patientsWithEMRNotes: {
                    ...state.nlp_filter.patientsWithEMRNotes,
                    value: 1,
                },
                patientWithNLPContributor: {
                    ...state.nlp_filter.patientWithNLPContributor,
                value: 2,
                },
            },
            };
        
        case "load-filters":
            return {
                ...state,
                ...action.payload
            };
        
        case "reset-loaded-filters":
                return {
                    ...state,
                    ...initialState,
                    filterName: null,
                    isSavedFilterApplied: false,
                };

        case "applied-checkbox-filter":
            return {
                ...state,
                appliedFilterCheckboxes: {
                        ...state.appliedFilterCheckboxes,
                        appliedFilterCheckboxesList :[ 
                        ...state.appliedFilterCheckboxes.appliedFilterCheckboxesList, action.payload
                    ]
                }
            };
            
        case "applied-checkbox-filter-update":
            return {
                ...state,
                appliedFilterCheckboxes: {
                        ...state.appliedFilterCheckboxes,
                        appliedFilterCheckboxesList : action.payload.data,
                        isMergingFilters: action.payload.isMergingFilters
                }
            };
        case "applied-outlier-filter":
            return {
                ...state,
                outlierFilter: { ...state.outlierFilter, ...action.payload } 
            };
        default:
            return null;
    }
};

function DropdownFilterProvider(props) {
    const [state, dispatch] = useReducer(setInputValues, initialState);

    return (
        <DropdownFilterContext.Provider value={{ state, dispatch }}>
            {props.children}
        </DropdownFilterContext.Provider>
    );
}

export { DropdownFilterContext, DropdownFilterProvider };
