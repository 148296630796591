import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import AlgoliaAutocomplete from "../../Components/Autocomplete/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

import Button from "@mui/material/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import "./PatientNotes.scss";

import MultiSelectBar from "../MultiSelectBar/MultiSelectBar";
import { Typography } from "@mui/material";

import patientServices from "../../Services/patientServices";
import { usePatientSetContext } from "../../Contexts/patientSetContext";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";

import CircularProgress from "@mui/material/CircularProgress";
import { usePatientContext } from "../../Contexts/patientContext";
import formatHeading from "../../Utils/formatHeading";
import { useToastContext } from "../../Contexts/ToastContext";
import TabPanel from "../TabPanel/TabPanel";

const appId = "DWZGOQ2XBO";
const apiKey = "f997aa0b37b7f3e93ef91093556ea7d2";
const searchClient = algoliasearch(appId, apiKey);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PatientNotes = ({ patientData }) => {
  const { patient_set_id = "" } = usePatientSetContext();
  const {
    emrNotesAvailable,
    contributorsFilter,
    interventionFilter,
    patientNote,
    handleSelectionChange,
    isFetchingNotes,
  } = usePatientContext();

  const { patient_id } = patientData || {};

  return (
    <>

      {!isFetchingNotes ? (
        <div className="patient-notes">
          <div className="patient-notes-wrapper">
            <div>
              {emrNotesAvailable && (
                <div className="notes-dropdown-wrapper">
                  <MultiSelectBar
                    list={contributorsFilter}
                    title={"Contributors"}
                    onSelectionChange={(selectedOptions) =>
                      handleSelectionChange("Contributors", selectedOptions)
                    }
                  />
                  <MultiSelectBar
                    list={interventionFilter}
                    title={"Intervention"}
                    onSelectionChange={(selectedOptions) =>
                      handleSelectionChange("Intervention", selectedOptions)
                    }
                  />
                </div>
              )}

              <div className="notes-wrapper">
                {emrNotesAvailable ? (
                  <div>
                    {patientNote.map((note, index) => (
                      <div key={index} className="note-row">
                        <div className="note-title-row">
                          <span>{note.date}</span>
                          <span>{note.title}</span>
                          {note.contributors_abbr.length ? note.contributors_abbr.map(abbr => {
                            const text = contributorsFilter.find(i => i.abbr === abbr)?.label || '';
                            return (
                              <Button
                                variant="outlined"
                                className={"cont-btn"}
                              >
                                {text}
                              </Button>
                            )
                          }) : null}
                          {note.intervention_abbr.length ? note.intervention_abbr.map(abbr => {
                            const text = interventionFilter.find(i => i.abbr === abbr)?.label || '';
                            return (
                              <Button
                                variant="outlined"
                                className={"interv-btn"}>
                                {text}
                              </Button>
                            )
                          }) : null}
                        </div>
                        <p>
                          <HighlightedButton note={note} org_patient_id={patientData.org_patient_id} />
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className="pn-unavailable">
                    <strong>{`No data available!`}</strong>
                  </span>
                )}
              </div>
            </div>

            {emrNotesAvailable && (
              <div className="code-dismiss-wrapper">
                <span>
                  Note: Click
                  <Button
                    style={{ margin: "0px 5px" }}
                    size="small"
                    variant="outlined"
                    className="cont-btn"
                  />
                  button to dismiss contributor and
                  <Button
                    style={{ margin: "0px 5px" }}
                    size="small"
                    variant="outlined"
                    className="interv-btn"
                  />{" "}
                  button to dismiss intervention
                </span>
                <SearchIcdBar
                  patient_set_id={patient_set_id}
                  patient_id={patient_id}
                />
                {/* <DismissInputBox /> */}
              </div>
            )}
          </div>
          {emrNotesAvailable && (
            <div className="notes-tab-wrapper">
              <TabsWithTable patient_id={patient_id} />
            </div>
          )}
        </div>
      ) : (
        <div className="patient-notes loading-wrapper">
          <CircularProgress />
        </div>
      )}

    </>
  );
};

export default PatientNotes;

/* --------------- Dismiss highlighted button --------------- */
export const HighlightedButton = ({ note, org_patient_id }) => {
  const { showToast } = useToastContext();
  const { FetchPatientNotes, patientId } = usePatientContext();
  const { patientSetInfo } = usePatientSetContext();
  const  { selected_date, patient_set_id } = patientSetInfo || {};

  const { note_text: text = "", searchTerms: highlightedText = [] } = note;
  const [openDialog, setOpenDialog] = useState(false);
  const [dismissObj, setDismissObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dismissReason, setDismissReason] = useState("");

  const reasonList = ["not currently applicable", "incorrect identification", "other"]

  if (highlightedText.length === 0) return <span>{text}</span>;

  const terms = highlightedText.map((item) => item.termSearched);
  const searchRegex = new RegExp(
    `(${terms.toString().replace(/,/g, "|")})`,
    "gi"
  );
  const textParts = text.split(searchRegex);

  const showDialog = (part) => {
    const dismissObj = highlightedText.find((h) => h.termSearched === part);

    if (dismissObj) {
      const { reason } = dismissObj || "";
      setDismissObj(dismissObj);
      setDismissReason(reason);
      setOpenDialog(true);
    }
  };


  const handleChange = (event) => {
    setDismissReason(event.target.value);
  };

  const handleDismiss = async () => {
    if (!dismissReason) return;

    try {
      setIsLoading(true);
      const payload = {
        org_patient_id : org_patient_id,
        cohort_id : patient_set_id,
        selected_date : selected_date,
        note_id: note.note_id,
        note_date: note.date,
        abbr: dismissObj.abbr,
        patient_id: patientId,
        type: dismissObj.type,
        reason: dismissReason,
      };

      await patientServices.dismissPatientNote(payload);
      setDismissReason("");
      setOpenDialog(false);
      showToast("Note dismissed successfully");
      setIsLoading(false);
      // after dismissal fetch new patient notes
      FetchPatientNotes();
    } catch (error) {
      console.error("Error dismissing patient note:", error);
      showToast("Error while dismissing a note", "error");
      setIsLoading(false);
    }
  };

  const getClassName = (part) => {
    const isContributor = highlightedText.find((h) => h.termSearched === part);
    return isContributor.isDismissed
      ? "dismissed-btn"
      : isContributor.type === "contributor"
      ? "cont-btn"
      : "interv-btn";
  };

  return (
    <span>
      {textParts
        .filter((part) => part)
        .map((part, i) =>
          searchRegex.test(part) ? (
            <Button
              size="small"
              onClick={() => showDialog(part)}
              key={i}
              className={getClassName(part)}
            >
              {part}
            </Button>
          ) : (
            <span key={i}>{part}</span>
          )
        )}

      <Dialog
        sx={{ m: 0, p: 2 }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Dismiss Note</DialogTitle>
        <IconButton
          onClick={() => setOpenDialog(false)}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <p>
            Are you sure you want to dismiss{" "}
            <strong>{dismissObj?.termSearched}</strong> for this note{" "}
            <strong>{note?.title}</strong>?
          </p>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Dismiss Reason</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dismissReason}
              label="Select Dismiss Reason"
              onChange={handleChange}
            >
              {reasonList.map((item, index) => {
                return <MenuItem className="menu-item" value={item} key={index}>
                  {item}
                </MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDismiss} autoFocus disabled={isLoading}>
            {isLoading ? <CircularProgress size={20} /> : "Dismiss"}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};
/* --------------- Dismiss highlighted button --------------- */

/* --------------- Icd search bar --------------- */
const SearchIcdBar = ({ patient_set_id, patient_id }) => {
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToastContext();
  const { FetchIcdCodes } = usePatientContext();

  const addCode = (code) => {
    const findCode = selectedCodes.find(
      (item) => item.objectID === code.objectID
    );
    if (findCode) {
      removeCode(code.objectID);
    } else {
      setSelectedCodes([...selectedCodes, code]);
    }
  };

  const removeCode = (id) => {
    const newCodes = selectedCodes.filter((item) => item.objectID !== id);
    setSelectedCodes(newCodes);
  };

  const checkIfSelected = (id) => {
    const findCode = selectedCodes.find((item) => item.objectID === id);
    if (findCode) return true;
    else return false;
  };

  const codeIcdCodes = async () => {
    const { user } = JSON.parse(localStorage.getItem("user"));
    console.log(selectedCodes, patient_id, patient_set_id);
    if (selectedCodes.length === 0) return;

    try {
      setLoading(true);

      await patientServices.icdCodes({
        verified_on: new Date().toUTCString(),
        patient_id: patient_id,
        cohort_id: patient_set_id,
        icdCodes: selectedCodes.map(({ code, title }) => {
          return { code, title };
        }),
        verified_by_user: user.user_id,
      });
      setSelectedCodes([]);
      setLoading(false);
      showToast("Icd10 codes added successfully");
      FetchIcdCodes();
    } catch (error) {
      console.log(error);
      showToast("Error while adding Icd10 codes", "error");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="icd-search-container">
        <AlgoliaAutocomplete
          placeholder="Search code"
          openOnFocus={true}
          getSources={({ query }) => [
            {
              sourceId: "codes",
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "fhs_icd_10_codes",
                      query,
                    },
                  ],
                });
              },
              templates: {
                item({ item, components }) {
                  return (
                    <ProductItem
                      hit={item}
                      components={components}
                      addCode={addCode}
                      checkIfSelected={checkIfSelected}
                    />
                  );
                },
              },
            },
          ]}
        />

        <Button
          onClick={codeIcdCodes}
          className="action-btn"
          variant="contained"
          color="primary"
        >
          Code
          {loading && (
            <CircularProgress
              color="inherit"
              sx={{
                marginLeft: "5px",
              }}
              size={20}
            />
          )}
        </Button>
      </div>
      <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ rowGap: 1 }}>
        {selectedCodes.map((item) => {
          return (
            <Chip
              key={item.objectID}
              label={`
                ${item.title} (${item.code})
                `}
              variant="outlined"
              onDelete={() => {
                removeCode(item.objectID);
              }}
            />
          );
        })}
      </Stack>
    </>
  );
};

const ProductItem = ({ hit, components, addCode, checkIfSelected }) => {
  return (
    <div
      className="aa-ItemContent"
      style={{ height: "100%" }}
      onClick={(e) => {
        e.preventDefault();
        addCode(hit);
      }}
    >
      <div className="aa-ItemTitle">
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={checkIfSelected(hit.objectID)}
        />
        <components.Highlight hit={hit} attribute="title" />
        (<components.Highlight hit={hit} attribute="code" />)
      </div>
    </div>
  );
};

/* --------------- Icd search bar --------------- */

/* --------------- Tabs --------------- */

const TabsWithTable = () => {
  const [value, setValue] = React.useState(0);
  const [selectlistItem, setSelectedlistItem] = useState("");
  const {
    icdCodesList,
    dismissedInterventions,
    dismissContributors,
    undoNoteDismissal,
    undoDismissalInProgress,
  } = usePatientContext();

  const getHeaders = (list) => {
    if (list.length > 0) {
      return Object.keys(list[0]);
    }
    return [];
  };

  const tabs = [
    {
      title: "coded_ICD codes",
      headers: getHeaders(icdCodesList),
      list: icdCodesList,
    },
    {
      title: "dimiss_contributors",
      headers: getHeaders(dismissContributors),
      list: dismissContributors,
    },
    {
      title: "dismiss_interventions",
      headers: getHeaders(dismissedInterventions),
      list: dismissedInterventions,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (note) => {
    setSelectedlistItem(note["id"]);
    undoNoteDismissal(note["id"]);
  };

  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
          className="dismiss-tabs"
        >
          {tabs.map(({ title }, index) => {
            const [firstLine, secondLine] = title.split("_");
            return (
              <Tab
                key={index}
                label={
                  <div>
                    <Typography className="tab-label" variant="body1">
                      {firstLine}
                    </Typography>
                    <Typography className="tab-label" variant="body1">
                      {secondLine}
                    </Typography>
                  </div>
                }
              />
            );
          })}
        </Tabs>

        {tabs.map(({ headers, list }, index) => (
          <TabPanel value={value} index={index} key={index}>
            {list.length > 0 ? (
              <table className="dismiss-table">
                <thead className="sticky-header">
                  <tr>
                    {headers
                      .filter((heading) => heading !== "id")
                      .map((heading, index) => (
                        <th key={index}>{formatHeading(heading)}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {list.map((listItem, index) => (
                    <tr key={index}>
                      {headers
                        .filter((heading) => heading !== "id")
                        .map((element, index) => (
                          <td key={index}>
                            {element === "undo" ? (
                              undoDismissalInProgress &&
                              selectlistItem === listItem["id"] ? (
                                <CircularProgress size={20} />
                              ) : (
                                <IconButton
                                  onClick={() => handleClick(listItem)}
                                >
                                  <ReplayIcon />
                                </IconButton>
                              )
                            ) : (
                              listItem[element]
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="data-not-available">No data available</div>
            )}
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

/* --------------- Tabs --------------- */
