import { Box, Grid, Skeleton } from "@mui/material";

const GridSkeletonLoader = () => {
  return (
    <Grid item lg={6} sm={12} xs={12} md={12}>
      <div
        className="GraphBg widget-bg-shadow cohortClaimsCosts"
        style={{ minHeight: "300px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Skeleton variant="rectangular" height={20} width={300} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={25} />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export default GridSkeletonLoader;
