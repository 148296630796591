const calculateSeries = (type, data) => {
  const dataToSort = data[type].riskData;
  const barChartData = dataToSort.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  let labels = barChartData.map((ele) => ele.label);
  const seriesdata = barChartData.map((ele) => [
    ele.cat1_cnt?.count,
    ele.cat2_cnt?.count,
    ele.cat3_cnt?.count,
    ele.cat4_cnt?.count,
  ]);
  const linedata = barChartData.map((ele) => [
    //formula to return original median value
    ele.median?.toFixed(2),
  ]);

  const extractedData = seriesdata.length
    ? Object.keys(seriesdata[0]).map((col) => seriesdata.map((row) => row[col]))
    : [];

  const extractedLineData = linedata.length
    ? Object.keys(linedata[0]).map((col) => linedata.map((row) => row[col]))
    : [];

  const transponsedSeries = [],
    transponsedLine = [];

  const riskIndex = {
    0: "Low Risk",
    1: "Moderate Risk",
    2: "High Risk",
    3: "Critical Risk",
  };

  for (let i = 0; i < extractedData.length; i++) {
    const cols = {
      name: riskIndex[i],
      data: extractedData[i],
      type: "column",
    };

    transponsedSeries.push(cols);
  }

  for (let i = 0; i < extractedLineData.length; i++) {
    const lines = {
      name: "Median",
      data: extractedLineData[i],
      type: "line",
    };
    transponsedLine.push(lines);
  }
  const finalSeries = [...transponsedSeries, ...transponsedLine];

  return { labels, finalSeries };
};

export default calculateSeries;
