import React, { useEffect, useContext, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

import HealthTrendsHeader from "./HeathTrendsHeader";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import HealthTrendsModal from "../Modal/WcchcDashboard/HealthTrendsModal";
import { health_trends_radio_list, colors, LOINC_CODES } from "../../constants";
import { mmYYDateFormatter, getMarkerColor, sortGraphData } from "../../Utils/wcchcPatientSummary";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    width: "100%",
    height: "450px",
    overflow: "hidden",
  },
  chartContainer: {
    padding: "0 10px !important",    
    maxHeight: "485px",
    height: "302px !important",
    width: (props) => props.width,
  },
  chartContainer1:{
    overflowX: "scroll"
  },
  chart: {
    minHeight: "50px !important",
    "& .apexcharts-legend-marker": {
      display: "none",
    },
    marginTop: "-10px"
  },
  emptyData: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 600,
    textAlign: "center",
    padding: "20px 0px",
  },
}));

const options = {
      chart: {
        height: "250px",
        type: "line",
        zoom: {
          enabled: false,
        },
        fontFamily: "Lato",
        toolbar:{
          show: false,
        },
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      legend: {
        show: true,
        position: "left",
        width: "20px",
        fontSize: "12px",
        fontFamily: "Lato",
        color: "#6C7278",
        fontWeight: "400",
        markers: {
          width: 0,
          height: 0,
        },
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
        padding: {
          top: 0,
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
          show: true,
          style: {
            cssClass: "apexcharts-xaxis-label",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        shared: false,
        style: {
          fontSize: "10px",
          color: "#0080b2",
        },
      },
      title: {
        text: "",
      },
};

const HealthTrends = (props) => {

  const [chartData, setChartData] = useState([]);
  const [label, setLabel] = useState("");
  const { state } = useContext(WcchcPatientSummaryContext);
  const { selectedDisease,duration, handleHealthTrendsModal, open, showBorder } = props;

  let width = duration > 24 ? '1500px' : (duration > 12 ? '1000px' : '100%');

  const classes = useStyles({width});

  const getOption = (series,i, selectedDisease) =>{

      return {
        colors: ["#36E948"],
        xaxis: {
          categories: series.data.map((item) =>
            dayjs(item.x).format("MM/DD/YY")
          ),
          labels: {
            show: true,
            rotate: -40,
            formatter: function (value) {
              return mmYYDateFormatter(value);
            },
            style: {
              cssClass: "apexcharts-xaxis-label",
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: series.name,
          align: "left",
          floating: false,
          offsetY: 70,
          offsetX: -10,
          style: {
            fontSize: "12px",
            fontFamily: "Lato",
            color: "#6C7278",
            fontWeight: "400",
          },
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
          padding: {
            left: 155,
          },
        },
        markers: {
          size: 3,
          strokeWidth: 0,
          discrete: series.data.map((item, index) => {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: getMarkerColor(selectedDisease, item, colors, i),
              size: 3,
            };
          }),
        },
        yaxis: {
          tickAmount: 4,
          labels: { 
            show: false,
            style: {
              cssClass: "apexcharts-yaxis-label",
            },
          },
          title: {
            text: series.unit ? series.unit : "",
            rotate: -90,
            offsetX: 145,
            offsetY: 5,
            style: {
              fontSize: "12px",
              fontFamily: "Lato",
              color: "#6C7278",
              fontWeight: "400",
            },
          },
        },
      };
  }


  const setInitialChartData = () =>{
    let data = [];
    let finalChartData = {};

    const label = health_trends_radio_list.find((item) => {
      return item.value === selectedDisease;
    });
    setLabel(label?.label);
    const currentDate = dayjs().endOf("month");
    const pastDate = currentDate.subtract(duration, "month");
    if (selectedDisease === "vitals") {
      const series = [];

      Object.keys(state?.vitals).forEach((key, index) => {
        const items = state?.vitals[key];

        const filteredData = items?.chartData.filter((item) => {
          return (
            dayjs(item?.x).isAfter(pastDate) &&
            dayjs(item?.x).isBefore(currentDate) &&
            item?.y
          );
        });
        if (filteredData.length) {
          series.push({
            name: items?.interpretation,
            type: "line",
            data: filteredData,
          });
        }
        finalChartData.series = series;
        finalChartData.options = options;
      });
    } else {
      const series = [];
      let trendsChart={};
      const selected_diseases_loinc_codes = LOINC_CODES[selectedDisease];
      selected_diseases_loinc_codes?.forEach((currentLoincCodes) =>{
        const { name, codes } = currentLoincCodes;
        let arr = [];
        codes?.forEach((code)=>{
          if(state.healthTrends[code]){
            const combined_data_of_loinc = Object.values(state.healthTrends[code]).flatMap(item => item);
            arr = [...arr, ...combined_data_of_loinc];
          }
        })
        if(arr.length){
          let final_chart = {
            [name]: sortGraphData(arr)
          }
          trendsChart ={
            ...trendsChart,
            ...final_chart
          }
        }
      })


      if (trendsChart) {
        Object.keys(trendsChart).forEach((key) => {
          const filteredData = trendsChart[key].filter((item) => {
            return (
              dayjs(item?.x).isAfter(pastDate) &&
              dayjs(item?.x).isBefore(currentDate) &&
              item?.y
            );
          });
          if (filteredData.length) {
            series.push({
              name: key,
              type: "line",
              data: filteredData,
              unit: trendsChart[key][0].unit,
            });
          }
        });
      }

      finalChartData.series = series;
      finalChartData.options = options;
    }

    // console.log("**********finalChartData", finalChartData)

    finalChartData?.series?.forEach((series, index) => {
      data.push({
        series: [
          {
            name: series.name,
            data: series.data.map((item) => Number(item.y)),
          },
        ],
        options: {
          ...options,
          ...getOption(series, index, selectedDisease)
        },
      });
    });

    setChartData(JSON.parse(JSON.stringify(data)))
  }

  useEffect(() => {
      setInitialChartData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ selectedDisease, duration, JSON.stringify(state.vitals), JSON.stringify(state?.labResults) ]
  );

  return (
    <Box className={`${classes.mainContainer} ${showBorder ? "selected-border" : ""}`} id="health-trends">
      <HealthTrendsHeader {...props} />
      <Box className={classes.chartContainer1}>
        <Box className={classes.chartContainer}>
          {state.isFetchingVitals ? (
            <>
              <Skeleton height={70} />
              <Skeleton height={70} />
              <Skeleton height={70} />
              <Skeleton height={70} />
            </>
          ) : chartData.length ? (
                chartData.map((data, index) => (
                  <ReactApexChart
                    key={index}
                    options={data.options || {}}
                    series={data.series || []}
                    type="line"
                    height={150}
                    className={classes.chart}
                  />
                ))
          ) : (
            <Typography className={classes.emptyData}>
              {label} information is not available for last{" "}
              {duration < 13
                ? `${duration} months`
                : `${duration / 12} years`}{" "}
            </Typography>
          )}
        </Box>
      </Box>
      {open && (
        <HealthTrendsModal
          open={open}
          handleHealthTrendsModal={handleHealthTrendsModal}
          selectedDisease={selectedDisease}
          duration={duration}
        />
      )}
    </Box>
  );
};

export default HealthTrends;
