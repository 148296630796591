import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

const DatePicker2 = ({ onChange, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year", "month", "day"]}
        renderInput={(params) => <TextField {...params} />}
        onChange={onChange}
        // inputFormat="mm-yyyy"
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DatePicker2;
