export function addMissingMonths(months) {
    // Helper function to parse month strings
    function parseMonth(monthString) {
        const [month, year] = monthString.match(/([a-zA-Z]+)(\d{4})/).slice(1);
        return new Date(Date.parse(`${month} 1, ${year}`));
    }

    // Helper function to format month strings
    function formatMonth(date) {
        return date.toLocaleString('default', { month: 'long' }) + date.getFullYear();
    }

    // Parse the months into Date objects
    const parsedMonths = months.map(item => parseMonth(item.treatment_month));

    // Find the minimum and maximum dates
    const minDate = new Date(Math.min(...parsedMonths));
    const maxDate = new Date(Math.max(...parsedMonths));

    // Generate all months between minDate and maxDate
    let currentDate = new Date(minDate);
    const allMonths = [];

    while (currentDate <= maxDate) {
        allMonths.push(formatMonth(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Add missing months to the original array
    allMonths.forEach(month => {
        if (!months.some(item => item.treatment_month === month)) {
            months.push({ treatment_month: month, clientCount: 0, data: [], client_data:[] });
        }
    });

    // Sort the result by date
    months.sort((a, b) => parseMonth(a.treatment_month) - parseMonth(b.treatment_month));

    return months;
}
