import React, { useState, useEffect, useContext } from "react";
import LeafletMap from "../../Components/HeatMap/LeafletMap";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import Dialog from "@mui/material/Dialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { IconButton } from "@mui/material";

const HeatMap = ({ loading }) => {
  const { state } = useContext(DropdownFilterContext);

  const [coordinates, setCoordinates] = useState([]);
  const { setPageTitle } = useContext(MetaDetailsContext);
  const [fullScreen, setFullScreen] = useState(false);
  const [patientCount, setPatientCount] = useState(0);

  useEffect(() => {
    setPageTitle("FHS: Geographic");
    if (loading) {
      setCoordinates([]);
      setPatientCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  
  useEffect(() => {
    if (state.filteredCoordinates.coordinates.length) {
      setCoordinates(state.filteredCoordinates.coordinates);
      setPatientCount(state.filteredCoordinates.count);
      console.log('Populated geo records: ', coordinates.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filteredCoordinates]);

  return (
    <>
    <div className="db-table-ar">
      <div style={{ display: "flex", justifyContent: "flex-end" }} >
        <IconButton
          onClick={() => setFullScreen(!fullScreen)}
          style={{ color: "#0082b2" }}
        >
          {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      </div>
      <div className="app-bg-color" style={{ textAlign: "left" }}>
        <LeafletMap
          center={{ lat: 37.0902, lng: -95.7129 }}
          coordinates={coordinates}
          loading={loading}
          count={patientCount}
        />
      </div>
    </div>
    {fullScreen &&
      <Dialog
        open={fullScreen}
        fullScreen
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }} >
          <IconButton
            onClick={() => setFullScreen(!fullScreen)}
            style={{ color: "#0082b2" }}
          >
            {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </div>
        <LeafletMap
          center={{ lat: 37.0902, lng: -95.7129 }}
          coordinates={coordinates}
          loading={loading}
          fullScreen={fullScreen}
          count={patientCount}
        />
      </Dialog>
    }
    </>
  )
};

export default HeatMap;
