import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { IframeContext } from "../../Contexts/iframeContext";
import services from "../../Services/patientServices";
import { CircularProgress } from "@mui/material";
import "./PatientScores.scss";

const PatientScores = () => {
  const { iframePatientMrn, iframe } = useContext(IframeContext);
  const [patientScore, setPatientScore] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const getPatientScore = async () => {
    try {
      setIsFetching(true);
      const response = await services.patientScore(iframePatientMrn, iframe);
      const { data } = response.data;
      const patient_score = data.patient_score;
      setPatientScore(patient_score);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log("error while fetching patient's scores", error);
    }
  };

  useEffect(() => {
    if (iframePatientMrn.length) {
      getPatientScore();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isFetching ? (
        <div className="spinner-container">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div className="score-container">
          <div className="score-item">
            <Avatar className="score-avatar">
              {patientScore.comp_score ? patientScore.comp_score : "NA"}
            </Avatar>
            <span className="score-label">Risk Score</span>
          </div>
          <div className="score-item">
            <Avatar className={`score-avatar ${patientScore.risk_level}`}>
              {patientScore.risk_level ? patientScore.risk_level : "NA"}
            </Avatar>
            <span className="score-label">Risk Level</span>
          </div>
          <div className="score-item">
            <Avatar className="score-avatar">
              {patientScore.impactability ? patientScore.impactability : "NA"}
            </Avatar>
            <span className="score-label">Impact Score</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientScores;
