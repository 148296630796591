import React, { createContext, useState, useCallback } from "react";

const InterventionColorContext = createContext();

function InterventionProvider(props) {
  const [intervention, setIntervention] = useState({
    active: false,
    color: "#a2df77",
  });

  const toggleColor = useCallback((ele_active) => {
    const color = ele_active ? "#ffec076b" : "#a2df77";
    setIntervention({ active: ele_active, color });
  }, []);

  return (
    <InterventionColorContext.Provider
      value={{
        intervention,
        toggleColor,
      }}
    >
      {props.children}
    </InterventionColorContext.Provider>
  );
}

export { InterventionColorContext, InterventionProvider };
