import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import UpIcon from '../../Assets/Images/green-up-arrow-icon.png'
import DownIcon from '../../Assets/Images/red-down-arrow-icon.png'

const ProviderBarChart = ({ chartData, selectedChart, benchmark }) => {

    const [xLabels, setXLabels] = useState([]);
    const [series, setSeries] = useState([]);

    const formatTooltip = (count) => {
        if (count == null || isNaN(Number(count))) return "-";

        const type = selectedChart.format;

        switch (type) {
            case "comma-separated":
                return Number(count).toLocaleString();
            case "decimal-fixed":
                return Number(count).toFixed(2);
            case "currency-string":
                return "$" + Number(count).toLocaleString();
            case "currency-string-million":
                return "$" + (count / 1000000).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
                    "M"
            default:
                return "-";
        }
    };

    const options = {
        chart: {
            id: 'apexchart-example',
            events: {
                mounted(chart, { globals }) {
                    let barWidth = 30;
                    globals.dataLabelsRects.forEach((rects, seriesIndex) => {
                        rects.forEach((rect, dataIndex) => {
                            const yValue = globals.series[seriesIndex][dataIndex]
                            if (yValue) {
                                chart.addPointAnnotation({
                                    x: rect.x,
                                    y: yValue,
                                    marker: { strokeWidth: 0, radius: 0, fillColor: 'rgba(0,0,0,0)' },
                                    image: {
                                        path: yValue > benchmark.benchmark_2 ? UpIcon : DownIcon,
                                        offsetY: 10,
                                        height: Math.ceil(barWidth / 2),
                                        width: Math.ceil(barWidth / 2)
                                    }
                                }, true)
                            }
                        })
                    }
                    )
                }
            }
        },
        xaxis: {
            categories: xLabels,
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                trim: false,
                minHeight: 100,
                maxHeight: 150,
            },
        },
        yaxis: {
            labels: {
                formatter: formatTooltip,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                dataLabels: {
                    position: 'center', // top, center, bottom
                    hideOverflowingLabels: false,
                }
            }
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
            textAnchor: 'end',
            formatter: function (val) {
                return '';
            },
            style: {
                fontSize: '12px',
            }
        },
        colors: ['#AFE7DC'],
        legend: {
            show: false
        },
        annotations: {
            yaxis: [
                {
                    y: benchmark.benchmark_2,
                    borderColor: '#000',
                    strokeDashArray: 4,
                    label: {
                        style: {
                            color: '#000',
                        },
                        text: formatTooltip(benchmark.benchmark_2)
                    }
                },
                {
                    y: benchmark.benchmark_1,
                    borderColor: '#F00',
                    strokeDashArray: 0,
                    label: {
                        style: {
                            color: '#F00',
                        },
                        text: formatTooltip(benchmark.benchmark_1),
                        offsetY: 18
                    }
                },
            ]
        },
        tooltip: {
            y: {
                formatter: formatTooltip, // Format tooltip values as millions
                title: {
                    formatter: (seriesName) => 'Value: ',
                },
            },
            marker: {
                show: false,
            },
        },
    }

    useEffect(() => {
        const xLabels = chartData.map(row => {
            return row.provider_name;
        })
        setXLabels(xLabels);
        const data = chartData.map(row => row[selectedChart.key] !== '-' ? row[selectedChart.key] : 0);
        const formattedData = [{
            type: 'column',
            name: 'series-1',
            data: data,
        }];
        setSeries(formattedData);

    }, [chartData, selectedChart]);

    return (
        <div id="chart">
            <Chart
                options={options}
                series={series}
                type="bar"
                height={340}
                max-width={500}
            />
        </div>
    );
}

export default ProviderBarChart;
