import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import "../GenerateReport.scss";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import BackdropLoader from "../../../Components/Backdrop/Backdrop";
import DatePicker2 from "../../../Components/DatePicker/DatePicker2";
import {
  carePlanColumnList,
  carePlanList,
} from "../../../Utils/carePlanReportData";

const GenerateCarePlanReport = () => {
  const [isFetching, setIsFetching] = useState(false);
  const todayDate = new Date();
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [dateError, setDateError] = useState(false);
  const [clinicSite, setClinicSite] = useState("");
  const [carePlanStatus, setCarePlanStatus] = useState("");
  const [programs, setPrograms] = useState("");
  const [benefit, setBenefit] = useState("");
  const [healthPlan, setHealthPlan] = useState("");

  const history = useNavigate();

  useEffect(() => {
    compareDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const onStartDateChange = (date) => {
    setStartDate(new Date(date));
  };

  const onEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const compareDate = () => {
    if (endDate < startDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const generateReport = async () => {
    if (dateError) return;
    setIsFetching(true);

    const filter_list = {
      "Created At": new Date().toString(),
      "Health Plan": healthPlan,
      Benefit: benefit,
      Programs: programs,
      "Clinic Site": clinicSite,
      "Care Plan Status": carePlanStatus,
    };

    const carePlanReportData = {
      list: carePlanList,
      report_title: "Treatment Plan Outcomes Summary",
      filter_list: filter_list,
      start_date: startDate,
      end_date: endDate,
      column_list: carePlanColumnList,
    };

    localStorage.setItem("report_data", JSON.stringify(carePlanReportData));
    setIsFetching(false);
    history("/genesis/report/care-plan-report");
  };

  return (
    <Sidebar>
      <div className="uploadpage app-bg-color DashboardOuter">
        <div className="upload-outer gt-report-box">
          <div className="upload-inner gt-report-box widget-bg-shadow">
            <div className="report-title">Client Report</div>
            <div className="query-container">
              <div>
                <div className="query-label">Start Date:</div>
                <div className="query-value">
                  <DatePicker2
                    onChange={() => false}
                    onAccept={onStartDateChange}
                    value={startDate}
                    inputFormat="MM/DD/YYYY"
                    className="datepicker"
                    maxDate={todayDate}
                  />
                </div>
              </div>
              <div>
                <div className="query-label">End Date:</div>
                <div className="query-value">
                  <DatePicker2
                    onChange={() => false}
                    onAccept={onEndDateChange}
                    value={endDate}
                    inputFormat="MM/DD/YYYY"
                    className="datepicker"
                    maxDate={todayDate}
                  />
                  {dateError ? (
                    <div className="error">
                      End Date should be greater than Start Date
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <div className="query-label">Health Plan (Optional):</div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={healthPlan}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      onChange={(event) => setHealthPlan(event.target.value)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Short-term health insurance plans">
                        Short-term health insurance plans
                      </MenuItem>
                      <MenuItem value="Health Savings Accounts (HSAs)">
                        Health Savings Accounts (HSAs)
                      </MenuItem>
                      <MenuItem value="Health Reimbursement Arrangements (HRAs)">
                        Health Reimbursement Arrangements (HRAs)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="query-label">Benefit (Optional):</div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={benefit}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      onChange={(event) => setBenefit(event.target.value)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Coverage for home health care">
                        Coverage for home health care
                      </MenuItem>
                      <MenuItem value="Coverage for emergency medical services">
                        Coverage for emergency medical services
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="query-label">Programs (Optional):</div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={programs}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      onChange={(event) => setPrograms(event.target.value)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Medicaid">Medicaid</MenuItem>
                      <MenuItem value="Medicare">Medicare</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="query-label">Clinic site (Optional):</div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={clinicSite}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      onChange={(event) => setClinicSite(event.target.value)}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="AHMC Seton Medical Center">
                        AHMC Seton Medical Center
                      </MenuItem>
                      <MenuItem value="AHMC Anaheim Regional Medical Center">
                        AHMC Anaheim Regional Medical Center
                      </MenuItem>
                      <MenuItem value="ACMH Hospital">ACMH Hospital</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="query-label">Care plan status (Optional):</div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                    <Select
                      value={carePlanStatus}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="select-box"
                      onChange={(event) =>
                        setCarePlanStatus(event.target.value)
                      }
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Recommended">Recommended</MenuItem>
                      <MenuItem value="In Progress/Current">
                        In Progress/Current
                      </MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                      <MenuItem value="Deleted/Voided">Deleted/Voided</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="upload-submit">
              <Button
                variant="contained"
                component="label"
                color="primary"
                onClick={generateReport}
                disabled={isFetching}
              >
                Generate Report
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BackdropLoader isFetching={isFetching} />
    </Sidebar>
  );
};

export default GenerateCarePlanReport;
