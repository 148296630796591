import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    borderRadius: "4px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "1px solid #ced4da",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0082b2",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
      outline: 0,
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
  disableScrollLock: true,
};

const MultiSelectBar = ({ list, onSelectionChange, title }) => {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState([...list.map((doc)=>doc.abbr)]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    let updatedSelectedItems;

    if (value === "All") {
      updatedSelectedItems = checked ? [...list.map((doc)=>doc.abbr)] : [];
    } else {
      updatedSelectedItems = checked
        ? [...selectedItems.filter((item) => item !== "All"), value]
        : selectedItems.filter((item) => item !== value && item !== "All");
    }

    setSelectedItems(updatedSelectedItems);
    onSelectionChange(updatedSelectedItems);
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-multiple-checkbox-label">{title}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-mutiple-name"
          multiple
          value={selectedItems}
          input={<OutlinedInput label={title} />}
          MenuProps={MenuProps}
          classes={classes}
          style={{ width: 250, borderRadius: "4px" }}
          className="multiple-select"
          renderValue={(item) =>{ 
           return item.map((doc)=>{
              return list.find((ele)=>ele.abbr === doc).label
            }).join(", ")
    }}
        >
          <FormGroup>
            {list.map((item) => (
              <FormControlLabel
                style={{ marginLeft: "10px" }}
                value={item.abbr}
                id={item.abbr}
                control={
                  <Checkbox
                    checked={selectedItems.includes(item.abbr)}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </Select>
      </FormControl>
    </>
  );
};

export default MultiSelectBar;
