import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Backdrop,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Snackbar,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import MuiAlert from "@mui/material/Alert";
import services from "../../Services/patientServices";

const Hr = styled.hr({
  margin: "0.5rem 0rem",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditFilter = ({ action, open, setOpen, filter, refresh }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [filterSetName, setFilterSetName] = useState({ value: "", error: "" });
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    severity: "",
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const handleClose = () => {
    setFilterSetName({ state: "", error: "" });
    setOpen(false);
  };

  const handleClick = async () => {
    if (action === "edit") {
      if (!filterSetName.value) {
        const data = filterSetName;
        data.error = "Filter set name is required";
        setFilterSetName((prev) => ({
          value: prev.value,
          error: "Filter set name is required",
        }));
        return;
      }

      const payload = {
        saved_filter_id: filter.saved_filter_id,
        name: filterSetName.value,
      };

      try {
        setIsFetching(true);
        const { data } = await services.editSavedFilter(payload);
        setAlertMessage({ message: data?.message, severity: "success" });
        setIsFetching(false);
        setShowToast(true);
        setOpen(false);
        setFilterSetName({ state: "", error: "" });
        refresh();
      } catch (error) {
        setIsFetching(false);
        console.log("Edit filter =>", error);
      }
    }
    if (action === 'delete') {
      try {
        setIsFetching(true);
        const payload = {
          saved_filter_id: filter.saved_filter_id,
        }
        const { data } = await services.deleteSavedFilter(payload);
        setAlertMessage({ message: data?.message, severity: "success" });
        setIsFetching(false);
        setShowToast(true);
        setOpen(false);
        setFilterSetName({ state: "", error: "" });
        refresh();
      } catch (error) {
        setIsFetching(false);
        console.log("Delete filter =>", error);
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-save-filter"
        aria-describedby="modal-save-filter"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid
              item
              container
              xs={12}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <Typography variant="h6">
                  {action === "edit" ? "Update" : "Delete"} Filter
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end">
                <IconButton onClick={() => handleClose()}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            {action === "edit" ? (
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <p style={{ margin: "8px" }}>Filter set name:</p>
                <Typography variant="body1">
                  <input
                    className="form-control save-filter-input"
                    type="text"
                    value={filterSetName.value}
                    placeholder="Enter filter set name"
                    onChange={(e) =>
                      setFilterSetName({ value: e.target.value, error: "" })
                    }
                  />
                  {filterSetName?.error && (
                    <FormHelperText error sx={{ marginLeft: "10px" }}>
                      {filterSetName.error}
                    </FormHelperText>
                  )}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1">
                Are you sure you want to delete the filter <b>{filter.name}</b>? <br />This action cannot be undone.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="end">
              <button
                className="submit-form-btn"
                onClick={() => handleClose()}
                style={{ width: "150px" }}
              >
                Cancel
              </button>
              <button
                disabled={isFetching}
                className="submit-form-btn"
                onClick={() => handleClick()}
                style={{ width: "150px" }}
              >
                {action === "edit" ? "Update" : "Delete"}
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 200 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="toaster">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleAlertClose}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert
            onClose={handleAlertClose}
            variant="outlined"
            severity={alertMessage?.severity}
          >
            <span>{alertMessage?.message}</span>
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default EditFilter;
