import * as React from 'react';
import './Button.scss';

const ThemedButton = (props) => {
  return (
    <button className='fhs-themed-button' onClick={props.handleClick} disabled={props.disabled}>
      {props.children}
    </button>
  );
};

export default ThemedButton;