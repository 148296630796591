const behavioralConsultantList = [
    {
        "id": 5,
        "name": "Diamond Keith",
    },
    {
        "id": 3057,
        "name": "Sendon Faith",
    },
    {
        "id": 3058,
        "name": "Burns Reilly",
    },
    {
        "id": 3061,
        "name": "Heinrich Larissa",
    },
    {
        "id": 3062,
        "name": "Ramirez Bryana",
    },
    {
        "id": 3074,
        "name": "Castillo Keely",
    },
    {
        "id": 3079,
        "name": "Miller Ashley"

    },
    {
        "id": 3082,
        "name": "Maxfield Ashley",
    },
    {
        "id": 3103,
        "name": "Rodriguez Karen",
    },
    {
        "id": 3104,
        "name": "Bruce Sara",
    }
]

const clinicalReportData = [
    {
        "provider": " Diamond, Keith",
        "service_hours_provided_(available)": "8:00h (20:00h)",
        "direct_&_indirect": "7:12h & 0:48h (10%)",
        "client_and_sessions": "6 clients in 23 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    },
    {
        "provider": " Sendon, Faith",
        "service_hours_provided_(available)": "6:30h (20:00h)",
        "direct_&_indirect": "6:12h & 3:48h (30%)",
        "client_and_sessions": "5 clients in 33 sessions",
        "average_direct_session_length": "3:14h",
        "trend": ""
    },
    {
        "provider": "Reilly, Burns",
        "service_hours_provided_(available)": "2:00h (10:00h)",
        "direct_&_indirect": "3:12h & 1:48h (22%)",
        "client_and_sessions": "2 clients in 12 sessions",
        "average_direct_session_length": "2:24h",
        "trend": ""
    },
    {
        "provider": "Larissa, Heinrich",
        "service_hours_provided_(available)": "3:05h (22:00h)",
        "direct_&_indirect": "6:12h & 10:48h (33%)",
        "client_and_sessions": "5 clients in 20 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    },
    {
        "provider": "Bryana, Ramirez",
        "service_hours_provided_(available)": "8:00h (20:00h)",
        "direct_&_indirect": "7:12h & 0:48h (10%)",
        "client_and_sessions": "6 clients in 23 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    },
    {
        "provider": "Keely, Castillo",
        "service_hours_provided_(available)": "8:00h (20:00h)",
        "direct_&_indirect": "7:12h & 0:48h (10%)",
        "client_and_sessions": "6 clients in 23 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    },

    {
        "provider": "Ashley, Miller",
        "service_hours_provided_(available)": "8:00h (20:00h)",
        "direct_&_indirect": "7:12h & 0:48h (10%)",
        "client_and_sessions": "6 clients in 23 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    },

    {
        "provider": "Ashley, Maxfield",
        "service_hours_provided_(available)": "8:00h (20:00h)",
        "direct_&_indirect": "7:12h & 0:48h (10%)",
        "client_and_sessions": "6 clients in 23 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    },
    {
        "provider": "Karen, Rodriguez",
        "service_hours_provided_(available)": "8:00h (20:00h)",
        "direct_&_indirect": "7:12h & 0:48h (10%)",
        "client_and_sessions": "6 clients in 23 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    },
    {
        "provider": "Sara, Bruce",
        "service_hours_provided_(available)": "8:00h (20:00h)",
        "direct_&_indirect": "7:12h & 0:48h (10%)",
        "client_and_sessions": "6 clients in 23 sessions",
        "average_direct_session_length": "0:24h",
        "trend": ""
    }
]

// const serviceData = [
//     {
//       "service_name": "Paraprofessional providing direct services",
//       "Service_type": "Direct",
//       "hours": 8,
//       "number_sessions": 23,
//       "number_clients": 6
//     },
//     {
//       "service_name": "Supervision of paraprofessional by BCBA or licensed ABA provider",
//       "Service_type": "Direct",
//       "hours": 6.30,
//       "number_sessions": 33,
//       "number_clients": 5
//     },
//     {
//       "service_name": "BCBA DIRECT Consult & Supervision",
//       "Service_type": "Direct",
//       "hours": 2,
//       "number_sessions": 12,
//       "number_clients": 2
//     },
//     {
//       "service_name": "DIRECT 1:1 treatment",
//       "Service_type": "Direct",
//       "hours": 8,
//       "number_sessions": 23,
//       "number_clients": 6
//     },
//     {
//       "service_name": "Supervision of paraprofessional by BCBA or licensed ABA provider",
//       "Service_type": "Indirect",
//       "hours": 4,
//       "number_sessions": 13,
//       "number_clients": 3
//     },
//     {
//       "service_name": "Report writing",
//       "Service_type": "Indirect",
//       "hours": 1,
//       "number_sessions": 6,
//       "number_clients": 2
//     },
//     {
//       "service_name": "Paraprofessional providing direct services",
//       "Service_type": "Indirect",
//       "hours": 2,
//       "number_sessions": 11,
//       "number_clients": 2
//     },
//     {
//       "service_name": "Direct service provided by BCBA or licensed ABA provider (can include parent training)",
//       "Service_type": "Indirect",
//       "hours": 4,
//       "number_sessions": 3,
//       "number_clients": 1
//     },
//     {
//       "service_name": "Planning",
//       "Service_type": "Indirect",
//       "hours": 3,
//       "number_sessions": 12,
//       "number_clients": 2
//     },
//     {
//       "service_name": "Skill Assessment",
//       "Service_type": "Indirect",
//       "hours": 0.30,
//       "number_sessions": 2,
//       "number_clients": 1
//     }
//   ]

  const serviceData = [
    {
      "client_name": "Diamond, Keith (0000111121)",
      "direct_session_count_and_hours": "7 sessions, 1:49 hours",
      "total_hours": "1:49 hours",
      "date_range": "12/17/2021 - 8/29/2022"
    },
    {
      "client_name": "Bruce, Sara (0000111122)",
      "direct_session_count_and_hours": "1 session, 2:00 hours",
      "total_hours": "2:00 hours",
      "date_range": "12/17/2021 - 8/29/2022"
    },
    {
      "client_name": "Rodriguez, Karen (0000111123)",
      "direct_session_count_and_hours": "3 sessions, 1:26 hours",
      "total_hours": "1:26 hours",
      "date_range": "12/17/2021 - 8/29/2022"
    },
    {
      "client_name": "Maxfield, Ashley (0000111124)",
      "direct_session_count_and_hours": "4 sessions, 1:50 hours",
      "total_hours": "1:50 hours",
      "date_range": "12/17/2021 - 8/29/2022"
    },
    {
      "client_name": "Miller, Ashley (0000111125)",
      "direct_session_count_and_hours": "1 session, 0:24 hours",
      "total_hours": "0:24 hours",
      "date_range": "12/17/2021 - 8/29/2022"
    }
  ];


  const patientTableData = [
    {
        "name": "Diamond, Keith (2000012356)",
        "session_hours_provided_(authorized)": "8:00h (11:30h)",
        "direct & indirect": "7:12h & 0:48h (10%)",
        "provider(s)": "Burns, Reilly",
        "funding_source" : "Insurance"
    }
  ]; 
  
  const patientServiceData = [
    {
        "date": "December 13, 2022, 10:12am", 
        "provider": "Burns, Reilly",
        "service_type": "Direct",
        "service_name": "Paraprofessional providing direct services",
        "length_(hours)": "2:30h"
    },
    {
        "date": "December 13, 2022, 01:12pm", 
        "provider": "Miller, Ashley",
        "service_type": "Direct",
        "service_name": "Report writing",
        "length_(hours)": "3:30h"
    },
    {
        "date": "December 11, 2022, 10:12am", 
        "provider": "Rodriguez, Karen",
        "service_type": "Direct",
        "service_name": "Planning",
        "length_(hours)": "1:12h"
    }
  ];

export {
    behavioralConsultantList,
    clinicalReportData,
    serviceData,
    patientTableData,
    patientServiceData
};
