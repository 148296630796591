import React, { createContext, useReducer } from "react";

const WcchcPatientSummaryContext = createContext();

const initialState = {
    isFetchingLabResults: true,
    labResults: {},
    isFetchingAlertNotifications: true,
    alertNotifications: [],
    isFetchingAllSmartAlerts: true,
    allSmartAlerts: [],
    isFetchingMedications: true,
    medications: {},
    isFetchingVitals: true,
    vitals: {},
    healthTrends: {},
    isFetchingProblems: true,
    problemsIdentified: {},
    isFetchingComprehensiveRisk: true,
    comprehensiveRiskScore: {},
    isFetchingPatientDetails: true,
    patientDetails: {},
    isFetchingVisits: true,
    visits: [],
    isFetchingSdohAnalysis: true,
    sdohAnalysis: {},
    isFetchingSmartAlerts: true,
    smartAlerts: [],
    isFetchingSummary: true,
    patientSummary: {summary: ''},
    isFetchingReferrals: true,
    referrals:[],
    tablesDataLength:{
        medications: 0,
        recentlabsmodal: 0,
        recentlabstable: 0,
        referrals: 0,
        hospitaladmits: 0,
        diagnosesandsymptoms: 0,
        sdohAnalysis: 0
    },
    isFetchingLastUpdatedDate: true,
    lastUpdatedDate: ""
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LAB_RESULTS":
            return {
                ...state,
                labResults: action.payload.labResults,
                isFetchingLabResults: false,
            };
        case "ALERT_NOTIFICATIONS":
            return {
                ...state,
                alertNotifications: action.payload.alertNotifications,
                isFetchingAlertNotifications: false,
            };
        case "LIST_SMART_ALERTS":
            return {
                ...state,
                allSmartAlerts: action.payload.allSmartAlerts,
                isFetchingAllSmartAlerts: false
            }
        case "MEDICATIONS":
            return {
                ...state,
                medications: action.payload.medications,
                isFetchingMedications: false,
            };
        case "VITALS":
            return {
                ...state,
                vitals: action.payload.vitals,
                healthTrends: action.payload.healthTrends,
                isFetchingVitals: false,
            };
        case "PROBLEMS_IDENTIFIED":
            return {
                ...state,
                problemsIdentified: action.payload.problemsIdentified,
                isFetchingProblems: false,
            };
        case "COMPREHENSIVE_RISK_SCORE":
            return {
                ...state,
                comprehensiveRiskScore: action.payload.comprehensiveRiskScore,
                isFetchingComprehensiveRisk: false,
            };
        case "PATIENT_DETAILS":
            return {
                ...state,
                patientDetails: action.payload.patientDetails,
                isFetchingPatientDetails: false,
            };
        case "PATIENT_VISITS":
            return {
                ...state,
                visits: action.payload.visits,
                isFetchingVisits: false,
            };
        case "SDOH_ANALYSIS":
            return {
                ...state,
                sdohAnalysis: action.payload.sdohAnalysis,
                isFetchingSdohAnalysis: false,
            };
        case "SMART_ALERTS":
            return {
                ...state,
                smartAlerts: action.payload.smartAlerts,
                isFetchingSmartAlerts: false,
            };
        case "PATIENT_SUMMARY":
            return {
                ...state,
                patientSummary: action.payload.patientSummary,
                isFetchingSummary: false,
            };
        case "REFERRALS":
            return {
                ...state,
                referrals: action.payload.referrals,
                isFetchingReferrals: false,
            };
        case "UPDATE_TABLE_LENGTH":
            return {
                ...state,
                tablesDataLength: {
                    ...state.tablesDataLength,
                    ...action.payload
                }
            }

        case "WIDGET_LAST_DATE_UPDATE":
            return {
                ...state,
                lastUpdatedDate: action.payload.last_updated_date,
                isFetchingLastUpdatedDate: false,
            }
        
        case "RESET":
            return initialState;
        default:
            return state;
    }
}

function WcchcPatientSummaryProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <WcchcPatientSummaryContext.Provider value={{ state, dispatch }}>
            {props.children}
        </WcchcPatientSummaryContext.Provider>
    )
};

export { WcchcPatientSummaryContext, WcchcPatientSummaryProvider };