import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// Accrodion imports
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";

import PatientRecommendations from "../PatientRecommendations/PatientRecommendations";
import PatientDemographics from "../PatientDemographics/PatientDemographics";
import PatientNotes from "../PatientNotes/PatientNotes";

import IconButton from "@mui/material/IconButton";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { usePatientContext } from "../../Contexts/patientContext";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";

import "./PatientInfo.scss"; // Importing CSS file

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.6rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  height: "40px",
  minHeight: "40px",
  borderBottom: "1px solid #0082b2",
  color: "#0082b2",
  backgroundColor: "white",
  "& .MuiAccordionSummary-expandIconWrapper": {
    fill: "#0082b2",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiSvgIcon-root": {
    fill: "inherit !important",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "55vh",
  overflowY: 'scroll'
}));

const PatientInfo = ({ close, selectedSection, expandNestedSection, openQualitySelect }) => {
  const { state } = useContext(DropdownFilterContext);
  const [expanded, setExpanded] = useState(selectedSection);
  const [nestedExpanded, setNestedExpanded] = useState(expandNestedSection);
  const {
    patientDetails,
    isFetchingDetails,
    isHalfScreen,
    changeDialogScreen,
    handleBackForthNavigation,
    currentPatientIndex,
  } = usePatientContext();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    if (patientDetails?.coordinator_list.length <= 1) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeNestedAccordion = (panel) => (event, newExpanded) => {
    setNestedExpanded(newExpanded ? panel : false); // Toggle nested accordion
  };

  const recommendationComponent = patientDetails ? (
    <PatientRecommendations patientData={patientDetails} />
  ) : (
    "NA"
  );

  const detailsComponent = <PatientDemographics patientData={patientDetails} openQualitySelect={openQualitySelect}/>;

  const notesComponent = (
    <PatientNotes patientData={patientDetails}></PatientNotes>
  );


  const QualityMeasureService = () => {
    
      if (!patientDetails?.patient_screening_details?.filter(item => item.measure_status === 0)?.length) {
          return <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px'}}>
                    <Typography>No Quality Measure Service available</Typography>
                  </div>
      }

      return (
              <TableContainer
                sx={{ maxHeight: 400, width: "100%" }}
                className="recommendation-table"
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Quality Measure Services</TableCell>
                      <TableCell align="center">Date Last Provided</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientDetails?.patient_screening_details?.filter(item => item.measure_status === 0).map((data, index) => (
                      
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {data.screening_long_name}
                        </TableCell>
                        <TableCell align="center">{data?.appointment_date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
      );
  };

  const accordianList = [
    {
      title: "Details",
      component: detailsComponent,
      id: "details",
      isNested: false
    },
    {
      title: "Notes",
      component: notesComponent,
      id: "notes",
      isNested: false
    },
    {
      title: "Recommendations",
      component: recommendationComponent,
      id: "recommendations",
      isNested: true,
      subList: [
            {
              title: "Care Enabling Services",
              component: recommendationComponent,
              id: "care_enable",
            },
            {
              title: "Quality Measure Services",
              component: <QualityMeasureService />,
              id: "quality_measure",
            },
      ]
    },
  ];

  return (
    <>
      {!isFetchingDetails ? (
        <div className="patient-info-container">
          <div className="main-info-wrapper">
            <div className="info-btn-wrapper">
              <Button
                variant="text"
                startIcon={<ArrowBackIcon className="prev-btn" />}
                className="prev-btn"
                onClick={() => handleBackForthNavigation("previous")}
                disabled={currentPatientIndex === 0}
              >
                Previous
              </Button>
              <Button
                variant="text"
                endIcon={<ArrowForwardIcon className="next-btn" />}
                className="next-btn"
                onClick={() => handleBackForthNavigation("next")}
                disabled={currentPatientIndex === state.filteredData.length - 1}
              >
                Next
              </Button>
              <div className="icon-wrapper">
                {isHalfScreen ? (
                  <FullscreenIcon
                    className="full-screen-icon"
                    onClick={() => changeDialogScreen(false)}
                  />
                ) : (
                  <FullscreenExitIcon
                    className="full-screen-icon"
                    onClick={() => changeDialogScreen(true)}
                  />
                )}
                <IconButton
                  color="inherit"
                  onClick={() => {
                    close(false);
                    changeDialogScreen(true);
                  }}
                  aria-label="close"
                  className="close-icon"
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </div>
            </div>
            <div className="patient-info">
              <div className="patient-name">
                {patientDetails?.first_name} {patientDetails?.middle_name}{" "}
                {patientDetails?.last_name}
              </div>
              <div className="patient-age">
                {" "}
                <span className="gender-icon">
                  {patientDetails?.sex === "male" ? (
                    <MaleIcon />
                  ) : (
                    <FemaleIcon />
                  )}
                </span>
                <span>
                  {patientDetails?.sex} {patientDetails?.age}
                </span>
              </div>
              <div className="patient-org-id">
                ID {patientDetails?.org_patient_id}
              </div>
              <div className="patient-coodinators-tooltip">
                <div className="coordinator-list">
                  <strong>Coordinators</strong>:
                  <Typography
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    {patientDetails?.coordinator_list.length > 1
                      ? "multiple"
                      : patientDetails?.coordinator_list[0]?.name}
                  </Typography>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    PaperProps={{
                      style: {
                        boxShadow: "none",
                        borderRadius: 0,
                      }
                    }}
                  >
                    <ul className="ul-popover">
                      {patientDetails?.coordinator_list.map(
                        (coordinator, index) => {
                          return <li key={index}>{coordinator.name}</li>;
                        }
                      )}
                    </ul>
                  </Popover>
                </div>
              </div>
            </div>

            {/* Accordian */}
            <div className="accordion wrapper">
                <div>
                  {accordianList.map((item, index) => (
                    <Accordion
                      key={index}
                      expanded={expanded === item.id}
                      onChange={handleChange(item.id)}
                    >
                      <AccordionSummary aria-controls={`panel-${item.id}-content`} id={`panel-${item.id}-header`}>
                        <Typography>{item.title}</Typography>
                      </AccordionSummary>

                      {/* Render the component for the parent accordion */}
                      {!item?.isNested && <AccordionDetails>{item.component}</AccordionDetails>}

                      {/* If there are nested accordions, render them */}
                      {item?.subList?.map((subItem, subIndex) => (
                        <Accordion
                          key={subIndex}
                          expanded={nestedExpanded === subItem.id}
                          onChange={handleChangeNestedAccordion(subItem.id)}
                          style={{margin: "0px 20px"}}
                        >
                          <AccordionSummary aria-controls={`panel-${subItem.id}-content`} id={`panel-${subItem.id}-header`}>
                            <Typography>{subItem.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>{subItem.component}</AccordionDetails>
                        </Accordion>
                      ))}
                    </Accordion>
                  ))}
                </div>
              </div>
            {/* Accordian */}
          </div>
        </div>
      ) : (
        <div className="loading-wrapper">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PatientInfo;
