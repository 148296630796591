import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import 'leaflet.heat';
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import CircularProgress from '@mui/material/CircularProgress';
import markerList from './marker.json';

import "./styles.css";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});


L.Icon.Default.prototype.options.iconUrl = "/marker-icon.png";
L.Marker.prototype.options.icon = DefaultIcon;

const state = {
  radius: 15,
  blur: 15,
  max: 1,
};

const renderMarkerList = (markerList) =>{
  let list = markerList?.map(({
    geo_lat, 
    geo_long,
    AMH_City,
    AMH_First_Name,
    AMH_Last_Name,
    AMH_Address_Line1,
    AMH_Address_Line2,
    AMH_State,
  },index) =>{
    return(
      <Marker position={[geo_lat, geo_long]} key={index}>
        <Popup>
          <div className="geospatial-marker">
           <p className="name">{AMH_First_Name === AMH_Last_Name ? AMH_First_Name: `${AMH_First_Name} ${AMH_Last_Name}`}</p>
            {(AMH_Address_Line1 || AMH_Address_Line2) && (
              <p className="address">{AMH_Address_Line1 === AMH_Address_Line2 ? AMH_Address_Line1 : `${AMH_Address_Line1}, ${AMH_Address_Line2}`}</p>
            )}
            <p className="city">{AMH_City}, {AMH_State}</p>
          </div>
        </Popup>
      </Marker>
    )
  })
  return list
}

const LeafletMap = ({ coordinates, loading, count }) => {
  const [map, setMap] = useState(null);
  const heatmapLayerRef = useRef(null);
  const [orgId, setOrgId] = useState([]);

  const heatmapData = {
    max: Number.parseFloat(state.max),
    radius: Number(state.radius),
    blur: Number(state.blur),
  };

  useEffect(() => {
    if (loading && map) {
      // Clear heat layers if loading is true
      map.eachLayer((layer) => {
        if (layer instanceof L.HeatLayer) {
          map.removeLayer(layer);
        }
      });
    }
  }, [loading, map]);

  useEffect(() => {
    if (map && coordinates.length > 0) {
      // Create or update the heat layer with new coordinates
      if (heatmapLayerRef.current) {
        map.removeLayer(heatmapLayerRef.current);
      }
      const updateHeatmap = () => {
        heatmapLayerRef.current = L.heatLayer(coordinates, heatmapData).addTo(map);
      };

      // Throttle updates to avoid blocking the main thread
      const throttleUpdate = (callback) => {
        let lastUpdate = 0;
        const interval = 200; // milliseconds

        return () => {
          const now = Date.now();
          if (now - lastUpdate >= interval) {
            callback();
            lastUpdate = now;
          } else {
            requestAnimationFrame(() => throttleUpdate(callback));
          }
        };
      };

      throttleUpdate(updateHeatmap)();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, map]);

  useEffect(() => {
    const { org_id } = JSON.parse(localStorage.getItem("user")).user;
    setOrgId(org_id);
  }, []);

  const progressStyle = {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '15px',
    height: '15px',
    marginLeft: "5px"
  };

  const textStyle = {
    display: 'flex',
    alignItems: 'center',
  };


  return (
    <section className="DashboardOuter clearfix">
      <section className="pt-data patient-viewwrappers">
        <div className="app-map position-relative">
        {loading ? (
          <p className="geospatial-patient-count" style={textStyle}>
            Populated {count} coordinates <CircularProgress style={progressStyle} />
          </p>
        ) : (
          <p className="geospatial-patient-count">
            Number of Coordinates: {count}
          </p>
        )}
          <MapContainer
            style={{ width: "100%", height: "100%" }}
            center={[37.0902, -95.7129]}
            zoom={5}
            whenCreated={(_map) => { setMap(_map) }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {(markerList?.length && orgId === 22) && renderMarkerList(markerList)}
          </MapContainer>
          
        </div>
      </section>
    </section>
  );
};

export default LeafletMap;
