import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  TextField,
  FormControl
} from '@mui/material';

import "../GenerateReport.scss";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import reportServices from "../../../Services/reportServices";
import BackdropLoader from "../../../Components/Backdrop/Backdrop";
import { formateDateWithTimezone } from "../../../Utils/formatDate";
import DatePicker2 from "../../../Components/DatePicker/DatePicker2";
import BackButton from "../../../Components/BackButton/BackButton";

const GenerateClientReport = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateError, setDateError] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  const [clinicValue, setClinicValue] = useState({id: -1, ChildCenterLocation: "All"})
  const [clinicList, setClinicList] = useState([]);
  const [payeeList, setPayeeList] = useState([]);
  const [selectedPayee, setSelectedPayee] = useState({id: 0, name: "All"});
  const [fundingSourceList, setFundingSourceList] = useState([]);
  const [selectedSourceFunding, setSelectedSourceFunding] = useState({id: 0, name: "All"});


  const { user } = JSON.parse(localStorage.getItem("user"));
  const { org_guid } = user;

  let history = useNavigate();
 
  useEffect(() => {
    compareDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    getReportFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var getDayOfMonth = (date, no, day) => {
    const prevMonth = date.getMonth() - no;
    const dayOfMonth = day === "firstDay" ? 1 : 0;
    return new Date(date.getFullYear(), prevMonth, dayOfMonth);
  };

  const onStartDateChange = (date) => {
    setStartDate(new Date(date));
  };

  const onEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const compareDate = () => {
    if (endDate < startDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const handleChange = (value) => {
    if(value === null){
      setClinicValue({id: -1, ChildCenterLocation: "All"})
      return 
    }
    setClinicValue(value)
  };

  const getReportFilters = async () => {
    try {
      setIsFetching(true);
      const response = await reportServices.getClientFilter(org_guid);
      const { data } = response.data;
      const max_date = new Date(data.max_date);
      const one_year_back_date = getDayOfMonth(max_date, 12, "firstDay");
      setClinicList([{id: -1, ChildCenterLocation: "All"},...data?.clinic_list])
      setFundingSourceList([{id: "", name: "All"},...data?.funding_source_list])
      setPayeeList([{id: "", name: "All"},...data?.payee_list])
      setStartDate(one_year_back_date);
      setEndDate(max_date);
      setMaxDate(max_date);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false)
    }
  };

  const generateReport = async () => {
    try {
      if (dateError) return;
      const todayDate = formateDateWithTimezone(new Date());
      setIsFetching(true);
      const requestBody = {
        end_date: endDate,
        start_date: startDate,
        clinic_site_id: clinicValue?.id,
        clinic_site: clinicValue?.ChildCenterLocation,
        org_guid: org_guid,
        created_at: todayDate,
        source_id: selectedSourceFunding?.name === "All" ? "" : selectedSourceFunding?.id,
        payee_name: selectedPayee?.name === "All" ? "" : selectedPayee?.name
      };
      const response = await reportServices.getClientReport(requestBody);
      const { data } = response.data;
      localStorage.setItem("report_data", JSON.stringify(data));
      setIsFetching(false);
      history('/genesis/report/client-report')
    } catch (error) {
      setIsFetching(false);
    }
  };

  return (
    <Sidebar>
      <div className="uploadpage app-bg-color DashboardOuter rp-overflow">
        <div className="btn-back">
          <BackButton color={"#fff"} backgroundColor={"#0082b2"}/>
        </div>
        <div className="upload-outer">
          <div className="upload-inner widget-bg-shadow">
            <div className="report-title">Client Report</div>
            <div className="query-container">
              <div>
                <div className="query-label">Start Date:</div>
                <div className="query-value">
                  <DatePicker2
                    onChange={() => false}
                    onAccept={onStartDateChange}
                    value={startDate}
                    inputFormat="MM/DD/YYYY"
                    className="datepicker"
                    maxDate={maxDate}
                  />
                </div>
              </div>
              <div>
                <div className="query-label">End Date:</div>
                <div className="query-value">
                  <DatePicker2
                    onChange={() => false}
                    onAccept={onEndDateChange}
                    value={endDate}
                    inputFormat="MM/DD/YYYY"
                    className="datepicker"
                    maxDate={maxDate}
                  />
                  {dateError ? (
                    <div className="error">
                      End Date should be greater than Start Date
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                  <div className="query-label">Clinic site (Optional):</div>
                  <div className="query-value">
                    <FormControl
                      sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={clinicList}
                        value={clinicValue}
                        getOptionLabel={(option) => option.ChildCenterLocation || ''}
                        className="select-box"
                        renderInput={(params) => <TextField {...params}/>}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          handleChange(newValue);
                        }}
                      />
                    </FormControl>
                  </div>
              </div>
              <div>
                <div className="query-label">Payer:</div>
                <div className="query-value">
                  <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={payeeList}
                        value={selectedPayee}
                        getOptionLabel={(option) => option.name || ''}
                        className="select-box"
                        renderInput={(params) => <TextField {...params}/>}
                        isOptionEqualToValue={(option, value) =>
                            option.name=== value.name
                        }
                        onChange={(event, value) => {
                          if(value === null){
                            setSelectedPayee({name: "All", id: ""})
                          }else{
                            setSelectedPayee(value);
                          }
                        }}
                      />
                  </FormControl>
                </div>
              </div>
              <div>
                <div className="query-label">Funding Sources:</div>
                <div className="query-value">
                <FormControl
                    sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                  >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={fundingSourceList}
                        value={selectedSourceFunding}
                        getOptionLabel={(option) => option.name || ''}
                        className="select-box"
                        renderInput={(params) => <TextField {...params}/>}
                        isOptionEqualToValue={(option, value) =>
                            option.name=== value.name
                        }
                        onChange={(event, value) => {
                          if(value === null){
                            setSelectedSourceFunding({name: "All", id: ""})
                          }else{
                            setSelectedSourceFunding(value);
                          }
                        }}
                      />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="upload-submit">
              <Button
                variant="contained"
                component="label"
                color="primary"
                onClick={generateReport}
                disabled={isFetching}
              >
                Generate Report
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BackdropLoader isFetching={isFetching} />
    </Sidebar>
  );
};

export default GenerateClientReport;
