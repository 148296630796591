import React, { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { PyramidContext } from "../../Contexts/uiContext";
import closeIcon from "../../Assets/Images/close.png";
// import generateCSV from "../../Utils/generateCSV";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { toCapitalize, toPluralize } from "../../Utils/stringOprations";
import { PatientSetContext } from "../../Contexts/patientSetContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #eee",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 800,
    height: "auto",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "5px"
  }
}));

export default function CustomModal(props) {
  const history = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(props.show);
  const { setInfo } = useContext(PyramidContext);
  const { event } = useContext(AnalyticsContext);
  const { patientSetInfo } = useContext(PatientSetContext);
  const location = useLocation();

  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };

  const sendViewBack = (label) => {
    let currentLocation = `${location.pathname}`.slice(1);
    setInfo({
      abbr: props.mnemo + "_" + props.abbr,
      currentui: label,
      prevui: currentLocation,
      interventionName: props.label,
      mnemo: props.mnemo
    });
    history("/patient-list", {
      state: { uititle: props.type === "intervention" ? `Patients ${props.is_individual ?"who received":"recommended for"} ${props.label.toLowerCase()}` : `Patients with contributor ${props.label.toLowerCase()}` }
    })

    //GA - Track clicking on the explore cohort button
    event('click_explore_cohort' , {});
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.modalHeader} >
              <span 
                className={props.type === 'intervention-simulations' ? "modalHdSim" : "modalHd"} id="transition-modal-title">
                {toPluralize(patientSetInfo.patient_name)} {props.type === 'intervention' ? `${props.is_individual ?" who received":"recommended for"} ` : "with contributor"} {props.label.toLowerCase()}
              </span>
              <div style={{ marginLeft: "6px" }}>
                  <img
                    className="closemodal"
                    src={closeIcon}
                    alt="close icon"
                    onClick={handleClose}
                  />
              </div>
            </div>
            {props.children}
             {props.dataSource ? "" : <div className="explore-data"> <button className="explore" onClick={() => sendViewBack("patientview")}>Explore {toCapitalize(patientSetInfo.cohort_name)}</button>
              </div>} 
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
