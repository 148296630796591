import React from "react";
import { CancelOutlined } from "@mui/icons-material";
import "./GenesisIframe.scss";

const ErrorPage = ({ errorMessage, clientDetailModal = false }) => {
  console.log("Error Page Called");
  return (
    <div className={clientDetailModal ? "error-container-2": "error-container"}>
      <div className="inner-container">
        <CancelOutlined sx={{ fontSize: "3rem", fill: "#B00020 !important" }} />
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

export default ErrorPage;
