import React, { useState, useMemo, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { withStyles, makeStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import services from "../../Services/patientServices";
import Alert from "@mui/lab/Alert";
import ApexCharts from 'apexcharts';
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { Skeleton } from "@mui/material";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    textAlign: "left",
    color: "#666",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 20,
    },
    "@media (max-width: 1023px)": {
      fontSize: 10,
    },
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  formControl: {
    width: "140px",
    "@media (max-width: 1023px)": {
      width: "100px",
    },
  },
  select:{
    width: "175px", 
    "@media (max-width: 1280px)": {
      width: "135px",
    },
    "@media (max-width: 1023px)": {
      width: "100px",
    }, 
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
  },
  label:{
    fontSize: '16px !important', 
    fontWeight: '400 !important',
    lineHeight: '24px', 
    color: 'rgba(0,0,0,0.87)'
  }
}));

const maxValue = (data, dataLable1, dataLable2 ) =>{
  let dataLable1Max = 0, dataLable2Max =0;
  data.forEach((item)=>{
    if(item[dataLable1]>dataLable1Max)
    dataLable1Max = item[dataLable1];

    if(item[dataLable2]>dataLable2Max)
    dataLable2Max = item[dataLable2];
  })

  if(dataLable1Max >= dataLable2Max)
  return dataLable1Max;
  else
  return dataLable2Max;


}

const InterventionBarChart = (props) => {
  const classes = useStyles();
  const { patientSetInfo } = useContext(PatientSetContext);

  const [interventionList, setInterventionList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [message, setMessage] = useState("");
  const [monthNumber, setMonthNumber] = useState(1);
  const [option, setOption] = useState(1);
  const [serviceStartDate, setServiceStartDate] = useState("");
  const [serviceEndDate, setServiceEndDate] = useState("");

  const disableChartOptions=[
    {
      id: 0,
      title: 'Member Count',
    },
    {
      id: 1,
      title: 'Intervention Count',
    },
    {
      id: 2,
      title: 'Both',
    },
  ]

  const sortByOptions = [
    {
      title : "1 month",
      value : 1,
    },
    {
      title : "12 month",
      value : 12,
    }
  ]
  const [activeChartOption, setActiveChartOption] = useState(disableChartOptions[2].id);

  const transformDate =(date)=> {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']; 
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    return `${day} ${monthNames[month]} ${year}`;
  }

  const fetchData = async (mount) => {
    const { patient_set_id: pid, selected_date } = patientSetInfo;

    setIsFetching(true);
    const interventionResponse = await services.interventions(
      {
        filter: {
          count: 0,
          countType: "patient_count",
          items: 20,
          order: "DESC",
          sortBy: "patient_count",
        },
        selected_date,
        no_of_months: monthNumber,
      },
      pid
    );

    if(interventionResponse.data.interventions.length){
      const endDate = transformDate(new Date(interventionResponse.data.service_date_to));
      const startDate = transformDate(new Date(interventionResponse.data.service_date_from));
      setServiceStartDate(startDate);
      setServiceEndDate(endDate)
    }
    setInterventionList(interventionResponse.data.interventions);


    if (!interventionList.length) {
      if (mount)
        setMessage(
          `Intervention data for this ${patientSetInfo.cohort_name} is not available yet. Please check back later.`
        );
      else
        setMessage(
          "No interventions are matching the current selection criteria."
        );
    }

    setIsFetching(false);
    setActiveChart(activeChartOption);
    
  };

  useEffect(() => {
    fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthNumber, option]);

  const createMixedChartSeries = (
    data,
    columnName,
    lineName,
    columnKey,
    lineKey,
    labelKey,
    title,
    xaxis,
    yaxis,
    enabeledSeries
  ) => {
 
    const series = [
      {
        name: columnName,
        type: "column",
        data: [],
      },
      {
        name: lineName,
        type: "column",
        data: [],
      },
    ];

    const options = {
      legend: {
        show: true,
        formatter: (seriesName, opts) => {
          if (opts.seriesIndex === 0) {
            return [`Showing counts from ${serviceStartDate} - ${serviceEndDate}`]
          } 
          return []
        },
        markers: {
          width: 0,
          height: 0,
        },
        onItemClick: {
          toggleDataSeries: false
        },
        onItemHover: {
          highlightDataSeries: false
      },
      },
      chart: {
        id:"mychart",
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: [0, 0],
      },
      markers: {
        size: [6, 6],
      },
      title,
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: false,
          hideOverflowingLabels: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: enabeledSeries,
        offsetY: -9,
        style: {
          fontSize: "10px",
          colors: ["#333333"],
        },
        background: {
          enabled: false,
        },
      },
      labels: [],
      xaxis,
      yaxis,
      tooltip: {
        intersect: true,
        shared: false,
        followCursor: true,
        enabledOnSeries: [0,1],
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const currentIntervention = interventionList[dataPointIndex];
          return (
            getTooltipHtmlString(currentIntervention, seriesIndex) 
          );
         
        }
      }
    };

    data.map((item) => {
      series[0].data.push(item[columnKey]);
      series[1].data.push(item[lineKey] > 0 ? item[lineKey] : 0.0);
      options.labels.push(item[labelKey]);
      return false;
    });

    return { series, options };
  };

  const getTooltipHtmlString =(data, seriesIndex)=>{
    const isMonthlyInterventionDataPresent = data?.month_wise_intervention_count?.length;
    const interventionData = isMonthlyInterventionDataPresent
      ? data.month_wise_intervention_count :
      [];
    const table_heading = seriesIndex === 0 ? "Member Count" : "Intervention Count";
    const tableData =  interventionData;
  
        return '<div class="tooltip-table-outer" style="background-color: rgba(97, 97, 97, 0.9)">' +
                '<table class="tooltip-table table table-bordered" style="color: #fff; font-size: 8px;">' +
                    '<thead>'+ 
                      '<tr style="padding : 3px">'+
                        `<th style="padding : 3px; height : 25px;" colspan="2">${data.label}</th>`+
                      '</tr>'+
                      '<tr style="padding : 3px">'+
                        '<th style="padding : 3px; height : 25px;">Month</th>'+
                        `<th style="padding : 3px; height : 25px;">${table_heading}</th>`+
                        '</tr>'+
                      '</thead>'+
                  '<tbody>'+
                    getToolTipData(tableData, seriesIndex) +
                  '</tbody>'+
                '</table>'+
                '</div>' 
  }

  const getToolTipData = (data, seriesIndex) => {
    let htmlString = "";
    data.forEach((item) => {
      let count = seriesIndex === 0 ? item.patient_count : item.intrv_count;
      htmlString =
        htmlString +
        `<tr style="backgroundColor: "#7b7e82;padding : 3px;"}>
        <td style="padding : 3px; font-size: 10px;" class="active">
        ${item.month} ${item.year}
        </td>
        <td style="padding : 3px; font-size: 10px;" class="active">
         ${count}
        </td>
      </tr>`;
    });
    return htmlString;
  };


  const chartSeries = useMemo(
    () => {
      const _maxValue = maxValue(interventionList,"intrv_patient_count", "individual_interventions");
      return createMixedChartSeries(
        interventionList,
        "Member Count",
        "Individual Intervention Count",
        "intrv_patient_count",
        "individual_interventions",
        "label",
        {
          text: "",
        },
        {
          labels: {
            show: true,
            rotate: -55,
            rotateAlways: true,
            trim: true
          },
          tooltip: {
            enabled: false
          }
        },
        [
          {
            title: {
              text: "Member Count",
              style: {
                color: "rgb(0, 143, 251)",
                fontWeight: 700,
                fontSize: '12px'
              },
            },
            tickAmount : 5,
            min : 0,
            max : _maxValue,
            forceNiceScale : true,
            labels : {
              formatter: (value) => parseInt(value),
            },

          },
          {
            opposite: true,
            title: {
              text: "Individual Intervention Count",
              style: {
                color: "rgb(0, 227, 150)",
                fontWeight: 700,
                fontSize: '12px'
              },
            },
            tickAmount : 5,
            min : 0,
            max : _maxValue,
            forceNiceScale : true,
            labels: {},
          },
        ],
        [0, 1]
        // eslint-disable-next-line react-hooks/exhaustive-deps
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interventionList]);

  const handleChange = (event) => {
    setOption(event.target.value);
    setMonthNumber(event.target.value);
  };

  const handleChartOptionChange = (event) => { 
    const chartOption = event.target.value;
    setActiveChart(chartOption);
    setActiveChartOption(chartOption);
  };

  const setActiveChart=(chartOption)=>{
    if(interventionList.length){
      if(chartOption===0 ){
        ApexCharts.exec('mychart', 'hideSeries', 'Individual Intervention Count');
        ApexCharts.exec('mychart', 'showSeries', 'Member Count');
        enableLegendForSeries(0);
       
      } else if(chartOption===1 ){
        ApexCharts.exec('mychart', 'hideSeries', 'Member Count');
        ApexCharts.exec('mychart', 'showSeries', 'Individual Intervention Count');
        enableLegendForSeries(1);
      }
      else {
        ApexCharts.exec('mychart', 'showSeries', 'Member Count');
        ApexCharts.exec('mychart', 'showSeries', 'Individual Intervention Count');
      }
    }
   
  }

  const enableLegendForSeries = (seriesIndex) => {
    ApexCharts.exec('mychart', 'updateOptions', {
      legend: {
        formatter: (seriesName, opts) => {
          const legendName = opts.seriesIndex === seriesIndex ? [`Showing counts from ${serviceStartDate} - ${serviceEndDate}`] : []
          return legendName
        },
      }
    }, false, true);
  }

  return (
    <div>
      <div className="riskFilterOuter">
        <div
          className="diagNosOuter diagNosOuter-resp"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            className="left-side widget-bg-shadow"
            id="intervention-dashboard3"
            style={{ width: "100%" }}
          >
              <div className="flexGroup">
                <div className="dignoHD" style={{width : "30%"}}>Interventions Provided Chart</div>
                {!isFetching && sortByOptions.length ? (
                  <div className="dropdown__container">
                  <span
                    className="filtrDropdown ml-0"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      width: "50%",
                      justifyContent: "flex-end",
                      gap :"30px"
                    }}
                  >
                    <Typography className={classes.label}> Toggle Chart</Typography>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="simple-select-toggle"
                        value={activeChartOption}
                        role="listbox"
                        onChange={handleChartOptionChange}
                        className={classes.select}
                        input={<BootstrapInput />} 
                        MenuProps={{disableScrollLock: true}}
                      >
                        {disableChartOptions.map((item) => {
                          return (
                            <MenuItem role="listbox" value={item.id}>
                              {`${item.title}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </span>
               
                    <span
                    className="filtrDropdown ml-0"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      width: "45%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography className={classes.label}>Time Window</Typography>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="simple-select-timewindow"
                        value={option}
                        role="listbox"
                        onChange={handleChange}
                        input={<BootstrapInput />} 
                        style={{borderBottom: "1px solid rgba(0, 0, 0, 0.42)"}}
                        MenuProps={{disableScrollLock: true}}
                      >
                        {sortByOptions.map((item) => {
                          return (
                            <MenuItem role="listbox" value={item.value}>
                              {`${item.title}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </span>
                  
                  </div>  
                ) : <div></div>}
                </div>
            {isFetching ? (
              <div className="mt-3 p-20">
                <Skeleton variant="rectangular" height={40} sx={{ margin: "20px" }} />
                <Skeleton variant="rectangular" height={40} sx={{ margin: "20px" }} />
                <Skeleton variant="rectangular" height={40} sx={{ margin: "20px" }} />
                <Skeleton variant="rectangular" height={40} sx={{ margin: "20px" }} />
              </div>
            ) : !isFetching && !interventionList.length ? (
              <div className="mt-3">
                <Alert
                  icon={false}
                  severity="warning"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {message}
                </Alert>
              </div>
            ) : (
              <div>
                <div id="mychart">
                    <Chart
                      options={chartSeries.options}
                      series={chartSeries.series}
                      type="line"
                      height={400}
                      max-width={500}
                    />
                </div> 
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionBarChart;
