import { IconButton, SvgIcon } from "@mui/material";

const ExpandButton = ({ onClick, disable }) => {
  return (
    <IconButton onClick={onClick} disableRipple disabled={disable}>
      <SvgIcon viewBox="0 0 16 16" sx={{ width: "12px", height: "12px" }}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.75 14.75H1.25V10.25"
            stroke="#0080B2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.25 14.75L6.5 9.5"
            stroke="#0080B2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.25 1.25H14.75V5.75"
            stroke="#0080B2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.75 1.25L9.5 6.5"
            stroke="#0080B2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    </IconButton>
  );
};

export default ExpandButton;
