import React from 'react';

const GenesisIframeComponent = () => {
    const iframeSrc = 'http://localhost:3000/genesis/launch';

    window.addEventListener('message', function(event) {
      console.log('Received message:', event.data);
    });
  
    return (
      <div>
        <h1>Dynamic Iframe Component</h1>
        <iframe
          title="DynamicIFrame"
          width="100%"
          height="400"
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
}

export default GenesisIframeComponent