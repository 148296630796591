import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import "../GenerateReport.scss";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import BackButton from "../../../Components/BackButton/BackButton";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import reportServices from "../../../Services/reportServices";
import { formateDateWithTimezone } from "../../../Utils/formatDate";
import Autocomplete from "@mui/material/Autocomplete";
import BackdropLoader from "../../../Components/Backdrop/Backdrop";
import { IframeContext } from "../../../Contexts/iframeContext";
import DatePicker2 from "../../../Components/DatePicker/DatePicker2";

const IFGenerateReferralReport = () => {
  const [isFetching, setIsFetching] = useState(false);
  const todayDate = new Date();
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [dateError, setDateError] = useState(false);
  const { iframe } = useContext(IframeContext);

  const [dropdowns, setDropdowns] = useState([
    { care_staff: { name: "Care Staff", value: "All", options: [] } },
    { service: { name: "Service", value: "All", options: [] } },
    { status: { name: "Status", value: "All", options: [] } },
    {
      refer_from_org: {
        name: "Refer From Organization",
        value: "All",
        options: [],
      },
    },
    {
      refer_to_org: {
        name: "Refer To Organization",
        value: "All",
        options: [],
      },
    },
  ]);

  let history = useNavigate();

  const onStartDateChange = (date) => {
    setStartDate(new Date(date));
  };

  const onEndDateChange = (date) => {
    setEndDate(new Date(date));
  };

  const compareDate = () => {
    if (endDate < startDate) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const getOptionLabel = (option) => {
    if (typeof option === "string") {
      return option;
    }
    return ` ${option.name}`;
  };

  const generateReport = async () => {
    try {
      if (dateError) return;
      const todayDate = formateDateWithTimezone(new Date());
      setIsFetching(true);

      const requestBody = {
        end_date: endDate,
        start_date: startDate,
        created_at: todayDate,
      };
      // setting request paramters
      dropdowns.forEach((dropdown) => {
        const dropdownKey = Object.keys(dropdown)[0];
        const dropdownValue = dropdown[dropdownKey].value;

        requestBody[`${dropdownKey}_id`] = dropdownValue.id || "";
        requestBody[`${dropdownKey}_name`] = dropdownValue.name || "";
      });

      const response = await reportServices.getReferralReportData(
        requestBody,
        iframe
      );
      const { data } = response.data;
      localStorage.setItem("report_data", JSON.stringify(data));
      setIsFetching(false);
      history('/if/report/referral-report');
    } catch (error) {
      setIsFetching(false);
    }
  };

  const getReportFilters = async () => {
    try {
      setIsFetching(true);
      const response = await reportServices.getReferralReportFilter(iframe);
      const { data } = response.data;

      setDropdowns((prevState) => {
        return prevState.map((dropdown) => {
          const dropdownKey = Object.keys(dropdown)[0];
          return {
            [dropdownKey]: {
              ...dropdown[dropdownKey],
              options: data[`${dropdownKey}`],
            },
          };
        });
      });

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log(error);
    }
  };

  useEffect(() => {
    compareDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    getReportFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sidebar>
      <div className={`${iframe ? "ccs__iframe__active" : ""}`}>
        <div className="uploadpage app-bg-color DashboardOuter rp-overflow">
          <div className="btn-back">
            <BackButton color={"#fff"} backgroundColor={ iframe ? "#00ab55": "#0082b2"} />
          </div>
          <div className="upload-outer">
            <div className="upload-inner widget-bg-shadow">
              <div className="report-title">Referral Report</div>
              <div className="query-container">
                <div>
                  <div className="query-label">Start Date:</div>
                  <div className="query-value">
                    <DatePicker2
                      onChange={() => false}
                      onAccept={onStartDateChange}
                      value={startDate}
                      inputFormat="MM/DD/YYYY"
                      className="datepicker"
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div>
                  <div className="query-label">End Date:</div>
                  <div className="query-value">
                    <DatePicker2
                      onChange={() => false}
                      onAccept={onEndDateChange}
                      value={endDate}
                      inputFormat="MM/DD/YYYY"
                      className="datepicker"
                      maxDate={new Date()}
                    />
                    {dateError ? (
                      <div className="error">
                        End Date should be greater than Start Date
                      </div>
                    ) : null}
                  </div>
                </div>
                {dropdowns.map((dropdown, index) => {
                  const dropdownKey = Object.keys(dropdown)[0];
                  const dropdownData = dropdown[dropdownKey];

                  return (
                    <div key={dropdownKey}>
                      <div className="query-label">
                        {dropdownData.name} (Optional):
                      </div>
                      <div className="query-value">
                        <FormControl
                          sx={{ marginTop: 1, minWidth: 120, width: "100%" }}
                        >
                          <Autocomplete
                            disablePortal
                            id={`combo-box-demo-${index}`}
                            options={["All"].concat(dropdownData.options)}
                            getOptionLabel={getOptionLabel}
                            className="select-box"
                            renderInput={(params) => <TextField {...params} />}
                            value={dropdownData.value}
                            defaultValue="All"
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              const updatedDropdowns = [...dropdowns];
                              updatedDropdowns[index][dropdownKey].value =
                                newValue;
                              setDropdowns(updatedDropdowns);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="upload-submit">
                <Button
                  disabled={isFetching}
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={generateReport}
                >
                  Generate Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackdropLoader isFetching={isFetching} />
    </Sidebar>
  );
};

export default IFGenerateReferralReport;
