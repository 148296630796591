import { Link } from "react-router-dom";
import logo from "../../Assets/Images/logo-white.png";
import MenuIcon from '@mui/icons-material/Menu';

export default function LandingPageHeader () {
    const redirectTo = (url) => {
        window.location.href = url
    }

    const handleToggle = () =>{
        var navbarNav = document.getElementById('navbarNav');
        navbarNav.classList.toggle('show');
    }
    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg navbar-dark shadow">
                <Link to="#!" className="navbar-brand">
                    <img src={logo} height="50" alt="Foresight Health Solutions" /> <span className="header-title">Foresight Health Solutions</span>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    {/* <span className="navbar-toggler-icon"></span> */}
                    <MenuIcon sx={{width: "2rem", height: "1.8rem",fill: "#ffffff80 !important"}} onClick={handleToggle}/>
                </button>
                <div className="navbar-collapse transform" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to="#" className="nav-item nav-link">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownSolutions" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Our Solutions
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownSolutions">
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/careAnalytics.html')}>Care Analytics</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/technical.html')}>Technology</Link>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownServe" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               Who we serve
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownServe">
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/whoweserve.html')}>Community Health centers</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/whoweserve.html#CommunityBasedOrganizations')}>Community Based Organization</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo("https://www.foresighthealthsolutions.com/whoweserve.html#ProviderHealthNetworks")}>Provider Health Networks</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/whoweserve.html#HealthPlans')}>Health Plans</Link>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownAbout" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              About Us
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownAbout">
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/company.html')}>Company</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/careers.html')}>Careers</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/news.html')}>News</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/contact.html')}>Contact</Link>
                            </div>
                        </li>

                        <li className="nav-item">
                            <Link to="#" onClick={()=>redirectTo('https://www.foresighthealthsolutions.com/resources.html')} className="nav-item nav-link">Resources</Link>
                        </li>


                    </ul>
                </div>
            </nav>
        </header>
    );
}