import React, { useEffect, useState, useContext } from "react";
import { Modal, Box, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

import HeathTrendsHeader from "../../WcchcPatientSummary/HeathTrendsHeader";
import { WcchcPatientSummaryContext } from "../../../Contexts/wcchcPatientSummaryContext";
import {health_trends_radio_list, colors, LOINC_CODES } from '../../../constants';
import { getMarkerColor, sortGraphData } from '../../../Utils/wcchcPatientSummary';

const useStyles = makeStyles((theme) => ({
  mainModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "1074px",
    maxHeight: "95vh",
    maxWidth: "95vw",
    backgroundColor: "#fff",
    outline: "none",
  },
  chartContainer: {
    height: "900px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "calc(100% - 150px)",
    overflow: "auto",
  },
  trendsChart: {
    backgroundColor: "#fff",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    width: "1000px",
    maxWidth: "90vw",
    minHeight: "305px !important",
    marginBottom: "27px",
    padding: "10px 0px 34px 10px",
    "& .apexcharts-xaxis-label": {
      color: "#1b2632",
      fontFamily: "Lato important",
      fontSize: "12px",
      fontWeight: 500,
    },
    "& .apexcharts-yaxis-label": {
      color: "#1b2632",
      fontFamily: "Lato important",
      fontSize: "12px",
      fontWeight: 500,
    },
  },
  emptyData: {
    fontFamily: "Lato",
    fontSize: "14px !important",
    fontWeight: 600,
    textAlign: "center",
    padding: "20px 0px",
  },
  scenarioBox: {
    padding: "0px 28px !important",
  },
  scenario: {
    fontFamily: "Lato",
    fontSize: "18px !important",
    fontWeight: 600,
  },
}));

const options = {
    chart: {
      height: "250px",
      type: "line",
      zoom: {
        enabled: false,
      },
      fontFamily: "Lato",
      toolbar:{
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    legend: {
      show: true,
      position: "left",
      width: "20px",
      fontSize: "12px",
      fontFamily: "Lato",
      color: "#6C7278",
      fontWeight: "400",
      markers: {
        width: 0,
        height: 0,
      },
    },
    grid: {
      padding:{
        right: 25,
        left: 20,
      },
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          cssClass: "apexcharts-yaxis-label",
        },
      },
    },
    tooltip: {
      shared: false,
      style: {
        fontSize: "10px",
        color: "#0080b2",
      },
      marker: {
        show: false,
      },
    },
};

const HealthTrendsModal = ({ open = true, duration, selectedDisease,  ...props }) => {
  const classes = useStyles();

  const [chartData, setChartData] = useState([]);
  const [modalDuration, setModalDuration] = useState(duration);
  const [modalDisease, setModalDisease] = useState(selectedDisease);
  const [label, setLabel] = useState("");

  const { state } = useContext(WcchcPatientSummaryContext);

  const getOption = (series, colors, index) =>{
    return{
      colors: ["#36E948"],
      xaxis: {
        categories: series.data.map((item) =>
          dayjs(item.x).format("MM/DD/YY")
        ),
        labels: {
          show: true,
          rotate: -45,
          style: {
            cssClass: "apexcharts-xaxis-label",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      markers: {
        size: 4,
        strokeWidth: 0,
        discrete: series.data.map((item, i) => {
          return {
            seriesIndex: 0,
            dataPointIndex: i,
            fillColor: getMarkerColor(modalDisease,item, colors, index),
            size: 4,
          };
        }),
      },
      title: {
        text: series.unit ? `${series.name} (${series.unit})` : series.name,
        style: {
          fontSize: "14px",
          fontWeight: "700",
          fontFamily: "Lato",
          color: "#1B2632",
        },
      },
    }
  }

  const setInitialChartData = () =>{
    let data = [];
    let finalChartData = {};
    const label = health_trends_radio_list.find((item) => {
      return item.value === modalDisease;
    });
    setLabel(label?.label);
    const currentDate = dayjs().endOf("month");
    const pastDate = currentDate.subtract(modalDuration, "month");
    if (modalDisease === "vitals") {
      const series = [];

      Object.keys(state?.vitals).forEach((key, index) => {
        const items = state?.vitals[key];

        const filteredData = items?.chartData.filter((item) => {
          return (
            dayjs(item?.x).isAfter(pastDate) &&
            dayjs(item?.x).isBefore(currentDate) &&
            item?.y
          );
        });
        if (filteredData.length) {
          series.push({
            name: items?.interpretation,
            type: "line",
            data: filteredData,
          });
        }
        finalChartData.series = series;
        finalChartData.options = options;
      });
    } else {
      // const series = [];
      // const hcc_code = loinc_codes[modalDisease];
      // let trends = {};

      // hcc_code.forEach((code) => {
      //   if(typeof code === "string"){
      //     trends = {
      //       ...trends,
      //       ...state?.healthTrends[code],
      //     };
      //   }else{
      //     let arr = [];
      //     let keys = [];
      //     code.forEach((code2) =>{
      //         if(state.healthTrends[code2]){
      //         const combinedArray = Object.values(state.healthTrends[code2]).flatMap(item => item);
      //         const key = Object.keys(state.healthTrends[code2]);
      //         arr = [...arr, ...combinedArray];
      //         keys = [...keys, ...key];
      //       }
      //     })
      //     let finalObject = {
      //       [keys[0]]: sortGraphData(arr)
      //     }
      //     trends ={
      //       ...trends,
      //       ...finalObject
      //     }
      //   }
      // });
      let trendsChart={};
      const series = [];
      const selected_diseases_loinc_codes = LOINC_CODES[modalDisease];
      selected_diseases_loinc_codes?.forEach((currentLoincCodes) =>{
        const { name, codes } = currentLoincCodes;
        let arr = [];
        codes?.forEach((code)=>{
          if(state.healthTrends[code]){
            const combined_data_of_loinc = Object.values(state.healthTrends[code]).flatMap(item => item);
            arr = [...arr, ...combined_data_of_loinc];
          }
        })
        if(arr.length){
          let final_chart = {
            [name]: sortGraphData(arr)
          }
          trendsChart ={
            ...trendsChart,
            ...final_chart
          }
        }
      })

      if (trendsChart) {
        Object.keys(trendsChart).forEach((key) => {
          const filteredData = trendsChart[key].filter((item) => {
            return (
              dayjs(item?.x).isAfter(pastDate) &&
              dayjs(item?.x).isBefore(currentDate) &&
              item?.y
            );
          });
          if (filteredData.length) {
            series.push({
              name: key,
              type: "line",
              data: filteredData,
              unit: trendsChart[key][0].unit,
            });
          }
        });
      }

      finalChartData.series = series;
      finalChartData.options = options;
    }

    console.log("**********finalChartData",finalChartData)

    finalChartData?.series?.forEach((series, index) => {
      data.push({
        series: [
          {
            name: series.name,
            data: series.data.map((item) => Number(item.y)),
          },
        ],
        options: {
          ...options,
          ...getOption(series, colors, index)
        },
      });
    });
    setChartData(JSON.parse(JSON.stringify(data)));
  }

  useEffect(() => {
    setInitialChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalDisease, modalDuration,JSON.stringify(state.vitals), JSON.stringify(state?.labResults)]);

  return (
    <Modal
      open={open}
      onClose={() => props.handleHealthTrendsModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <Box className={classes.mainModalContainer}>
        <HeathTrendsHeader 
          modal={true} 
          setSelectedDisease={setModalDisease}
          setDuration={setModalDuration}
          duration={modalDuration}
          selectedDisease={modalDisease}
          {...props} 
        />
        <Box className={classes.scenarioBox}>
          <Typography className={classes.scenario}>
            Scenario : {props.label}
          </Typography>
        </Box>
        <Box className={classes.chartContainer}>
          <Box>
            {chartData.length ? (
              chartData.map((data, index) => (
                <ReactApexChart
                  key={index}
                  options={data.options || {}}
                  series={data.series || []}
                  type="line"
                  height={300}
                  className={classes.trendsChart}
                />
              ))
            ) : (
              <Typography className={classes.emptyData}>
                {label} information is not available for last{" "}
                {modalDuration} months
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default HealthTrendsModal;
