import React, { useContext } from "react";
import { AuthContext } from "../../Contexts/authContext";

const PublicRoute = ({ element: Element, ...rest }) => {
  const { state: { isFetching } } = useContext(AuthContext);

  if (isFetching) {
    return null; // Optionally return a loading spinner
  }

  return <Element {...rest} />;
};

export default PublicRoute;
