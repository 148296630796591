import { useContext } from "react";
import { Box, Typography, Skeleton } from "@mui/material";

import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import ExpandButton from "./ExpandButton";

const RiskCard = ({ setComprehensiveRiskModal, demo = false, showBorder }) => {
  const { state } = useContext(WcchcPatientSummaryContext);

  const riskValue = demo ? 22.09 : state?.comprehensiveRiskScore?.comp_risk_score;

  const riskInfo = () => {
    if (state.isFetchingComprehensiveRisk) {
      return <Skeleton variant="text" width={125} height={33} />;
    }
    
    if (!riskValue) return "";
  
    let riskCategory, riskColor;
  
    if (Number(riskValue) < 0.0) {
      riskCategory = "Unknown-risk";
      riskColor = "#7d7b7b";
    } else if (Number(riskValue) <= 0.7) {
      riskCategory = "Low-risk";
      riskColor = "#107C10";
    } else if (Number(riskValue) <= 1.3) {
      riskCategory = "Medium-risk";
      riskColor = "#F5EA14";
    } else if (Number(riskValue) <= 2.0) {
      riskCategory = "High-risk";
      riskColor = "#E95C16";
    } else {
      riskCategory = "Critical-risk";
      riskColor = "#FD0808";
    }
  
    return (
      <Box className="risk-icon-container">
        <Typography className="high-risk-text" sx={{ color: riskColor }}>{riskCategory}</Typography>
      </Box>
    );
  };
  

  return (
    <Box className={`risk-score-section ${showBorder ? "selected-border" : ""}`}>
      <Box className="left-container">
        <Typography className="risk-score-text">
          Comprehensive Risk
        </Typography>
      </Box>
      <Box className="right-container">
        {riskInfo()}
        <ExpandButton
          onClick={() => {
            setComprehensiveRiskModal(true);
          }}
          disable={state.isFetchingComprehensiveRisk}
        />
      </Box>
    </Box>
  );
};

export default RiskCard;
