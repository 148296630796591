import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/DashboardHeader";
import Alert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CreateNewUser from "../CreateUser/CreateUser";
import CreateOrganization from "../CreateUser/CreateOrganization";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import AkaAccessListTable from "../../Components/Tables/AkaAccessListTable";
import ReInviteUser from "../../Components/ReInvite";
import withRouter from "../../Utils/withRouterHOC";

const Admin = ()  => {
  const { setPageTitle } = useContext(MetaDetailsContext);
  const [showToast, setShowToast] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setPageTitle("FHS: Admin Dashboard");
    console.log("Admin dashboard page");
    const open = JSON.parse(localStorage.getItem("aka_alert_open"));
    if (open) {
      setShowToast(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const name = user.user.first_name + " " + user.user.last_name;
      setName(name);
      localStorage.setItem("aka_alert_open", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
        <div className="homepage DashboardOuter">
        <div>
            <Header homepage />
          </div>
          <div className="app-bg-color">
            <div className="patient-set-flex">
              <div className="patient-set-outer-left">
                <div className="patient-set-inner-left widget-bg-shadow">
                  <div className="patient-set-title">Aka access</div>
                  <div className="">
                    <AkaAccessListTable />
                  </div>
                </div>
              </div>

              <div className="patient-set-outer-right">
                <div className="patient-set-inner-right widget-bg-shadow">
                  <CreateOrganization />               
                </div>
              </div>
            </div>


            <div className="patient-set-flex">
                <div className="patient-set-outer-left mt-1">
                  <div className="patient-set-inner-left widget-bg-shadow">
                    <CreateNewUser />
                  </div>
                </div>
                <div className="patient-set-outer-left mt-1">
                  <div className="patient-set-inner-left widget-bg-shadow">
                    <ReInviteUser />
                  </div>
                </div>
            </div>
          </div>

          <div className="toaster">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={showToast}
              autoHideDuration={16000}
              onClose={() => setShowToast(false)}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setShowToast(false)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            >
              <Alert
                onClose={() => setShowToast(false)}
                variant="outlined"
                severity="success"
              >
                <span>
                  Aka Access: Initiated browsing session as user {name}.{" "}
                </span>
              </Alert>
            </Snackbar>
          </div>
        </div>
    </div>
  );
};

export default withRouter(Admin);
