const formatHeading = (heading) => {
    const splitHeading = heading.split("_")
    const captilizeHeading = splitHeading.map(element => {
        return element.charAt(0).toUpperCase() + element.slice(1)
    })
    const transformedHeading = captilizeHeading.join(" ")
    return transformedHeading;
}


export default formatHeading;