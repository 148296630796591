import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useContext, useEffect, useState } from "react";
import FileList from "./FileList";
import Alert from "../../Components/Alert/Alert";
import "./DataContentAnalysis.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";
import dayjs from "dayjs";
import services from '../../Services/patientServices';
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import { useNavigate } from "react-router-dom";

const DataContentAnalysis = ()  => {
  const [isFetching, setIsFetching] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('');
  const { setPageTitle } = useContext(MetaDetailsContext);
  const history = useNavigate();

  const initialState = {
    days30: false,
    custom: false,
  };

  const [selection, setSelection] = useState({
    days30: true,
    custom: false,
  });

  const [startDate, setStartDate] = useState(dayjs().subtract(30, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  
  useEffect(() => {
    setPageTitle("FHS: Content Assessment");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = (open, message, severity) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setOpenAlert(open);
  };

  const handleStartDateChange = (date) => {
     setStartDate(date);
  }

  const handleEndDateChange = (date) => {
    setEndDate(date);
  }

  const validateDate = (startDate, endDate) => {
    if (!startDate || !endDate) {
      handleAlert(true, "Please select start date and end date", 'warning');
      return false;
    }
    if (!dayjs(startDate).isBefore(endDate)) {
      handleAlert(true, "Start date should be before end date", 'warning');
      return false;
    }
    return true;
  }

  const handleGenerateDCA = async () => {
    try {
      if (!selectedFiles.length) {
        handleAlert(true, "No files selected for dca assessment", 'warning');
        return;
      } 

      if (!validateDate(startDate, endDate)) {
        return;
      }

      const user = JSON.parse(localStorage.getItem('user')).user;

      const payload = {
        start_date: startDate.format('DD MMM YYYY'),
        end_date: endDate.format('DD MMM YYYY'),
        user_name: `${user.first_name} ${user.last_name}`,
        org_id: user.org_id,
        input_files: selectedFiles.filter((file) => file !== 'All')
      }

      console.log('Input files => ', payload);

      setIsFetching(true); 
      const { data } = await services.generateDcaAnalysis(payload);
      setIsFetching(false);
      handleAlert(true, data?.message, 'success');
    } catch (error) {
      setIsFetching(false);
    }
  };

  return (
    <Sidebar>
      <div className="uploadpage DashboardOuter app-bg-color">
 
          <div className="upload-outer" style={{ width: "37%" }}>
            <div
              className="upload-inner widget-bg-shadow"
              style={{ height: "700px" }}
            >
              <div className="upload-title mt-4">
                Data Content Assessment (DCA)
              </div>
              <div className="upload-subtitle">
                Please select available files for DCA
              </div>
              <FormControl sx={{ display: "flex" }} color="primary">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="30days"
                  name="radio-buttons-group"
                  className="dca-checkBox"
                  row
                  onChange={(e) => {
                    if (e.target.value === "custom") {
                      setStartDate(null);
                      setEndDate(null);
                    } else {
                      setStartDate(dayjs().subtract(30, "day"));
                      setEndDate(dayjs());
                    }
                    setSelection((state) => ({
                      ...initialState,
                      [e.target.value]: true,
                    }));
                  }}
                  color="primary"
                >
                  <FormControlLabel
                    color="primary"
                    value="days30"
                    control={<Radio checked={selection.days30} />}
                    label="30 days"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio checked={selection.custom} />}
                    label="Custom"
                  />
                </RadioGroup>
              </FormControl>
              <Grid container xs={12} spacing={2} mb={2}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      value={startDate}
                      label="From Date"
                      onChange={handleStartDateChange}
                      disableFuture
                      disabled={selection.days30}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      value={endDate}
                      label="To Date"
                      disabled={selection.days30}
                      onChange={handleEndDateChange}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            <FileList
                isFetching={isFetching}
                setIsFetching={setIsFetching}
                startDate={startDate}
                endDate={endDate}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div className="upload-submit">
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    disabled={isFetching}
                    onClick={() => {
                      handleGenerateDCA();
                    }}
                  >
                    Generate DCA
                  </Button>
                </div>                
                <div className="upload-submit" style={{ marginTop: '10px'}}>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={() => { history('/data-content-analysis/dca-new-output') }}
                  >
                    View Previous Assessments
                  </Button>
                </div>
              </div>
            </div>
          </div>
        <div className="customizeAlert">
          <Alert
            setOpen={setOpenAlert}
            open={openAlert}
            message={alertMessage}
            severity={alertSeverity}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default DataContentAnalysis;