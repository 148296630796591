import React from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container:{
        width: "100vw",
        height: "100vh",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    containerInner:{
        maxWidth: "150px",
        maxHeight: "150px",
        position: "absolute"
    },
    containerInner2:{
        maxWidth: "300px",
        maxHeight: "300px",
        position: "absolute"
    },
    loader:{
        border: '8px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '8px solid #0082b2',
        width: '120px',
        height: '120px',
        animation: '$spin 2s linear infinite',
    },
    loader2:{
        border: '8px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '8px solid #0082b2',
        width: '180px',
        height: '180px',
        animation: '$spin 2s linear infinite',
    },
    message:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontSize: '14px'
    },
    "@keyframes spin": {
        "0%": { transform: "rotate(0deg)" },
        "100%": { transform: "rotate(360deg)" }
    }
  }));

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
        <div className={classes.containerInner}>
            <div className={classes.loader}>
            </div>
            <p className={classes.message}>Loading.....</p>
        </div>
    </div>  
)
}

export default Loader