import React from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tooltip:{
    "& p":{
      fontSize: "12px",
      color: "#fff"
    }
  },
}));


export const CustomTooltip = ({ data }) => {
    const classes = useStyles();
    
    return (
      <div className={classes.tooltip}>
          <p>Treatment Month Number: {data?.month_number}</p>
          <p>Total Percentage: {data?.total_percentage}</p>
          <p>Met: {data?.met}</p>
          <p>Total Objective: {data?.total_objectives}</p>
      </div>
    );
};

export const ClientDetailsTooltip = ({ data }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.tooltip}>
        <p>Total Precentage: {data?.total_percentage === " " ? " - " :  parseFloat(data?.total_percentage?.toFixed(2))}</p>
        <p>Met: {data?.met === " " ? " - " :  data?.met}</p>
        <p>Total_objectives: {data?.total_objectives ?? "-"}</p>
    </div>
  );
};