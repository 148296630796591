import React, { useContext } from "react";
import "./FileUpload.scss";
import Dropzone from "./Dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Clear";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";
import LockIcon from "@mui/icons-material/Lock";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { toCapitalize, toLowerCase } from "../../Utils/stringOprations";
import { PatientSetContext } from "../../Contexts/patientSetContext";

const FileUploadCohort = ({
  file,
  setFile,
  setProgress,
  isFetching,
  openSuccessSnack,
  setOpenSuccessSnack,
  openFailureSnack,
  setOpenFailureSnack,
  dataSent,
  setDataSent,
  complete,
  setComplete,
  cancelUpload,
  patientSets,
  isFetchings,
  refreshData,
  patientIds
}) => {
  const history = useNavigate();
  const { patientSetInfo } = useContext(PatientSetContext);

  useEffect(() => {
    if(!complete) {
      setProgress(0);
      setDataSent(0);
    };
  }, [complete,setDataSent,setProgress]);

  const handleClose = (callback) => {
    callback(false);
  };

  return (
    <div className="uploadpage DashboardOuter cohortUpload">
      <div>
        <div className="upload-inner upload-inner-cohort">
          <div className="upload-limits mt-4">
            <span>Accepted file types: .csv</span>
            <span>
              <LockIcon />
              Secure
            </span>
          </div>
          <Dropzone setFile={setFile} setComplete={setComplete} value="newCohort"/>
          {file ? (
            <div className="upload-preview">
              <div className="upload-preview-flex">
                <div>
                  <FolderIcon />
                  <span className="ml-1">{file.name}</span>
                </div>
                <div>
                {complete ? (
                  <DoneIcon
                    style={{ cursor: "pointer", color: "#33e033" }}
                  />
                ) : (
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => cancelUpload()}
                  />
                )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="upload-snack-success">
        <Modal
          open={openSuccessSnack}
          onClose={() => handleClose(setOpenSuccessSnack)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="success-modal">
            <div className="close-icon"><CloseIcon onClick={() => handleClose(setOpenSuccessSnack)}/></div>
            <div className="add-link" style={{padding: "10px 30px 30px 30px"}}>
              <div style={{ width: "100%" }} className="fileUploaded"><b>{toCapitalize(patientSetInfo?.cohort_name)} created successfully.</b></div>
              <div className="cohort-link d-flex">
                  <button 
                    className="tblBtn mt-4"
                    onClick={() => history(`/patient-sets?cohort=${patientSetInfo?.patient_set_id}`)}
                  >
                    edit settings
                  </button>
                  <button 
                    className="tblBtn mt-4"
                    onClick={() => history("/")}
                  >
                    Go to dashboard
                  </button>
              </div><br />
              {patientIds.length > 0 ? (
                <>
                  <div className="duplicateIdsTitle">Found duplicate {toLowerCase(patientSetInfo.patient_name)} ID(s):</div>
                    <div className="duplicateIdsContainer">
                    {
                      patientIds.map((ids, index) => {
                        return <div className="duplicateIds">{ids.ID }</div>
                      })
                    }
                  </div>
                </>
              ) : null}   
            </div>
          </Box>
        </Modal>
      </div>
      <div className="upload-snack-failure">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openFailureSnack}
          onClose={() => handleClose(setOpenFailureSnack)}
          autoHideDuration={8000}
        >
          <Alert
            onClose={() => handleClose(setOpenFailureSnack)}
            severity="error"
            sx={{ width: "100%" }}
          >
            File upload cancelled.
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default FileUploadCohort;
