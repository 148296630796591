import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import Spinner from "../../../Components/Spinner/Spinner";
import AT_RISK_DATA from "./Data/At_Risk.json";

const PROGRAMS = [
  {
    key: "atRisk",
    title: "Re-admit and Risk Management",
    data: AT_RISK_DATA,
  },
];

const ProgramBarChart = ({ selectedProgram, column }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [xLabels, setXLabels] = useState([]);
  const [memberCountData, setMemberCountData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  const getChartData = (programKey = "atRisk") => {
    setIsFetching(true);
    const baseData = PROGRAMS.find((ele) => ele.key === programKey).data;
    const name = column.title;

    let preAvg, postAvg;
    if (column.key === "Avg") {
      preAvg = calculatePMPMSum(baseData,-18,-1,"Claims_Sum","Member_Count");
      postAvg = calculatePMPMSum(baseData, 1, 18, "Claims_Sum", "Member_Count");
    } else {
      preAvg = calculateOtherAvg(baseData, -18, -1, column.key);
      postAvg = calculateOtherAvg(baseData, 1, 18, column.key);
    }

    // x-axis labels
    const _xLabels = baseData.map((ele) => Number(ele.Months));
    setXLabels(_xLabels);

    // Member count
    let _memberCount = [];
    if(column.key === "Avg" || column.key === "Claims_Sum"){
       _memberCount = baseData.map((ele)=>(ele["Member_Count"]));
    }else{
      _memberCount = baseData.map((ele)=>(ele["Mem_Count"]));
    }
    setMemberCountData(_memberCount)

    // Draw Average line
    const yAnnotations = [
      createAnnotation(
        preAvg,
        formatLabels(preAvg),
        chartWidth * 0.02,
        "left",
        "pre"
      ),
      createAnnotation(
        postAvg,
        formatLabels(postAvg),
        chartWidth * 0.5,
        "right",
        "post"
      ),
    ];

    if (chartRef.current) {
      chartRef.current.chart.updateOptions({
        // Add or update any other chart options here
        annotations: {
          yaxis: yAnnotations,
        },
      });
    }

    if (column) {
      // y-axis labels
      const _yData = baseData.map((ele) => Number(ele[column.key]));
      setSeriesData([
        {
          name: name,
          type: "column",
          data: _yData,
        }
      ]);
    }

    setIsFetching(false);
  };

  function calculatePMPMSum(data, startIndex, endIndex, fistKey, secondKey) {
    let sumCost = 0,
      memberCount = 0;

    // Iterate over the data array
    for (let i = 0; i < data.length; i++) {
      const month = parseFloat(data[i].Months);
      const PMPM = parseFloat(data[i][fistKey]);
      const Member_Count = parseFloat(data[i][secondKey]);

      // Check if the month is within the specified range and not 0
      if (month >= startIndex && month <= endIndex && month !== 0) {
        sumCost += PMPM;
        memberCount += Member_Count;
      }
    }

    const avg = sumCost / memberCount;
    return avg;
  }

  function calculateOtherAvg(data, startIndex, endIndex, fistKey) {
    let sumCost = 0,
      noOfMonths = 0;

    // Iterate over the data array
    for (let i = 0; i < data.length; i++) {
      const month = parseFloat(data[i].Months);
      const PMPM = parseFloat(data[i][fistKey]);

      // Check if the month is within the specified range and not 0
      if (month >= startIndex && month <= endIndex && month !== 0) {
        sumCost += PMPM;
        noOfMonths++;
      }
    }

    const avg = sumCost / noOfMonths;
    return avg;
  }

  useEffect(() => {
    getChartData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgram, chartWidth]);

  const formatLabels = (count) => {
    const type = column ? column.type : "comma-separated";
    switch (type) {
      case "comma-separated":
        return Number(count).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
      case "decimal-fixed":
        return Number(count).toFixed(2);
      case "currency-string":
        return Number(count).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
      case "currency-string-million":
        return (
          (count / 1000000).toLocaleString(undefined, {
            maximumFractionDigits: 1,
          }) + "M"
        );
      default:
        return "-";
    }
  };

  const customTooltip = ({series, seriesIndex, dataPointIndex, w}) =>{

    return `<div style="min-width: 300px; max-width: 700px;">
        <div style="font-size:14px; background-color: #f5f5f5; padding: 5px; border-bottom: 1px solid #e6e6e6; ">
          <span>${"Normalized Enrollment (Month)"} : <span style="font-weight:700;">${xLabels[dataPointIndex]}</span></span><br/>
        </div>
        <div style="background-color:#ffffff; font-size:14px; padding: 8px;">
          <span>${column.title} : <span style="font-weight:700;">${formatLabels(series[seriesIndex][dataPointIndex])}</span></span><br/>
        </div>
        <div style="background-color:#ffffff; font-size:14px; padding: 8px;">
          <span>${"Member Count"} : <span style="font-weight:700;">${memberCountData[dataPointIndex]}</span></span><br/>
        </div>
        <div style="font-size:12px; background-color: #e6f8ff; padding: 5px;">
          <span>${"** Positive and negative months denote metrics post and pre enrollment month respectively."}</span><br/>
        </div>
        </div>`
  }

  const chartOptions = {
    chart: {
      id: "comparison-chart",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      formatter: formatLabels,
      offsetY: -20,
      style: {
        fontSize: "9px",
        colors: ["#333"],
      },
    },
    colors: [
      "#0C66E4",
      "#5F5F5F",
      "#D6CFC7",
      "#B9BBB6",
      "#D9DDDC",
      "#97978F",
      "#5F5F5F",
      "#D6CFC7",
      "#B9BBB6",
      "#D9DDDC",
      "#97978F",
    ],
    xaxis: {
      title: {
        text: "Normalized Enrollment (Months)",
      },
      categories: xLabels,
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: column ? column.title : "",
      },
      labels: {
        formatter: formatLabels,
      },
      tooltip: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "#fff"],
        opacity: 0.8,
      },
    },
    markers: {
      size: [3, 3],
    },
    tooltip:{
      custom: customTooltip
    },
  };

  const createAnnotation = (y, text, offsetX = 0, position = "left", type) => {
    return {
      y: y,
      borderColor: "red",
      width: "50%",
      strokeDashArray: 0,
      offsetX: type === "pre" ? 0 : offsetX,
      label: {
        text: text,
        offsetX: type === "post" ? 0 : offsetX,
        position: position,
        style: {
          color: "red",
        },
      },
    };
  };

  const calculateChartWidth = () => {
    if (chartRef.current) {
      const gridRowElement =
        chartRef.current.chart.w.globals.dom.baseEl.querySelector(
          ".apexcharts-grid .apexcharts-grid-row"
        );
      if (gridRowElement) {
        const gridRowBBox = gridRowElement.getBBox();
        const gridRowWidth = gridRowBBox.width;
        setChartWidth(gridRowWidth);
      }
    }
  };

  useEffect(() => {
    calculateChartWidth();
  }, [xLabels]);

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <Chart
          ref={chartRef}
          options={chartOptions}
          series={seriesData}
          type="bar"
          height={400}
        />
      )}
    </>
  );
};

export default ProgramBarChart;
