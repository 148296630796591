import { Box, Grid, Skeleton } from "@mui/material";

const PyramidSkeleton = () => {
  return (
    <Grid item lg={6} sm={12} xs={12} md={12}>
      <div
        className="GraphBg widget-bg-shadow cohortClaimsCosts"
        style={{ minHeight: "400px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Skeleton variant="rectangular" height={20} width={300} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Skeleton variant="rectangular" height={20} width={170} />
              <Skeleton variant="rectangular" height={20} width={150} />
              <Skeleton variant="rectangular" height={20} width={160} />
              <Skeleton variant="rectangular" height={20} width={180} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
              }}
            >
              <Skeleton variant="rectangular" height={70} width={300} />
              <Skeleton variant="rectangular" height={70} width={300} />
              <Skeleton variant="rectangular" height={70} width={300} />
              <Skeleton variant="rectangular" height={70} width={300} />
            </Box>
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export default PyramidSkeleton;
