import React from 'react';
import { makeStyles } from "@mui/styles";
import {FormControl, Select, MenuItem, InputLabel, TextField } from "@mui/material";
import DatePicker2 from '../DatePicker/DatePicker2';
import { createNumberArray } from '../../Utils/createNumberArray';

const useStyles = makeStyles((theme) => ({
    titleContainer:{
        borderBottom: "5px solid #eaeaea"
    },
    title:{
        color: "#e85e1e"
    },
    filterContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: "10px",
        margin: "10px 0px"
    },
  }));

const Filter = ({
    dateDatePickerFilter = false,
    dateRangeFilter = false,
    onFromDateChange,
    fromDate,
    onToDateChange,
    toDate,
    treatmentType,
    handleSetTreatmentType,
    fromMonthNumber,
    onFromMonthNumberChange,
    toMonthNumber,
    onToMonthNumberChange,
    monthFilterLength = 5,
    learnerFilter=  false,
    learnerType,
    handleLearnerTypeChange,
    minDate="2020-01-01",
    locationFilter = false,
    locationList, 
    location, 
    handleLocationChange
}) => {
    const classes = useStyles();
    // console.log("monthFilterLength",monthFilterLength)
    return (
        <div className={classes.filterContainer}>
            {
                locationFilter && <FormControl sx={{ m: 1, minWidth: '200px' }}>
                                        <InputLabel id="demo-simple-select-label">Select Locations</InputLabel>
                                        <Select
                                            value={location}
                                            onChange={(e) =>handleLocationChange(e?.target?.value)}
                                            sx={{width: '100%'}}
                                            labelId="demo-simple-select-label"
                                            MenuProps={{
                                                disableScrollLock: true,
                                                marginThreshold: null
                                            }}       
                                            id="demo-simple-select"    
                                            label="Choose Program" 
                                        >
                                            {
                                            locationList?.map((location, index) => {
                                                 return <MenuItem value={location} key={index}>{location === "all_location" ? "All Locations" :location}</MenuItem>
                                            }
                                            ) 
                                            }
                                        </Select>
                                    </FormControl>
            }
            {
                dateDatePickerFilter && <>  <DatePicker2 
                                                    views={["year", "month"]}
                                                    onChange={() => false}
                                                    onAccept={onFromDateChange}
                                                    value={fromDate}
                                                    label="From"
                                                    minDate={new Date(minDate)}
                                                    maxDate={new Date()}
                                                    renderInput={(params) => <TextField 
                                                                                {...params} 
                                                                                error={false} 
                                                                                placeholder="mm yyyy" // Set the placeholder text
                                                                                inputProps={{
                                                                                  ...params.inputProps,
                                                                                  placeholder: 'mm yyyy', // Set the placeholder text
                                                                                }}
                                                                               />}
                                                />
                                                <DatePicker2 
                                                    views={["year", "month"]}
                                                    onChange={() => false}
                                                    onAccept={onToDateChange}
                                                    value={toDate}
                                                    label="To"
                                                    minDate={new Date(minDate)}
                                                    maxDate={new Date()}
                                                    renderInput={(params) => <TextField 
                                                                                {...params} 
                                                                                error={false} 
                                                                                placeholder="mm yyyy" // Set the placeholder text
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    placeholder: 'mm yyyy', // Set the placeholder text
                                                                                }}
                                                                             />}
                                                />
                                        </>
            } 
            {   dateRangeFilter &&  <>
                                        <FormControl sx={{ m: 1, minWidth: '200px' }}>
                                            <InputLabel id="demo-simple-select-label">From Month</InputLabel>
                                            <Select
                                                value={fromMonthNumber}
                                                onChange={(e) =>onFromMonthNumberChange(e?.target?.value)}
                                                sx={{width: '100%'}}
                                                labelId="demo-simple-select-label"
                                                MenuProps={{
                                                    disableScrollLock: true,
                                                    marginThreshold: null,
                                                    PaperProps: { sx: { maxHeight: 300 } }
                                                }}       
                                                id="demo-simple-select"    
                                                label="Choose Program" 
                                            >
                                                {
                                                    createNumberArray(monthFilterLength).map((value,index) =>(
                                                        <MenuItem value={value} key={index}>{value}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ m: 1, minWidth: '200px' }}>
                                            <InputLabel id="demo-simple-select-label">To Month</InputLabel>
                                            <Select
                                                value={toMonthNumber}
                                                onChange={(e) =>onToMonthNumberChange(e?.target?.value)}
                                                sx={{width: '100%'}}
                                                labelId="demo-simple-select-label"
                                                MenuProps={{
                                                    disableScrollLock: true,
                                                    marginThreshold: null,
                                                    PaperProps: { sx: { maxHeight: 300 } }
                                                }}       
                                                id="demo-simple-select"    
                                                label="Choose Program" 
                                            >
                                                {
                                                    createNumberArray(monthFilterLength).map((value,index) =>(
                                                        <MenuItem value={value} key={index}>{value}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </>
            }
            { 
                learnerFilter &&  <FormControl sx={{ m: 1, minWidth: '200px' }}>
                                        <InputLabel id="demo-simple-select-label">Learner Type</InputLabel>
                                        <Select
                                            value={learnerType}
                                            onChange={(e) =>handleLearnerTypeChange(e?.target?.value)}
                                            sx={{width: '100%'}}
                                            labelId="demo-simple-select-label"
                                            MenuProps={{
                                                disableScrollLock: true,
                                                marginThreshold: null
                                            }}       
                                            id="demo-simple-select"    
                                            label="Choose Program" 
                                        >
                                            <MenuItem value={"learnerall"}>All</MenuItem>
                                            <MenuItem value={"learner0"}>Learner 1</MenuItem>
                                            <MenuItem value={'learner1'}>Learner 2</MenuItem>
                                        </Select>
                                </FormControl>
            }
            <FormControl sx={{ m: 1, minWidth: '200px' }}>
                <InputLabel id="demo-simple-select-label">Choose Program</InputLabel>
                <Select
                    value={treatmentType}
                    onChange={(e) =>handleSetTreatmentType(e?.target?.value)}
                    sx={{width: '100%'}}
                    labelId="demo-simple-select-label"
                    MenuProps={{
                        disableScrollLock: true,
                        marginThreshold: null
                    }}       
                    id="demo-simple-select"    
                    label="Choose Program" 
                >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={'Behavior'}>Behavior</MenuItem>
                    <MenuItem value={'Skill'}>Skill</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default Filter