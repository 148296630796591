import React, { useState, useEffect, useContext } from "react";
import dashlogo from "../../Assets/Images/dashboard-logo.png";
import services from "../../Services/patientServices";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { AuthContext } from "../../Contexts/authContext";
import { v4 as uuidv4 } from 'uuid';
import swal from "sweetalert";
import "./Aka.scss";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 500,
    color: "#78cbf2",
  }
}));

const Home = ()  => {
  const [userId, setUserId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const classes = useStyles();
  const history = useNavigate();

  useEffect(() => {
    // fetchPatientSets();
  }, []);

  const handleChange = (value) => {
    setUserId(value);
  };

  const handleSubmit = async () => {
    try {
      setIsFetching(true);
      const result = await services.akaAccess({
        user_id: userId,
      });
      const aka_id = result.data.aka_id;

      //Set aka id and aka session enabled params in localstorage
      localStorage.setItem("aka_id", aka_id);
      localStorage.setItem("aka_session_enabled", true);
      localStorage.setItem("aka_alert_open", true);
      localStorage.setItem('aka_session_id', uuidv4());

      //Store the old user (admin) data in local storage backup
      const current_user = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('backup_user', JSON.stringify({ ...current_user.user }));

      //Replace old user (admin) with the new user
      localStorage.setItem("user", JSON.stringify({
        isLoggedIn: true,
        user: result.data.user
      }));
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: result.data.user
      });      

      setIsFetching(false);
      history('/');
    }
    catch(error) {
      const { status, data: { message } } =  error.response;
      swal("Oops!", message, "error");
      swal(
        "Oops! Something went wrong.", 
        `HTTP Response Code ${status}: ${message}`, 
        "error"
      );
      console.log(error);
      setIsFetching(false);
    }
  };

  return (
    <div className="akapage DashboardOuter app-bg-color">
      <div className="aka-outer">
        <div className="aka-inner widget-bg-shadow">
          <div className="aka-logo">
            <img className="webLogo" src={dashlogo} alt="logo" />
          </div>
          <div className="aka-title">Please enter the desired value</div>
          <div className="aka-input mt-3">
            <input
              type="text"
              className="form-control"
              name="individual"
              value={userId}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
          </div>
          <div className="aka-submit mt-4">
            <button
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Home;
