import React, { useContext } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "./Header";
import MedicationsTable from "../Tables/WcchcDashboard/MedicationsTable";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    width: "100%",
    height: (props) => props.height,
    overflow: "hidden",
  },
  mainContainerOutline: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    width: "100%",
    height: "600px",
    border: "1px solid #FD0808",
    overflow: "hidden",
  },
}));

const Medications = ({ setMedicationsModal, attentive, setAttentive, showBorder }) => {
  const { state } = useContext(WcchcPatientSummaryContext);
  let height = state.tablesDataLength.medications ? "329px" : "200px";
  const classes = useStyles({height});

  return (
    <Box
      className={`${attentive ? classes.mainContainerOutline : classes.mainContainer} ${showBorder ? "selected-border": ""}`}
      id="medications"
    >
      <Header
        title="Medications"
        onClick={setMedicationsModal}
        disable={state.isFetchingMedications}
      />
      <MedicationsTable setAttentive={setAttentive} />
    </Box>
  );
};

export default Medications;
