import * as React from "react";
import { useState } from "react";
import {
  CssBaseline,
  Box,
  Toolbar,
  IconButton,
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import dashlogo from "../../Assets/Images/dashboard-logo.png";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import RESOURCE_LIST from './resource_list.json'

const resourcesList = RESOURCE_LIST;

export default function CommunityResourcesList({ handleClose }) {
  const [page, setPage] = useState(0);

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  return (
    <>
      <CssBaseline />
      <MuiAppBar
        position="fixed"
        sx={{
          zIndex: "1202",
          boxShadow: "none",
          borderBottom: "#0080B2 solid 1px",
        }}
      >
        <Toolbar>
          <img src={dashlogo} className="dash-logo pl-3" alt="logo" />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handleClose}>
              <HighlightOffSharpIcon sx={{ height: 25, width: 25 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </MuiAppBar>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ p: "30px 50px" }}>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer
                sx={{ maxHeight: 500, width: "100%", padding: "0 20px" }}
                className="recommendation-table"
              >
                <Table stickyHeader aria-label="sticky table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" width={'35%'}>Organization Name</TableCell>
                      <TableCell align="center">Services</TableCell>
                      <TableCell align="center">Address</TableCell>
                      <TableCell align="center">Contact Number</TableCell>
                      {/* <TableCell align="center">Services Cost</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resourcesList.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{data.providerName}</TableCell>
                        <TableCell align="center">{data.providedService}</TableCell>
                        <TableCell align="center">{data.address || '-'}</TableCell>
                        <TableCell align="center">{data.contactNumber || '-'}</TableCell>
                        {/* <TableCell align="center">
                          {data.service_cost || ''}
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={resourcesList.length}
                page={page}
                rowsPerPage={10}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
