import { useContext, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { AuthContext } from "../../Contexts/authContext";
import services from "../../Services/patientServices";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 500,
    color: "#78cbf2",
  },
}));

const AkaAccessListTable = ()  => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const history = useNavigate();

  const getUsers = async () => {
    try {
      setIsFetching(true);
      const { data } = await services.listUsersAkaAccess();
      setIsFetching(false);
      setData(data);
    } catch (error) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  // Aka access handler
  const handleSubmit = async (userId) => {
    try {
      setIsFetching(true);
      const result = await services.akaAccess({
        user_id: userId,
      });
      const aka_id = result.data.aka_id;

      //Set aka id and aka session enabled params in localstorage
      localStorage.setItem("aka_id", aka_id);
      localStorage.setItem("aka_session_enabled", true);
      localStorage.setItem("aka_alert_open", true);
      localStorage.setItem("aka_session_id", uuidv4());

      //Store the old user (admin) data in local storage backup
      const current_user = JSON.parse(localStorage.getItem("user"));
      localStorage.setItem(
        "backup_user",
        JSON.stringify({ ...current_user.user })
      );

      //Replace old user (admin) with the new user
      localStorage.setItem(
        "user",
        JSON.stringify({
          isLoggedIn: true,
          user: result.data.user,
        })
      );
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: result.data.user,
      });
      history("/");
      window.location.reload();
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      const {
        status,
        data: { message },
      } = error.response;
      swal("Oops!", message, "error");
      swal(
        "Oops! Something went wrong.",
        `HTTP Response Code ${status}: ${message}`,
        "error"
      );
      console.log(error);
      setIsFetching(false);
    }
  };

  const UserList = ({ data }) => (
    <>
      <div
        className="btnGroup aka-list-back-button"
        style={{ display: "flex" }}
      >
        <button
          className="btn print"
          onClick={() => setSelectedOrganization(null)}
        >
          <span style={{ color: "black" }} className="btnLabel">
            {" "}
            {"<- Back"}
          </span>
        </button>
      </div>
      <table className="table table-light">
        <thead>
          <tr>
            <th width="50%">
              <div className="headings-wrapper">User name</div>
            </th>
            <th width="50%">
              <div className="headings-wrapper">Access</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* List of non-admin users from selected organization FHS-admin can access */}
          {data
            ? data?.map((user, index) => (
                <tr key={index}>
                  <td width="60%">
                    <p className="patient-table-link">
                      {`${user?.first_name} ${user?.last_name}`}
                    </p>
                  </td>
                  <td width="40%">
                    <button
                      className="tblBtn aka-list-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(user?.user_id);
                      }}
                    >
                      Access
                    </button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </>
  );

  return (
    <div className="db-table-ar aka-list-table">
      {selectedOrganization ? (
        <UserList
          data={data?.users?.filter(
            (user) => user?.org_id === selectedOrganization
          )}
        />
      ) : (
        <table className="table table-light">
          <thead>
            <tr>
              <th width="50%">
                <div className="headings-wrapper">Organization Name</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* List of organization FHS admin can access */}
            {data?.organizations?.length ? (
              data?.organizations?.map((org, index) => (
                <tr key={index}>
                  <td width="50%">
                    <p
                      onClick={() => {
                        setSelectedOrganization(org?.org_id);
                      }}
                      className="patient-table-link"
                    >
                      {org?.name}
                    </p>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td width="100%">
                  Access permission for AKA has not been granted for any organization.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AkaAccessListTable;
