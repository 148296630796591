import React, { useEffect, useState, useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import CustomModal from "../../Modal/CustomModal";
import ProgressBar from "../../ProgressBar/ProgressBar";
import InterventionTable from "../../Tables/InterventionTable";
import eyeIcon from "../../../Assets/Images/eye.png";
import recommendationIcon from "../../../Assets/Images/recommendation.svg";
import { InterventionColorContext } from "../../../Contexts/interventionColor";
import { AnalyticsContext } from "../../../Contexts/analyticsContext";
import { toLowerCase, toPluralize } from "../../../Utils/stringOprations";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props.type === 'cost_analysis' ? "350px !important" : "75% !important"),
    position: "absolute !important",
    top: (props) => (props.type === "intervention" ? "-8%" : "22%"),
    left: "30%",
    "@media (max-width: 1023px)": {
    width: (props) => (props.type === 'cost_analysis' ? "270px !important" : "60% !important" ),
    left: "30%",
    },
    "@media (max-width: 768px)": {
      width: (props) => (props.type === 'cost_analysis' ? "150px !important" : "40% !important"),
      left: "54%",
      top: (props) => (props.type === "intervention" ? "-66%" : "-22%"),
      },
  },
  margin: {
    height: 100,
  },
}));

const PrettoSlider = styled(Slider)({
  color: "#a2df77",
  height: 8,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
      border: "2px solid #fff407 !important",
    }
  },
  '& .MuiSlider-valueLabel': {
    right: "0px !important",
    top: "28px !important",
    background: "unset",
    fontSize: "12px",
    '& > *': {
      background: "transparent",
      color: "#000",
       "@media (max-width: 767px)": {
         position: "relative",
         left: "-3px",
         top: "-1px"
       },    
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-mark': {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  '& .MuiSlider-markActive': {
    opacity: 1,
    backgroundColor: "currentColor",
  },
});

const CustomizedSlider = ({
  id,
  abbr,
  type,
  minElig,
  maxElig,
  sliderValue,
  setSliderValue,
  label,
  tabledata,
  sliderPreviousValue,
  tooltip,
  topWidget,
  mnemo,
  intrv_type,
  rtype
}) => {
  const { toggleColor } = useContext(InterventionColorContext);
  const { patientSetInfo, analysisType } = useContext(PatientSetContext);

  const [value, setValue] = useState(0);
  const [modal, setModal] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [newMnemo, setNewMnemo] = useState(true);
  const { event } = useContext(AnalyticsContext);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    setSliderValue(newValue, newValue, id);
    toggleColor(true);
  };
  
  useEffect(() => {
    setValue(sliderPreviousValue);
  }, [sliderPreviousValue]);

  const classes = useStyles({ type, analysisType });

  const renderSlider =
    type === "intervention" ? (
      //Render Intervention Slider
      <PrettoSlider
        valueLabelDisplay="on"
        aria-label="pretto slider"
        defaultValue={0}
        value={value}
        onChange={handleChange}
        //onChangeCommitted={handleChangeCommitted}
        min={minElig}
        max={maxElig}
      />
    ) : (
      //Render Diagnosis Slider
      <PrettoSlider
        valueLabelDisplay="on"
        aria-label="pretto slider"
        defaultValue={0}
        min={minElig}
        max={maxElig}
        value={sliderValue}
      />
    );

  //when openModal is triggered, the data will be fetched from the API, which will be passed to the modal & intervention table both as prop.
  const openModal = () => {
    setModal(true);
    setIsIndividual(true);
    setNewMnemo(mnemo)

    //GA4 - Track click intervention eye
    event('click_intervention_eye' , {});
  };

  const closeModal = (close) => {
    setModal(close);
  };

  const recommendationOpenModal = () => {
    setModal(true);
    setIsIndividual(false);
    setNewMnemo("recommand")


    //GA4 - Track click intervention eye
    event('click_intervention_recommendation' , {});
  };

  return (
    <>
      <div className={classes.root} style={{width: analysisType === 'quality_analysis' && '75% !important'}}>
        {type === "intervention" ? (
          <ProgressBar max={maxElig} value={sliderValue} />
        ) : null}

        <div
          className={`${type === "intervention" ? "bg-active" : "bg-inactive"}`}
        >
          <Tooltip
            title={tooltip}
            placement="top"
            arrow
          >
            {renderSlider}
          </Tooltip>
          <label className="valueLeftBottom">0</label>
          <label className="valueRightBottom">{maxElig}</label>
        </div>

        <div className={`${topWidget ? `top-widget` : null} valueOuter clearfix`}>
          <button>
            <Tooltip
               title={`Click to view ${toPluralize(toLowerCase(patientSetInfo?.patient_name))} interventions`}
              placement="top"
              arrow
            >
                <img src={eyeIcon} alt="eye icon" onClick={openModal} className="eye-icon"/>
            </Tooltip>
          </button>
          {rtype === "intervention" && <button style={{position:"absolute", right:"-50px"}}>
            <Tooltip
              title={`${toPluralize(patientSetInfo?.patient_name)} recommended for ${label.toLowerCase()}`}
              placement="top"
              arrow
            >
              <img src={recommendationIcon} alt="recommendation icon" onClick={recommendationOpenModal} className="eye-icon"/>
            </Tooltip>
          </button> }
        </div>
      </div>
      {modal && (
        <CustomModal
          show={modal}
          close={closeModal}
          label={label}
          abbr={abbr}
          type="intervention"
          mnemo={newMnemo}
          is_individual={isIndividual}
        >
          <InterventionTable is_individual={isIndividual} contr_type={intrv_type} type="intervention" tabledata={tabledata} />
        </CustomModal>
      )}
    </>
  );
};
export default CustomizedSlider; 
