/**
 * To captalize the string
 * @param {*} str 
 * @returns 
 */
const toCapitalize = (str) => {
  if (str) {
    // Split the string into an array of words
    const words = str.split(' ');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words back into a string
    const capitalizedString = capitalizedWords.join(' ');

    return capitalizedString;
  }
  return ""
}

/**
 * For string lowercase
 * @param {*} str 
 * @returns 
 */
const toLowerCase = (str) => {
  return str ? str.toLowerCase() : "";
}

/**
 * Convert singular to plural string
 * @param {*} word 
 * @returns 
 */
const toPluralize = (word )=> {
  /*
  Converts a singular word to its plural form.
  */
  const irregularPlurals = {
      'man': 'men',
      'woman': 'women',
      'child': 'children',
      'tooth': 'teeth',
      'foot': 'feet',
      'mouse': 'mice',
      'goose': 'geese',
      'ox': 'oxen',
      'deer': 'deer',
      'fish': 'fish',
      'sheep': 'sheep'
  };

  if (word in irregularPlurals) {
      return irregularPlurals[word];
  } else if (word.endsWith('y') && !word.endsWith('ay') && !word.endsWith('ey') &&
      !word.endsWith('iy') && !word.endsWith('oy') && !word.endsWith('uy')) {
      return word.slice(0, -1) + 'ies';
  } else if (word.endsWith('s') || word.endsWith('x') || word.endsWith('z') ||
      word.endsWith('sh') || word.endsWith('ch')) {
      return word + 'es';
  } else {
      return word + 's';
  }
}

export {
  toCapitalize,
  toLowerCase,
  toPluralize
}