import { useState } from "react";
import {
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { RecommendationTabs } from "../InsightsAndRecommendation";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
// import SquareIcon from "@mui/icons-material/Square";
// import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";

function InsightsAndRecommendationModal({ openModal, handleCloseModal, tabs }) {
  const [currentTab, setCurrentTab] = useState("diag_contrib");
  // const [view, setView] = useState("tabs");
  const history = useNavigate();
  const view = "tabs";

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // const handleViewChange = (view) => {
  //   setView(view);
  // };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={openModal}
      onClose={handleCloseModal}
      className="cohort-tabs"
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {"Population Health Insights"}
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: "0px 24px" }}>
        {view === "tabs" ? (
          <RecommendationTabs
            handleTabChange={handleTabChange}
            currentTab={currentTab}
            openModal={openModal}
            tabs={tabs}
          />
        ) : (
          <Grid container>
            <Grid item xs={6} p={1}>
              <RecommendationTabs currentTab={"diag_contrib"} tabs={tabs} />
            </Grid>
            <Grid item xs={6} p={1}>
              <RecommendationTabs currentTab={"sdoh_contrib"} tabs={tabs} />
            </Grid>
            <Grid item xs={6} p={1}>
              <RecommendationTabs currentTab={"intervention"} tabs={tabs} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {/* <IconButton onClick={() => handleViewChange("tabs")}>
          <SquareIcon co sx={{ height: 30, width: 30 }} />
        </IconButton>
        <IconButton onClick={() => handleViewChange("grid")}>
          <GridViewSharpIcon sx={{ height: 30, width: 30 }} />
        </IconButton> */}
        <Link
          onClick={() =>
            history(
              currentTab === "diag_contrib" || currentTab === "sdoh_contrib"
                ? "/contributors"
                : "/interventions"
            )
          }
          sx={{ display: "flex", cursor: 'pointer' }}
        >
          View Details
          <KeyboardDoubleArrowRightSharpIcon />
        </Link>
      </DialogActions>
    </Dialog>
  );
}

export default InsightsAndRecommendationModal;
