import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import genesisServices from "../../Services/genesisServices";
import "./GenesisIframe.scss";
import { formateDate2 } from "../../Utils/formatDate";
import Filter from "../../Components/Genesis/Filter";
import LoaderWithTimer from "./LoaderWithTimer";
import ErrorPage from "./ErrorPage";
import "./GenesisIframe.scss";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    borderBottom: "5px solid #eaeaea",
  },
  title: {
    color: "#e85e1e",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "10px",
    margin: "20px 0px",
  },
  toolTipContainer: {
    backgroundColor: "#000",
  },
}));
//4088
//3919
const CumulativeTrendChart = ({clientId = 3919}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [treatmentType, setTreatmentType] = useState("All");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [finalData, setFinalData] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [minDate, setMinDate] = useState("")

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Cumulative total objective %",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            // download: true,
          },
          export: {
            csv: {
              filename: "cumulative-trend-chart",
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            png: {
              filename: "cumulative-trend-chart",
            }
          },
          autoSelected: 'zoom' 
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${Math.trunc(val)}%`;
        },
        position: "top",
        offsetY: -30,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
          padding: 10
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        title: {
          text: "Treatment month",
        },
        categories: [],
        labels: {
          formatter: (val) => formateDate2(val),
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        title: {
          text: "Total Objective %",
        },
        labels: {
          formatter: (val) => Math.trunc(val),
        },
        min: 0,
        max: 100,
        tickAmount: 2,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return (
            "<div>" +
            '<div class="toolTipHeader2">' +
            formateDate2(data?.x) +
            '</div><div class="tooltipInnerContainer"><div class="itemRowInToolTip"><span class="blue-dot"></span><span>Cumulative Total Objective%</span>' +
            "<span>" +
            data?.y?.toFixed(2) +
            "</span>" +
            '</div><div class="itemRowInToolTip"><span class="blue-dot"></span><span>Cumulative Goals Achieved</span>' +
            "<span>" +
            data?.cumulative_met +
            "</span>" +
            '</div><div class="itemRowInToolTip"><span class="blue-dot"></span><span>Cumulative Goals Assigned</span>' +
            "<span>" +
            data?.cumulative_total_objectives +
            "</span>" +
            "</div></div></div>"
          );
        },
        y: {
          formatter: function (val) {
            return `${val?.toFixed(2)}%`;
          },
        },
      },
      grid:{
        padding: {
          right: 10
        }
      }
    },
  });

  const convertArrayDataIntoGraphData = (data) => {
    let data2 = [...data];
    let convertedData = [];
    convertedData = data2.map(
      ({ final_date, cum_total_percentage, ...restData }) => {
        return {
          x: final_date,
          y: cum_total_percentage,
          ...restData,
        };
      }
    );
    return convertedData;
  };

  const handleChartDataChange = (data) => {
    setChartData({
      ...chartData,
      series: [{ ...chartData.series[0], data: data }],
    });
  };

  const applyFilter = () => {
    let data = [...finalData[treatmentType]];
    // to filter in from date is selected
    if (fromDate) {
      data = data.filter(({ final_date }) => {
        // eslint-disable-next-line
        let date = new Date(`${final_date}-01`).setHours(0, 0, 0, 0);
        let date2 = new Date(fromDate).setHours(0, 0, 0, 0);
        return date >= date2;
      });
    }
    // to filter if end date is selected
    if (toDate) {
      data = data.filter(({ final_date }) => {
        // eslint-disable-next-line
        let date = new Date(`${final_date}-01`).setHours(0, 0, 0, 0);
        let date2 = new Date(toDate).setHours(0, 0, 0, 0);
        return date <= date2;
      });
    }
    const transformedData = convertArrayDataIntoGraphData(data);
    handleChartDataChange(transformedData);
  };

  const handleSetTreatmentType = (value) => {
    setTreatmentType(value);
  };

  const onFromDateChange = (date) => {
    setFromDate(date);
  };

  const onToDateChange = (date) => {
    setToDate(date);
  };

  const setMinDateValue = (data) =>{
      let minValue = data['All']?.[0];
      let minDate = `${minValue?.final_date.split('-')[0]}-01-01`;
      setMinDate(minDate);
  }

  const getCumulativetrenndData = async (clientId) => {
    try {
      setLoader(true);
      const response = await genesisServices.getCumulativeTrendProgress(clientId);
      console.log("<======response=======>",response)
      let responseData = response.data;
      console.log("<===responseData===>", responseData);
      if (typeof responseData === 'string') {
        try {
          responseData = responseData.replace(/NaN/g,0)
          responseData = JSON.parse(responseData);
        } catch (e) {
          console.error('Failed to parse response data as JSON', e);
          setError(true);
          setError('Failed to parse response data as JSON');
          return;
        }
      }
      const responseData2 = responseData.data;
      console.log("<===responseData2 before destructuring===>", responseData2);
      const { All } = responseData2;
      const transFormedData = convertArrayDataIntoGraphData(All);
      handleChartDataChange(transFormedData);
      setFinalData(responseData2);
      setMinDateValue(responseData2)
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getCumulativetrenndData(clientId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(finalData).length !== 0) {
      applyFilter();
    }
  }, [fromDate, toDate, treatmentType]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("Chart Data in Cumulative Trend", chartData);
  return (
    <div>
      {loader ? (
        <LoaderWithTimer />
      ) : error ? (
        <ErrorPage errorMessage={errorMessage} />
      ) :(  
            <div className="main-container">
              <div className="chart-container">
                <div className={classes.titleContainer}>
                  <h4 className={classes.title}>
                    Client Progress Towards All Goals
                  </h4>
                </div>
                <Filter
                  dateDatePickerFilter={true}
                  onToDateChange={onToDateChange}
                  toDate={toDate}
                  onFromDateChange={onFromDateChange}
                  fromDate={fromDate}
                  treatmentType={treatmentType}
                  handleSetTreatmentType={handleSetTreatmentType}
                  minDate={minDate}
                />
                {  
                  chartData?.series[0]?.data?.length === 0 ?
                    <div className="empty-container">
                      <h6 className="empty-container-text">No Data Available.</h6>
                    </div>
                    :
                    <ReactApexChart
                      options={chartData?.options}
                      series={chartData?.series}
                      type="bar"
                      height={400}
                    />
                }
              </div>
            </div>
          )}
    </div>
  );
};

export default CumulativeTrendChart;
