import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MenuItem, Select } from "@mui/material";

const checkAttributeType = (attr_values) => {
  let val = "";
  attr_values?.forEach(({ attr_value }) => {
    if (!isNaN(attr_value)) {
      val = "numerical";
    }
  });
  return val ? val : "categorical";
};

const CustomAttributeChart = (props) => {
  const { data, attr_values, label, initialSelect } = props;
  const attr_type = checkAttributeType(attr_values);

  const [isCumulative, setIsCumulative] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(initialSelect);
  const [options, setOptions] = useState();

  const optionsRef = useRef({
    plotOptions: { bar: { columnWidth: "16%" } },
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: "smooth",
      width: [5, 2],
      colors: ["#3B9CF8", "#EE7D31"],
    },
    markers: {
      size: 5,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          return y;
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.8,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: 'Patient Count',
        style: {
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
    labels: label,
  });

  useEffect(() => {
    let series = null;
    let width = null;
    let point = isCumulative ? "cumulativeCount" : "count";
    if (attr_type === "categorical") {
      series = Object.keys(data).map((key) => ({
        name: key,
        data: data[key].map((val) => val?.[point]),
      }));
      width = Object.keys(data).map((key) => {
        return key === selectedAttribute ? 5 : 2;
      });
      setOptions({ series });
    } else {
      width = [5];
      series = [{ data: data[selectedAttribute].map((val) => val?.[point]), name: selectedAttribute }];
    }
    optionsRef.current = {
      ...optionsRef.current,
      stroke: {
        width: width,
        curve: "smooth",
      },
    };
    setOptions({ series });
  }, [data, selectedAttribute, isCumulative, attr_type, initialSelect]);

  return (
    <>
      <Select
        value={selectedAttribute}
        disableUnderline={true}
        onChange={(e) => {
          e.preventDefault();
          setSelectedAttribute(e.target.value);
        }}
        variant="standard"
        sx={{ display: "flex", float: "right", bottom: "25px" }}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 200 } },
          disableScrollLock: true,
        }}
      >
        {attr_values.map(({ attr_value }) => (
          <MenuItem value={attr_value} key={attr_value}>
            {attr_value}
          </MenuItem>
        ))}
      </Select>
      <div id="chart" className="custom_attr_chart">
        {options && (
          <Chart
            options={optionsRef.current}
            series={options.series}
            height={230}
            max-width={500}
          />
        )}
        <FormControlLabel
          sx={{ marginTop: "10px" }}
          control={
            <Checkbox
              checked={isCumulative}
              onChange={(e) => setIsCumulative(e.target.checked)}
              style={{
                color: "#0080b2",
              }}
            />
          }
          label={"Cumulative"}
        />
      </div>
    </>
  );
};

export default CustomAttributeChart;
